<div class="step2" *ngIf="isLoading">
    <div class="widget__wrapper">
        <div class="widget__header">
            <div class="widget__header-left">
                <h2>Получаем данные</h2><span>Это может занять несколько секунд</span>
                <br><br>
            </div>
            <div class="widget__header-right">
            </div>
        </div>
        <div class="widget__loader active"></div>
    </div>
</div>

<div [hidden]="isLoading" class="step2" [ngClass]="{ 'widget__edit': edit }">
    <div class="widget__wrapper">
        <div class="widget__header scroll-target">
            <div class="widget__header-title" id="vendors-anchor">
                <div class="widget__header-title-text">Данные авто</div>
            </div>
            <div *ngIf="!fromUrlOrSms"
                 [class.widget__header-number]="!edit"
                 [class.widget__header-change]="edit">
                <p>{{ license | licenseNumber }}</p>
                <span (click)="isShowLicenseModal = true">{{ changeLicenseTitle }}</span>
            </div>
        </div>
        <form id="vehicleForm" *ngIf="vehicleForm" [formGroup]="vehicleForm">
            <div class="widget__body">
                <div class="widget-input-full">
                    <app-alert>
                        <p>Заполните информацию ниже, она формирует предварительную стоимость ОСАГО для вашего автомобиля с учетом города регистрации</p>
                    </app-alert>
                </div>
                <div class="widget-input-inner-dropdown">
                    <app-select-autocomplete
                        fieldName="Марка"
                        [fieldControl]="brand"
                        fieldId="brand"
                        [options]="brands"
                        [isReadonly]="false"
                        helperText="Выберите марку или начните вводить название"
                        (changed)="onBrandChange($event)"
                        #brandComponent>
                    </app-select-autocomplete>
                </div>
                <div class="widget-input-inner-dropdown">
                    <app-select-autocomplete
                        fieldName="Модель автомобиля"
                        [fieldControl]="model"
                        fieldId="model"
                        [options]="(models$ | async)"
                        [isReadonly]="brandSelected == false"
                        [isLoading]="modelsLoading == true"
                        helperText="Выберите модель или начните вводить название"
                        (changed)="onModelChange($event)"
                        #modelComponent>
                    </app-select-autocomplete>
                </div>
                <div class="widget-input-inner">
                    <app-input-numeric
                        [numericMaxLength]="4"
                        [fieldControl]="productionYear"
                        fieldId="production-year"
                        fieldName="Год выпуска"
                        helperText="Укажите год выпуска вашего автомобиля"
                        (changed)="onProductionChange($event)">
                    </app-input-numeric>
                </div>
                <div class="widget-input-inner">
                    <app-input-numeric
                        numericThousandSeparator=" "
                        [numericMaxLength]="4"
                        [fieldControl]="horsePower"
                        [decimalPlaces]="2"
                        decimalSeparator="."
                        fieldId="horse-power"
                        fieldName="Мощность"
                        helperText="Укажите мощность Вашего автомобиля в л.с."
                        (changed)="onHorsePowerChange($event)"
                        #carPowerComponent>
                    </app-input-numeric>
                </div>
<!--                <div class="widget-input-inner widget-input-full">-->
<!--                    <app-input-->
<!--                        [fieldControl]="city"-->
<!--                        fieldId="city"-->
<!--                        fieldName="Город регистрации собственника"-->
<!--                        helperText="Город регистрации влияет на стоимость ОСАГО"-->
<!--                        (input)="onCityChanged($event)">-->
<!--                    </app-input>-->
<!--                </div>-->
            </div>
            <!--DOCUMENTS-->
            <div class="step5" *ngIf="!isLoading">
                <div class="widget__body">
                    <div class="widget-input-inner-dropdown">
                        <app-select
                            [fieldControl]="documentType"
                            [options]="documentTypes"
                            fieldId="documentType"
                            helperText="Выберите тип документа на автомобиль"
                            fieldName="СТС / ПТС / ЕПТС">
                        </app-select>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedDocument != 1)">
                        <app-input
                            [fieldControl]="stsHide"
                            [isHidden]="(selectedDocument != 1)"
                            fieldId="stsHide"
                            fieldName="Серия и номер СТС"
                            [textMask]="{ mask: masks.sts, guide: true }">
                            <div class="widget__icon"
                                 [class.hovered]="seriesNumberStsPopupOpen"
                                 (click)="seriesNumberStsPopupOpen = true"
                                 #seriesNumberStsPopup>
                                <img class="info-icon" class="info-icon" src="./assets/images/icons/info.svg" alt="">
                                <div class="widget__helper widget__helper-right"><img src="/assets/images/sts.png"/>
                                </div>
                            </div>
                        </app-input>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedDocument != 1)">
                        <app-input-date
                            [fieldControl]="stsDate"
                            fieldId="stsDate"
                            [modifier]="'widget-input-full'"
                            [isHidden]="(selectedDocument != 1)"
                            [checkFill]="true"
                            [changeYear]="true"
                            [showIcon]="false"
                            [maxDate]="maxDate"
                            fieldName="Дата выдачи СТС">
                            <div class="widget__icon"
                                 [class.hovered]="stsDatePopupPopupOpen"
                                 (click)="stsDatePopupPopupOpen = true"
                                 #stsDatePopup>
                                <img class="info-icon" src="./assets/images/icons/info.svg" alt="">
                                <div class="widget__helper widget__helper-right">
                                    <img src="/assets/images/datests.png"/>
                                </div>
                            </div>
                        </app-input-date>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedDocument != 0)">
                        <app-input
                            [fieldControl]="pts"
                            [isHidden]="(selectedDocument != 0)"
                            fieldId="pts"
                            fieldName="Серия и номер ПТС"
                            [textMask]="{ mask: masks.pts, guide: true }">
                            <div class="widget__icon"
                                 [class.hovered]="seriesNumberPtsPopupOpen"
                                 (click)="seriesNumberPtsPopupOpen = true"
                                 #seriesNumberPtsPopup>
                                <img class="info-icon" src="./assets/images/icons/info.svg" alt="">
                                <div class="widget__helper widget__helper-right">
                                    <img src="/assets/images/pts-number.png"/>
                                </div>
                            </div>
                        </app-input>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedDocument != 0)">
                        <app-input-date
                            [fieldControl]="ptsDate"
                            fieldId="ptsDate"
                            [modifier]="'widget-input-full'"
                            [checkFill]="true"
                            [isHidden]="(selectedDocument != 0)"
                            [maxDate]="maxDate"
                            [changeYear]="true"
                            [showIcon]="false"
                            fieldName="Дата выдачи ПТС">
                            <div class="widget__icon"
                                 [class.hovered]="ptsDatePopupOpen"
                                 (click)="ptsDatePopupOpen = true"
                                 #ptsDatePopup>
                                <img class="info-icon" src="./assets/images/icons/info.svg" alt="">
                                <div class="widget__helper widget__helper-right">
                                    <img src="/assets/images/pts-date.png"/>
                                </div>
                            </div>
                        </app-input-date>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedDocument != 2)">
                        <app-input
                            [fieldControl]="epts"
                            [isHidden]="(selectedDocument != 2)"
                            fieldId="epts"
                            fieldName="Серия и номер еПТС"
                            [textMask]="{ mask: masks.epts, guide: true }">
                        </app-input>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedDocument != 2)">
                        <app-input-date
                            [fieldControl]="eptsDate"
                            fieldId="eptsDate"
                            [modifier]="'widget-input-full'"
                            [checkFill]="true"
                            [isHidden]="(selectedDocument != 2)"
                            [maxDate]="maxDate"
                            [changeYear]="true"
                            [showIcon]="false"
                            fieldName="Дата выдачи еПТС">
                        </app-input-date>
                    </div>
                    <div class="widget-input-inner-dropdown">
                        <app-select
                            [fieldControl]="identifier"
                            [options]="identifiers"
                            fieldId="identifier"
                            fieldName="VIN / Шасси / Кузов">
                        </app-select>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedIdentifier != 0)">
                        <app-input
                            [fieldControl]="vinHide"
                            [isHidden]="(selectedIdentifier != 0)"
                            fieldId="vinHide"
                            fieldName="Номер VIN"
                            [textMask]="{ mask: masks.vin, guide: true }">
                            <div class="widget__icon"
                                 [class.hovered]="vinPopupOpen"
                                 (click)="vinPopupOpen = true"
                                 #vinPopup>
                                <img class="info-icon" src="./assets/images/icons/info.svg" alt="">
                                <div class="widget__helper widget__helper-right">
                                    <img src="/assets/images/vin.png"/>
                                </div>
                            </div>
                        </app-input>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedIdentifier != 1)">
                        <app-input
                            [fieldControl]="bodyNumber"
                            [isHidden]="(selectedIdentifier != 1)"
                            [maxLength]="20"
                            fieldId="bodyNumber"
                            fieldName="Номер кузова">
                            <div class="widget__icon">
                                <img class="info-icon" src="./assets/images/icons/info.svg" alt="">
                                <div class="widget__helper">
                                    <img src="/assets/images/body-number.png"/>
                                </div>
                            </div>
                        </app-input>
                    </div>
                    <div class="widget-input-inner"
                         [class.widget-input-inner-hidden]="(selectedIdentifier != 2)">
                        <app-input
                            [fieldControl]="chassisNumber"
                            [isHidden]="(selectedIdentifier != 2)"
                            [maxLength]="20"
                            fieldId="chassisNumber"
                            fieldName="Номер шасси">
                            <div class="widget__icon">
                                <img class="info-icon" src="./assets/images/icons/info.svg" alt="">
                                <div class="widget__helper widget__helper-right">
                                    <img src="/assets/images/shassi.png"/>
                                </div>
                            </div>
                        </app-input>
                    </div>
                </div>
            </div>
            <!--END DOCUMENTS-->
        </form>

        <div class="price-container">
            <div class="row">
                <div class="price-title">Предварительная цена</div>
                <div class="podeli-alert">
                    <img src="/assets/images/podeli-alert.svg" alt="Podeli">
                    <div class="alert-window">
                        <img src="/assets/images/podeli-alert-img.png" alt="Podeli">
                    </div>
                </div>
            </div>
            <div class="price-text">
                Для точного расчета и покупки полиса потребуются сведения
                по водительским правам и паспорту собственника
            </div>

            <div class="price-values-control">
                <app-podeli-price [horsePower]="horsePower.value" [hasNoLicencePlate]="license !== undefined"></app-podeli-price>
                <div class="buttonMob">
                    <app-button
                        modifier="blue-button"
                        buttonType="submit"
                        (click)="onSubmit()">
                        {{ submitCaption }}
                    </app-button>
                </div>
            </div>
        </div>

<!--        <div class="widget__footer">-->
<!--            <div class="widget__footer-number">-->
<!--                <div class="widget__footer-number-text" *ngIf="formValid">Предварительная стоимость полиса (без-->
<!--                    учета стажа водителя):-->
<!--                </div>-->
<!--                <div class="widget__footer-empty" *ngIf="!formValid">Рассчитаем после заполнения всех полей</div>-->
<!--                <div class="widget__footer-amount" *ngIf="formValid">-->
<!--                    <div class="widget__footer-amount-text">от {{ price | currencyRU }}</div>-->
<!--                    <div class="widget__icon">-->
<!--                        <img class="info-icon" src="./assets/images/icons/info.svg" alt="">-->
<!--                        <div class="widget__helper widget__helper-down">-->
<!--                            <p>Стоимость посчитана:</p>-->
<!--                            <ul>-->
<!--                                <li><span class="dot">•</span>для <span>указанного авто</span></li>-->
<!--                                <li><span class="dot">•</span>с учетом скидки <span>КБМ 50%</span></li>-->
<!--                            </ul>-->
<!--                            <p>Мы уточним цену после ввода данных водителей</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="buttonMob">-->
<!--                <app-button-->
<!--                    modifier="blue-button"-->
<!--                    buttonType="submit"-->
<!--                    (click)="onSubmit()">-->
<!--                    {{ submitCaption }}-->
<!--                </app-button>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="widget__check">-->
<!--            <label>-->
<!--                <input type="checkbox" checked="true" (change)="onPurposeChange($event)">-->
<!--                <p>Цель использования - личная-->
<!--                    <span>(в прокат, аренду не сдается, в качестве такси не используется)</span></p>-->
<!--                <div class="errorAlert">В настоящее время можно оформить полис только на личный автомобиль</div>-->
<!--            </label>-->
<!--        </div>-->
    </div>
</div>

<ng-container *ngIf="isShowLicenseModal">
    <div class="popupBg popupBg-active"></div>
    <div class="popupMain popupMain-active">
        <app-license
            [editMode]="(true)"
            (close)="isShowLicenseModal = false;">
        </app-license>
    </div>
</ng-container>
