import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaSettings, RECAPTCHA_SETTINGS, RECAPTCHA_BASE_URL } from 'ng-recaptcha';

import {
    DeniedComponent,
    EmptyComponent,
    ErrorComponent,
    FailedPaymentComponent,
    SuccessComponent } from './components';

import {
    ApplicationService,
    ApplicationMockService,
    GlobalErrorHandler,
    HttpErrorInterceptor,
    LicensePlateService } from './services';

import { AppRoutingModule } from './app-routing.module';
import { LazyLoaderModule } from './modules/lazy-loader/lazy-loader.module';
import { SharedModule } from './modules/shared/shared.module';
import { ClientService } from './services/client.service';
import { OsagoModule } from './modules/osago/osago.module';
import { LoggingService } from './services/loggingService';
import { ClientMockService } from './services/client.mock.service';

@NgModule({
    declarations: [
        AppComponent,
        DeniedComponent,
        EmptyComponent,
        ErrorComponent,
        FailedPaymentComponent,
        SuccessComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        RecaptchaV3Module,
        LazyLoaderModule,
        SharedModule,
        AppRoutingModule,
        OsagoModule
    ],
    providers: [
        environment.mock
            ? {provide: ClientService, useClass: ClientMockService}
            : ClientService,
        LicensePlateService,
        LoggingService,
        environment.mock
            ? {provide: ApplicationService, useClass: ApplicationMockService}
            : ApplicationService,
        {provide: APP_BASE_HREF, useValue: '/'},
        {provide: RECAPTCHA_SETTINGS, useValue: {size: 'invisible'} as RecaptchaSettings},
        {provide: RECAPTCHA_BASE_URL, useValue: 'https://recaptcha.net/recaptcha/api.js'},
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeqedIbAAAAAKBil2D4gh6UjKUEIOV58Xmwf_hi'},
        Location,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy},
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            // interceptor for HTTP errors
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true // multiple interceptors are possible
        }
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
