import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/components/base/base.component';

@Component({
    selector: 'app-license-region',
    templateUrl: './license-region.component.html'
})
export class LicenseRegionComponent extends BaseComponent {
    @Input() maxLength = 15;
    @Input() fieldType = 'tel';
    @Input() numericThousandSeparator = '';
    @Input() numericMaxLength = 0;
    @Output() changed = new EventEmitter<void>();
    @HostListener('keydown', ['$event'])

    // Событие нажатия
    public onKeyDown(event: any): void {
        if (event.key === 'ArrowLeft' || event.key === 'Backspace') {
            const position = this.getCaretPosition(event.target);
            if (position === 0) {
                this.moveLeft.emit();
                if (event.key === 'ArrowLeft') {
                    event.preventDefault();
                }
            }
        }
    }

    // Событие ввода
    public onInput(event: any): void {
        if (event.target.value !== null && event.target.value.length > 1) {
            this.currentValue = event.target.value;
        } else if (event.target.value != null && event.target.value.length === 1) {
            this.currentValue = '0' + event.target.value;
        }
    }
}
