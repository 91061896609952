<ng-container
    *ngIf="isSubmitting == true; then loaderTemplate; else licenseTemplate">
</ng-container>

<ng-template #licenseTemplate>
    <div id="license-anchor" class="step1 scroll-target" [ngClass]="{ 'widget__edit': edit }">
        <div class="widget__container">
            <div class="widget__content">
<!--                <div class="widget__intro" *ngIf="!edit">-->
<!--                    <h2 class="desktop">ОСАГО онлайн<br>-->
<!--                        c экономией до <span>55%</span></h2>-->
<!--                    <h2 class="mobile">ОСАГО онлайн<br>-->
<!--                        c экономией до 55%</h2>-->
<!--                    <p>Введите номер авто, чтобы узнать предварительную <br>-->
<!--                        стоимость полиса, данные автомобиля мы заполним за вас</p>-->
<!--                </div>-->
                <div class="widget__topInfo" *ngIf="!edit">
                    <div class="title-landing">
                        <p>Введите номер авто</p>
                    </div>
                    <span class="without-number" (click)="onManualFill($event)">Рассчитать без номера</span>

                    <div class="widget__introText-landing">
                    </div>
                </div>

                <div class="title-mobile" *ngIf="edit">
                    Изменить гос. номер
                </div>
                <p class="post-title-mobile" *ngIf="edit">Введите номер авто и остальные данные<br>
                    мы обновим автоматически</p>

                <div class="widget__topForm">
                    <div 
                        [class.keyboard-info]="!hasCustomKeybordStyles()"
                        [class.keyboard-info-custom]="hasCustomKeybordStyles()"
                        *ngIf="!edit">
                        убедитесь что включена русская раскладка клавиатуры
                    </div>
                    <div class="topForm-container">
                        <div class="widget__topForm-input" [ngClass]="{ 'error-text': isError || isSuspended}">
                            <div class="widget__topForm-input-inner">
                                <app-license-number
                                    fieldId="number"
                                    (filled)="onNumberFilled()"
                                    fieldName="Номер"
                                    #numberRef>
                                </app-license-number>
                            </div>
                            <div class="widget__topForm-input-inner">
                                <app-license-region
                                    [numericMaxLength]="3"
                                    placeHolder="999"
                                    fieldId="region"
                                    (moveLeft)="onRegionMoveLeft()"
                                    fieldName="Регион"
                                    #regionRef>
                                </app-license-region>
                            </div>
                            <div class="error-content" *ngIf="isError == true">Укажите номер автомобиля</div>
                            <div class="error-content" *ngIf="isSuspended == true">Повторный расчёт по номеру через {{ suspendedCounter }}</div>
                        </div>
                    </div>
                    <div class="button-container">
                        <app-button
                            buttonType="submit"
                            modifier="blue-button"
                            [isDisabled] ="isSubmitting == true"
                            (clicked)="onSubmit()">
                            Рассчитать стоимость
                        </app-button>
                        <app-button
                            *ngIf="edit"
                            buttonType="submit"
                            modifier="border-button"
                            (clicked)="close.emit(true)">
                            Закрыть
                        </app-button>
                    </div>
                    <div class="widget__introFooter" *ngIf="!edit">
                        <span (click)="onManualFill($event)">
                            Рассчитать без номера
                        </span>
                    </div>
                </div>
            </div>
<!--            <div class="widget__image" *ngIf="!edit">-->
<!--                <img [attr.src]="carImageSource" alt="Widget Car">-->
<!--            </div>-->
        </div>
        <div class="swiper-block">
            <div class="swiper-hidden-block">&nbsp;</div>
            <div class="widget__auto-container" *ngIf="osagoPolicies && osagoPolicies.length > 0">
                <swiper [slidesPerView]="'auto'"
                        [spaceBetween]="10"
                        class="carsSwiper">
                    <ng-template swiperSlide *ngFor="let item of osagoPolicies;">
                        <div class="swiper-slider" *ngIf="item.license">
                            <a (click)="changeOsagoPolicies(item.license, item)">
                                <span class="title">{{ item.osago?.carData?.brandName }}</span>
                                <span class="number">{{ item.osago?.carData?.licensePlate }}</span>
                            </a>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide *ngIf="osagoPolicies.length > 3">
                    </ng-template>
                </swiper>
            </div>
        </div>

        <div class="widget__introText" *ngIf="!edit">
            <p>*Экономия достигается за счет разницы между тарифами страховой компании и скидкой за безубыточную езду (КБМ)</p>
            <p>Услуга предоставляется сервисом электронного страхования Insapp</p>
        </div>
    </div>
</ng-template>

<ng-template #loaderTemplate>
    <div class="step2">
        <div class="widget__wrapper">
            <div class="widget__header">
                <div class="widget__header-left">
                    <h2>Получаем данные</h2><span>Это может занять несколько секунд</span>
                    <br><br>
                </div>
                <div class="widget__header-right">
                </div>
            </div>
            <div class="widget__loader active"></div>
        </div>
    </div>
</ng-template>
