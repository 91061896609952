import { Directive, ElementRef, HostListener } from '@angular/core';
import { LicensePlateService } from '../services/licensePlate.service';

@Directive({
  selector: '[isLicense]'
})
export class LicenseDirective {
    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];
    inputElement: HTMLElement;

    constructor(public el: ElementRef, private licenseService: LicensePlateService) {
        this.inputElement = el.nativeElement;
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: any): void {
        if (
            this.navigationKeys.indexOf(event.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
            (event.key === 'a' && event.ctrlKey === true) || // Allow: Ctrl+A
            (event.key === 'c' && event.ctrlKey === true) || // Allow: Ctrl+C
            (event.key === 'v' && event.ctrlKey === true) || // Allow: Ctrl+V
            (event.key === 'x' && event.ctrlKey === true) || // Allow: Ctrl+X
            (event.key === 'a' && event.metaKey === true) || // Allow: Cmd+A (Mac)
            (event.key === 'c' && event.metaKey === true) || // Allow: Cmd+C (Mac)
            (event.key === 'v' && event.metaKey === true) || // Allow: Cmd+V (Mac)
            (event.key === 'x' && event.metaKey === true)    // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }

        const value = event.target.value || '';
        if (value.length >= this.licenseService.numberLength) {
            event.preventDefault();
            return;
        }

        const key = (event.key || '').toLowerCase();
        const caretPosition = this.getCaretPosition(event.target);
        if (!this.licenseService.isKeyAllowed(key, caretPosition)) {
            event.preventDefault();
        }
    }

    @HostListener('input', ['$event'])
    onInput(event: any): void {
        const elem = event.target;
        const value = elem.value || '';

        const allowedValue = this.licenseService.getPlateNumberValue(value);
        if (value !== allowedValue) {
            const position = this.getCaretPosition(elem);
            elem.value = allowedValue;
            const currentPostioin = this.getCaretPosition(elem);
            if (currentPostioin !== position && position < allowedValue.length) {
                this.setCaretPosition(elem, position);
            }
        }
    }

    private getCaretPosition(elem: any): number {
        return elem.selectionStart || elem.selectionStart === 0
            ? elem.selectionStart
            : -1;
    }

    private setCaretPosition(elem: any, position: number): void {
        if (elem.createTextRange) {
            const range = elem.createTextRange();
            range.move('character', position);
            range.select();
        } else if (elem.selectionStart) {
            elem.setSelectionRange(position, position);
        }
    }
}
