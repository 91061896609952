import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApplicationService } from '../../../../services/application.service';

@Component({
    selector: 'app-pay',
    templateUrl: './pay.component.html',
    styleUrls: ['./pay.component.css']
})
export class PayComponent {

    constructor(private readonly route: ActivatedRoute,
                private readonly router: Router,
                private sanitizer: DomSanitizer,
                appService: ApplicationService) {
        if (appService.payUrl != null) {
            // this.link = this.sanitizer.bypassSecurityTrustResourceUrl(appService.payUrl);
            window.open(appService.payUrl, '_blank');
            appService.returnToOffers();
        }
    }

    public link: SafeResourceUrl | undefined;


    public sendMessage(): void {
        parent.postMessage('myevent', '*');
    }

}
