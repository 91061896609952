export class Modification {
    constructor(
        public bodyType: string,
        public driveType: string,
        public enginePower: number,
        public engineType: string,
        public modelName: string,
        public modificationId :string,
        public modificationName: string,
        public transmissionType: string
    ){}
}