import { Driver } from "./driver.model";
import { Owner } from "./owner.model";
import { Vehicle } from "./vehicle.model";

export class Application {
    constructor(
        public applicationId: string,
        public brandId?: number,
        public modelId?: number,
        public drivers?: Driver[],
        public owner?: Owner,
        public vehicle?: Vehicle,
        public apiKey?: string,
        public license?: string
    ) { }
}