<div class="step12 scroll-target-wide">

    <app-price-summary [showClose]="(false)"></app-price-summary>

	<div class="mt15">
		<div class="widget__header">
			<h2>Стоимость полиса</h2>
			<div class="widget__header-info">
				<div class="widget__header-left">
					<p>{{ offer?.premiumAmount | currencyRU }}</p>
                    <div class="widget__icon">
                        <img src="/assets/images/icons/info.svg" alt="">
                        <div class="widget__helper widget__helper-down">
                            <p>Мы не берем комиссию. Вы оформляете
                                полис по цене страховой компании
                                без переплат</p>
                        </div>
                    </div>
				</div>
				<div class="widget__header-right">
					<p>Дата начала действия полиса<br> в {{ offer?.insurerName }}: <span>{{ offer?.startDateDescription }}</span></p>
				</div>
			</div>
		</div>

		<div class="widget__buttons">
			<div class="buttonMob">
				<app-button
					modifier="blue-button"
                    class="pay-for-desktop"
					(clicked)="onOfferConfirmed('desctop')">
					<span>Перейти к оплате</span>
					<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9001 9.8794L11.3111 5.2934C11.1207 5.12124 10.8714 5.0289 10.6148 5.03549C10.3582 5.04208 10.1139 5.1471 9.93259 5.3288C9.75127 5.51051 9.64677 5.75498 9.64073 6.01161C9.63468 6.26824 9.72756 6.51736 9.90012 6.7074L14.4861 11.2934C14.6736 11.4809 14.7789 11.7352 14.7789 12.0004C14.7789 12.2656 14.6736 12.5199 14.4861 12.7074L9.90012 17.2934C9.71262 17.481 9.60733 17.7355 9.60742 18.0008C9.60752 18.266 9.71298 18.5204 9.90062 18.7079C10.0883 18.8954 10.3427 19.0007 10.608 19.0006C10.8732 19.0005 11.1276 18.895 11.3151 18.7074L15.9001 14.1214C16.4625 13.5588 16.7785 12.7959 16.7785 12.0004C16.7785 11.2049 16.4625 10.442 15.9001 9.8794Z" fill="white"/></svg>
				</app-button>
				<app-button
					modifier="blue-button"
                    class="pay-for-mobile"
					(clicked)="onOfferConfirmed('mobile')">
					<span>Перейти к оплате</span>
					<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9001 9.8794L11.3111 5.2934C11.1207 5.12124 10.8714 5.0289 10.6148 5.03549C10.3582 5.04208 10.1139 5.1471 9.93259 5.3288C9.75127 5.51051 9.64677 5.75498 9.64073 6.01161C9.63468 6.26824 9.72756 6.51736 9.90012 6.7074L14.4861 11.2934C14.6736 11.4809 14.7789 11.7352 14.7789 12.0004C14.7789 12.2656 14.6736 12.5199 14.4861 12.7074L9.90012 17.2934C9.71262 17.481 9.60733 17.7355 9.60742 18.0008C9.60752 18.266 9.71298 18.5204 9.90062 18.7079C10.0883 18.8954 10.3427 19.0007 10.608 19.0006C10.8732 19.0005 11.1276 18.895 11.3151 18.7074L15.9001 14.1214C16.4625 13.5588 16.7785 12.7959 16.7785 12.0004C16.7785 11.2049 16.4625 10.442 15.9001 9.8794Z" fill="white"/></svg>
				</app-button>
			</div>
			<div class="buttonMob">
				<app-button
					modifier="border-button"
					(clicked)="onReturnToOffers()">
					Выбрать другую страховую
				</app-button>
			</div>

		</div>

		<div class="widget__tip">
			<span>Оплата производится на официальном сайте {{ offer?.insurerName }}. Переходя к оплате,<br> вы подтверждаете дату начала действия полиса — {{ offer?.policyStartDateApproved }}.</span>
		</div>
	</div>

</div>

<!--<ng-container *ngIf="isShowPayModal">-->
<!--    <div class="popupBg popupBg-active"></div>-->
<!--    <div class="popupMain popupMain-active">-->
<!--        <div class="popupMain__close" (click)="isShowPayModal = false;">-->
<!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">	<path d="M17.9998 6.00006C17.8123 5.81259 17.558 5.70728 17.2928 5.70728C17.0277 5.70728 16.7733 5.81259 16.5858 6.00006L11.9998 10.5861L7.41382 6.00006C7.22629 5.81259 6.97198 5.70728 6.70682 5.70728C6.44165 5.70728 6.18735 5.81259 5.99982 6.00006C5.81235 6.18759 5.70703 6.4419 5.70703 6.70706C5.70703 6.97223 5.81235 7.22653 5.99982 7.41406L10.5858 12.0001L5.99982 16.5861C5.81235 16.7736 5.70703 17.0279 5.70703 17.2931C5.70703 17.5582 5.81235 17.8125 5.99982 18.0001C6.18735 18.1875 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1875 7.41382 18.0001L11.9998 13.4141L16.5858 18.0001C16.7733 18.1875 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1875 17.9998 18.0001C18.1873 17.8125 18.2926 17.5582 18.2926 17.2931C18.2926 17.0279 18.1873 16.7736 17.9998 16.5861L13.4138 12.0001L17.9998 7.41406C18.1873 7.22653 18.2926 6.97223 18.2926 6.70706C18.2926 6.4419 18.1873 6.18759 17.9998 6.00006Z" fill="#979797"/></svg>-->
<!--        </div>-->

<!--        <iframe [src]="paySrc" frameborder="0" style="width: 100%; height: 90vh;"></iframe>-->

<!--    </div>-->
<!--</ng-container>-->

