import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html'
})

export class ButtonComponent {
    @Input() buttonType = 'button';
    @Input() modifier: string | null = null;
    @Input() loaderModifyer: string | null = null;
    @Input() action: string | null = null;
    @Input() isDisabled: boolean | null = false;
    @Input() index: string | null = null;
    @Output() clicked = new EventEmitter<any>();

    public onClick(e: any) {
        this.clicked.emit();
    }

    public get modifierClassString(): string {
        if (this.modifier === null) {
            return 'button';
        }

        return this.modifier;
    }
}
