<div class="widget__title">
	<div class="widget__title-left"><img [src]="model?.logo" [alt]="model?.altText"/>
		<div class="widget__title-content">
			<p>{{ model?.productName || model?.insurerName }}</p>
			<span>Стоимость: {{ model?.premiumAmount | currencyRU }}</span>
		</div>
	</div>
	<div
		*ngIf="showClose == true"
		class="widget__title-close"
		(click)="onCloseClick()">
		<span>Вернуться к списку страховых</span><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.9998 7.99976C23.7497 7.7498 23.4106 7.60938 23.0571 7.60938C22.7035 7.60938 22.3645 7.7498 22.1144 7.99976L15.9998 14.1144L9.88509 7.99976C9.63505 7.7498 9.29598 7.60938 8.94242 7.60938C8.58887 7.60938 8.24979 7.7498 7.99976 7.99976C7.7498 8.24979 7.60938 8.58887 7.60938 8.94242C7.60938 9.29598 7.7498 9.63505 7.99976 9.88509L14.1144 15.9998L7.99976 22.1144C7.7498 22.3645 7.60938 22.7035 7.60938 23.0571C7.60938 23.4106 7.7498 23.7497 7.99976 23.9998C8.24979 24.2497 8.58887 24.3901 8.94242 24.3901C9.29598 24.3901 9.63505 24.2497 9.88509 23.9998L15.9998 17.8851L22.1144 23.9998C22.3645 24.2497 22.7035 24.3901 23.0571 24.3901C23.4106 24.3901 23.7497 24.2497 23.9998 23.9998C24.2497 23.7497 24.3901 23.4106 24.3901 23.0571C24.3901 22.7035 24.2497 22.3645 23.9998 22.1144L17.8851 15.9998L23.9998 9.88509C24.2497 9.63505 24.3901 9.29598 24.3901 8.94242C24.3901 8.58887 24.2497 8.24979 23.9998 7.99976Z" fill="#979797"/></svg>
	</div>
</div>