import { AfterViewInit, Component } from "@angular/core";
import { ApplicationService } from "../../services/application.service";

@Component({
    templateUrl: './success.component.html'
})

export class SuccessComponent implements AfterViewInit {
    constructor(
        private appService: ApplicationService
    ) { }

    ngAfterViewInit() {
        this.appService.onYandexReachGoal('SUCCESS_GOAL')    }
}