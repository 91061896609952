export class InitialData {
    constructor(
        public apiKey: string,
        public apiBaseUrl?: string,
        public cpaClientUid?: string,
        public cpaClientUid2?: string,
        public licensePlate?: string,
        public modelId?: number,
        public partnerBrandName?: string,
        public partnerModelName?: string,
        public platformID?: string,
        public webMasterID?: string,
        public isSuccess?: boolean,
        public licenseCheckEnabled?: boolean,
        public noFocus?: boolean,
        public recommendedInsurer?: string,
        public applicationId?: string,
        public offerId?: string,
        public clientId?: string,
        public src?: string,
        public landing?: boolean,
        public sessionId?: string,
        public partnerClientId?: string,
        public successPaymentUrl?: string,
        public utm?: string,
        public referer?: string,
        public isNewClient?: boolean,
        public partnerApplicationId?: string,
        public cloudflareEnabled?: boolean,
        public recaptchaEnabled?: boolean,
        public AgentId?: string,
    ) { }
}
