import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-prolongation-alfaid',
    template: ''
})
export class AppProlongationAlfaidComponent implements OnInit {

    constructor(private router: Router,
                private route: ActivatedRoute
                ) {

    }

    private getCurrentUrl(): string {
        try {
            return window.location.href;
        } catch {
            return '';
        }
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (params.state === 'insapp4') {
              const newParams = { ...params, state: 'insapp4' };
              this.router.navigate(['/'], { queryParams: newParams });
            }
          });
    }
    // --------------------------------------------------------------------------
}
