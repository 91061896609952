import { Component, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ApplicationService } from "../../../../../services";
import { Offer } from "../../../models";
import { OsagoService, PriceCalculatorService } from "../../../services";

@Component({
	selector: 'app-price-summary',
	templateUrl: './price-summary.component.html'
})
export class PriceSummaryComponent implements OnDestroy {
	@Input() showClose = true;
	model: Offer | null = null;

	private subscription = new Subscription();

	constructor(
		private appService: ApplicationService,
		private osagoService: OsagoService,
		private priceService: PriceCalculatorService
	) {
		this.model = osagoService.selectedOffer || osagoService.preOffer;

		if (osagoService.selectedOffer != null)
			return;

		this.subscription.add(
			priceService.price$.subscribe((value) => {
				if (value != null) {
					const offers = osagoService.getPreapprovedOffers(value, priceService.basePrice);
					const index = offers.findIndex(x => x.insurerType == this.model?.insurerType);
					if (index != -1)
						this.model!.premiumAmount = offers[index].premiumAmount;
				}
        	}));

		this.subscription.add(
			osagoService.preoffer$.subscribe((offer) => {
				if (offer != null)
					this.model!.premiumAmount = offer.premiumAmount;
			}));
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	onCloseClick() {
		this.appService.returnToPreoffers();
	}
}
