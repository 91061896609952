import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyComponent, ErrorComponent, FailedPaymentComponent, SuccessComponent } from './components';
import { DeniedComponent } from './components/denied/denied.component';
import { NavigationGuard } from './modules/shared/guards/navigation.guard';
import { LicenseComponent } from './modules/osago/components/license';
import { AppProlongationAlfaidComponent } from './modules/osago/components/prolongation-alfaid/prolongation-alfaid.component';

const routes: Routes = [
    { path: 'error', component: ErrorComponent },
    { path: 'fail', component: FailedPaymentComponent },
    { path: 'info', loadChildren: () => import('./modules/osago/osago.module').then(m => m.OsagoModule) },
    { path: 'result', component: DeniedComponent },
    { path: 'license', component: LicenseComponent },
    { path: 'success', component: SuccessComponent },
    { path: 'prolongation-alfaid', component: AppProlongationAlfaidComponent },
    { path: '', component: EmptyComponent, canActivate: [NavigationGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
