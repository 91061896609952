import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import * as moment from 'moment-mini';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    protected messages: any = [];

    public debug(message?: any, ...optionalParams: any[]) {
        var logMessage = moment().format('HH:mm:ss.SSS') + ' ' + message;
        
        if (environment.logging.debug)
            console.debug(message, optionalParams);

        this.messages.push(logMessage);
    }

    public trace(component: string, method: string, message: string = '') {
        var debugMessage = component + '::' + method + ':: ' + message;
        this.debug(debugMessage);
    }

    public get journal(): string {
        return JSON.stringify(this.messages);
    }
}