<input
    class="license-number"
    isLicense
    placeholder="А999АА"
    [id]="fieldId"
    [name]="fieldId"
    [type]="fieldType"
    [readOnly]="isReadOnly"
    (input)="onInput($event)"
    #input
    autocomplete="off"
>
