import { Component, HostListener, EventEmitter, Input, Output } from '@angular/core';
import { BaseFormComponent } from '../base/base-form.component';

@Component({
    selector: 'app-input-numeric',
    templateUrl: './input-numeric.component.html'
})
export class InputNumericComponent extends BaseFormComponent {
    @Input() maxLength = 15;
    @Input() fieldType = 'tel';
    @Input() numericThousandSeparator = '';
    @Input() numericMaxLength = 0;
    @Input() decimalPlaces = 0;
    @Output() changed = new EventEmitter<string>();

    @HostListener('keydown', ['$event'])
    onKeyDown(event: any): void {
        if (event.key === 'ArrowLeft' || event.key === 'Backspace') {
            const position = this.getCaretPosition(event.target);
            if (position === 0) {
                this.moveLeft.emit();
                if (event.key === 'ArrowLeft') {
                    event.preventDefault();
                }
            }
        }
    }

    onChange(event: any): void {
        this.changed.emit(event.target.value || '');
    }
}
