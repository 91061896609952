<div class="step2" *ngIf="isLoadingPage">
    <div class="widget__wrapper">
        <div class="widget__header">
            <div class="widget__header-left">
                <h2>Получаем данные</h2><span>Это может занять несколько секунд</span>
            </div>
            <div class="widget__header-right">
            </div>
        </div>
        <br>
        <div class="widget__loader active"></div>
    </div>
</div>


<div class="step11 scroll-target scroll-target-wide" id="offers-anchor">
	<div *ngIf="!isLoadingPage" [ngStyle]="{'min-height': isShowConfirm ? '1200px' : 'auto' }">

        <div class="title-page">Выберите компанию</div>
        <div *ngIf="osago && osago?.carData?.brandName
        && osago?.carData?.modelName
        && osago?.carData?.carPower
        && osago?.drivers
        && osago?.owner?.firstName
        && osago?.owner?.lastName
        && osago?.owner?.passport
        && osago?.owner?.birthDate
        && (osago?.owner?.email || osago?.insured?.email)
        && (osago?.owner?.phone || osago?.insured?.phone)" class="police-data-container">
            <div class="widget-header-container">
                <div class="osago-driver-block">
                    <div class="driver-title-block">
                        <div class="driver-title">Данные полиса</div>
                        <div *ngIf="!isChangeNewDate" class="info-modal-btn show-mobile" (click)="isShowConfirm = true">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_433_41971)">
                                    <path d="M17.42 3.78886L8.04734 13.1615C7.68937 13.5175 7.40557 13.9411 7.21239 14.4075C7.0192 14.874 6.92046 15.3742 6.92189 15.879V16.9115C6.92189 17.1154 7.00288 17.3109 7.14705 17.4551C7.29122 17.5992 7.48676 17.6802 7.69064 17.6802H8.72308C9.22797 17.6817 9.72812 17.5829 10.1946 17.3897C10.6611 17.1965 11.0846 16.9127 11.4406 16.5548L20.8133 7.18214C21.2625 6.73178 21.5148 6.12162 21.5148 5.4855C21.5148 4.84937 21.2625 4.23922 20.8133 3.78886C20.3564 3.35213 19.7487 3.1084 19.1166 3.1084C18.4846 3.1084 17.8769 3.35213 17.42 3.78886ZM19.7262 6.09512L10.3536 15.4678C9.92012 15.8986 9.33424 16.1411 8.72308 16.1427H8.4594V15.879C8.461 15.2679 8.70353 14.682 9.13436 14.2485L18.507 4.87588C18.6712 4.71902 18.8895 4.63149 19.1166 4.63149C19.3437 4.63149 19.562 4.71902 19.7262 4.87588C19.8876 5.03771 19.9783 5.25694 19.9783 5.4855C19.9783 5.71406 19.8876 5.93329 19.7262 6.09512Z" fill="#009DFF"/>
                                    <path d="M20.7594 9.97685C20.5556 9.97685 20.36 10.0578 20.2159 10.202C20.0717 10.3462 19.9907 10.5417 19.9907 10.7456V14.6055H16.9157C16.304 14.6055 15.7174 14.8485 15.2849 15.281C14.8524 15.7135 14.6094 16.3001 14.6094 16.9118V19.9868H6.92189C6.31023 19.9868 5.72363 19.7438 5.29112 19.3113C4.85861 18.8788 4.61563 18.2922 4.61563 17.6805V6.91798C4.61563 6.30633 4.85861 5.71972 5.29112 5.28721C5.72363 4.8547 6.31023 4.61172 6.92189 4.61172H13.873C14.0768 4.61172 14.2724 4.53073 14.4165 4.38656C14.5607 4.24239 14.6417 4.04686 14.6417 3.84297C14.6417 3.63909 14.5607 3.44355 14.4165 3.29938C14.2724 3.15521 14.0768 3.07422 13.873 3.07422H6.92189C5.90284 3.07544 4.92587 3.4808 4.20529 4.20138C3.4847 4.92196 3.07935 5.89893 3.07813 6.91798L3.07812 17.6805C3.07935 18.6996 3.4847 19.6765 4.20529 20.3971C4.92587 21.1177 5.90284 21.5231 6.92189 21.5243H15.6419C16.1469 21.5257 16.6471 21.427 17.1137 21.2338C17.5803 21.0406 18.004 20.7568 18.3602 20.3988L20.402 18.3555C20.76 17.9995 21.0439 17.576 21.2372 17.1095C21.4305 16.643 21.5294 16.1429 21.5282 15.6379V10.7456C21.5282 10.5417 21.4472 10.3462 21.303 10.202C21.1589 10.0578 20.9633 9.97685 20.7594 9.97685ZM17.2732 19.3118C16.9641 19.6201 16.5733 19.8335 16.1469 19.9268V16.9118C16.1469 16.7079 16.2279 16.5124 16.3721 16.3682C16.5163 16.224 16.7118 16.143 16.9157 16.143H19.933C19.8379 16.5685 19.6248 16.9586 19.318 17.2685L17.2732 19.3118Z" fill="#009DFF"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_433_41971">
                                        <rect width="18.4501" height="18.4501" fill="white" transform="translate(3.07812 3.07471)"/>
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                    </div>
                    <div *ngIf="osago" class="police-data-block">
                        {{ osago?.carData?.brandName }} {{ osago?.carData?.modelName }}
                        <svg *ngIf="osago?.carData?.licensePlate" width="1" height="12" viewBox="0 0 1 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="0.5" y1="2.18558e-08" x2="0.499999" y2="12" stroke="#E1E1E1"/>
                        </svg>
                        {{ osago?.carData?.licensePlate }}
                        <svg *ngIf="osago?.carData?.licensePlate" width="1" height="12" viewBox="0 0 1 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="0.5" y1="2.18558e-08" x2="0.499999" y2="12" stroke="#E1E1E1"/>
                        </svg>
                        <span *ngFor="let driver of osago?.drivers; let isLast=last"> {{driver.lastName}} {{driver.firstName}} {{driver.middleName}}
                            <svg *ngIf="!isLast" width="1" height="12" viewBox="0 0 1 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="0.5" y1="2.18558e-08" x2="0.499999" y2="12" stroke="#E1E1E1"/>
                            </svg>
                        </span>
                    </div>
                    <div class="osago-driver-actions show-desktop">
                        <button (click)="onEditClick()"
                                [disabled]="isChangeNewDate"
                                class="btn-outline">Редактировать</button>
                    </div>

                </div>
            </div>
            <div class="widget-header-container overflow-hidden">
                <div class="osago-driver-block"
                     (click)="isShowKbmDialog = !isShowKbmDialog;"
                     *ngIf="maxKbm && strokeDashoffset && drivers && drivers.length && !driverStatusError">
                    <div class="driver-title-block">
                        <div class="driver-title" *ngIf="maxKbm < 1">Скидка {{ maxKbmPercent }}%</div>
                        <div class="driver-title" *ngIf="maxKbm > 1">Надбавка {{ maxKbmPercent }}%</div>
                        <div class="driver-title" *ngIf="maxKbm == 1">Нет скидки</div>
                        <div class="info-modal-btn">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9984 2.3999C10.0997 2.3999 8.24368 2.96293 6.66497 4.01779C5.08625 5.07265 3.8558 6.57197 3.1292 8.32614C2.4026 10.0803 2.21249 12.0105 2.5829 13.8728C2.95332 15.735 3.86763 17.4455 5.21022 18.7881C6.5528 20.1307 8.26335 21.045 10.1256 21.4154C11.9878 21.7858 13.918 21.5957 15.6722 20.8691C17.4264 20.1425 18.9257 18.9121 19.9805 17.3334C21.0354 15.7547 21.5984 13.8986 21.5984 11.9999C21.5957 9.45467 20.5834 7.01447 18.7836 5.21472C16.9839 3.41496 14.5437 2.40266 11.9984 2.3999ZM11.9984 19.9999C10.4162 19.9999 8.86947 19.5307 7.55388 18.6516C6.23828 17.7726 5.21291 16.5232 4.6074 15.0614C4.0019 13.5996 3.84348 11.991 4.15216 10.4392C4.46084 8.88733 5.22277 7.46187 6.34159 6.34305C7.4604 5.22423 8.88587 4.4623 10.4377 4.15362C11.9896 3.84494 13.5981 4.00336 15.0599 4.60887C16.5217 5.21437 17.7711 6.23975 18.6502 7.55534C19.5292 8.87093 19.9984 10.4176 19.9984 11.9999C19.9961 14.1209 19.1525 16.1544 17.6527 17.6542C16.1529 19.154 14.1195 19.9976 11.9984 19.9999ZM11.9969 8.79942C12.6596 8.79942 13.1969 8.26216 13.1969 7.59942C13.1969 6.93667 12.6596 6.39941 11.9969 6.39941C11.3341 6.39941 10.7969 6.93667 10.7969 7.59942C10.7969 8.26216 11.3341 8.79942 11.9969 8.79942ZM12.8031 11.2004C12.8031 10.7586 12.445 10.4004 12.0031 10.4004C11.5613 10.4004 11.2031 10.7586 11.2031 11.2004V16.8004C11.2031 17.2422 11.5613 17.6004 12.0031 17.6004C12.445 17.6004 12.8031 17.2422 12.8031 16.8004L12.8031 11.2004Z" fill="#009DFF"/>
                            </svg>
                        </div>
                    </div>
                    <div class="kbm-progress-container">
                        <div *ngIf="strokeDashoffset" class="kbm-progress">
                            <svg class="defs-only" viewBox="0 0 228 228" preserveAspectRatio="xMidYMin slice">
                                <defs *ngIf="maxKbm <= 1">
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" stop-color="#BDFF00" />
                                        <stop offset="100%" stop-color="#11D100" />
                                    </linearGradient>
                                </defs>
                                <defs *ngIf="maxKbm > 1">
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" stop-color="#FFA717" />
                                        <stop offset="100%" stop-color="#FF5C00" />
                                    </linearGradient>
                                </defs>
                                <circle class="base" r="100" cx="114" cy="114" transform="rotate(-180 114 114)"></circle>
                                <circle id="progress" [style]="'stroke-dashoffset: ' + strokeDashoffset" class="progress" stroke="url(#gradient)" transform="rotate(-180 114 114)" r="100" cx="114" cy="114"></circle>
                                <g class="chart-text"><text x="50%" y="50%" class="chart-number">{{ maxKbm }} КБМ</text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
<!--                <div class="spinner-circle" *ngIf="!maxKbm"></div>-->
                <div class="osago-driver-block"
                     *ngIf="!maxKbm && drivers && drivers.length && !driverStatusError">
                    <div class="driver-title-block">
                        <div class="driver-title">Загрузка...</div>
                        <div class="info-modal-btn">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9984 2.3999C10.0997 2.3999 8.24368 2.96293 6.66497 4.01779C5.08625 5.07265 3.8558 6.57197 3.1292 8.32614C2.4026 10.0803 2.21249 12.0105 2.5829 13.8728C2.95332 15.735 3.86763 17.4455 5.21022 18.7881C6.5528 20.1307 8.26335 21.045 10.1256 21.4154C11.9878 21.7858 13.918 21.5957 15.6722 20.8691C17.4264 20.1425 18.9257 18.9121 19.9805 17.3334C21.0354 15.7547 21.5984 13.8986 21.5984 11.9999C21.5957 9.45467 20.5834 7.01447 18.7836 5.21472C16.9839 3.41496 14.5437 2.40266 11.9984 2.3999ZM11.9984 19.9999C10.4162 19.9999 8.86947 19.5307 7.55388 18.6516C6.23828 17.7726 5.21291 16.5232 4.6074 15.0614C4.0019 13.5996 3.84348 11.991 4.15216 10.4392C4.46084 8.88733 5.22277 7.46187 6.34159 6.34305C7.4604 5.22423 8.88587 4.4623 10.4377 4.15362C11.9896 3.84494 13.5981 4.00336 15.0599 4.60887C16.5217 5.21437 17.7711 6.23975 18.6502 7.55534C19.5292 8.87093 19.9984 10.4176 19.9984 11.9999C19.9961 14.1209 19.1525 16.1544 17.6527 17.6542C16.1529 19.154 14.1195 19.9976 11.9984 19.9999ZM11.9969 8.79942C12.6596 8.79942 13.1969 8.26216 13.1969 7.59942C13.1969 6.93667 12.6596 6.39941 11.9969 6.39941C11.3341 6.39941 10.7969 6.93667 10.7969 7.59942C10.7969 8.26216 11.3341 8.79942 11.9969 8.79942ZM12.8031 11.2004C12.8031 10.7586 12.445 10.4004 12.0031 10.4004C11.5613 10.4004 11.2031 10.7586 11.2031 11.2004V16.8004C11.2031 17.2422 11.5613 17.6004 12.0031 17.6004C12.445 17.6004 12.8031 17.2422 12.8031 16.8004L12.8031 11.2004Z" fill="#009DFF"/>
                            </svg>
                        </div>
                    </div>
                    <div class="kbm-progress-container">
                        <div class="kbm-progress">
                            <svg class="defs-only" viewBox="0 0 228 228" preserveAspectRatio="xMidYMin slice">
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" stop-color="#BDFF00" />
                                        <stop offset="100%" stop-color="#11D100" />
                                    </linearGradient>
                                </defs>
                                <circle class="base" r="100" cx="114" cy="114" transform="rotate(-180 114 114)"></circle>
                                <g class="chart-text"><text x="50%" y="50%" class="chart-number"></text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="osago-driver-block"
                     *ngIf="(osago?.drivers && drivers && drivers.length === 0) || driverStatusError">
                    <div class="driver-title-block">
                        <div class="driver-title show-desktop">
                            <br>
                            <br>
                            Цены указаны напрямую от страховых без наценок
                        </div>
                        <div class="show-mobile">
                            &nbsp;
                        </div>
                        <div class="info-modal-btn">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.12537 18C0.837375 18 0.549375 17.8905 0.329625 17.6708C-0.109875 17.2313 -0.109875 16.5195 0.329625 16.08L16.0796 0.329618C16.5191 -0.109873 17.2309 -0.109873 17.6704 0.329618C18.1099 0.768359 18.1099 1.48159 17.6704 1.92033L1.92112 17.6708C1.70137 17.8905 1.41337 18 1.12537 18ZM6.75037 3.3753C6.75037 1.51459 5.23612 0.000374999 3.37537 0.000374999C1.51463 0.000374999 0.000375006 1.51459 0.000375006 3.3753C0.000375006 5.23602 1.51463 6.75023 3.37537 6.75023C5.23612 6.75023 6.75037 5.23602 6.75037 3.3753ZM4.50037 3.3753C4.50037 3.99554 3.99562 4.50028 3.37537 4.50028C2.75512 4.50028 2.25037 3.99554 2.25037 3.3753C2.25037 2.75507 2.75512 2.25033 3.37537 2.25033C3.99562 2.25033 4.50037 2.75507 4.50037 3.3753ZM18.0004 14.6251C18.0004 12.7644 16.4861 11.2501 14.6254 11.2501C12.7646 11.2501 11.2504 12.7644 11.2504 14.6251C11.2504 16.4858 12.7646 18 14.6254 18C16.4861 18 18.0004 16.4858 18.0004 14.6251ZM15.7504 14.6251C15.7504 15.2453 15.2456 15.75 14.6254 15.75C14.0051 15.75 13.5004 15.2453 13.5004 14.6251C13.5004 14.0048 14.0051 13.5001 14.6254 13.5001C15.2456 13.5001 15.7504 14.0048 15.7504 14.6251Z" fill="#009DFF"/>
                            </svg>
                        </div>
                    </div>
                    <div class="show-mobile kbm-error-txt">
                        Цены указаны напрямую от страховых без наценок
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="osago && osago?.carData?.brandName
        && osago?.carData?.modelName
        && osago?.carData?.carPower
        && osago?.carData?.productionYear
        && osago?.drivers
        && osago?.owner?.firstName
        && osago?.owner?.lastName
        && osago?.owner?.passport
        && osago?.owner?.birthDate
        && (osago?.owner?.email || osago?.insured?.email)
        && (osago?.owner?.phone || osago?.insured?.phone)" class="widget-header-container">
            <div class="osago-star-date-block">
                <div class="osago-start-date-form-block">

                    <div class="widget-input-inner date-control-info">
                        <div class="osago-star-date-header">
                            <div class="osago-star-date-title">
                                Начало действия полиса
                            </div>
                            <div class="info-modal-btn show-mobile">
                                <div class="widget__icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9984 2.3999C10.0997 2.3999 8.24368 2.96293 6.66497 4.01779C5.08625 5.07265 3.8558 6.57197 3.1292 8.32614C2.4026 10.0803 2.21249 12.0105 2.5829 13.8728C2.95332 15.735 3.86763 17.4455 5.21022 18.7881C6.5528 20.1307 8.26335 21.045 10.1256 21.4154C11.9878 21.7858 13.918 21.5957 15.6722 20.8691C17.4264 20.1425 18.9257 18.9121 19.9805 17.3334C21.0354 15.7547 21.5984 13.8986 21.5984 11.9999C21.5957 9.45467 20.5834 7.01447 18.7836 5.21472C16.9839 3.41496 14.5437 2.40266 11.9984 2.3999ZM11.9984 19.9999C10.4162 19.9999 8.86947 19.5307 7.55388 18.6516C6.23828 17.7726 5.21291 16.5232 4.6074 15.0614C4.0019 13.5996 3.84348 11.991 4.15216 10.4392C4.46084 8.88733 5.22277 7.46187 6.34159 6.34305C7.4604 5.22423 8.88587 4.4623 10.4377 4.15362C11.9896 3.84494 13.5981 4.00336 15.0599 4.60887C16.5217 5.21437 17.7711 6.23975 18.6502 7.55534C19.5292 8.87093 19.9984 10.4176 19.9984 11.9999C19.9961 14.1209 19.1525 16.1544 17.6527 17.6542C16.1529 19.154 14.1195 19.9976 11.9984 19.9999ZM11.9969 8.79942C12.6596 8.79942 13.1969 8.26216 13.1969 7.59942C13.1969 6.93667 12.6596 6.39941 11.9969 6.39941C11.3341 6.39941 10.7969 6.93667 10.7969 7.59942C10.7969 8.26216 11.3341 8.79942 11.9969 8.79942ZM12.8031 11.2004C12.8031 10.7586 12.445 10.4004 12.0031 10.4004C11.5613 10.4004 11.2031 10.7586 11.2031 11.2004V16.8004C11.2031 17.2422 11.5613 17.6004 12.0031 17.6004C12.445 17.6004 12.8031 17.2422 12.8031 16.8004L12.8031 11.2004Z" fill="#009DFF"/>
                                    </svg>
                                    <div class="widget__helper">
                                        <p *ngIf="!prolongationOffer">Рекомендуем выбрать дату начала действия полиса {{ recommendDate }} (+4 дня)
                                            для увеличения количества предложений от страховых компаний</p>
                                        <p *ngIf="prolongationOffer">Мы нашли ваш текущий полис в
                                            {{ prolongationOffer.insurerName }},
                                            который действует до
                                            {{ prolongationOffer.policyExpireDate }}.
                                            Рекомендуем выбрать дату начала действия полиса
                                            {{ getNextDayProlongation }}.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <span *ngIf="!prolongationOffer">
                            Рекомендуем выбрать дату начала действия полиса {{ recommendDate }} (+4 дня)
                        для увеличения количества предложений от страховых компаний
                        </span>

                        <span *ngIf="prolongationOffer">
                            Мы нашли ваш текущий полис в
                            {{ prolongationOffer.insurerName }},
                            который действует до
                            {{ prolongationOffer.policyExpireDate }}.
                            Рекомендуем выбрать дату начала действия полиса
                            {{ getNextDayProlongation }}.</span>
                    </div>

                    <div class="widget-input-inner date-control" *ngIf="startDate">
                        <div class="widget-input filled">
                            <label>Вы можете изменить дату</label>
                            <ngx-datepicker [ngClass]="{'pointerEvent': isChangeNewDate}"
                                            [formControl]="date"
                                            (ngModelChange)="changePolicyDate($event)"
                                            [options]="options"></ngx-datepicker>
                            <div *ngIf="!isChangeNewDate" class="widget__icon">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22 6H23C24.3256 6.00159 25.5964 6.52888 26.5338 7.46622C27.4711 8.40356 27.9984 9.67441 28 11V23C27.9984 24.3256 27.4711 25.5964 26.5338 26.5338C25.5964 27.4711 24.3256 27.9984 23 28H9C7.67441 27.9984 6.40356 27.4711 5.46622 26.5338C4.52888 25.5964 4.00159 24.3256 4 23V11C4.00159 9.67441 4.52888 8.40356 5.46622 7.46622C6.40356 6.52888 7.67441 6.00159 9 6H10V5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4C11.2652 4 11.5196 4.10536 11.7071 4.29289C11.8946 4.48043 12 4.73478 12 5V6H20V5C20 4.73478 20.1054 4.48043 20.2929 4.29289C20.4804 4.10536 20.7348 4 21 4C21.2652 4 21.5196 4.10536 21.7071 4.29289C21.8946 4.48043 22 4.73478 22 5V6ZM6.87868 8.87868C6.31607 9.44129 6 10.2044 6 11V12H26V11C26 10.2044 25.6839 9.44129 25.1213 8.87868C24.5587 8.31607 23.7956 8 23 8H9C8.20435 8 7.44129 8.31607 6.87868 8.87868ZM6.87868 25.1213C7.44129 25.6839 8.20435 26 9 26H23C23.7956 26 24.5587 25.6839 25.1213 25.1213C25.6839 24.5587 26 23.7956 26 23V14H6V23C6 23.7956 6.31607 24.5587 6.87868 25.1213ZM17.5 19C17.5 19.8284 16.8284 20.5 16 20.5C15.1716 20.5 14.5 19.8284 14.5 19C14.5 18.1716 15.1716 17.5 16 17.5C16.8284 17.5 17.5 18.1716 17.5 19ZM11 20.5C11.8284 20.5 12.5 19.8284 12.5 19C12.5 18.1716 11.8284 17.5 11 17.5C10.1716 17.5 9.5 18.1716 9.5 19C9.5 19.8284 10.1716 20.5 11 20.5ZM22.5 19C22.5 19.8284 21.8284 20.5 21 20.5C20.1716 20.5 19.5 19.8284 19.5 19C19.5 18.1716 20.1716 17.5 21 17.5C21.8284 17.5 22.5 18.1716 22.5 19Z" fill="#E1E1E1"/>
                                </svg>
                            </div>
                            <div *ngIf="isChangeNewDate" class="widget__icon widget__icon-spinner spinner-circle"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

		<ng-container
			*ngIf="isLoading == true; then loaderTemplate; else offersTemplate">
		</ng-container>

        <div class="offer-list">

            <app-pre-offer
                *ngIf="recommendedOffer !== null"
                [offer]="recommendedOffer"
                [isRecommended]="true"
                [showStartDate]="true"
                [isActualOffer]="true"
                (selected)="onOfferSelected(recommendedOffer)"
                (clicked)="onOfferTooltipClicked($event)"
            ></app-pre-offer>

            <ng-container *ngFor="let offer of approvedOffers; let i=index;">
                <app-pre-offer
                    [class.priorityOfferBlock]="offer?.insurerType === 'Alfa' && !checkPriorityApiKey"
                    *ngIf="approvedOffers.length"
                    [offer]="offer"
                    [isProfit]="i == 0"
                    [showStartDate]="(true)"
                    [isActualOffer]="(true)"
                    (selected)="onOfferSelected(offer)"
                    (clicked)="onOfferTooltipClicked($event)"
                ></app-pre-offer>
            </ng-container>

        </div>

        <div class="search-container-text" *ngIf="!isLoadingPage && preofferApproved !== true">
            <div class="widget-header-container">
                <div class="search-text">
                    Поиск предложений может занять до 2-х минут.
                    <br><br>
                    Финальное решение о заключении договора принимает страховая компания. Если не удалось получить нужное вам предложение, вы можете обратиться напрямую в офис страховой.
                </div>
            </div>
        </div>

	</div>
</div>

<ng-template #loaderTemplate>

	<div class="loader-template">
        <div class="loader-block">
            <div class="widget__loader active"></div>
        </div>
	</div>

</ng-template>

<ng-template #offersTemplate>

	<ng-container *ngIf="preofferApproved == true">
		<app-price-summary [showClose]="(false)"></app-price-summary>
	</ng-container>

	<div class="complete-search-offers">

        <div class="loader-template" *ngIf="(isLoadingOffers || isChangeNewDate || !osagoService.offersReceived) && recommendedOffer === null && approvedOffers.length === 0">
            <div class="loader-block">
                <div class="widget__loader active"></div>
            </div>
        </div>

        <div class="deniedOffers" *ngIf="(!isLoadingOffers && !isChangeNewDate && osagoService.offersReceived) && recommendedOffer === null && approvedOffers.length === 0 && checkPriorityApiKey">
            <div class="empty-block">
                <div class="emoji">
                    <img src="./assets/images/icons/empty-offers-emoji.png" alt="">
                </div>
                <div class="empty-block-title">
                    К сожалению, предложений нет
                </div>
                <div *ngIf="!isBcsApiKey" class="empty-info">
                    <p class="empty-offers-text">
                        Мы не получили финальное предложение для вас от страховых компаний.<br>
                        Попробуйте изменить дату страхования или <a (click)="isShowConfirm = true">данные водителя/собственника ТС</a><br>
                        Наличие водителей с КБМ больше 1 существенно снижает вероятность получения предложений.
                    </p>
                    <p class="empty-offers-text-hidden"></p>
                    <div class="empty-control">
                        <app-button
                            buttonType="submit"
                            modifier="blue-button"
                            (click)="isShowConfirm = true">
                            Проверить даннные
                        </app-button>
                    </div>
                </div>
                <div *ngIf="isBcsApiKey" class="empty-info">
                    <p class="empty-offers-text">
                        Мы не получили финальное предложение для вас от страховых компаний.<br>
                        Попробуйте изменить дату страхования или <a (click)="isShowConfirm = true">данные водителя/собственника ТС.</a><br>
                        Наличие водителей с КБМ больше 1 существенно снижает вероятность получения предложений.
                    </p>
                    <p class="empty-offers-text-hidden"></p>
                    <div class="empty-control">
                        <app-button
                            buttonType="submit"
                            modifier="blue-button"
                            (click)="isShowConfirm = true">
                            Проверить даннные
                        </app-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="deniedOffers" *ngIf="(!isLoadingOffers && !isChangeNewDate && osagoService.offersReceived) && (recommendedOffer || approvedOffers.length > 0) && !checkPriorityOffer && !checkPriorityApiKey">
            <div class="empty-block">
                <div class="empty-info">
                    <p class="empty-offers-text">
                        К сожалению, нам не удалось создать полис по заданным параметрам. Вы можете воспользоваться предложениями других компаний.
                    </p>
                </div>
            </div>
        </div>

	</div>

</ng-template>

<ng-container *ngIf="isPopupOpen">
	<div class="popupBg popupBg-active"></div>
	<div class="popupCalcInfo popupCalcInfo-active">
		<app-popup-price
			[model]="priceSummary"
			(closed)="togglePopup()">
		</app-popup-price>
	</div>
</ng-container>

<ng-container *ngIf="isShowConfirm">
    <div class="popupBg popupBg-active"></div>
    <div class="popupMain popupMain-active">
        <div class="popupMain__close" (click)="isShowConfirm = false;">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">	<path d="M17.9998 6.00006C17.8123 5.81259 17.558 5.70728 17.2928 5.70728C17.0277 5.70728 16.7733 5.81259 16.5858 6.00006L11.9998 10.5861L7.41382 6.00006C7.22629 5.81259 6.97198 5.70728 6.70682 5.70728C6.44165 5.70728 6.18735 5.81259 5.99982 6.00006C5.81235 6.18759 5.70703 6.4419 5.70703 6.70706C5.70703 6.97223 5.81235 7.22653 5.99982 7.41406L10.5858 12.0001L5.99982 16.5861C5.81235 16.7736 5.70703 17.0279 5.70703 17.2931C5.70703 17.5582 5.81235 17.8125 5.99982 18.0001C6.18735 18.1875 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1875 7.41382 18.0001L11.9998 13.4141L16.5858 18.0001C16.7733 18.1875 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1875 17.9998 18.0001C18.1873 17.8125 18.2926 17.5582 18.2926 17.2931C18.2926 17.0279 18.1873 16.7736 17.9998 16.5861L13.4138 12.0001L17.9998 7.41406C18.1873 7.22653 18.2926 6.97223 18.2926 6.70706C18.2926 6.4419 18.1873 6.18759 17.9998 6.00006Z" fill="#979797"/></svg>
        </div>
        <app-confirm
            [editMode]="(true)"
            (close)="changeConformData($event)">
        </app-confirm>
    </div>
</ng-container>

<ng-container *ngIf="isShowKbmDialog">
    <div class="popupBg popupBg-active"></div>
    <div class="popupMain popupMain-active">
        <div class="popupMain__close" (click)="isShowKbmDialog = false;">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">	<path d="M17.9998 6.00006C17.8123 5.81259 17.558 5.70728 17.2928 5.70728C17.0277 5.70728 16.7733 5.81259 16.5858 6.00006L11.9998 10.5861L7.41382 6.00006C7.22629 5.81259 6.97198 5.70728 6.70682 5.70728C6.44165 5.70728 6.18735 5.81259 5.99982 6.00006C5.81235 6.18759 5.70703 6.4419 5.70703 6.70706C5.70703 6.97223 5.81235 7.22653 5.99982 7.41406L10.5858 12.0001L5.99982 16.5861C5.81235 16.7736 5.70703 17.0279 5.70703 17.2931C5.70703 17.5582 5.81235 17.8125 5.99982 18.0001C6.18735 18.1875 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1875 7.41382 18.0001L11.9998 13.4141L16.5858 18.0001C16.7733 18.1875 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1875 17.9998 18.0001C18.1873 17.8125 18.2926 17.5582 18.2926 17.2931C18.2926 17.0279 18.1873 16.7736 17.9998 16.5861L13.4138 12.0001L17.9998 7.41406C18.1873 7.22653 18.2926 6.97223 18.2926 6.70706C18.2926 6.4419 18.1873 6.18759 17.9998 6.00006Z" fill="#979797"/></svg>
        </div>
        <div class="drivers-kbm-container">
            <div class="drivers-header">
                <div class="drivers-kbm-container-title">
                    Водители
                    <div class="drivers-kbm-container-pre-title">Для расчета ОСАГО всегда используется самый высокий КБМ
                        (безаварийность) из всех добавленных водителей</div>
                </div>
                <button [disabled]="isChangeNewDate"
                        routerLink="/info/drivers" class="btn-outline show-desktop">Редактировать</button>
            </div>

            <div class="osago-list">
                <ul>
                    <li *ngFor="let driver of priceService.driversKbm; let i = index;">
                        <div class="driver-wrap" *ngIf="driver?.fio">
                            <div class="driver-name">
                                {{ driver?.fio }}
                                {{maxKbmIndexDriver === i ? '(КБМ применён для расчёта)' : ''}}
                            </div>
                            <div class="driver-kbm" *ngIf="driver.status === 'Complete' || driver.status === 'Error'">
                                <div>
                                    КБМ: {{ driver.kbm }}
                                </div>
                                <div class="driver-kbm-text green" *ngIf="+driver.kbm < 1">Скидка {{ driver.kbmPercent }}%</div>
                                <div class="driver-kbm-text orange" *ngIf="+driver.kbm > 1">Надбавка {{ driver.kbmPercent }}%</div>
                                <div class="driver-kbm-text" *ngIf="+driver.kbm == 1">1 / Нет скидки</div>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>

            <div class="actions">
                <button [disabled]="isChangeNewDate"
                        routerLink="/info/drivers" class="blue-button show-mobile">Редактировать</button>
                <button (click)="isShowKbmDialog = false;" class="btn-outline show-mobile">Закрыть</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="osagoService.isPaymentError">
    <div class="popupBg popupBg-active"></div>
    <div class="popupMain popupMain-active offer-error-popup">
        <div class="widget__company-container">
            <img [src]="osagoService?._selectedOffer?.logo" [alt]="osagoService?._selectedOffer?.altText"/>

            <div class="company-name">
                <strong>{{ osagoService?._selectedOffer?.productName || osagoService?._selectedOffer?.insurerName }}</strong>
                <div class="start-date">
                        <span class="rate-gray">Начало действия: {{ osagoService?._selectedOffer?.startDateDescription }}</span>
                    </div>
                </div>
        </div>
        <div class="offer-error-text">
            Страховая компания не ответила
            <div *ngIf="osagoService?._selectedOffer?.insurerType !== 'Sber'">
                Возможно, дело в технической ошибке. Рекомендуем выбрать другое предложение
            </div>
            <div *ngIf="osagoService?._selectedOffer?.insurerType === 'Sber'">
                Возможно, дело в технической ошибке. Рекомендуем изменить номер телефона в анкете и попробовать повторить оплату
            </div>
        </div>
        <button (click)="osagoService._isPaymentError = false;" class="btn-outline">Выбрать другое предложение</button>
    </div>
</ng-container>
