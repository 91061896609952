import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

// Компоненты
import { BaseComponent } from '../../../../shared/components/base/base.component';

@Component({
    selector: 'app-license-number',
    templateUrl: './license-number.component.html'
})
export class LicenseNumberComponent extends BaseComponent {
    @Input() fieldType = 'text';
    @Output() changed = new EventEmitter<void>();
    @HostListener('keydown', ['$event'])

    // Событие нажатия
    public onKeyDown(event: any): void {
        if (event.key === 'ArrowRight' && this.getCaretPosition(event.target) === 6) {
            this.filled.emit();
            event.preventDefault();
        }
    }

    // Событие ввода
    public onInput(event: any): void {
        if (event.target.value != null && event.target.value.length === 6) {
            this.currentValue = event.target.value;
            if (this.getCaretPosition(event.target) === 6) { this.filled.emit(); }
        }
        this.changed.emit();
    }
}
