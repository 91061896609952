import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
    ConfirmationComponent,
    ContactsComponent,
    DocumentsComponent,
    DriversComponent,
    OffersComponent,
    OwnerComponent,
    PaymentComponent,
    PriceComponent,
    VendorsComponent,
    OrderingComponent,
    ConfirmComponent,
    PayComponent,
    PaymentLinkComponent
} from './components';
import { NavigationGuard } from '../shared/guards/navigation.guard';

const routes: Routes = [
    {
        path: '', children: [
            { path: 'car', component: VendorsComponent, canActivate: [NavigationGuard] },
            { path: 'confirmation', component: ConfirmationComponent, canActivate: [NavigationGuard] },
            { path: 'contacts', component: ContactsComponent, canActivate: [NavigationGuard] },
            { path: 'documents', component: DocumentsComponent, canActivate: [NavigationGuard] },
            { path: 'drivers', component: DriversComponent, canActivate: [NavigationGuard] },
            { path: 'offers', component: OffersComponent, canActivate: [NavigationGuard] },
            { path: 'owner', component: OwnerComponent, canActivate: [NavigationGuard] },
            { path: 'payment', component: PaymentComponent, canActivate: [NavigationGuard] },
            { path: 'price', component: PriceComponent, canActivate: [NavigationGuard] },
            { path: 'ordering', component: OrderingComponent, canActivate: [NavigationGuard] },
            { path: 'confirm', component: ConfirmComponent, canActivate: [NavigationGuard] },
            { path: 'pay', component: PayComponent, canActivate: [NavigationGuard] },
            { path: 'paymentlink', component: PaymentLinkComponent },
            { path: '', redirectTo: 'car', pathMatch: 'full'},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OsagoRoutingModule {}
