import { LicenseApplication } from './licenseApplication.model';
import { LicenseOsago } from './licenseOsago.model';

export class OsagoClient {
    constructor(
        public applications: LicenseApplication[],
        public osagoPolicies: LicenseOsago[]
    ) { }

    public static create(): OsagoClient {
        return {
            applications: [],
            osagoPolicies: []
        };
    }
}
