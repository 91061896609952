<div class="alertBlock">
	<!-- <div class="alertBlock__close">
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9998 5.99982C17.8123 5.81235 17.558 5.70703 17.2928 5.70703C17.0277 5.70703 16.7733 5.81235 16.5858 5.99982L11.9998 10.5858L7.41382 5.99982C7.22629 5.81235 6.97198 5.70703 6.70682 5.70703C6.44165 5.70703 6.18735 5.81235 5.99982 5.99982C5.81235 6.18735 5.70703 6.44165 5.70703 6.70682C5.70703 6.97198 5.81235 7.22629 5.99982 7.41382L10.5858 11.9998L5.99982 16.5858C5.81235 16.7733 5.70703 17.0277 5.70703 17.2928C5.70703 17.558 5.81235 17.8123 5.99982 17.9998C6.18735 18.1873 6.44165 18.2926 6.70682 18.2926C6.97198 18.2926 7.22629 18.1873 7.41382 17.9998L11.9998 13.4138L16.5858 17.9998C16.7733 18.1873 17.0277 18.2926 17.2928 18.2926C17.558 18.2926 17.8123 18.1873 17.9998 17.9998C18.1873 17.8123 18.2926 17.558 18.2926 17.2928C18.2926 17.0277 18.1873 16.7733 17.9998 16.5858L13.4138 11.9998L17.9998 7.41382C18.1873 7.22629 18.2926 6.97198 18.2926 6.70682C18.2926 6.44165 18.1873 6.18735 17.9998 5.99982Z" fill="#979797"/></svg>
	</div> -->
	<div class="alertBlock__wrapper">
		<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M85.3732 14.6316C65.8671 -4.87641 34.131 -4.87641 14.6287 14.6269C-4.87747 34.1302 -4.87559 65.8672 14.6305 85.3743C34.131 104.877 65.8671 104.877 85.3714 85.3705C104.876 65.8672 104.875 34.1321 85.3732 14.6316ZM79.915 79.916C63.4204 96.4106 36.5806 96.4125 20.085 79.9179C3.58668 63.4205 3.58857 36.5779 20.085 20.0833C36.5796 3.58963 63.4185 3.58775 79.9168 20.0852C96.4114 36.5798 96.4096 63.4224 79.915 79.916ZM72.6171 69.7736C73.2375 71.2077 72.5775 72.8718 71.1444 73.4913C69.7103 74.1136 68.0461 73.4517 67.4257 72.0185C64.698 65.7117 58.2515 61.6356 50.9999 61.6356C43.5814 61.6356 37.0954 65.7079 34.4789 72.011C34.0273 73.0991 32.9741 73.7562 31.8662 73.7562C31.5042 73.7562 31.1374 73.6865 30.7819 73.5394C29.3393 72.9397 28.6558 71.284 29.2545 69.8424C32.7525 61.4206 41.2893 55.9793 50.9999 55.9793C60.5078 55.9793 68.9937 61.3933 72.6171 69.7736ZM31.341 36.4619C31.341 33.2335 33.9594 30.6152 37.1878 30.6152C40.4152 30.6152 43.0336 33.2326 43.0336 36.4619C43.0336 39.6922 40.4152 42.3096 37.1878 42.3096C33.9594 42.3096 31.341 39.6922 31.341 36.4619ZM57.8518 36.4619C57.8518 33.2335 60.472 30.6152 63.7004 30.6152C66.9278 30.6152 69.5462 33.2326 69.5462 36.4619C69.5462 39.6922 66.9288 42.3096 63.7004 42.3096C60.472 42.3096 57.8518 39.6922 57.8518 36.4619Z" fill="#ED2E7E"/></g><defs><clipPath id="clip0"><rect width="100" height="100" fill="white"/></clipPath></defs></svg>
		<h3>Что-то пошло не так...</h3>
		<p>Мы уже работаем над устранением данной ошибки,<br> попробуйте обновить страницу</p>
        <button class="blue-button mt15" (click)="refresh()" type="button">
            <span>Обновить страницу</span>
        </button>
	</div>
</div>
