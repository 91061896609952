import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Converter } from "../../../../../../../common-lib//src/lib/utils/converters";
import { DictionaryItem } from "../../../../../../../common-lib/src/lib/models";
import { ApplicationService } from "../../../../services";
import { OsagoValidators, TextMaskHelper } from "../../helpers";
import { OsagoCarDocument, OsagoIdentifier } from "../../models";
import { FormsService, OsagoService } from "../../services";
import * as moment from 'moment-mini';
import { Subscription } from "rxjs";

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html'
})

export class DocumentsComponent implements AfterViewInit, OnDestroy {
    documentsForm: FormGroup;
    selectedDocument = 1;
    selectedIdentifier = 0;
    maxDate = moment().format('DD.MM.YYYY');
    isFormError = false;
    isSubmit = false;
    needCheckValidity = false;
    carDescription = "";
    isPopupOpen = false;
    isHover = false;
    private _validatorsSet = false;
    private _subscription = new Subscription();
    private _interval = 0;

    documentTypes = [
        new DictionaryItem(0, "ПТС"),
        new DictionaryItem(1, "СТС"),
        new DictionaryItem(2, "еПТС")
    ];

    identifiers = [
        new DictionaryItem(0, "VIN"),
        new DictionaryItem(1, "Номер кузова"),
        new DictionaryItem(2, "Номер шасси")
    ];

    masks = {
        sts: [ /\d/, /\d/,  ' ', /^[0-9а-яА-ЯёЁ]$/, /^[0-9а-яА-ЯёЁ]$/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/ ],
        pts: [ /\d/, /\d/,  ' ', /^[а-яА-ЯёЁ]$/, /^[а-яА-ЯёЁ]$/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/ ],
        epts: [ /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/ ],
        vin: function () {
            var index = 0;
            var result = [];
            while (index < 19) {
                if (index ==3 || index == 10)
                    result.push(' ');
                else
                    result.push(/^[0-9a-jklmnpr-zKLMNPR-ZA-JKLMNPR-Z]$/);

                index++;
            }

            return result;
        }
    };

    constructor(
        fb: FormBuilder,
        private appService: ApplicationService,
        private formsService: FormsService,
        private osagoService: OsagoService
    ) {
        let documentType = parseInt(osagoService.carData?.carDocument?.documentType || '');
        if (isNaN(documentType) || documentType < 0 || documentType > 2)
            documentType = 1;

        let pts = null;
        let sts = null;
        let epts = null;

        const documentNumber = (osagoService.carData?.carDocument?.documentSeries || '') + (osagoService.carData?.carDocument?.documentNumber || '')
        switch (documentType) {
            case 0:
                if (documentNumber.length == 10)
                    pts = documentNumber.substr(0, 2) + " " + documentNumber.substr(2, 2) + " " + documentNumber.substr(4, 3) + " " + documentNumber.substr(7);
                break;
            case 1:
                if (documentNumber.length == 10)
                    sts = documentNumber.substr(0, 2) + " " + documentNumber.substr(2, 2) + " " + documentNumber.substr(4, 3) + " " + documentNumber.substr(7);
                else
                    sts = TextMaskHelper.toPtsFormat(appService.carData?.stsSeriesNumber || null);
                break;
            case 2:
                if (documentNumber.length == 15)
                    epts = documentNumber.substr(0, 5) + " " + documentNumber.substr(5, 5) + " " + documentNumber.substr(10);
                break;
        }

        let date = null;
        if (osagoService.carData?.carDocument?.documentIssueDate != null && osagoService.carData?.carDocument?.documentIssueDate.length > 0) {
            const d = moment(osagoService.carData?.carDocument?.documentIssueDate, "DD.MM.YYYY", true);
            if (d.isValid())
                date = d.format("YYYY-MM-DD");
            else
                date = osagoService.carData?.carDocument?.documentIssueDate;
        } else if (documentType == 1)
            date = Converter.toDate(appService.carData?.stsIssueDate);

        let identifier = 0;
        let vin = null;

        if (osagoService.carData?.carIdentificators?.bodyNumber != null && osagoService.carData?.carIdentificators?.bodyNumber.length > 0)
            identifier = 1;

        if (osagoService.carData?.carIdentificators?.chassisNumber != null && osagoService.carData?.carIdentificators?.chassisNumber.length > 0)
            identifier = 2;

        if (osagoService.carData?.carIdentificators?.vin != null && osagoService.carData?.carIdentificators?.vin.length == 17)
            vin = TextMaskHelper.toVinFormat(osagoService.carData?.carIdentificators?.vin);
        else if (appService.carData?.vin != null)
            vin = TextMaskHelper.toVinFormat(appService.carData?.vin);

        this.documentsForm = fb.group({
            "document-type": new FormControl(documentType, null),
            "pts": new FormControl(pts, null),
            "pts-date": new FormControl(date, null),
            "sts": new FormControl(sts, null),
            "sts-date": new FormControl(date, null),
            "epts": new FormControl(epts, null),
            "epts-date": new FormControl(date, null),
            "identifier": new FormControl(identifier, null),
            "vin": new FormControl(vin, null),
            "chassis-number": new FormControl(osagoService.carData?.carIdentificators?.chassisNumber, null),
            "body-number": new FormControl(osagoService.carData?.carIdentificators?.bodyNumber, null),
        }, { updateOn: 'change' });

        this._subscription.add(
            this.documentType!.valueChanges.subscribe((value: number) => this.selectedDocument = value)
        );

        this._subscription.add(
            this.identifier!.valueChanges.subscribe((value: number) => this.selectedIdentifier = value)
        );

        this._subscription.add(
            this.documentsForm.valueChanges.subscribe(x => {
                this.saveState();
                this.needCheckValidity = true;
            })
        );

        this._interval = window.setInterval(() => {
            if (this.needCheckValidity) {
                this.needCheckValidity = false;
                this.isFormError = this.isSubmit && !this.formsService.isFormValid(this.documentsForm, false, false, false);
            }
        }, 100)

        this._subscription.add(
            osagoService.carDescription$
                .subscribe((value) => this.carDescription = value || "")
        );
    }

    ngAfterViewInit() {
        this.osagoService.onYandexReachGoal("CAR_DOCUMENTS_GOAL");
        this.appService.scrollToElement("documents-anchor");
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
        window.clearInterval(this._interval);
    }

    private setValidators() {
        if (this._validatorsSet)
            return;

        this._validatorsSet = true;
        this.pts?.setValidators([ OsagoValidators.pts(() => { return this.selectedDocument != 0; }) ]);
        this.ptsDate?.setValidators([ OsagoValidators.ptsDate(() => { return this.selectedDocument != 0; }) ]);
        this.sts?.setValidators([ OsagoValidators.sts(() => { return this.selectedDocument != 1; }) ]);
        // this.stsDate?.setValidators([ OsagoValidators.stsDate(() => { return this.selectedDocument != 1; }) ]);
        this.epts?.setValidators([ OsagoValidators.epts(() => { return this.selectedDocument != 2; }) ]);
        this.eptsDate?.setValidators([ OsagoValidators.eptsDate(() => { return this.selectedDocument != 2; }) ]);
        this.vin?.setValidators([ OsagoValidators.vinNumber(() => { return this.selectedIdentifier != 0; }) ]);
        this.chassisNumber?.setValidators([ OsagoValidators.chassisNumber(() => { return this.selectedIdentifier != 2; }) ]);
        this.bodyNumber?.setValidators([ OsagoValidators.bodyNumber(() => { return this.selectedIdentifier != 1; }) ]);
    }

    get documentType(): AbstractControl | null {
        return this.documentsForm.get('document-type');
    }

    get pts(): AbstractControl | null {
        return this.documentsForm.get('pts');
    }

    get ptsDate(): AbstractControl | null {
        return this.documentsForm.get('pts-date');
    }

    get sts(): AbstractControl | null {
        return this.documentsForm.get('sts');
    }

    get stsDate(): AbstractControl | null {
        return this.documentsForm.get('sts-date');
    }

    get epts(): AbstractControl | null {
        return this.documentsForm.get('epts');
    }

    get eptsDate(): AbstractControl | null {
        return this.documentsForm.get('epts-date');
    }

    get identifier(): AbstractControl | null {
        return this.documentsForm.get('identifier');
    }

    get vin(): AbstractControl | null {
        return this.documentsForm.get('vin');
    }

    get chassisNumber(): AbstractControl | null {
        return this.documentsForm.get('chassis-number');
    }

    get bodyNumber(): AbstractControl | null {
        return this.documentsForm.get('body-number');
    }

    onMouseEnter() {
		this.isHover = true;
	}

	onMouseLeave() {
		this.isHover = false;
	}

    toggleLicensePopup() {
        this.isPopupOpen = !this.isPopupOpen;
    }

    onBackClick() {
        this.saveState();
        this.appService.onDocumentsBack();
    }

    onSubmit(event: any) {
        this.isSubmit = true;
        this.setValidators();
        if (!this.formsService.isValid(this.documentsForm))
            return;

        this.osagoService.onDocumentsComplete(this.getDocument(), this.getIdentifier(), true);
        this.appService.onDocumentsComplete();
    }

    private saveState() {
        this.osagoService.onDocumentsComplete(this.getDocument(), this.getIdentifier());
    }

    private getDocument(): OsagoCarDocument {
        return {
            documentIssueDate: this.getDocumentIssueDate(),
            documentNumber: this.getDocumentNumber(),
            documentSeries: this.getDocumentSeries(),
            documentType: this.getDocumentType()
        };
    }

    private getIdentifier(): OsagoIdentifier {
        const identifier = typeof this.selectedIdentifier == "string" ? parseInt(this.selectedIdentifier) : this.selectedIdentifier;
        switch (identifier) {
            case 0:
                return {
                    vin: TextMaskHelper.removeMask(this.vin!.value || '', 17)
                };
            case 1:
                return {
                    bodyNumber: this.bodyNumber!.value
                };
            default:
                return {
                    chassisNumber: this.chassisNumber!.value
                };
        }
    }

    private getDocumentType(): string {
        const document = typeof this.selectedDocument == "string" ? parseInt(this.selectedDocument) : this.selectedDocument;
        switch (document) {
            case 0:
                return "PTS";
            case 1:
                return "STS";
            case 2:
                return "ePTS";
        }

        return "";
    }

    private getDocumentIssueDate(): string {
        const document = typeof this.selectedDocument == "string" ? parseInt(this.selectedDocument) : this.selectedDocument;
        let result = "";
        switch (document) {
            case 0:
                result = this.ptsDate!.value || '';
                break;
            case 1:
                result = this.stsDate!.value || '';
                break;
            case 2:
                result = this.eptsDate!.value || '';
                break;
        }

        return result.length > 0
            ? TextMaskHelper.removeMask(Converter.toDateRu(result))
            : result;
    }

    private getDocumentNumber(): string {
        const document = typeof this.selectedDocument == "string" ? parseInt(this.selectedDocument) : this.selectedDocument;
        switch (document) {
            case 0:
                return TextMaskHelper.removeMask(this.pts!.value || '', 10).substr(4);
            case 1:
                return TextMaskHelper.removeMask(this.sts!.value || '', 10).substr(4);
            case 2:
                return TextMaskHelper.removeMask(this.epts!.value  || '', 15);
        }

        return "";
    }

    private getDocumentSeries(): string | undefined {
        const document = typeof this.selectedDocument == "string" ? parseInt(this.selectedDocument) : this.selectedDocument;
        switch (document) {
            case 0:
                return TextMaskHelper.removeMask(this.pts!.value || '', 10).substr(0, 4);
            case 1:
                return TextMaskHelper.removeMask(this.sts!.value || '', 10).substr(0, 4);
            case 2:
                return undefined;
        }

        return "";
    }
}
