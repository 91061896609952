import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OsagoService} from '../../../services';
import {Subscription} from 'rxjs';
import {ApplicationService, LicensePlateService} from "../../../../../services";

@Component({
    selector: 'app-podeli-price',
    templateUrl: './podeli-price.component.html',
    styleUrls: ['./podeli-price.component.scss']
})
export class PodeliPriceComponent implements OnInit, OnDestroy {
    @Input() horsePower!: string;
    @Input() hasNoLicencePlate!: boolean;
    @Input() regionCode!: number;

    public maximalCost = null;
    public middleCost = null;
    public minimalCost = null;

    public splittedMax = null;
    public splittedMiddle = null;
    public splittedMinimal = null;

    constructor(private osagoService: OsagoService,
                private licenseService: LicensePlateService,
                private appService: ApplicationService) {
    }

    private subscription = new Subscription();

    public ngOnInit(): void {
        const license = this.appService.licensePlate || '';
        const region = this.licenseService.getLicenseRegion(license);
        const horsePower = this.horsePower?.replace(' л.с.', '');
        this.subscription.add(this.osagoService.getPodeliPrice(horsePower, license === null, region)
            .subscribe((price) => {
            this.maximalCost = price?.value?.maximalCost;
            this.middleCost = price?.value?.middleCost;
            this.minimalCost = price?.value?.minimalCost;
            this.splittedMax = price?.value?.splittedMax;
            this.splittedMiddle = price?.value?.splittedMiddle;
            this.splittedMinimal = price?.value?.splittedMinimal;
        }));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
