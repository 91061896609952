import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-osago-router',
    templateUrl: './osago-router.component.html',
    styleUrls: ['./osago-router.component.scss']
})

export class OsagoRouterComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
