import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Offer, OsagoApplication, OsagoDriver, OsagoPolicy, PriceSummary} from '../../models';
import {ApplicationService} from '../../../../services';
import {OsagoService, PriceCalculatorService} from '../../services';
import * as moment from 'moment-mini';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {ClientService} from '../../../../services/client.service';
import {Subscription, timer} from 'rxjs';
import {DatepickerOptions} from 'ng2-datepicker';
import {getYear} from 'date-fns';
import locale from 'date-fns/locale/ru';
import {Converter} from '../../../../../../../common-lib/src/lib/utils/converters';
import {TextMaskHelper} from '../../helpers';
import {IDriversForOffersList} from '../../../shared/interfaces/drivers-for-offers-list';
import {LoggingService} from '../../../../services/loggingService';
import { switchMap } from 'rxjs/operators';

@Component({
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit, OnDestroy, AfterViewInit {
    isLoading = true;
    isLoadingOffers = true;
    approvedOffers: Offer[] = [];
    deniedOffers: Offer[] = [];
    recommendedOffer: Offer | null = null;
    recommendedDeniedOffer: Offer | null = null;
    hasOffers = false;
    timeLeft: string | null = null;
    interval = 0;
    preofferApproved = false;
    preOffer: Offer | null = null;
    recommendedInsurer: string | null = null;
    isPopupOpen = false;
    priceSummary: PriceSummary | null = null;
    // Стартовая дата начала полиса
    public startDate: string | undefined;
    // Форма
    public form: FormGroup = new FormGroup({});
    // Данные осаго
    public osago: OsagoApplication | null;
    private start = moment();
    // Рекомендуемая дата начала действия осаго
    public recommendDate = moment().add(4, 'day').format('DD.MM.YYYY');

    // КВС
    public ageRate: number | null = null;
    // КБМ
    public kbmRate: string | null = null;
    // Окно КВС, КБМ
    public popupOpen = false;
    // Если выбрали новую дату
    public isChangeNewDate = false;
    // Подписка
    private subscription = new Subscription();
    // Подписка на создание новой заявки
    private createApplicationSubscription = new Subscription();
    // Открываем страницу "редактирования"
    public isShowConfirm = false;
    // Максимальная дата выбора полиса + 60 дней
    public maxDate = moment().add(2, 'months').format('DD.MM.YYYY');
    // Дата начала действия полиса
    public date: FormControl = new FormControl('');
    public maxDateCalendar = new Date();
    // Возраст страхователя
    public ownerAge = '';
    // Максимальный КБМ
    public maxKbm!: number;
    // Максимальный КБМ в прцоентах
    public maxKbmPercent!: string;
    // Индекс водителя с максимальным КБМ
    public maxKbmIndexDriver!: number;
    // Значение для графика круга
    public strokeDashoffset: string | undefined;
    // Открыть окно с данными КБМ
    public isShowKbmDialog = false;
    // Фио страховщика
    public ownerFio!: string | undefined;
    // Оффер пролонгации
    public prolongationOffer!: Offer;

    // Настройки для календаря
    public options: DatepickerOptions = {
        minDate: new Date(),
        maxDate: this.maxDateCalendar,
        minYear: getYear(new Date()),
        maxYear: getYear(new Date()),
        format: 'dd.MM.yyyy',
        formatTitle: 'dd.MM.yyyy',
        firstCalendarDay: 1,
        locale,
        position: 'bottom',
        inputClass: '',
        calendarClass: 'datepicker-default',
        scrollBarColor: '#dfe3e9',
        enableKeyboard: true
    };

    // Индикатор загрузки
    public isLoadingPage = true;
    // Текущий водитель
    public currentDriver: OsagoDriver | undefined;
    // Стаж вождения (лет)
    public driverExperienceAge: string | undefined;
    // Серия и номер ВУ
    public licenseDriver: string | undefined;
    // Склонения значения год
    public ageValuesList = ['год', 'года', 'лет'];
    public drivers: OsagoDriver[];
    // Ошибка получения ссылки на оплату
    public isShowPayError: boolean | null | undefined;
    // Водители с КБМ
    public driversKbm: IDriversForOffersList[] = [];
    // Если хотя бы один водитель получил КБМ c ошибкой
    public driverStatusError = false;

    get policyDate(): AbstractControl | null{
        return this.form.get('policyDate');
    }

    // Проверка приоритетного оффера
    get checkPriorityOffer(): boolean {
        return (this.approvedOffers.find(item => item.insurerType === 'Alfa') !== null
            || this.recommendedOffer?.insurerType === 'Alfa');
    }

    get checkPriorityApiKey(): boolean {
        return this.osagoService.getApiKey() !== '090c9d5eb76f486d85094d165e324b75';
    }

    get isBcsApiKey(): boolean {
        return this.osagoService.getApiKey() !== 'ff3926f1d1f0427c9d04c44aa27928f1';
    }

    // Следующий день для проллонгации
    get getNextDayProlongation(): string {
        const parsedDate = moment(this.prolongationOffer.policyExpireDate, 'DD.MM.YYYY');
        const newDate = parsedDate.add(1, 'day');
        return newDate.format('DD.MM.YYYY');
    }

    constructor(
        public appService: ApplicationService,
        public osagoService: OsagoService,
        public priceService: PriceCalculatorService,
        private loggingService: LoggingService,
        private readonly clientService: ClientService
    ) {
        // Максимальная дата выбора действия осаго, + 2 месяца от текущего дня
        this.maxDateCalendar.setMonth(this.maxDateCalendar.getMonth() + 2);
        this.osago = this.clientService.getCurrentApplication();
        this.preOffer = this.osagoService.preOffer;
        this.recommendedInsurer = this.appService.recommendedInsurer;
        this.appService._payUrl = null;
        this.appService.crossNotApproved = false;

        if (this.osagoService.offersStartTime != null) {
            this.start = this.osagoService.offersStartTime;
        } else {
            this.osagoService.offersStartTime = this.start;
        }

        this.subscription.add(
            this.osagoService.offers$.subscribe((offers) => {
                if (offers != null) {
                    if (offers.length === 0) {
                        this.isLoading = false;
                        window.clearInterval(this.interval);
                        return;
                    }

                    this.isLoading = !this.osagoService.offersReceived;
                    this.isLoadingOffers = !this.osagoService.offersReceived;
                    const index = offers.findIndex(x => x.isComplete === true && x.insurerType === this.osagoService.preOffer?.insurerType);
                    if (index !== -1 && offers[index].isApproved === true) {
                        this.preofferApproved = true;
                        if (!this.appService.offerSelected) {
                            this.onOfferSelected(offers[index]);
                            return;
                        }
                    }

                    const result = offers.filter(x => x.isComplete === true);
                    result.sort((a, b) => Offer.sort(a, b));
                    this.hasOffers = result.length > 0;
                    const approvedOffers = result.filter(x => x.isApproved === true);
                    this.updateApprovedOffers(approvedOffers);

                    const prolongationOffer = offers.find(x => x.isComplete && x.isProlongation && x.policyExpireDate);
                    if (prolongationOffer) {
                        this.prolongationOffer = prolongationOffer;
                    }
                    // const deniedOffers = result.filter(x => x.isApproved === false);
                    // this.updateDeniedOffers(deniedOffers);
                }
            })
        );

        this.subscription.add(
            this.priceService.ageRate$.subscribe(
                (value) => this.ageRate = value
            )
        );

        this.subscription.add(
            this.priceService.kbmRate$.subscribe(
                (value) => this.kbmRate = value
            )
        );

        this.interval = window.setInterval(() => this.calculateTimeLeft(), 100);

        this.osagoService.paymentResult$.subscribe((paymentResult) => {
            this.isShowPayError = paymentResult;
        });
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.loggingService.trace('OffersComponent', 'ngOnInit');
        this.initPage();
    }

    public ngAfterViewInit(): void {
        this.osagoService.onYandexReachGoal('OFFERS_GOAL');
        this.appService.scrollToElement('offers-anchor', 'offers-list');
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.stopSubscribe();
        this.subscription.unsubscribe();
        this.createApplicationSubscription.unsubscribe();
    }
    // --------------------------------------------------------------------------

    // Инициализация страницы
    public initPage(): void {
        this.subscription.add(
            this.appService.onPreloadedApp$.subscribe((value) => {
                if (value === true && this.appService.onlyOffersMode) {
                    this.loggingService.trace('OffersComponent', 'appService.onPreloadedApp$', 'calling changePolicyDate()');
                    this.osagoService.offersReceived = true;
                    this.appService.onLoadApp(false);
                }
            })
        );

        this.subscription.add(
            this.appService.onLoadApp$.subscribe((value) => {
                this.loggingService.trace('OffersComponent', 'appService.onLoadApp$', 'value = ' + value);
                this.isLoadingPage = value;
                if (!value) {
                    // Если мы уже делали заявку и ушли на редактирование, то нужно искать оферов с новой заявкой
                    if (this.osagoService.offersReceived) {
                        if (!this.appService.isRoutePayment) {
                            this.loggingService.trace('OffersComponent', 'appService.onLoadApp$', 'calling changePolicyDate()');
                            this.changePolicyDate(this.date.value);
                        }
                    } else {
                        this.loggingService.trace('OffersComponent', 'appService.onLoadApp$', 'calling osagoService.waitForOffers()');
                        this.osagoService.waitForOffers();
                    }
                    this.osago = this.clientService.getCurrentApplication();
                    const nameAndMiddleName = this.osago?.owner?.firstName && this.osago?.owner?.firstName?.length
                        ? this.osago?.owner?.firstName[0] + '. ' : '' + this.osago?.owner?.middleName && this.osago?.owner?.middleName?.length
                            ? this.osago?.owner?.middleName[0] + '. ' : '';
                    this.ownerFio = this.osago?.owner?.lastName + ' ' + nameAndMiddleName;
                    this.loggingService.debug('offers::initPage, osago set', this.osago);
                    this.createForm();
                    this.initKbmGraphData();
                }
            })
        );
    }

    // Создание формы
    private createForm(): void {
        const minDate = moment().add(4, 'day');
        this.startDate = minDate.format('DD.MM.YYYY');

        let policyDate = minDate.format('YYYY-MM-DD');
        this.loggingService.debug("offers::createForm, policyDate set", policyDate);
        this.loggingService.trace('OffersComponent', 'createForm');
        // где-то здесь ошибка на клиентеЮ примерно вот такая
        // https://stackoverflow.com/questions/54462227/rangeerror-invalid-time-value
        try {
            if (this.osagoService.policy?.policyStartDate != null && this.osagoService.policy?.policyStartDate.length > 0) {
                const date = moment(this.osagoService.policy?.policyStartDate, 'DD.MM.YYYY', true);
                if (date.isValid()) {
                    policyDate = date.format('YYYY-MM-DD');
                    this.loggingService.debug("OffersComponent::createForm, policyDate set to formatted policy.policyStartDate", policyDate);
                } else {
                    policyDate = this.osagoService.policy?.policyStartDate;
                    this.loggingService.debug("OffersComponent::createForm, policyDate set to unformatted policy.policyStartDate", policyDate);
                }
                // Если дата раньше текущей
                if (this.isDateBeforeToday(new Date(date.format('YYYY-MM-DD')))) {
                    policyDate = this.clientService.modifyPolicyDate(this.osagoService.policy?.policyStartDate);
                    this.loggingService.debug("OffersComponent::createForm, policyDate set to modifyPolicyDate from policy", policyDate);
                    // this.changePolicyDateAndSaveForm(policyDate);
                }
            } else if (this.osago?.policyParameters?.policyStartDate) {
                const date = moment(this.osago?.policyParameters?.policyStartDate, 'DD.MM.YYYY', true);
                if (date.isValid()) {
                    policyDate = date.format('YYYY-MM-DD');
                    this.loggingService.debug("OffersComponent::createForm, policyDate set to formatted policyParameters.policyStartDate", policyDate);
                } else {
                    policyDate = this.osago?.policyParameters?.policyStartDate;
                    this.loggingService.debug("OffersComponent::createForm, policyDate set to unformatted policyParameters.policyStartDate", policyDate);
                }

                // Если дата раньше текущей
                if (this.isDateBeforeToday(new Date(this.osago?.policyParameters?.policyStartDate))) {
                    policyDate = this.clientService.modifyPolicyDate(this.osago?.policyParameters?.policyStartDate);
                    this.loggingService.debug("OffersComponent::createForm, policyDate set to modifyPolicyDate from policyParameters", policyDate);
                }
            }
        } catch (e) { }

        // Если мы перешли с почты или смс, по умолчанию поиск офферов на дату +4 дня
        if (this.appService.onlyOffersMode) {
            policyDate = moment().add(4, 'day').format('YYYY-MM-DD');
        }

        this.date.setValue(moment(minDate).toDate());

        if (this.osago?.drivers) {
            this.drivers = this.osago.drivers;
            this.osagoService.onDriversComplete(this.osago?.drivers);
        }

        if (this.osago?.drivers) {
            this.osagoService.updateKbmDrivers(this.osago?.drivers);
            this.osago?.drivers.forEach((driver, index) => {
                if (driver) {
                    this.osagoService.getDriverKbm(driver, index);
                }
            });
            this.drivers = this.osago.drivers;
        }

        if (this.osago?.insured?.phone && this.osago?.insured?.email) {
            this.osagoService._insurerPhone = this.osago?.insured?.phone;
            this.osagoService._insurerEmail = this.osago?.insured?.email;
        }

        if (this.osago?.owner?.phone && this.osago?.owner?.email) {
            this.osagoService._insurerPhone = this.osago?.owner?.phone;
            this.osagoService._insurerEmail = this.osago?.owner?.email;
        }

        this.form = new FormGroup({
            policyDate: new FormControl(policyDate, null)
        }, { updateOn: 'change' });
    }

    private isDateBeforeToday(date: Date): boolean {
        return new Date(date.toDateString()) <= new Date(new Date().toDateString());
    }

    // Информация графика КБМ
    public initKbmGraphData(): void {
        this.driverStatusError = false;
        const interval = setInterval(() => {
            this.priceService.driversKbm.map((driver, index) => {
                if (driver.status === 'Complete') {

                    // Вычисляем процент КБМ
                    Number(driver.kbm) > 1
                        ? driver.kbmPercent = ((Number(driver.kbm) - 1) * 100).toFixed(0)
                        : driver.kbmPercent = ((1 - Number(driver.kbm)) * 100).toFixed(0);

                    this.maxKbm =  Math.max(...this.priceService.driversKbm.map(driverKbm => Number(driverKbm.kbm)));
                    if (this.maxKbm) {
                        Number(this.maxKbm) > 1
                            ? this.maxKbmPercent = ((Number(this.maxKbm) - 1) * 100).toFixed(0)
                            : this.maxKbmPercent = ((1 - Number(this.maxKbm)) * 100).toFixed(0);

                        if (Number(driver.kbm) === this.maxKbm) {
                            this.maxKbmIndexDriver = index;
                        }

                        this.strokeDashoffset =
                            (Number(628.32) - (( (((this.maxKbm > 1
                                ? this.maxKbm
                                : (1 - this.maxKbm) + 1) * 100) - 100) / 100 ) * Number(314.16))).toString();
                        // document.getElementById('progress').style.strokeDashoffset = this.strokeDashoffset;
                        clearInterval(interval);
                    }
                } else if (driver.status === 'Error') {
                    this.driverStatusError = true;
                }
            });
        }, 1500);
    }

    // Склонение слов 'год, года, лет'
    private ageInitValue(age: number, ageValuesList: string[]): string {
        return age + ' ' + ageValuesList[(age % 100 > 4 && age % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(age % 10 < 5) ? age % 10 : 5]];
    }

    // Получаем возраст водителя
    public getDriverAge(driver: OsagoDriver): string {
        const now = moment();
        const driverAge = now.diff(moment(driver?.birthDate, 'DD.MM.YYYY', true), 'years');
        return this.ageInitValue(driverAge, this.ageValuesList);
    }

    // Получаем КБМ водителя
    public getDriverKbm(index: number): number | string | null {
        return this.osagoService._driverKbms.value[index].kbm
            ? this.osagoService._driverKbms.value[index]?.kbm
            : '1.00';
    }

    // Получаем значение стажа водителя в годах
    public getDriverExperienceAge(driver: OsagoDriver): string {
        const now = moment();
        const driverExperienceAge = now.diff(moment(driver.experienceStartDate, 'DD.MM.YYYY', true), 'years');
        return this.ageInitValue(driverExperienceAge, this.ageValuesList);
    }

    // Расчет показателя КВС
    public recalculateAgeRate(driver: OsagoDriver): string {
        return '' + this.priceService.getAgeRate(
            driver.birthDate,
            driver.rememberOnlyYear ?
                driver.experienceStartDate
                : TextMaskHelper.getDate(driver.experienceStartDate) || ''
        );
    }

    // Выбрали дату
    public changePolicyDate(changedDate: any): void {
        this.osagoService.offersReceived = true;
        this.osagoService._applicationSent = false;
        this.osagoService._applicationSentToInsurers = false;
        this.isChangeNewDate = true;
        this.date.disable();
        this.recommendedOffer = null;
        this.approvedOffers = [];
        this.recommendedDeniedOffer = null;
        this.deniedOffers = [];
        this.isShowPayError = false;
        this.isLoadingOffers = true;

        this.loggingService.trace('OffersComponent', 'changePolicyDate', 'start');
        this.createApplicationSubscription.unsubscribe();
        this.osagoService.stopwWaitForOffers();

        this.createApplicationSubscription =
            timer(700).pipe(switchMap(() => this.osagoService.createApplication()))
            .subscribe((response) => {
                this.osagoService.setWidgetDisplayed(response).subscribe((apiResponse) => {
                    if (apiResponse.result) {

                        // После создания новой заявки, снова запрашиваем кбм водителя
                        if (this.osago?.drivers) {
                            this.osagoService.updateKbmDrivers(this.osago?.drivers);
                            this.osago?.drivers.forEach((driver, index) => {
                                if (driver) {
                                    this.osagoService.getDriverKbm(driver, index);
                                }
                            });
                            this.drivers = this.osago.drivers;
                        }
                        // _______________________________________

                        // Чистим данные офферов которые уже нашли
                        this.recommendedOffer = null;
                        this.approvedOffers = [];
                        this.recommendedDeniedOffer = null;
                        this.deniedOffers = [];

                        // Форматируем дату
                        const minDate = moment().add(4, 'day');

                        let policyDate = minDate.format('YYYY-MM-DD');
                        const date = moment(changedDate, 'DD.MM.YYYY', true);
                        if (date.isValid()) {
                            policyDate = date.format('YYYY-MM-DD');
                        }

                        // Данные о дате начала действия полиса и период действия полиса
                        const policy: OsagoPolicy = {
                            osagoUtilisationPeriod: 12,
                            policyStartDate: TextMaskHelper.removeMask(Converter.toDateRu(policyDate || ''), 10)
                        };

                        if (this.osago) {
                            this.osago.policyParameters = policy;
                            this.clientService.updateCurrentApplication(this.osago);
                        }

                        this.osagoService.offersReceived = false;
                        this.osagoService.waitForOffers();
                        setTimeout(() => {
                            this.isChangeNewDate = false;
                        }, 2500);
                        this.date.enable();
                    }
                });
            });
    }

    // Сохраняем дату действия осаго и обновляем даныне всей формы осаго
    public changePolicyDateAndSaveForm(policyDate: string): void {
        // Данные о дате начала действия полиса и период действия полиса
        const policy: OsagoPolicy = {
            osagoUtilisationPeriod: 12,
            policyStartDate: TextMaskHelper.removeMask(Converter.toDateRu(policyDate || ''), 10)
        };
        // Сохраняем дату начала действия полиса
        this.osagoService.onPolicyFromOwnerComplete(policy);
        // Сохраняем в кэше состояние формы осаго после выбора даты начала действия полиса
        const currentApp = this.clientService.getCurrentApplication();
        if (typeof(currentApp) !== 'undefined' && currentApp !== null) {
            this.clientService.updateCurrentApplication(currentApp);
            if (currentApp.drivers) {
                this.osagoService.onDriversComplete(currentApp.drivers);
            }
        }
    }

    public onEditClick() {
        this.osagoService.onYandexReachGoal('EDIT_GOAL');
        this.isShowConfirm = true;
    }

    // Выбрали офера, нажали "Оформить"
    public onOfferSelected(offer: Offer): void {
        this.osagoService.selectedOffer = offer;
        this.osagoService.onYandexReachGoal('OFFER_SELECTED_GOAL');
        this.appService.onOfferSelected();
    }

    // Проверка параметров урла
    // private initRouteParams(): void {
    //     this.subscription.add(
    //         this.clientService.osagoApplications$.subscribe((osagoApplications) => {
    //             const findLicense = osagoApplications.filter((item) => item.applicationId === this.appService.applicationId);
    //             if (findLicense[0]) {
    //                 localStorage.setItem('license', findLicense[0]?.license);
    //                 this.appService.onLicenseScreenCompleteFromUrl(findLicense[0]?.license);
    //             }
    //             this.osago = this.clientService.getCurrentApplication();
    //             this.preOffer = this.osagoService.preOffer;
    //             this.recommendedInsurer = this.appService.recommendedInsurer;
    //         })
    //     );
    // }

    public onOfferTooltipClicked(offer: Offer): void {
        this.priceSummary = this.priceService.getPriceSummary(offer);
        this.togglePopup();
    }

    public togglePopup(): void {
        this.isPopupOpen = !this.isPopupOpen;
    }

    private calculateTimeLeft(): void {
        const now = moment();
        const diff = 179 - now.diff(this.start, 'seconds');
        if (diff <= 0) {
            this.timeLeft = null;
            window.clearInterval(this.interval);
            return;
        }

        let result = '';
        const minutes = Math.floor(diff / 60);

        switch (minutes) {
            case 0:
                break;
            case 1:
                result += '1 минута';
                break;
            default:
                result += minutes + ' минуты';
                break;
        }

        const seconds = diff - minutes * 60;

        if (seconds > 0) {
            result += ' ' + seconds + ' ';
            const left = seconds - 10 * (Math.floor(seconds / 10));
            switch (left) {
                case 1:
                    result += 'секунда';
                    break;
                case 2:
                case 3:
                case 4:
                    result += 'секунды';
                    break;
                default:
                    result += 'секунд';
            }
        }

        this.timeLeft = result;
    }

    private updateApprovedOffers(offers: Offer[]): void {
        let index = -1;

        if (this.recommendedOffer == null && this.recommendedInsurer != null) {
            index = offers.findIndex(x => x.insurerType === this.recommendedInsurer);

            if (index !== -1) {
                this.recommendedOffer = Offer.create(offers[index]);
            }
        }

        const approvedOffers = offers.filter(x => x.insurerType !== this.recommendedInsurer);

        approvedOffers.forEach((item) => {
            if (this.approvedOffers && this.approvedOffers.length === 0) {
                this.approvedOffers.push(item);
            } else if (this.approvedOffers && this.approvedOffers.length > 0) {
                // let isPush = true;
                const findOffer = this.approvedOffers.find((showItem) => item.offerId === showItem.offerId);
                if (!findOffer) {
                    var backingArray = this.approvedOffers.map(x => Offer.create(x));
                    backingArray.push(item);
                    backingArray.sort((a, b) => Offer.sort(a, b));
                    var newItemIndex = backingArray.indexOf(item);

                    if (newItemIndex != -1)
                        this.approvedOffers.splice(newItemIndex, 0, item);
                    else
                        this.approvedOffers.push(item);

                }
            }
        });
    }

    private updateDeniedOffers(offers: Offer[]): void {
        let index = -1;

        if (this.recommendedDeniedOffer == null && this.recommendedInsurer != null) {
            index = offers.findIndex(x => x.insurerType === this.recommendedInsurer);

            if (index !== -1) {
                this.recommendedDeniedOffer = Offer.create(offers[index]);
            }
        }

        const deniedOffers = offers.filter(x => x.insurerType !== this.recommendedInsurer);
        index = deniedOffers.findIndex(x => this.deniedOffers.findIndex(o => o.insurerType === x.insurerType) === -1);

        if (index !== -1) {
            this.deniedOffers = deniedOffers.map(x => Offer.create(x));
        }
    }

    // Если отредактировали форму, делаем новую заявку на получение офферов
    public changeConformData(event?: any): void {
        this.osagoService.offersReceived = true;
        this.osagoService._applicationSent = false;
        this.osagoService._applicationSentToInsurers = false;
        this.isChangeNewDate = true;
        this.date.disable();
        this.recommendedOffer = null;
        this.approvedOffers = [];
        this.recommendedDeniedOffer = null;
        this.deniedOffers = [];
        this.isShowPayError = false;
    }

    public stopSubscribe(): void {
        this.osagoService.offersReceived = true;
        this.osagoService._applicationSent = false;
        this.osagoService._applicationSentToInsurers = false;
    }
}
