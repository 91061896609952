import { AfterViewInit, Component } from "@angular/core";
import { ApplicationService } from "../../services";

@Component({
    templateUrl: './denied.component.html',
    styleUrls: ['./denied.component.css']
})
export class DeniedComponent implements AfterViewInit {
    constructor(private appService: ApplicationService
    ) { }

    ngAfterViewInit() {
        this.appService.onYandexReachGoal("DENIED_GOAL");
    }
}
