<div class="scroll-target">
    <div class="confirm-page-container">

        <div class="confirm-page-container-title">
            Данные полиса
            <div class="confirm-page-container-pre-title">Проверка данных</div>
        </div>

        <div class="osago-list">
            <ul>
                <li>
                    <div class="title-block" (click)="isShowVendors = !isShowVendors">
                        <div class="title">
                            {{ osago?.carData?.brandName }} {{ osago?.carData?.modelName }}
                            <div class="pre-title">{{ osago?.carData?.licensePlate }}</div>
                        </div>
                        <div class="sprite-link">
                            <button *ngIf="isShowVendors" (click)="navigate('info/car', { fromConfirmation: true }); $event.stopPropagation()" class="btn-outline show-desktop">Редактировать</button>
                            <svg *ngIf="!isShowVendors" width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="10" fill="#F5F5F5"/>
                                <path
                                    d="M22.707 12.3266C22.5193 12.1175 22.2647 12 21.9993 12C21.7339 12 21.4794 12.1175 21.2917 12.3266L16.7016 17.4423C16.5113 17.6459 16.2577 17.7595 15.994 17.7595C15.7302 17.7595 15.4766 17.6459 15.2863 17.4423L10.6962 12.3266C10.5075 12.1234 10.2546 12.011 9.9922 12.0135C9.72977 12.0161 9.47873 12.1334 9.29316 12.3402C9.10758 12.547 9.00232 12.8268 9.00004 13.1193C8.99776 13.4118 9.09864 13.6935 9.28096 13.9039L13.8701 19.0196C14.1489 19.3304 14.4799 19.577 14.8443 19.7452C15.2086 19.9134 15.5991 20 15.9935 20C16.3878 20 16.7783 19.9134 17.1427 19.7452C17.507 19.577 17.838 19.3304 18.1169 19.0196L22.707 13.9039C22.8946 13.6947 23 13.4111 23 13.1153C23 12.8195 22.8946 12.5358 22.707 12.3266Z"
                                    fill="#979797"/>
                            </svg>
                            <svg *ngIf="isShowVendors" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="32" y="32" width="32" height="32" rx="10" transform="rotate(180 32 32)" fill="#F5F5F5"/>
                                <path d="M9.29305 19.6734C9.48074 19.8825 9.73528 20 10.0007 20C10.2661 20 10.5206 19.8825 10.7083 19.6734L15.2984 14.5577C15.4887 14.3541 15.7423 14.2405 16.006 14.2405C16.2698 14.2405 16.5234 14.3541 16.7137 14.5577L21.3038 19.6734C21.4925 19.8766 21.7454 19.989 22.0078 19.9865C22.2702 19.9839 22.5213 19.8666 22.7068 19.6598C22.8924 19.453 22.9977 19.1732 23 18.8807C23.0022 18.5882 22.9014 18.3065 22.719 18.0961L18.1299 12.9804C17.8511 12.6696 17.5201 12.423 17.1557 12.2548C16.7914 12.0866 16.4009 12 16.0065 12C15.6122 12 15.2217 12.0866 14.8573 12.2548C14.493 12.423 14.162 12.6696 13.8831 12.9804L9.29305 18.0961C9.10541 18.3053 9 18.5889 9 18.8847C9 19.1805 9.10541 19.4642 9.29305 19.6734Z" fill="#979797"/>
                            </svg>
                        </div>
                    </div>
                    <div *ngIf="isShowVendors" class="content-block">
                        <div class="items">
                            <div class="item">
                                <div class="item-title">
                                    Автомобиль
                                </div>
                                <div class="item-text">
                                    {{ osago?.carData?.carPower }} л.с.
                                </div>
                            </div>
                            <div class="item">
                                <div class="item-title">
                                    Документ на автомобиль
                                </div>
                                <div class="item-text">
                                    {{ osago?.carData?.carDocument?.documentType === 'STS'
                                    ? 'СТС'
                                    : osago?.carData?.carDocument?.documentType === 'PTS'
                                        ? 'ПТС'
                                        : 'еПТС' }}
                                    № {{ osago?.carData?.carDocument?.documentSeries }}
                                    {{ osago?.carData?.carDocument?.documentNumber }}
                                    от {{ osago?.carData?.carDocument?.documentIssueDate }}
                                </div>
                            </div>
                            <div class="item">
                                <div class="item-title">
                                    {{ osago?.carData?.carIdentificators?.vin
                                    ? 'VIN номер' : osago?.carData?.carIdentificators?.bodyNumber
                                        ? 'Номер кузова' : 'Номер шасси' }}
                                </div>
                                <div class="item-text">
                                    {{ osago?.carData?.carIdentificators?.vin
                                    ? osago?.carData?.carIdentificators?.vin : osago?.carData?.carIdentificators?.bodyNumber
                                        ? osago?.carData?.carIdentificators?.bodyNumber : osago?.carData?.carIdentificators?.chassisNumber }}
                                </div>
                            </div>
                        </div>

                        <div class="content-action">
                            <button *ngIf="isShowVendors" (click)="navigate('info/car'); $event.stopPropagation()" class="btn-outline show-mobile">Редактировать</button>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="title-block">
                        <div class="title">{{ phone }}
                            <div class="pre-title">{{ osago?.owner?.email ? osago?.owner?.email : osago?.insured?.email }}</div>
                        </div>
                        <div class="sprite-link">
                            <svg (click)="navigate('info/owner'); $event.stopPropagation()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="10" fill="#F5F5F5"/>
                                <g clip-path="url(#clip0_126_42273)">
                                    <path d="M19.884 9.54173L12.772 16.6537C12.5003 16.9239 12.285 17.2453 12.1384 17.5992C11.9918 17.9532 11.9169 18.3327 11.918 18.7158V19.4992C11.918 19.6539 11.9794 19.8023 12.0888 19.9117C12.1982 20.0211 12.3466 20.0826 12.5013 20.0826H13.2847C13.6678 20.0836 14.0474 20.0087 14.4013 19.8621C14.7553 19.7155 15.0766 19.5002 15.3468 19.2286L22.4588 12.1166C22.7997 11.7748 22.9912 11.3118 22.9912 10.8291C22.9912 10.3465 22.7997 9.88346 22.4588 9.54173C22.1121 9.21033 21.651 9.02539 21.1714 9.02539C20.6918 9.02539 20.2307 9.21033 19.884 9.54173ZM21.634 11.2917L14.522 18.4037C14.1931 18.7306 13.7485 18.9147 13.2847 18.9159H13.0846V18.7158C13.0859 18.2521 13.2699 17.8075 13.5968 17.4786L20.7088 10.3666C20.8334 10.2475 20.9991 10.1811 21.1714 10.1811C21.3437 10.1811 21.5094 10.2475 21.634 10.3666C21.7564 10.4894 21.8252 10.6557 21.8252 10.8291C21.8252 11.0026 21.7564 11.1689 21.634 11.2917Z" fill="#979797"/>
                                    <path d="M22.4167 14.2378C22.262 14.2378 22.1136 14.2992 22.0042 14.4086C21.8948 14.518 21.8333 14.6664 21.8333 14.8211V17.75H19.5C19.0359 17.75 18.5908 17.9344 18.2626 18.2626C17.9344 18.5908 17.75 19.0359 17.75 19.5V21.8333H11.9167C11.4525 21.8333 11.0074 21.649 10.6792 21.3208C10.351 20.9926 10.1667 20.5475 10.1667 20.0833V11.9167C10.1667 11.4525 10.351 11.0074 10.6792 10.6792C11.0074 10.351 11.4525 10.1667 11.9167 10.1667H17.1912C17.3459 10.1667 17.4943 10.1052 17.6036 9.99581C17.713 9.88642 17.7745 9.73804 17.7745 9.58333C17.7745 9.42862 17.713 9.28025 17.6036 9.17085C17.4943 9.06146 17.3459 9 17.1912 9H11.9167C11.1434 9.00093 10.4021 9.30851 9.8553 9.8553C9.30851 10.4021 9.00093 11.1434 9 11.9167L9 20.0833C9.00093 20.8566 9.30851 21.5979 9.8553 22.1447C10.4021 22.6915 11.1434 22.9991 11.9167 23H18.5334C18.9166 23.0011 19.2962 22.9262 19.6503 22.7796C20.0043 22.633 20.3258 22.4177 20.5961 22.146L22.1454 20.5955C22.4171 20.3253 22.6325 20.004 22.7792 19.65C22.9259 19.2961 23.0009 18.9166 23 18.5334V14.8211C23 14.6664 22.9385 14.518 22.8291 14.4086C22.7198 14.2992 22.5714 14.2378 22.4167 14.2378ZM19.7713 21.3212C19.5368 21.5551 19.2402 21.717 18.9167 21.7878V19.5C18.9167 19.3453 18.9781 19.1969 19.0875 19.0875C19.1969 18.9781 19.3453 18.9167 19.5 18.9167H21.7896C21.7174 19.2396 21.5557 19.5355 21.3229 19.7707L19.7713 21.3212Z" fill="#979797"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_126_42273">
                                        <rect width="14" height="14" fill="white" transform="translate(9 9)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </li>
                <li *ngFor="let driver of osago?.drivers; let i = index" (click)="driver['isShowDriver'] = !driver['isShowDriver']">
                    <div class="title-block">
                        <div class="title">
                            {{ driver?.lastName }} {{ driver?.firstName }}
                            <div class="pre-title">Водитель</div>
                        </div>
                        <div class="sprite-link">
                            <button *ngIf="driver['isShowDriver']" (click)="navigate('info/drivers'); $event.stopPropagation()" class="btn-outline show-desktop">Редактировать</button>
                            <svg *ngIf="!driver['isShowDriver']" width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="10" fill="#F5F5F5"/>
                                <path
                                    d="M22.707 12.3266C22.5193 12.1175 22.2647 12 21.9993 12C21.7339 12 21.4794 12.1175 21.2917 12.3266L16.7016 17.4423C16.5113 17.6459 16.2577 17.7595 15.994 17.7595C15.7302 17.7595 15.4766 17.6459 15.2863 17.4423L10.6962 12.3266C10.5075 12.1234 10.2546 12.011 9.9922 12.0135C9.72977 12.0161 9.47873 12.1334 9.29316 12.3402C9.10758 12.547 9.00232 12.8268 9.00004 13.1193C8.99776 13.4118 9.09864 13.6935 9.28096 13.9039L13.8701 19.0196C14.1489 19.3304 14.4799 19.577 14.8443 19.7452C15.2086 19.9134 15.5991 20 15.9935 20C16.3878 20 16.7783 19.9134 17.1427 19.7452C17.507 19.577 17.838 19.3304 18.1169 19.0196L22.707 13.9039C22.8946 13.6947 23 13.4111 23 13.1153C23 12.8195 22.8946 12.5358 22.707 12.3266Z"
                                    fill="#979797"/>
                            </svg>
                            <svg *ngIf="driver['isShowDriver']" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="32" y="32" width="32" height="32" rx="10" transform="rotate(180 32 32)" fill="#F5F5F5"/>
                                <path d="M9.29305 19.6734C9.48074 19.8825 9.73528 20 10.0007 20C10.2661 20 10.5206 19.8825 10.7083 19.6734L15.2984 14.5577C15.4887 14.3541 15.7423 14.2405 16.006 14.2405C16.2698 14.2405 16.5234 14.3541 16.7137 14.5577L21.3038 19.6734C21.4925 19.8766 21.7454 19.989 22.0078 19.9865C22.2702 19.9839 22.5213 19.8666 22.7068 19.6598C22.8924 19.453 22.9977 19.1732 23 18.8807C23.0022 18.5882 22.9014 18.3065 22.719 18.0961L18.1299 12.9804C17.8511 12.6696 17.5201 12.423 17.1557 12.2548C16.7914 12.0866 16.4009 12 16.0065 12C15.6122 12 15.2217 12.0866 14.8573 12.2548C14.493 12.423 14.162 12.6696 13.8831 12.9804L9.29305 18.0961C9.10541 18.3053 9 18.5889 9 18.8847C9 19.1805 9.10541 19.4642 9.29305 19.6734Z" fill="#979797"/>
                            </svg>
                        </div>
                        <!--                        <div class="update-link">-->
                        <!--                            <a (click)="navigate('info/drivers')">Изменить</a>-->
                        <!--                        </div>-->
                    </div>
                    <div *ngIf="driver['isShowDriver']" class="content-block">
                        <div class="items">
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    ФИО
                                </div>
                                <div class="item-text">
                                    {{ driver?.lastName }} {{ driver?.firstName }} {{ driver?.middleName }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Дата рождения
                                </div>
                                <div class="item-text">
                                    {{ driver?.birthDate }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Водительское удостоверение
                                </div>
                                <div class="item-text">
                                    {{ driver?.driverLicense?.licenseSeries }} {{ driver?.driverLicense?.licenseNumber }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Дата начала стажа
                                </div>
                                <div class="item-text">
                                    {{ driver?.experienceStartDate }}
                                </div>
                            </div>
                        </div>

                        <div class="content-action">
                            <button *ngIf="driver['isShowDriver']"
                                    (click)="navigate('info/drivers'); $event.stopPropagation()"
                                    class="btn-outline show-mobile">Редактировать</button>
                        </div>

                    </div>
                </li>
                <li (click)="navigate('info/drivers'); $event.stopPropagation()">
                    <div class="title-block">
                        <div class="title-gray">
                            Добавить водителя
                        </div>
                        <div class="sprite-link">
                            <button class="btn-outline plus">+</button>
                        </div>
                    </div>
                </li>
                <li (click)="isShowOwner = !isShowOwner">
                    <div class="title-block">
                        <div class="title">
                            {{ osago?.owner?.lastName }} {{ osago?.owner?.firstName }}
                            <div class="pre-title">Собственник <span *ngIf="osago?.owner?.ownerIsInsured">| Страхователь</span></div>
                        </div>
                        <div class="sprite-link">
                            <button *ngIf="isShowOwner" (click)="navigate('info/owner'); $event.stopPropagation()" class="btn-outline show-desktop">Редактировать</button>
                            <svg *ngIf="!isShowOwner" width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="10" fill="#F5F5F5"/>
                                <path
                                    d="M22.707 12.3266C22.5193 12.1175 22.2647 12 21.9993 12C21.7339 12 21.4794 12.1175 21.2917 12.3266L16.7016 17.4423C16.5113 17.6459 16.2577 17.7595 15.994 17.7595C15.7302 17.7595 15.4766 17.6459 15.2863 17.4423L10.6962 12.3266C10.5075 12.1234 10.2546 12.011 9.9922 12.0135C9.72977 12.0161 9.47873 12.1334 9.29316 12.3402C9.10758 12.547 9.00232 12.8268 9.00004 13.1193C8.99776 13.4118 9.09864 13.6935 9.28096 13.9039L13.8701 19.0196C14.1489 19.3304 14.4799 19.577 14.8443 19.7452C15.2086 19.9134 15.5991 20 15.9935 20C16.3878 20 16.7783 19.9134 17.1427 19.7452C17.507 19.577 17.838 19.3304 18.1169 19.0196L22.707 13.9039C22.8946 13.6947 23 13.4111 23 13.1153C23 12.8195 22.8946 12.5358 22.707 12.3266Z"
                                    fill="#979797"/>
                            </svg>
                            <svg *ngIf="isShowOwner" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="32" y="32" width="32" height="32" rx="10" transform="rotate(180 32 32)" fill="#F5F5F5"/>
                                <path d="M9.29305 19.6734C9.48074 19.8825 9.73528 20 10.0007 20C10.2661 20 10.5206 19.8825 10.7083 19.6734L15.2984 14.5577C15.4887 14.3541 15.7423 14.2405 16.006 14.2405C16.2698 14.2405 16.5234 14.3541 16.7137 14.5577L21.3038 19.6734C21.4925 19.8766 21.7454 19.989 22.0078 19.9865C22.2702 19.9839 22.5213 19.8666 22.7068 19.6598C22.8924 19.453 22.9977 19.1732 23 18.8807C23.0022 18.5882 22.9014 18.3065 22.719 18.0961L18.1299 12.9804C17.8511 12.6696 17.5201 12.423 17.1557 12.2548C16.7914 12.0866 16.4009 12 16.0065 12C15.6122 12 15.2217 12.0866 14.8573 12.2548C14.493 12.423 14.162 12.6696 13.8831 12.9804L9.29305 18.0961C9.10541 18.3053 9 18.5889 9 18.8847C9 19.1805 9.10541 19.4642 9.29305 19.6734Z" fill="#979797"/>
                            </svg>
                        </div>
                        <!--                        <div class="update-link">-->
                        <!--                            <a (click)="navigate('info/drivers')">Изменить</a>-->
                        <!--                        </div>-->
                    </div>
                    <div *ngIf="isShowOwner" class="content-block">
                        <div class="items">
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    ФИО
                                </div>
                                <div class="item-text">
                                    {{ osago?.owner?.lastName }} {{ osago?.owner?.firstName }} {{ osago?.owner?.middleName }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Паспортные данные
                                </div>
                                <div class="item-text">
                                    {{ osago?.owner?.passport?.passportSeries }}
                                    {{ osago?.owner?.passport?.passportNumber }}
                                    | выдан {{ osago?.owner?.passport?.passportIssueDate }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Дата рождения
                                </div>
                                <div class="item-text">
                                    {{ osago?.owner?.birthDate }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Адрес
                                </div>
                                <div class="item-text">
                                    {{ osago?.owner?.passport?.registrationAddressData?.addressAsString }}
                                </div>
                            </div>
                        </div>

                        <div class="content-action">
                            <button *ngIf="isShowOwner" (click)="navigate('info/owner'); $event.stopPropagation()" class="btn-outline show-mobile">Редактировать</button>
                        </div>

                    </div>
                </li>
<!--                <li>-->
<!--                    <div class="title-block">-->
<!--                        <div class="title">Собственник</div>-->
<!--                        <div class="sprite-link">-->
<!--                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"-->
<!--                                 xmlns="http://www.w3.org/2000/svg">-->
<!--                                <rect width="32" height="32" rx="10" fill="#F5F5F5"/>-->
<!--                                <path-->
<!--                                    d="M22.707 12.3266C22.5193 12.1175 22.2647 12 21.9993 12C21.7339 12 21.4794 12.1175 21.2917 12.3266L16.7016 17.4423C16.5113 17.6459 16.2577 17.7595 15.994 17.7595C15.7302 17.7595 15.4766 17.6459 15.2863 17.4423L10.6962 12.3266C10.5075 12.1234 10.2546 12.011 9.9922 12.0135C9.72977 12.0161 9.47873 12.1334 9.29316 12.3402C9.10758 12.547 9.00232 12.8268 9.00004 13.1193C8.99776 13.4118 9.09864 13.6935 9.28096 13.9039L13.8701 19.0196C14.1489 19.3304 14.4799 19.577 14.8443 19.7452C15.2086 19.9134 15.5991 20 15.9935 20C16.3878 20 16.7783 19.9134 17.1427 19.7452C17.507 19.577 17.838 19.3304 18.1169 19.0196L22.707 13.9039C22.8946 13.6947 23 13.4111 23 13.1153C23 12.8195 22.8946 12.5358 22.707 12.3266Z"-->
<!--                                    fill="#979797"/>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                        <div class="update-link">&ndash;&gt;-->
<!--                        &lt;!&ndash;                            <a (click)="navigate('info/owner')">Изменить</a>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                    </div>-->
<!--                    <div-->
<!--                        class="pre-title">{{ osago?.owner?.lastName }} {{ osago?.owner?.firstName }} {{ osago?.owner?.middleName }}</div>-->
<!--                    <div class="item">{{ osago?.owner?.birthDate }} г.р.</div>-->
<!--                    <div class="item">Паспорт:-->
<!--                        {{ osago?.owner?.passport?.passportSeries }}-->
<!--                        {{ osago?.owner?.passport?.passportNumber }}-->
<!--                        (выдано {{ osago?.owner?.passport?.passportIssueDate }})-->
<!--                    </div>-->
<!--                    <div class="item">-->
<!--                        Регистрация: {{ osago?.owner?.passport?.registrationAddressData?.addressAsString }}</div>-->
<!--                </li>-->
                <li *ngIf="!osago?.owner?.ownerIsInsured" (click)="isShowInsurer = !isShowInsurer">
                    <div class="title-block">
                        <div class="title">
                            {{ osago?.insured?.lastName }} {{ osago?.insured?.firstName }}
                            <div class="pre-title">Страхователь</div>
                        </div>
                        <div class="sprite-link">
                            <button *ngIf="isShowInsurer" (click)="navigate('info/owner'); $event.stopPropagation()" class="btn-outline show-desktop">Редактировать</button>
                            <svg *ngIf="!isShowInsurer" width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="10" fill="#F5F5F5"/>
                                <path
                                    d="M22.707 12.3266C22.5193 12.1175 22.2647 12 21.9993 12C21.7339 12 21.4794 12.1175 21.2917 12.3266L16.7016 17.4423C16.5113 17.6459 16.2577 17.7595 15.994 17.7595C15.7302 17.7595 15.4766 17.6459 15.2863 17.4423L10.6962 12.3266C10.5075 12.1234 10.2546 12.011 9.9922 12.0135C9.72977 12.0161 9.47873 12.1334 9.29316 12.3402C9.10758 12.547 9.00232 12.8268 9.00004 13.1193C8.99776 13.4118 9.09864 13.6935 9.28096 13.9039L13.8701 19.0196C14.1489 19.3304 14.4799 19.577 14.8443 19.7452C15.2086 19.9134 15.5991 20 15.9935 20C16.3878 20 16.7783 19.9134 17.1427 19.7452C17.507 19.577 17.838 19.3304 18.1169 19.0196L22.707 13.9039C22.8946 13.6947 23 13.4111 23 13.1153C23 12.8195 22.8946 12.5358 22.707 12.3266Z"
                                    fill="#979797"/>
                            </svg>
                            <svg *ngIf="isShowInsurer" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="32" y="32" width="32" height="32" rx="10" transform="rotate(180 32 32)" fill="#F5F5F5"/>
                                <path d="M9.29305 19.6734C9.48074 19.8825 9.73528 20 10.0007 20C10.2661 20 10.5206 19.8825 10.7083 19.6734L15.2984 14.5577C15.4887 14.3541 15.7423 14.2405 16.006 14.2405C16.2698 14.2405 16.5234 14.3541 16.7137 14.5577L21.3038 19.6734C21.4925 19.8766 21.7454 19.989 22.0078 19.9865C22.2702 19.9839 22.5213 19.8666 22.7068 19.6598C22.8924 19.453 22.9977 19.1732 23 18.8807C23.0022 18.5882 22.9014 18.3065 22.719 18.0961L18.1299 12.9804C17.8511 12.6696 17.5201 12.423 17.1557 12.2548C16.7914 12.0866 16.4009 12 16.0065 12C15.6122 12 15.2217 12.0866 14.8573 12.2548C14.493 12.423 14.162 12.6696 13.8831 12.9804L9.29305 18.0961C9.10541 18.3053 9 18.5889 9 18.8847C9 19.1805 9.10541 19.4642 9.29305 19.6734Z" fill="#979797"/>
                            </svg>
                        </div>
                        <!--                        <div class="update-link">-->
                        <!--                            <a (click)="navigate('info/drivers')">Изменить</a>-->
                        <!--                        </div>-->
                    </div>
                    <div *ngIf="isShowInsurer" class="content-block">
                        <div class="items">
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    ФИО
                                </div>
                                <div class="item-text">
                                    {{ osago?.insured?.lastName }} {{ osago?.insured?.firstName }} {{ osago?.insured?.middleName }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Паспортные данные
                                </div>
                                <div class="item-text">
                                    {{ osago?.insured?.passport?.passportSeries }}
                                    {{ osago?.insured?.passport?.passportNumber }}
                                    | выдан {{ osago?.insured?.passport?.passportIssueDate }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Дата рождения
                                </div>
                                <div class="item-text">
                                    {{ osago?.insured?.birthDate }}
                                </div>
                            </div>
                            <div class="item flex-basis-50">
                                <div class="item-title">
                                    Адрес
                                </div>
                                <div class="item-text">
                                    {{ osago?.insured?.passport?.registrationAddressData?.addressAsString }}
                                </div>
                            </div>
                        </div>

                        <div class="content-action">
                            <button *ngIf="isShowInsurer" (click)="navigate('info/owner'); $event.stopPropagation()" class="btn-outline show-mobile">Редактировать</button>
                        </div>

                    </div>
                </li>
<!--                <li *ngIf="osago?.owner?.ownerIsInsured">-->
<!--                    <div class="title-block">-->
<!--                        <div class="title">Страхователь</div>-->
<!--                        <div class="sprite-link">-->
<!--                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"-->
<!--                                 xmlns="http://www.w3.org/2000/svg">-->
<!--                                <rect width="32" height="32" rx="10" fill="#F5F5F5"/>-->
<!--                                <path-->
<!--                                    d="M22.707 12.3266C22.5193 12.1175 22.2647 12 21.9993 12C21.7339 12 21.4794 12.1175 21.2917 12.3266L16.7016 17.4423C16.5113 17.6459 16.2577 17.7595 15.994 17.7595C15.7302 17.7595 15.4766 17.6459 15.2863 17.4423L10.6962 12.3266C10.5075 12.1234 10.2546 12.011 9.9922 12.0135C9.72977 12.0161 9.47873 12.1334 9.29316 12.3402C9.10758 12.547 9.00232 12.8268 9.00004 13.1193C8.99776 13.4118 9.09864 13.6935 9.28096 13.9039L13.8701 19.0196C14.1489 19.3304 14.4799 19.577 14.8443 19.7452C15.2086 19.9134 15.5991 20 15.9935 20C16.3878 20 16.7783 19.9134 17.1427 19.7452C17.507 19.577 17.838 19.3304 18.1169 19.0196L22.707 13.9039C22.8946 13.6947 23 13.4111 23 13.1153C23 12.8195 22.8946 12.5358 22.707 12.3266Z"-->
<!--                                    fill="#979797"/>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                        <div class="update-link">&ndash;&gt;-->
<!--                        &lt;!&ndash;                            <a (click)="navigate('info/owner')">Изменить</a>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                    </div>-->
<!--                    <div-->
<!--                        class="pre-title">{{ osago?.owner?.lastName }} {{ osago?.owner?.firstName }} {{ osago?.owner?.middleName }}</div>-->
<!--                    <div class="item">{{ osago?.owner?.birthDate }} г.р.</div>-->
<!--                    <div class="item">Паспорт:-->
<!--                        {{ osago?.owner?.passport?.passportSeries }}-->
<!--                        {{ osago?.owner?.passport?.passportNumber }}-->
<!--                        (выдано {{ osago?.owner?.passport?.passportIssueDate }})-->
<!--                    </div>-->
<!--                    <div class="item">-->
<!--                        Регистрация: {{ osago?.owner?.passport?.registrationAddressData?.addressAsString }}</div>-->
<!--                </li>-->
<!--                <li *ngIf="!osago?.owner?.ownerIsInsured">-->
<!--                    <div class="title-block">-->
<!--                        <div class="title">Страхователь</div>-->
<!--                        <div class="sprite-link">-->
<!--                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"-->
<!--                                 xmlns="http://www.w3.org/2000/svg">-->
<!--                                <rect width="32" height="32" rx="10" fill="#F5F5F5"/>-->
<!--                                <path-->
<!--                                    d="M22.707 12.3266C22.5193 12.1175 22.2647 12 21.9993 12C21.7339 12 21.4794 12.1175 21.2917 12.3266L16.7016 17.4423C16.5113 17.6459 16.2577 17.7595 15.994 17.7595C15.7302 17.7595 15.4766 17.6459 15.2863 17.4423L10.6962 12.3266C10.5075 12.1234 10.2546 12.011 9.9922 12.0135C9.72977 12.0161 9.47873 12.1334 9.29316 12.3402C9.10758 12.547 9.00232 12.8268 9.00004 13.1193C8.99776 13.4118 9.09864 13.6935 9.28096 13.9039L13.8701 19.0196C14.1489 19.3304 14.4799 19.577 14.8443 19.7452C15.2086 19.9134 15.5991 20 15.9935 20C16.3878 20 16.7783 19.9134 17.1427 19.7452C17.507 19.577 17.838 19.3304 18.1169 19.0196L22.707 13.9039C22.8946 13.6947 23 13.4111 23 13.1153C23 12.8195 22.8946 12.5358 22.707 12.3266Z"-->
<!--                                    fill="#979797"/>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                        <div class="update-link">&ndash;&gt;-->
<!--                        &lt;!&ndash;                            <a (click)="navigate('info/owner')">Изменить</a>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                    </div>-->
<!--                    <div-->
<!--                        class="pre-title">{{ osago?.insured?.lastName }} {{ osago?.insured?.firstName }} {{ osago?.insured?.middleName }}</div>-->
<!--                    <div class="item">{{ osago?.insured?.birthDate }} г.р.</div>-->
<!--                    <div class="item">Паспорт:-->
<!--                        {{ osago?.insured?.passport?.passportSeries }}-->
<!--                        {{ osago?.insured?.passport?.passportNumber }}-->
<!--                        (выдано {{ osago?.insured?.passport?.passportIssueDate }})-->
<!--                    </div>-->
<!--                    <div class="item">-->
<!--                        Регистрация: {{ osago?.insured?.passport?.registrationAddressData?.addressAsString }}</div>-->
<!--                </li>-->
            </ul>
        </div>

<!--        <app-button-->
<!--            *ngIf="!edit"-->
<!--            modifier="blue-button"-->
<!--            buttonType="submit"-->
<!--            (click)="onSubmit('mobile')">-->
<!--            Продолжить-->
<!--        </app-button>-->

<!--        <div>-->
<!--            <pre>{{ osago | json }}</pre>-->
<!--        </div>-->
    </div>
</div>
