<div class="step2" *ngIf="isLoading">
    <div class="widget__wrapper">
        <div class="widget__header">
            <div class="widget__header-left">
                <h2>Получаем данные</h2><span>Это может занять несколько секунд</span>
            </div>
            <div class="widget__header-right">
            </div>
        </div>
        <div class="widget__loader active"></div>
    </div>
</div>


<div class="step7 scroll-target" id="owner-anchor">

    <form id="ownerForm"
          *ngIf="ownerForm"
          autocomplete="off"
          [formGroup]="ownerForm"
          (ngSubmit)="onSubmit()">
        <!--        <app-price-summary [hidden]="editOwner || editInsurer || editContacts"></app-price-summary>-->

        <div class="widget__wrapper" >

            <!--Собственник-->
            <div>
                <div class="widget__header">
                    <div class="widget__header-title">
                        <div class="widget__header-title-text">Собственник</div>
                    </div>
<!--                    <div class="widget__header-number" *ngIf="driverFio">-->
<!--                        <p>{{ driverFio }}</p>-->
<!--                        <span (click)="togglePopup()">Изменить</span>-->
<!--                    </div>-->
                </div>

                <div class="widget__body">
                    <app-alert>
                        Эти данные мы передаем в страховую компанию для подтверждения цены с учетом всех скидок.
                        Проверьте их несколько раз
                    </app-alert>
                    <div class="widget__radio"
                         [class.hidden]="drivers && drivers.length < 2">
                        <ng-container *ngFor="let item of drivers">
                            <label [for]="'driver' + item.id">
                                <input
                                    type="radio"
                                    name="owner"
                                    [id]="'driver' + item.id"
                                    [formControl]="driver"
                                    [value]="item.id">
                                <span>{{ item.value }}</span>
                            </label>
                        </ng-container>
                        <!--				<div class="widget__radio__clear">Очистить</div>-->
                    </div>
<!--                    			<div class="widget-input-inner-dropdown widget-input-full">-->
<!--                    				<app-select-->
<!--                    					[fieldControl]="driver"-->
<!--                    					[options]="drivers"-->
<!--                    					fieldId="driver"-->
<!--                    					helperText="Выбрать из водителей"-->
<!--                    					fieldName="Выбрать из водителей">-->
<!--                    				</app-select>-->
<!--                    			</div>-->


                    <div class="widget-input-inner widget-input-lastName">
                        <app-input
                            [fieldControl]="lastName"
                            [fieldId]="'lastName'"
                            [modifier]="'widget-input-full'"
                            fieldName="Фамилия"
                            helperText="Фамилия водителя как указано в правах"
                            (input)="onLastNameChanged($event)">
                        </app-input>
                    </div>
                    <div class="widget-input-inner widget-input-firstName">
                        <app-input
                            [fieldControl]="firstName"
                            [fieldId]="'firstName'"
                            [modifier]="'widget-input-full'"
                            fieldName="Имя"
                            helperText="Имя водителя как указано в правах"
                            (input)="onFirstNameChanged($event)">
                        </app-input>
                    </div>
                    <div class="widget-input-inner widget-input-middleName">
                        <app-input
                            [fieldControl]="middleName"
                            [fieldId]="'middleName'"
                            [modifier]="'widget-input-full'"
                            fieldName="Отчество"
                            helperText="Отчество водителя как указано в правах"
                            (input)="onMiddleNameChanged($event)">
                        </app-input>
                    </div>

                    <div *ngIf="!isLoading" class="widget-input-inner widget-input-birthday">
                        <app-input-date
                            [fieldControl]="birthDate"
                            [checkFill]="true"
                            [changeYear]="true"
                            [showError]="true"
                            [showIcon]="false"
                            [maxDate]="maxDate"
                            fieldId="birthDate"
                            fieldName="Дата рождения"
                            (changed)="onBirthDateChanged()"
                            #driverbirthdate>
                        </app-input-date>
                    </div>

                    <div *ngIf="!isLoading" class="widget-input-inner">
                        <app-input
                            [fieldControl]="passport"
                            [textMask]="{ mask: masks.passport, guide: true }"
                            fieldType="tel"
                            (changed)="onPassportChanged()"
                            fieldId="passport"
                            fieldName="Серия и номер паспорта">
                        </app-input>
                    </div>

                    <div class="widget-input-inner">
                        <app-input-date
                            [fieldControl]="passportDate"
                            [checkFill]="true"
                            [changeYear]="true"
                            [showError]="true"
                            [showIcon]="false"
                            [maxDate]="maxDate"
                            fieldId="passportDate"
                            fieldName="Дата выдачи паспорта"
                            #driverpassportdate>
                        </app-input-date>
                    </div>

                    <div class="widget-input-inner hidden-control">
                        <app-input
                            [fieldControl]="passportIssuerCode"
                            fieldType="tel"
                            [textMask]="{ mask: masks.passportIssuerCode, guide: true }"
                            fieldId="passportIssuerCode"
                            fieldName="Код подразделения"
                            (input)="onPassportIssuerCodeChanged($event)">
                        </app-input>
                    </div>

                    <div class="widget-input-inner hidden-control">
                        <app-input
                            [fieldControl]="passportIssuer"
                            [textMask]="{ mask: masks.passportIssuer, guide: false }"
                            fieldId="passportIssuer"
                            fieldName="Кем выдан паспорт"
                            #fmscomponent>
                        </app-input>
                    </div>

                    <div class="widget-input-inner widget-input-full">
                        <app-input
                            [fieldControl]="address"
                            fieldId="address"
                            fieldName="Адрес регистрации (город, улица, дом, квартира)"
                            (input)="onAddressChanged($event)"
                            #driveraddress>
                        </app-input>
                    </div>
                </div>
            </div>
            <!--//Собственник-->

            <!--Страхователь-->
            <div [class.hidden-mode]="(editOwner || !editInsurer || editContacts) && edit">
                <div class="widget__footer">
                    <div class="widget__header-title">
                        <div class="widget__header-title-text">Страхователь
                            <div class="widget__icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path
                                            d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z"
                                            fill="#979797"/>
                                        <path
                                            d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z"
                                            fill="#979797"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z"
                                              fill="#979797"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div class="widget__helper widget__helper-left">
                                    <p>Страхователь - лицо, от имени которого заключается договор страхования. Это тот,
                                        кто оплачивает полис и имеет право в будущем вносить в него изменения.
                                        Страхователь должен быть либо собственником ТС, либо вписан в полис как
                                        водитель</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-checkbox
                        fieldId="isInsurer"
                        [fieldName]="'Страхователь является собственником авто ' + ownerLastName + ' ' + ownerFirstName"
                        [fieldControl]="isInsurer"
                    ></app-checkbox>
                </div>

                <div class="widget__insurant"
                     [class.hidden]="ownerIsInsurer">

                    <app-alert>
                        <p>Страхователем может быть только собственник или один из водителей</p>
                    </app-alert>

                    <div class="widget__radio">
                        <ng-container *ngFor="let item of drivers">
                            <label>
                                <input
                                    type="radio"
                                    name="insurer"
                                    [formControl]="insurerDriver"
                                    [value]="item.id">
                                <span>{{ item.value }}</span>
                            </label>
                        </ng-container>
                    </div>

<!--                    <div class="widget-input-inner widget-input-fio">-->
<!--                        <app-input-->
<!--                            [fieldControl]="insurerFio"-->
<!--                            [maxLength]="155"-->
<!--                            fieldId="insurerFio"-->
<!--                            fieldName="Фамилия Имя Отчество"-->
<!--                            (input)="onInsurerFIOChanged($event)"-->
<!--                            #insurerdriverfio>-->
<!--                        </app-input>-->
<!--                    </div>-->

                    <div class="widget-input-inner widget-input-lastName">
                        <app-input
                            [fieldControl]="insurerLastName"
                            [fieldId]="'insurerLastName'"
                            [modifier]="'widget-input-full'"
                            fieldName="Фамилия"
                            helperText="Фамилия водителя как указано в правах"
                            (input)="onInsurerLastNameChanged($event)">
                        </app-input>
                    </div>
                    <div class="widget-input-inner widget-input-firstName">
                        <app-input
                            [fieldControl]="insurerFirstName"
                            [fieldId]="'insurerFirstName'"
                            [modifier]="'widget-input-full'"
                            fieldName="Имя"
                            helperText="Имя водителя как указано в правах"
                            (input)="onInsurerFirstNameChanged($event)">
                        </app-input>
                    </div>
                    <div class="widget-input-inner widget-input-middleName">
                        <app-input
                            [fieldControl]="insurerMiddleName"
                            [fieldId]="'insurerMiddleName'"
                            [modifier]="'widget-input-full'"
                            fieldName="Отчество"
                            helperText="Отчество водителя как указано в правах"
                            (input)="onInsurerMiddleNameChanged($event)">
                        </app-input>
                    </div>

                    <div class="widget-input-inner widget-input-birthday">
                        <app-input-date
                            [fieldControl]="insurerBirthDate"
                            [checkFill]="true"
                            [changeYear]="true"
                            [showError]="true"
                            [showIcon]="false"
                            [maxDate]="maxDate"
                            fieldId="insurerBirthDate"
                            fieldName="Дата рождения"
                            (changed)="onInsurerBirthDateChanged()"
                            #insurerbirthdate>
                        </app-input-date>
                    </div>

                    <div class="widget-input-inner">
                        <app-input
                            [fieldControl]="insurerPassport"
                            [textMask]="{ mask: masks.passport, guide: true }"
                            fieldType="tel"
                            (changed)="onInsurerPassportChanged()"
                            fieldId="insurerPassport"
                            fieldName="Серия и номер паспорта">
                        </app-input>
                    </div>

                    <div class="widget-input-inner">
                        <app-input-date
                            [fieldControl]="insurerPassportDate"
                            [checkFill]="true"
                            [changeYear]="true"
                            [showError]="true"
                            [showIcon]="false"
                            [maxDate]="maxDate"
                            fieldId="insurerPassportDate"
                            fieldName="Дата выдачи паспорта"
                            #insurerpassportdate>
                        </app-input-date>
                    </div>

                    <div class="widget-input-inner hidden-control">
                        <app-input
                            [fieldControl]="insurerPassportIssuerCode"
                            fieldType="tel"
                            [textMask]="{ mask: masks.passportIssuerCode, guide: true }"
                            fieldId="insurerPassportIssuerCode"
                            fieldName="Код подразделения"
                            (input)="onInsurerPassportIssuerCodeChanged($event)">
                        </app-input>
                    </div>

                    <div class="widget-input-inner hidden-control">
                        <app-input
                            [fieldControl]="insurerPassportIssuer"
                            [textMask]="{ mask: masks.passportIssuer, guide: false }"
                            fieldId="insurerPassportIssuer"
                            fieldName="Кем выдан паспорт"
                            #insurerfmscomponent>
                        </app-input>
                    </div>

                    <div class="widget-input-inner widget-input-full">
                        <app-input
                            [fieldControl]="insurerAddress"
                            fieldId="insurerAddress"
                            fieldName="Адрес регистрации (город, улица, дом, квартира)"
                            (input)="onInsurerAddressChanged($event)"
                            #insurerdriveraddress>
                        </app-input>
                    </div>

                </div>
            </div>
            <!--//Страхователь-->

            <!--Контакты-->
            <div [class.hidden-mode]="(editOwner || editInsurer || !editContacts) && edit">
                <div class="widget__footer" [hidden]="!editContacts">
                    <div class="widget__header-title">
                        <div class="widget__header-title-text">Контакты
                        </div>
                    </div>
                </div>

                <div class="widget__body contact-block">
                    <app-alert>
                        Телефон нужен страховой компании для подтверждения расчёта ОСАГО,
                        а на указанную почту мы вышлем готовый полис
                    </app-alert>
                    <div class="widget-input-inner">
                        <app-input
                            [fieldControl]="email"
                            [maxLength]="50"
                            (input)="onEmailChanged($event)"
                            fieldId="email"
                            fieldName="E-mail">
                        </app-input>
                    </div>
                    <div class="widget-input-inner">
                        <app-input
                            [fieldControl]="phone"
                            [isPhoneNumber]="(true)"
                            fieldType="tel"
                            [textMask]="{ mask: phoneMask, guide: true }"
                            fieldId="phone"
                            fieldName="Телефон">
                        </app-input>
                    </div>
                </div>
            </div>
            <!--//Контакты-->

            <!--Кнопки-->
            <div class="widget__buttons">
                <div [hidden]="(editOwner || editInsurer || editContacts) && edit"
                     class="buttonMob"
                     (mouseenter)="onMouseEnter()"
                     (mouseleave)="onMouseLeave()">
                    <app-button
                        modifier="border-button"
                        (clicked)="onBackClick()">
                        <svg [class.hover]="isHover" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.8798 17.715C10.9728 17.6222 11.0466 17.5119 11.0969 17.3905C11.1472 17.2691 11.1731 17.1389 11.1731 17.0075C11.1731 16.8761 11.1472 16.746 11.0969 16.6246C11.0466 16.5032 10.9728 16.3929 10.8798 16.3L7.58784 13.007L17.9998 13C18.2651 13 18.5194 12.8947 18.7069 12.7071C18.8945 12.5196 18.9998 12.2652 18.9998 12C18.9998 11.7348 18.8945 11.4805 18.7069 11.2929C18.5194 11.1054 18.2651 11 17.9998 11L7.58584 11.007L10.8798 7.71503C11.0675 7.52752 11.1729 7.27315 11.173 7.00788C11.1731 6.74261 11.0678 6.48817 10.8803 6.30053C10.6928 6.11289 10.4385 6.00742 10.1732 6.00732C9.90792 6.00723 9.65348 6.11252 9.46584 6.30003L5.87984 9.88603C5.60121 10.1646 5.38018 10.4953 5.22939 10.8594C5.07859 11.2234 5.00098 11.6135 5.00098 12.0075C5.00098 12.4015 5.07859 12.7917 5.22939 13.1557C5.38018 13.5197 5.60121 13.8504 5.87984 14.129L9.46584 17.715C9.65336 17.9025 9.90767 18.0078 10.1728 18.0078C10.438 18.0078 10.6923 17.9025 10.8798 17.715Z"/>
                        </svg>
                        <span>Назад</span>
                    </app-button>
                </div>
                <div [hidden]="(editOwner || editInsurer || editContacts) && edit"
                     class="buttonMob">
                    <app-button
                        modifier="blue-button"
                        buttonType="submit">
                        Продолжить
                    </app-button>
                </div>
                <div [hidden]="(!editOwner || !editInsurer || !editContacts) && !edit"
                     class="buttonMob">
                    <app-button
                        modifier="blue-button"
                        buttonType="submit">
                        Сохранить
                    </app-button>
                </div>
                <p
                    *ngIf="isFormError == true"
                    class="error-content">
                    Проверьте правильность введённых данных
                </p>
            </div>

            <div *ngIf="!edit"
                 class="widget__policy widget__body">
                <label>
                    <input
                        type="checkbox"
                        checked="checked"
                        (change)="onAgreementChange($event)"/>
                    <p>Согласен с
                        <a href="./assets/files/privacy.pdf" target="_blank">правилами предоставления информации</a>
                    </p>
                </label>
            </div>
            <!--//Кнопки-->
        </div>

    </form>
</div>

<ng-container *ngIf="isPopupOpen">
    <div class="popupBg popupBg-active"></div>
    <div class="popupMain popupMain-active">
        <div class="popupMain__close" (click)="togglePopup()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">	<path d="M17.9998 6.00006C17.8123 5.81259 17.558 5.70728 17.2928 5.70728C17.0277 5.70728 16.7733 5.81259 16.5858 6.00006L11.9998 10.5861L7.41382 6.00006C7.22629 5.81259 6.97198 5.70728 6.70682 5.70728C6.44165 5.70728 6.18735 5.81259 5.99982 6.00006C5.81235 6.18759 5.70703 6.4419 5.70703 6.70706C5.70703 6.97223 5.81235 7.22653 5.99982 7.41406L10.5858 12.0001L5.99982 16.5861C5.81235 16.7736 5.70703 17.0279 5.70703 17.2931C5.70703 17.5582 5.81235 17.8125 5.99982 18.0001C6.18735 18.1875 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1875 7.41382 18.0001L11.9998 13.4141L16.5858 18.0001C16.7733 18.1875 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1875 17.9998 18.0001C18.1873 17.8125 18.2926 17.5582 18.2926 17.2931C18.2926 17.0279 18.1873 16.7736 17.9998 16.5861L13.4138 12.0001L17.9998 7.41406C18.1873 7.22653 18.2926 6.97223 18.2926 6.70706C18.2926 6.4419 18.1873 6.18759 17.9998 6.00006Z" fill="#979797"/></svg>
        </div>
        <app-drivers
            [editMode]="(true)"
            [editDriverIndex]="-1"
            (close)="togglePopup()">
        </app-drivers>
    </div>
</ng-container>
