import { Component, OnDestroy, OnInit } from '@angular/core';

// Модели
import { Offer } from '../../models';

// Сервисы
import { ApplicationService } from '../../../../services';
import { OsagoService } from '../../services';
import { interval, Subscription } from 'rxjs';
import * as moment from 'moment-mini';
import {InitialData} from '../../../../../../../common-lib/src/lib/models';

@Component({
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
    public offer!: Offer | null;

    // Таймер
    public timer!: string;
    public dDay = moment().add(3, 'm').toDate();

    // Подписка
    private subscription: Subscription = new Subscription();

    // Ссылка на черновик
    public draftLink: string;

    // Индиатор загрузки
    public isLoading = false;

    // ID выбранного кроса
    public selectedCrossId: string | null = null;

    // Финальная цена с учетом кроса
    public finalPrice!: number;

    constructor(
        public appService: ApplicationService,
        private osagoService: OsagoService
    ) {
        this.appService._payUrl = null;
        this.appService.crossNotApproved = false;
        this.offer = osagoService.selectedOffer;
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.osagoService.onYandexReachGoal('PAYMENT_GOAL');
        this.appService.scrollToElement('payment-anchor');

        if (this.offer?.premiumAmount) {
            this.finalPrice = this.offer?.premiumAmount;
        }

        if (this.offer?.upsales && this.offer?.upsales?.length) {
            this.offer.upsales = this.offer.upsales.filter((cross: any) => {
                if (cross.isPreselected) {
                    cross.isSelected = cross.isPreselected;
                    this.finalPrice = this.offer?.premiumAmount + cross.price;
                    this.selectedCrossId = cross.id;
                }
                return cross?.isOptional;
            });
        } else {
            this.createPayment();
        }
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.appService.isRoutePayment = false;
        this.subscription.unsubscribe();
    }
    // --------------------------------------------------------------------------

    // Создаем поилс
    public createPayment(): void {
        this.isLoading = true;
        this.osagoService.generatePaymentLink();
        this.getTimer();
    }

    // Таймер
    private getTimer(): void {
        this.dDay = moment().add(3, 'm').toDate();
        this.subscription = interval(1000)
            .subscribe(x => this.allocateTimeUnits(this.dDay.getTime() - new Date().getTime()));
    }
    private allocateTimeUnits(timeDifference: any): void {
        const secondsToDday = Math.floor((timeDifference) / (1000) % 60);
        const minutesToDday = Math.floor((timeDifference) / (1000 * 60) % 60);
        this.timer = minutesToDday + ':' + secondsToDday;
    }

    // Открываем оплату в новой вкладке
    public openPayment(url: string): void {
        this.osagoService.onYandexReachGoal('PAYMENT_SUBMIT');
        window.open(url, '_blank');
        this.appService.setStatusBuyButtonPresssed();
        this.appService.returnToOffers();
    }

    // Получаем черновик
    public getDraft(): void {
        this.draftLink = '';
        this.subscription.add(
            this.osagoService.getDraft().subscribe({
                next: (response) => {
                    if (response?.result === true) {
                        if (response?.value?.status === 1) {
                            window.setTimeout(() => {
                                this.getDraft();
                            }, 1500);
                        } else if (response?.value?.status === 2) {
                            const initialData = (window as any).osagoApplicationData as InitialData;
                            this.draftLink = initialData.apiBaseUrl + 'File/draft/' + response?.value.policyDraft;
                        }
                    } else if (response.error?.type === 'ApplicationService.GetDraft') {
                        window.setTimeout(() => {
                            this.getDraft();
                        }, 1500);
                    } else {
                        // this.onPaymentError("GetDraft API error");
                    }
                },
                // error: () => this.onPaymentError("GetDraft http error")
            })
        );
    }

    public openDraft(): void {
        window.open(this.draftLink, '_blank');
    }

    // Выбрапли кросс
    public changeCross(crossId: string): void {
        this.offer?.upsales.forEach((item: any) => {
            if (item.id === crossId) {
                item.isSelected = !item.isSelected;
                this.selectedCrossId = crossId;
                if (this.offer) {
                    this.finalPrice = item.isSelected ? this.offer.premiumAmount + item.price : this.finalPrice - item.price;
                }
            } else {
                item.isSelected = false;
            }
        });
    }

}
