import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { DictionaryItem } from '../../../../../../common-lib/src/lib/models';

import { ApiError, ApiResponse, GetOffersResponse, Offer, Offers, OsagoPaymentLink, OsagoPaymentLinkResponse } from '../models';
import { RequestByIdRequest, RequestIdResult } from "../../startup/models";
import { RequestByIdResponse } from "../../startup/models/requestByIdResponse.model";
import { Modification } from "../models/modification.model";
import { ModificationList } from "../models/modificationList.model";
import { ModificationListResponse } from "../models/modificationListResponse.model";
import { OsagoOwner } from "../models/osagoOwner.model";
import { OsagoService } from "./osago.service";

@Injectable()
export class OsagoMockService extends OsagoService {
    private _requestCounter = 0;
    private _requests = new Map<number, ModificationListResponse>();
    private offersCounter = 0;
    // Получение всех моделей для текущей выбранной марки
    public getModels(brandId: number): Observable<DictionaryItem[]> {
        let models = this._modelsCache.get(brandId);
        if (models != null)
            return of(models) as Observable<DictionaryItem[]>;

        models = [];

        switch (brandId) {
            case 1:
                models = [
                    { id: 11, value: 'Civic' },
                    { id: 12, value: 'S2000' }];
                break;
            case 2:
                models = [
                    { id: 21, value: '240SX' },
                    { id: 22, value: '370Z' },
                    { id: 23, value: 'GT-R' },
                    { id: 24, value: 'Silvia' }];
                break;
            case 3:
                models = [
                    { id: 31, value: 'Altezza' },
                    { id: 32, value: 'GT86' },
                    { id: 33, value: 'Selica' },
                    { id: 34, value: 'Soarer' },
                    { id: 35, value: 'Supra' }];
                break;
        }

        this._modelsCache.set(brandId, models);
        return of(models).pipe(delay(2000));
    }

    public loadOffers() {
        const offers: Offer[] = [
            Offer.create(new Offer('1', 142000, 12000, 'Суперкаска', 'СОГАЗ', 'Sogaz', [], false, 'Approved', 3500, '2021-05-21T00:00:00', '12 месяцев', '', [], false)),
            new Offer('2', 135000, 15400, 'Ещё каска', 'РЕСО', 'RESO', [], false, '', 5000, '01.04.2021', '12 месяцев', '', [], false),
            new Offer('3', 151000, 20000, 'Просто каска', 'ГАЙДЕ', 'Gaide', [], false, '', 4350, '02.04.2021', '12 месяцев', '', [], false),
            new Offer('4', 165000, 30000, 'КАСКО ОНЛАЙН', 'РОСГОССТРАХ', 'Rosgosstrah', [], false, '', 4467, '02.04.2021', '12 месяцев', '', [], false)
        ];

        window.setTimeout(() => this._offers.next(offers), 1000);
    }

    protected getOffersObservable(): Observable<GetOffersResponse> {
        this.offersCounter++;
        let allOffers: Offer[] = [];
        let allOffersReceived = false;

        if (this.offersCounter > 16) {
            allOffers = [
                new Offer('1', 142000, 12000, 'Суперкаска', 'СОГАЗ', 'Sogaz', [], false, 'Approved', 3500, '2021-05-21T00:00:00', '12 месяцев', '', [], false),
                new Offer('2', 135000, 15400, 'Ещё каска', 'Альфастрахование', 'Alfa', [], false, 'Approved', 5000, '01.04.2021', '12 месяцев', '', [], false),
                new Offer('3', 151000, 11000, 'Просто каска', 'ГАЙДЕ', 'Gaide', [], false, 'Approved', 4350, '02.04.2021', '12 месяцев', '', [], false),
                new Offer('4', 165000, 30000, 'КАСКО ОНЛАЙН', 'РОСГОССТРАХ', 'Rosgosstrah', [], false, 'Approved', 3990, '02.04.2021', '12 месяцев', '', [], false)
            ];

            allOffersReceived = true;
        } else if (this.offersCounter > 12)
            allOffers = [
                new Offer('1', 142000, 12000, 'Суперкаска', 'СОГАЗ', 'Sogaz', [], false, 'Approved', 6600, '2021-05-21T00:00:00', '12 месяцев', '', [], false),
                new Offer('2', 135000, 15400, 'Ещё каска', 'Альфастрахование', 'Alfa', [], false, 'Approved', 5000, '01.04.2021', '12 месяцев', '', [], false),
                new Offer('3', 151000, 11000, 'Просто каска', 'ГАЙДЕ', 'Gaide', [], false, 'Approved', 4350, '02.04.2021', '12 месяцев', '', [], false),
                new Offer('4', 165000, 30000, 'КАСКО ОНЛАЙН', 'РОСГОССТРАХ', 'Rosgosstrah', [], false, 'Requested', 4467, '02.04.2021', '12 месяцев', '', [], false)
            ];
        else if (this.offersCounter > 8)
            allOffers = [
                new Offer('1', 142000, 12000, 'Суперкаска', 'СОГАЗ', 'Sogaz', [], false, 'Approved', 6600, '2021-05-21T00:00:00', '12 месяцев', '', [], false),
                new Offer('2', 135000, 15400, 'Ещё каска', 'Альфастрахование', 'Alfa', [], false, 'Approved', 5000, '01.04.2021', '12 месяцев', '', [], false),
                new Offer('3', 151000, 11000, 'Просто каска', 'ГАЙДЕ', 'Gaide', [], false, 'Requested', 4350, '02.04.2021', '12 месяцев', '', [], false),
                new Offer('4', 165000, 30000, 'КАСКО ОНЛАЙН', 'РОСГОССТРАХ', 'Rosgosstrah', [], false, 'Requested', 4467, '02.04.2021', '12 месяцев', '', [], false)
            ];
        else if (this.offersCounter > 5) {
            allOffers = [
                new Offer('1', 142000, 12000, 'Суперкаска', 'СОГАЗ', 'Sogaz', [], false, 'Approved', 6600, '2021-05-21T00:00:00', '12 месяцев', '', [], false),
                new Offer('2', 135000, 15400, 'Ещё каска', 'Альфастрахование', 'Alfa', [], false, 'Requested', 0, '01.04.2021', '12 месяцев', '', [], false),
                new Offer('3', 151000, 11000, 'Просто каска', 'ГАЙДЕ', 'Gaide', [], false, 'Requested', 0, '02.04.2021', '12 месяцев', '', [], false),
                new Offer('4', 165000, 30000, 'КАСКО ОНЛАЙН', 'РОСГОССТРАХ', 'Rosgosstrah', [], false, 'Requested', 4467, '02.04.2021', '12 месяцев', '', [], false)
            ];

        }
        else if (this.offersCounter == 1)
            allOffers = [
                new Offer('1', 142000, 12000, 'Суперкаска', 'СОГАЗ', 'Sogaz', [], false, 'Requested', 6600, '2021-05-21T00:00:00', '12 месяцев', '', [], false),
                new Offer('2', 135000, 15400, 'Ещё каска', 'Альфастрахование', 'Alfa', [], false, 'Requested', 0, '01.04.2021', '12 месяцев', '', [], false),
                new Offer('3', 151000, 20000, 'Просто каска', 'ГАЙДЕ', 'Gaide', [], false, 'Requested', 0, '02.04.2021', '12 месяцев', '', [], false),
                new Offer('4', 165000, 30000, 'КАСКО ОНЛАЙН', 'РОСГОССТРАХ', 'Rosgosstrah', [], false, 'Requested', 4467, '02.04.2021', '12 месяцев', '', [], false)
            ];

        const result = new GetOffersResponse(true, undefined, new Offers(allOffersReceived, allOffers));
        return of(result);
    }

    protected getPaymentLinkObservable(): Observable<OsagoPaymentLinkResponse> {
        var result = new OsagoPaymentLinkResponse(true, undefined, new OsagoPaymentLink('', '', '', null));
        return of(result).pipe(delay(5000));
    }

    public generatePaymentLink() {
        window.setTimeout(() => {
            //this.onPaymentError();
            //return;

            this._paymentLink = "http://www.happylend.ru";
            this._paymentResult.next(true);
        }, 2000);
    }

    public waitForOffers() {
        this.offersCounter = 0;
        this.getOffersAttempts = 0;
        this._preOffer.next(new Offer('4', 165000, 30000, 'КАСКО ОНЛАЙН', 'РОСГОССТРАХ', 'None', [], false, 'Approved', 4467, '02.04.2021', '12 месяцев', '', [], false));
        this.getOffers();
    }

    protected getModificationsRequestIdObservable(modelId: number, year: number): Observable<RequestByIdResponse> {
        this._requestCounter++;
        let modifications: Modification[] = [];
        switch (modelId) {
            case 11:
                modifications = [
                    new Modification("", "", 100, "", "", "11-1", "Civic mod 1", ""),
                    new Modification("", "", 200, "", "", "11-2", "Civic mod 2", "")
                ];
                break;
            case 12:
                modifications = [
                    new Modification("", "", 110, "", "", "12-1", "S2000 mod 1", ""),
                    new Modification("", "", 150, "", "", "12-2", "S2000 mod 2", "")
                ];
                break;
            case 21:
                modifications = [
                    new Modification("", "", 200, "", "", "21-1", "240SX mod 1", ""),
                    new Modification("", "", 230, "", "", "21-2", "240SX mod 2", "")
                ];
                break;
            case 22:
                modifications = [
                    new Modification("", "", 110, "", "", "22-1", "370Z mod 1", ""),
                    new Modification("", "", 150, "", "", "22-2", "370Z mod 2", "")
                ];
                break;
            case 23:
                modifications = [
                    new Modification("", "", 300, "", "", "23-1", "GT-R mod 1", ""),
                    new Modification("", "", 500, "", "", "23-2", "GT-R mod 2", "")
                ];
                break;
            case 24:
                modifications = [
                    new Modification("", "", 250, "", "", "24-1", "Silvia mod 1", ""),
                    new Modification("", "", 280, "", "", "24-2", "Silvia mod 2", "")
                ];
                break;
            case 31:
                modifications = [
                    new Modification("", "", 250, "", "", "31-1", "Altezza mod 1", ""),
                    new Modification("", "", 280, "", "", "31-2", "Altezza mod 2", "")
                ];
                break;
            case 32:
                modifications = [
                    new Modification("", "", 250, "", "", "32-1", "GT86 mod 1", ""),
                    new Modification("", "", 280, "", "", "32-2", "GT86 mod 2", "")
                ];
                break;
            case 33:
                modifications = [
                    new Modification("", "", 250, "", "", "33-1", "Selica mod 1", ""),
                    new Modification("", "", 280, "", "", "33-2", "Selica mod 2", "")
                ];
                break;
            case 34:
                modifications = [
                    new Modification("", "", 250, "", "", "34-1", "Soarer mod 1", ""),
                    new Modification("", "", 280, "", "", "34-2", "Soarer mod 2", "")
                ];
                break;
            case 35:
                modifications = [
                    new Modification("", "", 250, "", "", "35-1", "Supra mod 1", ""),
                    new Modification("", "", 280, "", "", "35-2", "Supra mod 2", "")
                ];
                break;
        };

        const modificationListResponse = new ModificationListResponse(true, undefined, new ModificationList(modifications, "Complete"));
        this._requests.set(this._requestCounter, modificationListResponse);
        const response = new RequestByIdResponse(true, undefined, new RequestIdResult(this._requestCounter.toString()));
        return of(response).pipe(delay(1000));
    }

    protected getModificationsByRequestIdObservable(requestId: string): Observable<ModificationListResponse> {
        const id = parseInt(requestId);
        const response = this._requests.get(id) || new ModificationListResponse(false, new ApiError("Unexpected error"));
        return of(response).pipe(delay(1000));
    }

    public getInitialData() {}
}
