<div class="steps" *ngIf="isShowStepper">
    <ul>
        <li *ngFor="let step of steps; let i = index">
            <a [routerLink]="step.route" [ngClass]="{'disabled': step.disabled}">
                <span class="title-desktop">{{ step.title }}</span>
                <span class="title-mobile">{{ step.titleMobile }}</span>
            </a>
        </li>
    </ul>
    <ul class="separator">
        <li><img src="./assets/images/icons/arr.svg" alt=""></li>
        <li><img src="./assets/images/icons/arr.svg" alt=""></li>
    </ul>
</div>
