import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment-mini';
import { environment } from '../../../../../../environments/environment';
import { CalendarHelper, OsagoValidators, TextMaskHelper } from '../../../helpers';
import { FormsService, OsagoService, PriceCalculatorService, ScriptService } from '../../../services';
import { DriverExperience, OsagoDriver } from '../../../models';
import { InputDateComponent } from '../../core';
import { IDriversForOffersList } from '../../../../shared/interfaces/drivers-for-offers-list';

// using jQuery suggestions, attached in Index.chtml
declare var $: any;

@Component({
    selector: 'app-driver',
    templateUrl: './driver.component.html',
    styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit, AfterViewInit, OnDestroy {
    // Форма водителя
    public driverForm!: FormGroup;
    description = '';
    canDelete = false;
    rememberOnlyYear = false;
    licenseChanged = false;
    id = 0;
    isPopupOpen = false;
    licenseDatePopupOpen = false;
    licenseSeriesPopupOpen = false;
    licenseDateStartPopupOpen = false;
    // Индекс водителя
    public indexDriver = 1;

    public masks = {
        fullDate: [ /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/ ],
        year: [ /\d/, /\d/, /\d/, /\d/ ],
        license: [ /^[0-9a-zA-Zа-яА-ЯёЁ\{\[]$/, /^[0-9a-zA-Zа-яА-ЯёЁ\{\[]$/, ' ', /^[0-9a-zA-Zа-яА-ЯёЁ\{\[]$/, /^[0-9a-zA-Zа-яА-ЯёЁ\{\[]$/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]
    };

    private _dayAndMonth = CalendarHelper.getRandomDayAndMonth();
    private _dayAndMonthThisYear = CalendarHelper.getRandomDayAndMonthThisYear();
    private _currentBirthDate = '';
    private _currentExperinceDate = '';
    private _currentLicenseDate = '';
    private $fio: any = null;
    private $firstName: any = null;
    private $lastName: any = null;
    private $middleName: any = null;
    private $oldfio: any = null;
    private _validatorsSet = false;
    private driver: OsagoDriver | null = null;
    private raiseChanged = false;
    private _subscription = new Subscription();
    public driversKvsKbm: IDriversForOffersList[] | undefined;

    @ViewChild('birthdate') private birthdateComponent: InputDateComponent | null = null;
    // @ViewChild('experiencedate') private experienceComponent: InputDateComponent | null = null;
    @ViewChild('licensedate') private licenseComponent: InputDateComponent | null = null;
    @ViewChild('licensedatepopup') private licensePopupElement: ElementRef | null = null;
    @ViewChild('licenseseriespopup') private licenseSeriesPopupElement: ElementRef | null = null;
    @ViewChild('licensedatestartpopup') private licenseStartPopupElement: ElementRef | null = null;

    @Output()
    deleted = new EventEmitter<void>();

    @Output()
    changed = new EventEmitter<void>();

    @Output()
    experienceChanged = new EventEmitter<DriverExperience>();

    // Локадер загрузки КБМ
    public isLoadingKbm = false;

    @Input()
    set options(val: any) {
        this.driver = val?.driver;
        this.driverForm = val?.form;
        this.id = this.driverForm?.get('id')?.value;

        let fio = null;
        if (this.driver != null && this.driver.firstName != null && this.driver.middleName != null && this.driver.lastName != null
            && this.driver.firstName.length > 0 && this.driver.lastName.length > 0 && this.driver.middleName.length > 0) {
            fio = `${ this.driver.lastName } ${ this.driver.firstName } ${ this.driver.middleName }`;
        }

        let firstName = null;
        if (this.driver != null && this.driver.firstName && this.driver.firstName.length > 0) {
            firstName = `${ this.driver.firstName }`;
        }

        let lastName = null;
        if (this.driver != null && this.driver.lastName && this.driver.lastName.length > 0) {
            lastName = `${ this.driver.lastName }`;
        }

        let middleName = null;
        if (this.driver != null && this.driver.middleName && this.driver.middleName.length > 0) {
            middleName = `${ this.driver.middleName }`;
        }

        let oldFio = null;
        if (this.driver != null && this.driver.oldDriverLicense != null
            && this.driver.oldDriverLicense.lastNameInOldLicense != null
            && this.driver.oldDriverLicense.lastNameInOldLicense.length > 0) {
            oldFio = this.driver.oldDriverLicense.lastNameInOldLicense;
        }

        let experienceStartDate = null;
        if (this.driver?.rememberOnlyYear !== true) {
            experienceStartDate = this.driver?.experienceStartDate || null;
        }

        let experienceStartYear = null;
        if (this.driver?.rememberOnlyYear === true
            && this.driver?.experienceStartDate !== null
            && this.driver?.experienceStartDate.length === 10) {
            experienceStartYear = this.driver?.experienceStartDate.substr(6);
        }

        this.licenseChanged = this.driver != null && this.driver.oldDriverLicense != null;
        this.rememberOnlyYear = this.driver?.rememberOnlyYear === true;

        let license = null;
        if (this.driver?.driverLicense?.licenseNumber != null && this.driver?.driverLicense?.licenseNumber.length > 0 && this.driver?.driverLicense?.licenseSeries != null && this.driver?.driverLicense?.licenseSeries.length > 0) {
            license = this.driver.driverLicense.licenseSeries.substr(0, 2) + ' ' + this.driver.driverLicense.licenseSeries.substr(2, 2) + ' ' + this.driver.driverLicense.licenseNumber;
        }

        let oldLicense = null;
        if (this.driver?.oldDriverLicense?.licenseNumber != null
            && this.driver?.oldDriverLicense?.licenseNumber.length > 0
            && this.driver?.oldDriverLicense?.licenseSeries != null
            && this.driver?.oldDriverLicense?.licenseSeries.length > 0) {
            oldLicense = this.driver.oldDriverLicense.licenseSeries.substr(0, 2) + ' ' + this.driver.oldDriverLicense.licenseSeries.substr(2, 2) + ' ' + this.driver.oldDriverLicense.licenseNumber;
        }

        // this.driverForm?.addControl('fio', new FormControl(fio, OsagoValidators.fio('fioDadata')));

        this.driverForm?.addControl('firstName', new FormControl(firstName,
            [OsagoValidators.firstName()]));
        this.driverForm?.addControl('lastName', new FormControl(lastName,
            [OsagoValidators.lastName()]));
        this.driverForm?.addControl('middleName', new FormControl(middleName,
            [OsagoValidators.middleName()]));
        this.driverForm?.addControl('oldfio', new FormControl(oldFio,
            [OsagoValidators.oldfio(() => !this.licenseChanged, 'oldfioDadata')]));

        // this.driverForm?.addControl('firstName', new FormControl('Павел', [OsagoValidators.required()]));
        // this.driverForm?.addControl('lastName', new FormControl('Бизюкин', [OsagoValidators.required()]));
        // this.driverForm?.addControl('middleName', new FormControl('Владимирович'));

        this._currentBirthDate = this.driver?.birthDate || '';

        if (!this.rememberOnlyYear) {
            this._currentExperinceDate = experienceStartDate || '';
        } else if (experienceStartYear != null) {
            this.setExperienceStartDateFromYear(experienceStartYear);
        }

        this.driverForm?.addControl(
            'fioDadata',
            new FormControl(null, null)
        );

        this.driverForm?.addControl(
            'firstNameDadata',
            new FormControl(null, null)
        );

        this.driverForm?.addControl(
            'lastNameDadata',
            new FormControl(null, null)
        );

        this.driverForm?.addControl(
            'birthDate',
            new FormControl(this.driver?.birthDate || null, null)
        );

        // this.driverForm?.addControl(
        //     'birthDate',
        //     new FormControl('23.05.1988' || null, null)
        // );

        this.driverForm?.addControl(
            'experienceStartDate',
            new FormControl(experienceStartDate, null)
        );

        this.driverForm?.addControl(
            'experienceStartYear',
            new FormControl(experienceStartYear, null)
        );

        // this.driverForm?.addControl(
        //     'license',
        //     new FormControl('3034788289', null)
        // );

        this.driverForm?.addControl(
            'license',
            new FormControl(license, null)
        );

        // this.driverForm?.addControl(
        //     'licenseDate',
        //     new FormControl('08.09.2006', null)
        // );

        this.driverForm?.addControl(
            'licenseDate',
            new FormControl(this.driver?.experienceStartDate, null)
        );

        this.driverForm?.addControl(
            'oldLicense',
            new FormControl(oldLicense, null)
        );

        this.driverForm?.addControl(
            'oldfioDadata',
            new FormControl(null, null)
        );

        this.driverForm?.addControl(
            'licenseWasChanged',
            new FormControl(this.licenseChanged, null)
        );

        this.driverForm?.addControl(
            'rememberOnlyYearCheckbox',
            new FormControl(this.rememberOnlyYear, { validators: null, updateOn: 'change' })
        );

        this._subscription.add(
            this.rememberOnlyYearCheckbox?.valueChanges.subscribe((value) => {
                this.rememberOnlyYear = value || false;
            })
        );

        this._subscription.add(
            this.driverForm?.valueChanges.subscribe(x => this.raiseChanged = true)
        );
    }

    @Input()
    set index(val: number) {
        this.indexDriver = val;
        switch (val) {
            case 1:
                this.description = 'Первый водитель';
                break;
            case 2:
                this.description = 'Второй водитель';
                break;
            case 3:
                this.description = 'Третий водитель';
                break;
            case 4:
                this.description = 'Четвёртый водитель';
                break;
            case 5:
                this.description = 'Пятый водитель';
                break;
        }
    }

    @Input()
    set allowDelete(val: boolean) {
        this.canDelete = val;
    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event: any): void {
        if (!this.licensePopupElement?.nativeElement?.contains(event.target)) {
            this.licenseDatePopupOpen = false;
        }

        if (!this.licenseSeriesPopupElement?.nativeElement?.contains(event.target)) {
            this.licenseSeriesPopupOpen = false;
        }

        if (!this.licenseStartPopupElement?.nativeElement?.contains(event.target)) {
            this.licenseDateStartPopupOpen = false;
        }
    }

    constructor(
        private formsService: FormsService,
        public priceService: PriceCalculatorService,
        public osagoService: OsagoService,
        private scriptService: ScriptService,
    ) {
        this.isLoadingKbm = this.osagoService.isLoadingKbm;
        this.driversKvsKbm = this.osagoService.driversKvsKbm;
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.subscribeForm();
    }

    public ngAfterViewInit(): void {
        if (!this.scriptService.ready) {
            window.setTimeout(() => this.ngAfterViewInit(), 500);
            return;
        }
        this.$fio = $('#fio-' + this.id);
        this.$firstName = $('#firstName-' + this.id);
        this.$lastName = $('#lastName-' + this.id);
        this.$middleName = $('#middleName-' + this.id);
        this.$oldfio = $('#oldfio-' + this.id);
        this.initDadata();
        // Проверка валидации ФИО через дадата
        // this.restoreDadata();
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
    // --------------------------------------------------------------------------

    // Подписка на изменение формы
    private subscribeForm(): void {
    }

    public togglePopup() {
        this.isPopupOpen = !this.isPopupOpen;
    }

    public licensePipe(conformedValue: string, config: any): any {
        if (conformedValue == null || conformedValue.length < 5) {
            return conformedValue;
        }

        const translatedCharPositions = [];
        const symbol3 = conformedValue.substr(3, 1);
        const symbol3Translated = TextMaskHelper.translateChar(symbol3);

        if (symbol3Translated == null) {
            return false;
        }

        if (symbol3.toLowerCase() !== symbol3Translated.toLowerCase()) {
            translatedCharPositions.push(2);
        }

        const symbol4 = conformedValue.substr(4, 1);
        const symbol4Translated = TextMaskHelper.translateChar(symbol4);

        if (symbol4Translated == null) {
            return false;
        }

        if (symbol4.toLowerCase() !== symbol4Translated.toLowerCase()) {
            translatedCharPositions.push(3);
        }

        const result = conformedValue.substr(0, 3)
            + symbol3Translated
            + symbol4Translated
            + conformedValue.substr(5);

        if (translatedCharPositions.length > 0) {
            const fieldId = document.activeElement?.id;
            const position = TextMaskHelper.getCaretPosition(document.activeElement);
            window.setTimeout(() => TextMaskHelper.adjustCaretPosition(fieldId, position));
        }

        return {
            value: result,
            translatedCharPositions
        };
    }

    public setValidators() {
        if (this._validatorsSet) {
            return;
        }

        this._validatorsSet = true;
        this.birthDate?.setValidators([ OsagoValidators.birthDate() ]);
        // this.experienceStartDate?.setValidators([ OsagoValidators.experienceStartDate(() => this.rememberOnlyYear == true) ]);
        this.experienceStartYear?.setValidators([ OsagoValidators.experienceStartYear(() => this.rememberOnlyYear === false) ]);
        this.license?.setValidators([ OsagoValidators.license() ]);
        if (this.rememberOnlyYear) {
            this.licenseDate?.clearValidators();
        } else {
            this.licenseDate?.setValidators([ OsagoValidators.licenseDate(() => this.getExperienceDateForLicenseValidation(), 'birthDate') ]);
        }
        this.oldLicense?.setValidators([ OsagoValidators.oldLicense(() => !this.licenseChanged) ]);
        // this.oldfio?.setValidators([ OsagoValidators.oldfio(() => !this.licenseChanged, 'oldfioDadata') ]);
    }

    get fio(): AbstractControl | null{
        return this.driverForm?.get('fio') || null;
    }

    get firstName(): AbstractControl | null{
        return this.driverForm?.get('firstName') || null;
    }

    get lastName(): AbstractControl | null{
        return this.driverForm?.get('lastName') || null;
    }

    get middleName(): AbstractControl | null{
        return this.driverForm?.get('middleName') || null;
    }

    get fioDadata(): AbstractControl | null {
        return this.driverForm?.get('fioDadata') || null;
    }

    get firstNameDadata(): AbstractControl | null {
        return this.driverForm?.get('firstNameDadata') || null;
    }

    get lastNameDadata(): AbstractControl | null {
        return this.driverForm?.get('lastNameDadata') || null;
    }

    get birthDate(): AbstractControl | null{
        return this.driverForm?.get('birthDate') || null;
    }

    get experienceStartDate(): AbstractControl | null{
        return this.driverForm?.get('experienceStartDate') || null;
    }

    get experienceStartYear(): AbstractControl | null{
        return this.driverForm?.get('experienceStartYear') || null;
    }

    get license(): AbstractControl | null{
        return this.driverForm?.get('license') || null;
    }

    get oldLicense(): AbstractControl | null{
        return this.driverForm?.get('oldLicense') || null;
    }

    get licenseDate(): AbstractControl | null{
        return this.driverForm?.get('licenseDate') || null;
    }

    get oldfio(): AbstractControl | null{
        return this.driverForm?.get('oldfio') || null;
    }

    get oldfioDadata(): AbstractControl | null {
        return this.driverForm?.get('oldfioDadata') || null;
    }

    get licenseWasChanged(): AbstractControl | null {
        return this.driverForm?.get('licenseWasChanged') || null;
    }

    get randomDate(): AbstractControl | null {
        return this.driverForm?.get('randomDate') || null;
    }

    get rememberOnlyYearCheckbox(): AbstractControl | null {
        return this.driverForm?.get('rememberOnlyYearCheckbox') || null;
    }

    onBirthdateChanged(value: string) {
        this._currentBirthDate = value;
        this.checkExperience();
    }

    onExperienceChanged(value: string) {
        this._currentExperinceDate = value;
        this.checkExperience();
    }

    // Выбор года начала стажа
    onExperienceYearChanged(value: string) {
        let year = '';
        if (typeof value === 'number') {
            year = '' + value;
        } else {
            year = TextMaskHelper.removeMask(value, 4);
        }

        if (year.length !== 4) {
            return;
        }

        if (Number(year) === moment().year()) {
            this._currentExperinceDate = this._dayAndMonthThisYear + year;
        } else {
            this._currentExperinceDate = this._dayAndMonth + year;
        }

        this.randomDate?.setValue(this._currentExperinceDate);
        this.checkExperience();
    }

    onClear(event: any) {
        event.preventDefault();
        this.driverForm?.reset();
        this.birthdateComponent?.checkFormValue();
        // this.experienceComponent?.checkFormValue();
        this.licenseComponent?.checkFormValue();
        this.rememberOnlyYear = false;
        this.licenseChanged = false;
        this.raiseChanged = true;
    }

    onDelete(event: any) {
        event.preventDefault();
        this.deleted.emit();
    }

    onLicenseChanged(event: any) {
        this.licenseChanged = event.target?.checked || false;
        this.licenseWasChanged?.setValue(this.licenseChanged);
    }

    onLicenseDateChanged(value: string) {
        this._currentLicenseDate = value;
    }

    onRemeberFullDateClick() {
        this.rememberOnlyYear = false;
    }

    onRemeberOnlyYearClick() {
        this.rememberOnlyYear = true;
    }

    onFIOChanged(event: any) {
        if (event != null && event.type == 'keyup' && (event.key == 'Enter' || event.keyCode == 13 || event.which == 13)) {
            return;
        }

        this.fioDadata?.setValue(null);
    }

    public onFirstNameChanged(event: any): void {
        if (event != null && event.type == 'keyup' && (event.key == 'Enter' || event.keyCode == 13 || event.which == 13)) {
            return;
        }

        this.firstNameDadata?.setValue(null);
    }

    public onLastNameChanged(event: any): void {
        if (event != null && event.type == 'keyup' && (event.key == 'Enter' || event.keyCode == 13 || event.which == 13)) {
            return;
        }

        this.lastNameDadata?.setValue(null);
    }

    public onMiddleNameChanged(event: any): void {
        if (event != null && event.type == 'keyup' && (event.key == 'Enter' || event.keyCode == 13 || event.which == 13)) {
            return;
        }

        this.fioDadata?.setValue(null);
    }

    public onOldFIOChanged(event: any): void {
        if (event != null && event.type == 'keyup' && (event.key == 'Enter' || event.keyCode == 13 || event.which == 13)) {
            return;
        }

        this.oldfioDadata?.setValue(null);
    }

    getExperienceDateForLicenseValidation(): string | null {
        if (this.rememberOnlyYear) {
            const value = this.experienceStartYear?.value;
            return value != null && value.length == 4
                ? '01.01.' + TextMaskHelper.getYear(value)
                : null;
        }

        return TextMaskHelper.getDate(this.experienceStartDate?.value);
    }

    protected setExperienceStartDateFromYear(year: string): void {
        if (parseInt(year) === moment().year()) {
            this._currentExperinceDate = this._dayAndMonthThisYear + year;
        } else {
            this._currentExperinceDate = this._dayAndMonth + year;
        }
    }

    private checkExperience() {
        if (OsagoValidators.checkExperience(this._currentBirthDate, this._currentExperinceDate)) {
            this.experienceChanged.emit(new DriverExperience(this._currentBirthDate, this._currentExperinceDate));
        }
    }

    private initDadata() {
        // this.$fio.suggestions({
        //     token: environment.dadataKey,
        //     type: 'NAME',
        //     count: 6,
        //     mobileWidth: 0,
        //     addon: 'none',
        //     triggerSelectOnEnter: true,
        //     enrichmentEnabled: false,
        //     onSelect: (suggestion: any) => {
        //         this.onDadataFIO(suggestion.data);
        //     }
        // });
        this.$firstName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['NAME']
            },
            onSelect: (suggestion: any) => {
                this.onDadataFirstName(suggestion.data);
            }
        });
        this.$lastName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['SURNAME']
            },
            onSelect: (suggestion: any) => {
                this.onDadataLastName(suggestion.data);
            }
        });
        this.$middleName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['PATRONYMIC']
            },
            onSelect: (suggestion: any) => {
                this.onDadataMiddleName(suggestion.data);
            }
        });

        this.$oldfio.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                // каждому полю --- соответствующая подсказка
                parts: ['SURNAME']
              },
            onSelect: (suggestion: any) => {
                this.onOldDadataFIO(suggestion.data);
            }
        });
    }

    private onDadataFIO(data: any) {
        const valid = data.surname != null;
        this.fioDadata?.setValue(valid ? data : null);
        this.fioDadata?.markAsDirty();

        if (valid) {
            this.formsService.setFieldValue(this.fio, this.$fio.val());
        }
    }

    private onDadataFirstName(data: any): void {
        const valid = data.name != null;
        this.firstNameDadata?.setValue(valid ? data : null);
        this.firstNameDadata?.markAsDirty();

        if (valid) {
            this.formsService.setFieldValue(this.firstName, this.$firstName.val());
        }
    }

    private onDadataLastName(data: any): void {
        const valid = data.surname != null;
        this.lastNameDadata?.setValue(valid ? data : null);
        this.lastNameDadata?.markAsDirty();

        if (valid) {
            this.formsService.setFieldValue(this.lastName, this.$lastName.val());
        }
    }

    private onDadataMiddleName(data: any): void {
        const valid = data.patronymic != null;
        this.fioDadata?.setValue(valid ? data : null);
        this.fioDadata?.markAsDirty();

        if (valid) {
            this.formsService.setFieldValue(this.middleName, this.$middleName.val());
        }
    }

    private onOldDadataFIO(data: any) {
        const valid = data.surname != null;
        this.oldfioDadata?.setValue(valid ? data : null);
        this.oldfioDadata?.markAsDirty();

        if (valid) {
            this.formsService.setFieldValue(this.oldfio, this.$oldfio.val());
        }
    }

    // Проверка валидации ФИО через дадата
    private restoreDadata(): void {
        // // fio
        // if (this.fio?.value != null && this.fio?.value.length > 0) {
        //     const fixInterval = window.setInterval(() => {
        //         if (this.$fio.suggestions &&
        //             this.$fio.suggestions().fixData) {
        //             window.clearInterval(fixInterval);
        //             this.$fio.on('suggestions-fixdata', (e: any, suggestion: any) => {
        //                 if (suggestion) {
        //                     this.onDadataFIO(suggestion.data);
        //                 }
        //
        //             });
        //
        //             this.$fio.suggestions().fixData();
        //         }
        //     }, 100);
        // }
        // firstName
        if (this.firstName?.value != null && this.firstName?.value.length > 0) {
            const fixInterval = window.setInterval(() => {
                if (this.$firstName.suggestions &&
                    this.$firstName.suggestions().fixData) {
                    window.clearInterval(fixInterval);
                    this.$firstName.on('suggestions-fixdata', (e: any, suggestion: any) => {
                        if (suggestion) {
                            this.onDadataFirstName(suggestion.data);
                        }

                    });

                    this.$firstName.suggestions().fixData();
                }
            }, 100);
        }
        // lastName
        if (this.lastName?.value != null && this.lastName?.value.length > 0) {
            const fixInterval = window.setInterval(() => {
                if (this.$lastName.suggestions &&
                    this.$lastName.suggestions().fixData) {
                    window.clearInterval(fixInterval);
                    this.$lastName.on('suggestions-fixdata', (e: any, suggestion: any) => {
                        if (suggestion) {
                            this.onDadataLastName(suggestion.data);
                        }

                    });

                    this.$lastName.suggestions().fixData();
                }
            }, 100);
        }
        // middleName
        if (this.middleName?.value != null && this.middleName?.value.length > 0) {
            const fixInterval = window.setInterval(() => {
                if (this.$middleName.suggestions &&
                    this.$middleName.suggestions().fixData) {
                    window.clearInterval(fixInterval);
                    this.$middleName.on('suggestions-fixdata', (e: any, suggestion: any) => {
                        if (suggestion) {
                            this.onDadataMiddleName(suggestion.data);
                        }

                    });

                    this.$middleName.suggestions().fixData();
                }
            }, 100);
        }

        // old fio
        if (this.oldfio?.value != null && this.oldfio?.value.length > 0) {
            const interval = window.setInterval(() => {
                if (this.$oldfio.suggestions &&
                    this.$oldfio.suggestions().fixData) {
                    window.clearInterval(interval);
                    this.$oldfio.on('suggestions-fixdata', (e: any, suggestion: any) => {
                        if (suggestion) {
                            this.onOldDadataFIO(suggestion.data);
                        }
                    });

                    this.$oldfio.suggestions().fixData();
                }
            }, 100);
        }
    }

}
