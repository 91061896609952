import { Injectable } from "@angular/core";
import { ApplicationService } from '../../../services/application.service';

@Injectable()
export class ScriptService {
    private loaded = false;
    protected _ready = false;
    private scripts: string[] = [
        "/assets/scripts/jquery.suggestions.20.3.0.min.js",
        "/assets/scripts/jquery-ui.min.js",
        "/assets/scripts/datepicker-ru.js"
    ];

    constructor(
        private appService: ApplicationService) { }

    public get ready() {
        return this._ready;
    }

    public load() {
        if (this.loaded)
            return;

        this.loaded = true;
        this.loadScript(this.scripts[0])
            .then(
                () => this.loadScript(this.scripts[1])
                .then(
                    () => this.loadScript(this.scripts[2])
                        .then(() => this._ready = true)
                        .catch(() => this.appService.error("Failed to load script " + this.scripts[2])))
                    .catch(() => this.appService.error("Failed to load script " + this.scripts[1])))
            .catch(() => this.appService.error("Failed to load script " + this.scripts[0]))
    }

    private loadScript(src: string) {
        return new Promise<void>((resolve, reject) => {
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;
            script.onload = () => resolve();
            script.onerror = (error: any) => reject(error);
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }
}