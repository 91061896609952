<div class="widget__driver-header">
	<div class="widget__driver-title">
        {{ description }}
        <div class="widget__driver-clear"
            (click)="onClear($event)">
            Очистить
        </div>
<!--        <div class="widget__header-info">-->
<!--            <div class="spinner-circle" *ngIf="isLoadingKbm"></div>-->
<!--            <div class="widget__header-info-item" *ngIf="!isLoadingKbm">-->
<!--                <p>КБМ: </p><span>{{kbmDescription}}</span>-->
<!--            </div>-->
<!--            <div class="widget__icon" (click)="togglePopup()">-->
<!--                <img src="/assets/images/icons/info.svg" alt="">-->
<!--            </div>-->
<!--        </div>-->
    </div>
	<div class="widget__header-info-inner">
		<div
			class="widget__driver-clear"
			*ngIf="canDelete == true"
			(click)="onDelete($event)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7208 0.279164C11.542 0.100415 11.2996 0 11.0467 0C10.7939 0 10.5514 0.100415 10.3726 0.279164L6 4.65179L1.62737 0.279164C1.44857 0.100415 1.2061 0 0.953269 0C0.700442 0 0.457967 0.100415 0.279164 0.279164C0.100415 0.457967 0 0.700442 0 0.953269C0 1.2061 0.100415 1.44857 0.279164 1.62737L4.65179 6L0.279164 10.3726C0.100415 10.5514 0 10.7939 0 11.0467C0 11.2996 0.100415 11.542 0.279164 11.7208C0.457967 11.8996 0.700442 12 0.953269 12C1.2061 12 1.44857 11.8996 1.62737 11.7208L6 7.34821L10.3726 11.7208C10.5514 11.8996 10.7939 12 11.0467 12C11.2996 12 11.542 11.8996 11.7208 11.7208C11.8996 11.542 12 11.2996 12 11.0467C12 10.7939 11.8996 10.5514 11.7208 10.3726L7.34821 6L11.7208 1.62737C11.8996 1.44857 12 1.2061 12 0.953269C12 0.700442 11.8996 0.457967 11.7208 0.279164V0.279164Z" fill="black"/>
            </svg>
        </div>
	</div>
</div>

<div class="widget__driver-body">
    <div class="widget-input-inner widget-input-lastName">
<!--        <app-input-->
<!--            [fieldControl]="fio"-->
<!--            [fieldId]="'fio-' + id"-->
<!--            [modifier]="'widget-input-full'"-->
<!--            fieldName="Фамилия"-->
<!--            helperText="Фамилия водителя как указано в правах"-->
<!--            (input)="onFIOChanged($event)">-->
<!--        </app-input>-->

        <app-input
            [fieldControl]="lastName"
            [fieldId]="'lastName-' + id"
            [modifier]="'widget-input-full'"
            fieldName="Фамилия"
            helperText="Фамилия водителя как указано в правах"
            (input)="onLastNameChanged($event)">
        </app-input>
    </div>
    <div class="widget-input-inner widget-input-firstName">
        <app-input
            [fieldControl]="firstName"
            [fieldId]="'firstName-' + id"
            [modifier]="'widget-input-full'"
            fieldName="Имя"
            helperText="Имя водителя как указано в правах"
            (input)="onFirstNameChanged($event)">
        </app-input>
    </div>
    <div class="widget-input-inner widget-input-middleName">
        <app-input
            [fieldControl]="middleName"
            [fieldId]="'middleName-' + id"
            [modifier]="'widget-input-full'"
            fieldName="Отчество"
            helperText="Отчество водителя как указано в правах"
            (input)="onMiddleNameChanged($event)">
        </app-input>
    </div>

    <div class="widget-input-inner widget-input-birthday">
        <app-input-date
            [fieldControl]="birthDate"
            [fieldId]="'birthDate-' + id"
            fieldType="tel"
            fieldName="Дата рождения"
            [checkFill]="true"
            [changeYear]="true"
            [showIcon]="false"
            (changed)="onBirthdateChanged($event)"
            helperText="Укажите дату рождения водителя"
            #birthdate>
        </app-input-date>
    </div>

    <div class="widget-input-inner widget-input-license">
        <app-input
            [fieldControl]="license"
            [fieldId]="'license-' + id"
            fieldName="Серия и номер текущих прав"
            [textMask] ="{ mask: masks.license, guide: true, pipe: licensePipe }"
            helperText="Укажите данные прав">
            <div class="widget__icon"
                 [class.hovered]="licenseSeriesPopupOpen"
                 (click)="licenseSeriesPopupOpen = true"
                 #licenseseriespopup>
                <img class="info-icon" src="./assets/images/icons/info.svg" alt="">
                <div class="widget__helper widget__helper-right"><img src="/assets/images/SeriesAndNumber.png"/></div>
            </div>
        </app-input>
    </div>

    <div class="widget-input-inner widget-input-license-date">
        <!--		<app-input-date-->
        <!--			[isHidden]="(rememberOnlyYear==true)"-->
        <!--			[fieldControl]="experienceStartDate"-->
        <!--			[fieldId]="'experienceStartDate-' + id"-->
        <!--			fieldType="tel"-->
        <!--			fieldName="Дата выдачи первых прав"-->
        <!--			[textMask] ="{ mask: masks.fullDate, guide: true }"-->
        <!--			[checkFill]="true"-->
        <!--			[changeYear]="true"-->
        <!--			(changed)="onExperienceChanged($event)"-->
        <!--			helperText="Укажите дату начала стажа"-->
        <!--			#experiencedate>-->
        <!--			<div class="widget__icon"-->
        <!--				[class.hovered]="licenseDateStartPopupOpen"-->
        <!--				(click)="licenseDateStartPopupOpen = true"-->
        <!--				#licensedatestartpopup><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>-->
        <!--				<div class="widget__helper widget__helper-right"><img src="/assets/images/dateStartStaj.png"/></div>-->
        <!--			</div>-->
        <!--		</app-input-date>-->

        <app-input-date
            [isHidden]="(rememberOnlyYear==true)"
            [fieldControl]="licenseDate"
            [fieldId]="'licenseDate-' + id"
            fieldType="tel"
            fieldName="Дата начала стажа"
            [textMask] ="{ mask: masks.fullDate, guide: true }"
            [checkFill]="true"
            [changeYear]="true"
            [showIcon]="false"
            (changed)="onLicenseDateChanged($event)"
            helperText="Укажите дату начала стажа"
            #licensedate>
            <div class="widget__icon"
                 [class.hovered]="licenseDatePopupOpen"
                 (click)="licenseDatePopupOpen = true"
                 #licensedatepopup>
                <img class="info-icon" src="./assets/images/icons/info.svg" alt="">
                <div class="widget__helper widget__helper-right"><img src="/assets/images/dateStartVU.png"/></div>
            </div>
        </app-input-date>
        <div *ngIf="!rememberOnlyYear" class="description-control">Совпадает с датой выдачи первых прав</div>

        <app-input
            [isHidden]="(rememberOnlyYear == false)"
            [fieldControl]="experienceStartYear"
            [fieldId]="'experienceStartYear-' + id"
            fieldName="Год начала стажа"
            [textMask] ="{ mask: masks.year, guide: true }"
            (changed)="onExperienceYearChanged($event)"
            helperText="Укажите год начала стажа">
        </app-input>
    </div>

	<div class="widget-input-inner remember-only-year-block">
		<app-checkbox
			fieldId="rememberOnlyYear"
			fieldName="Помню только год"
			[fieldControl]="rememberOnlyYearCheckbox"
		></app-checkbox>
	</div>

    <div class="widget-input-inner widget-input-license-changed">
        <app-checkbox
            [fieldId]="'license-changed-' + id"
            [fieldControl]="licenseWasChanged"
            fieldName="Права менялись в течение года"
            (changeCheckbox)="onLicenseChanged($event)">
        </app-checkbox>
    </div>

    <div class="widget-input-inner widget__driver-footer-inner" [style.display]="licenseChanged == false ? 'none' : null">
        <app-input
            [fieldControl]="oldLicense"
            [fieldId]="'oldlicense-' + id"
            fieldType="text"
            fieldName="Серия и номер старых ВУ"
            [textMask] ="{ mask: masks.license, guide: true }"
            helperText="Укажите данные старых ВУ">
        </app-input>
    </div>
    <div class="widget-input-inner widget__driver-footer-inner" [style.display]="licenseChanged == false ? 'none' : null">
        <app-input
            [fieldControl]="oldfio"
            [fieldId]="'oldfio-' + id"
            fieldName="Фамилия в старых правах"
            helperText="Фамилия в старых правах"
            (input)="onOldFIOChanged($event)">
        </app-input>
    </div>

</div>

<!--<div class="widget__header-info widget__header-info-mobile">-->
<!--    <div class="widget__header-info-item">-->
<!--        <p>КБМ: </p><span>{{ kbmDescription }}</span>-->
<!--    </div>-->
<!--    <div class="widget__icon" (click)="togglePopup()">-->
<!--        <img src="/assets/images/icons/info.svg" alt="">-->
<!--    </div>-->
<!--</div>-->

<!--<ng-container *ngIf=isPopupOpen>-->
<!--	<div class="popupBg popupBg-active"></div>-->
<!--	<div class="popupCoefficients popupCoefficients-active">-->
<!--		<div class="popupCoefficients__close" (click)="togglePopup()">-->
<!--            <img src="/assets/images/icons/close.svg" alt="">-->
<!--		</div>-->
<!--		<h3>Коэффициенты {{ driverFio }}</h3>-->
<!--		<ul>-->
<!--			<li>-->
<!--				<div class="popupCoefficients__header">-->
<!--					<p>Бонус-малус (КБМ)</p>-->
<!--					<span>{{kbmDescription ===  '1' ? '1 / нет скидки' : kbmDescription }}</span>-->
<!--				</div>-->
<!--				<div class="popupCoefficients__body">-->
<!--					<p>КБМ — это скидка за аккуратное вождение или надбавка за виновность в ДТП. Ваш текущий КБМ указан на последнем полисе. Если вы не были виновником ДТП в прошлом году, цена полиса уменьшится, если были — увеличится. КБМ обновляется 1 апреля каждого года. <strong>Если к управлению допущено более-->
<!--                        одного водителя, то используется наименьшее значение КБМ.</strong></p>-->
<!--				</div>-->
<!--			</li>-->
<!--		</ul>-->
<!--	</div>-->
<!--</ng-container>-->
