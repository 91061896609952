import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import * as moment from 'moment-mini';
import { TextMaskHelper } from './textmaskHelper';

export class OsagoValidators {
    static cyrillicRegex = /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/;

    private static isNullOrEmpty(value: string): boolean {
        return value == null || value.length == 0;
    }

    static address(dadataFieldName: string, ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите адрес регистрации как в паспорте' };
            }

            const dadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;

            if (dadata == null) {
                return { message: 'Укажите адрес регистрации как в паспорте' };
            }

            if (!(dadata.fias_level >= 8 || dadata.fias_level == 9 || (dadata.fias_level == 7 && dadata.house != null))) {
                return { message: 'Укажите адрес до дома' };
            }

            return null;
        };
    }

    static chassisNumber(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите номер шасси' };
            }

            return null;
        };
    }

    static birthDate(): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату рождения водителя' };
            }

            const value = TextMaskHelper.getDate(fc.value);
            if (value == null) {
                return { message: 'Укажите корректную дату рождения' };
            }

            const date = moment(value, 'DD.MM.YYYY', true);
            if (!date.isValid() || date.isAfter()) {
                return { message: 'Укажите корректную дату рождения' };
            }

            if (moment(value, 'DD.MM.YYYY', true).add(16, 'years').isAfter()) {
                return { message: 'Водителю должно быть больше 16 лет' };
            }

            if (!moment(value, 'DD.MM.YYYY', true).add(100, 'years').isAfter()) {
                return { message: 'Водителю не должно быть больше 100 лет' };
            }

            return null;
        };
    }

    static bodyNumber(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите номер кузова' };
            }

            return null;
        };
    }

    static brand(): ValidatorFn {
        return (fc: AbstractControl) => this.brandErrors(fc);
    }

    // Ошибка 'Марка'
    static brandErrors(fc: AbstractControl): any {
        if (this.isNullOrEmpty(fc.value)) {
            return { message: 'Укажите марку автомобиля' };
        }
        return null;
    }

    static checkExperience(birthDateValue: string, experinceDateValue: string): boolean {
        if (this.isNullOrEmpty(birthDateValue) || this.isNullOrEmpty(experinceDateValue)) {
            return false;
        }

        const birthDate = TextMaskHelper.getDate(birthDateValue);
        if (birthDate == null) {
            return false;
        }

        const birthDateDate = moment(birthDate, 'DD.MM.YYYY', true);
        if (!birthDateDate.isValid() || birthDateDate.isAfter() || moment().subtract(16, 'years').isBefore(birthDateDate)) {
            return false;
        }

        const experienceDate = TextMaskHelper.getDate(experinceDateValue);
        if (experienceDate == null) {
            return false;
        }

        const experienceDateDate = moment(experienceDate, 'DD.MM.YYYY', true);
        if (!experienceDateDate.isValid() || experienceDateDate.isAfter() || birthDateDate.add(16, 'years').isAfter(experienceDateDate)) {
            return false;
        }

        return true;
    }

    static city(dadataFieldName: string): ValidatorFn {
        return (fc: AbstractControl) => this.cityErrors(fc, dadataFieldName);
    }

    static cityErrors(fc: AbstractControl, dadataFieldName: string) {
        if (this.isNullOrEmpty(fc.value)) {
            return { message: 'Выберите город из выпадающего списка' };
        }

        const fioDadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;
        if (fioDadata == null) {
            return { message: 'Выберите город из выпадающего списка' };
        }

        return null;
    }

    static dcEndDate(): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату окончания действия ДК' };
            }

            const value = TextMaskHelper.getDate(fc.value);
            if (value == null) {
                return { message: 'Укажите корректную дату окончания ДК' };
            }

            const date = moment(value, 'DD.MM.YYYY', true);
            if (!date.isValid() || date.add(1, 'day').isBefore()) {
                return { message: 'Укажите корректную дату окончания ДК' };
            }

            return null;
        };
    }

    static dcNumber(): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите номер ДК' };
            }

            const value = TextMaskHelper.unmask(fc.value, 15);
            if (value == null || value.length != 15) {
                return { message: 'Укажите номер ДК' };
            }

            return null;
        };
    }

    static epts(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите данные еПТС' };
            }

            const value = TextMaskHelper.unmask(fc.value, 10);
            if (value == null || value.length != 10) {
                return { message: 'Укажите данные еПТС' };
            }

            return null;
        };
    }

    static eptsDate(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату выдачи еПТС' };
            }

            const date = moment(fc.value, 'YYYY-MM-DD', true);
            if (!date.isValid()) {
                return { message: 'Укажите корректную дату' };
            }

            if (date.isAfter()) {
                return { message: 'Документ не может быть выдан в будущем' };
            }

            return null;
        };
    }

    static experienceStartDate(ignore: Function = () => false, birthDateFieldName = 'birthDate'): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату начала стажа' };
            }

            const value = TextMaskHelper.getDate(fc.value);
            if (value == null) {
                return { message: 'Укажите корректную дату' };
            }

            const date = moment(value, 'DD.MM.YYYY', true);
            if (!date.isValid() || date.isAfter()) {
                return { message: 'Укажите корректную дату' };
            }

            const birthDate = (fc.parent as FormGroup)?.get(birthDateFieldName)!;
            if (!birthDate.valid) {
                return null;
            }

            const birthDateValue = TextMaskHelper.getDate(birthDate.value);
            if (moment(birthDateValue!, 'DD.MM.YYYY', true).add(16, 'year').isAfter(date)) {
                return { message: 'Стаж начался в возрасте до 16 лет' };
            }

            return null;
        };
    }

    static experienceStartYear(ignore: Function = () => false, birthDateFieldName = 'birthDate'): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите год начала стажа' };
            }

            const value = TextMaskHelper.getYear(fc.value);
            if (value == null || moment().year() < parseInt(value)) {
                return { message: 'Укажите корректный год' };
            }

            const date = moment('31.12.' + value, 'DD.MM.YYYY', true);
            if (!date.isValid()) {
                return { message: 'Укажите корректный год' };
            }

            const birthDate = (fc.parent as FormGroup)?.get(birthDateFieldName)!;
            if (!birthDate.valid) {
                return null;
            }

            const birthDateValue = TextMaskHelper.getDate(birthDate.value);
            if (moment(birthDateValue!, 'DD.MM.YYYY', true).add(16, 'year').isAfter(date)) {
                return { message: 'Стаж начался в возрасте до 16 лет' };
            }

            return null;
        };
    }

    static required(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() === true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Введите свой Email' };
            }

            return null;
        };
    }

    static firstNameCyrillic(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() === true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Выберите имя из выпадающего списка' };
            }

            if (!fc.value.match(this.cyrillicRegex)) {
                return { message: 'Имя должно содержать только кириллицу' };
            }

            return null;
        };
    }

    static lastNameCyrillic(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() === true) {
                return null;
            }

            if (!fc.value.match(this.cyrillicRegex)) {
                return { message: 'Фамилия должна содержать только кириллицу' };
            }

            return null;
        };
    }

    static fio(dadataFieldName: string, ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() === true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Выберите ФИО из выпадающего списка' };
            }

            const fioDadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;
            if (fioDadata == null || fioDadata.name == null || fioDadata.surname == null) {
                return { message: 'Выберите ФИО из выпадающего списка' };
            }

            return null;
        };
    }

    static firstName(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() === true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Выберите имя из выпадающего списка' };
            }

            if (!TextMaskHelper.isCyrillic(fc.value)) {
                return { message: 'Введите имя на русском языке' };
            }

            // const fioDadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;
            // if (fioDadata == null || fioDadata.name == null) {
            //     return { message: 'Выберите имя из выпадающего списка' };
            // }

            return null;
        };
    }

    static lastName(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() === true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Выберите фамилию из выпадающего списка' };
            }

            if (!TextMaskHelper.isCyrillic(fc.value)) {
                return { message: 'Введите имя на русском языке' };
            }

            // const fioDadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;
            // if (fioDadata == null || fioDadata.surname == null) {
            //     return { message: 'Выберите фамилию из выпадающего списка' };
            // }

            return null;
        };
    }

    static middleName(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() === true || !fc.value) {
                return null;
            }

            // if (this.isNullOrEmpty(fc.value)) {
            //     return { message: 'Выберите фамилию из выпадающего списка' };
            // }

            if (!TextMaskHelper.isCyrillic(fc.value)) {
                return { message: 'Введите имя на русском языке' };
            }

            // const fioDadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;
            // if (fioDadata == null || fioDadata.surname == null) {
            //     return { message: 'Выберите фамилию из выпадающего списка' };
            // }

            return null;
        };
    }

    static horsePower(): ValidatorFn {
        return (fc: AbstractControl) => this.horsePowerErrors(fc);
    }

    static horsePowerErrors(fc: AbstractControl) {
        if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите мощность Вашего автомобиля в л.с.' };
        }

        let value = parseFloat(fc.value?.replace(' л.с.', ''));

        if (isNaN(value)) {
                return { message: 'Укажите мощность Вашего автомобиля в л.с.' };
        }

        if (value < 30) {
                return { message: 'Не менее 30 л.с.' };
        }

        return null;
    }

    // Валидация номера ВУ
    static license(): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите данные прав' };
            }

            const value = TextMaskHelper.driverLicense(fc.value);
            if (value == null) {
                return { message: 'Укажите корректные данные прав' };
            }

            return null;
        };
    }

    static oldLicense(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите данные старых прав' };
            }

            const value = TextMaskHelper.driverLicense(fc.value);
            if (value == null) {
                return { message: 'Укажите корректные данные старых прав' };
            }

            return null;
        };
    }

    static licenseDate(experienceValue: Function = () => '', birthDateFieldName = 'birthDate'): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату начала стажа' };
            }

            const value = TextMaskHelper.getDate(fc.value);
            if (value == null) {
                return { message: 'Укажите корректную дату' };
            }

            const date = moment(value, 'DD.MM.YYYY', true);
            if (!date.isValid() || date.isAfter()) {
                return { message: 'Укажите корректную дату' };
            }

            const birthDate = (fc.parent as FormGroup)?.get(birthDateFieldName)!;
            if (birthDate.valid) {
                const birthDateValue = TextMaskHelper.getDate(birthDate.value);
                if (moment(birthDateValue!, 'DD.MM.YYYY', true).add(16, 'year').isAfter(date)) {
                    return { message: 'Стаж начался в возрасте до 16 лет' };
                }
            }

            return null;
        };
    }

    static model(): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите модель автомобиля' };
            }

            return null;
        };
    }

    static modification(): ValidatorFn {
        return (fc: AbstractControl) => this.modelErrors(fc);
    }

    // Ошибка 'Модель автомобиля'
    static modelErrors(fc: AbstractControl) {
        if (this.isNullOrEmpty(fc.value)) {
            return { message: 'Укажите модификацию автомобиля' };
        }

        return null;
    }

    // Валидация ФИО
    static oldfio(ignore: Function = () => false, dadataFieldName: string): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() === true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Выберите фамилию из выпадающего списка' };
            }

            if (!TextMaskHelper.isCyrillic(fc.value)) {
                return { message: 'Введите фамилию на русском языке' };
            }

            // const fioDadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;
            // if (fioDadata == null) {
            //     return { message: 'Выберите фамилию из выпадающего списка' };
            // }

            return null;
        };
    }

    static ownerBirthDate(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату рождения собственника' };
            }

            const value = TextMaskHelper.getDate(fc.value);
            if (value == null) {
                return { message: 'Укажите корректную дату рождения' };
            }

            const date = moment(value, 'DD.MM.YYYY', true);
            if (!date.isValid() || date.isAfter()) {
                return { message: 'Укажите корректную дату рождения' };
            }

            if (moment(value, 'DD.MM.YYYY', true).add(18, 'year').isAfter()) {
                return { message: 'Собственнику должно быть больше 18 лет' };
            }

            if (!moment(value, 'DD.MM.YYYY', true).add(100, 'year').isAfter()) {
                return { message: 'Собственнику не должно быть больше 100 лет' };
            }

            return null;
        };
    }

    static passport(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите серию и номер паспорта' };
            }

            const value = TextMaskHelper.unmask(fc.value, 10);
            if (value == null || value.length != 10) {
                return { message: 'Укажите серию и номер паспорта' };
            }

            return null;
        };
    }

    static passportDate(birthdateFieldName: string, ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату выдачи паспорта' };
            }

            const value = TextMaskHelper.getDate(fc.value);
            if (value == null) {
                return { message: 'Укажите корректную дату выдачи паспорта' };
            }

            const date = moment(value, 'DD.MM.YYYY', true);
            if (!date.isValid() || date.isAfter()) {
                return { message: 'Укажите корректную дату выдачи паспорта' };
            }

            const birthDateField = (fc.parent as FormGroup)?.get(birthdateFieldName)!;
            if (!birthDateField.valid) {
                return null;
            }

            const birthDate = moment(TextMaskHelper.getDate(birthDateField!.value!)!, 'DD.MM.YYYY', true);

            // более 45 лет
            const age45 = moment(moment(TextMaskHelper.getDate(birthDateField!.value!)!, 'DD.MM.YYYY', true).add(45, 'years').toDate());
            if (age45.isBefore() && !date.isAfter(age45)) {
                return { message: 'Паспорт следует заменить при достижении 45 лет' };
            }

            // 20 - 45 лет
            const age20 = moment(moment(TextMaskHelper.getDate(birthDateField!.value!)!, 'DD.MM.YYYY', true).add(19, 'years').add(364, 'day').toDate());
            if (age45.isAfter() && age20.isBefore() && !date.isAfter(age20)) {
                return { message: 'Паспорт следует заменить при достижении 20 лет' };
            }

            // 18 - 20 лет
            const age14 = moment(moment(TextMaskHelper.getDate(birthDateField!.value!)!, 'DD.MM.YYYY', true).add(14, 'years').toDate());
            const age18 = moment(moment(TextMaskHelper.getDate(birthDateField!.value!)!, 'DD.MM.YYYY', true).add(18, 'years').toDate());
            if (age20.isAfter() && age18.isBefore() && !date.isAfter(age14)) {
                return { message: 'Укажите корректную дату выдачи паспорта' };
            }

            return null;
        };
    }

    static passportIssuer(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите кем выдан паспорт' };
            }

            return null;
        };
    }

    static passportIssuerCode(dadataFieldName: string, ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите код подразделения' };
            }

            const fioDadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;
            if (fioDadata == null) {
                return { message: 'Укажите код подразделения' };
            }

            return null;
        };
    }

    static phone(): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите номер телефона' };
            }

            const value = TextMaskHelper.unmask(fc.value, 11);
            if (value == null || value.length != 11) {
                return { message: 'Укажите номер телефона' };
            }

            return null;
        };
    }

    // Валидация email
    static email(dadataFieldName: string): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) { return { message: 'Укажите адрес электронной почты' }; }

            const dadata = (fc.parent as FormGroup)?.get(dadataFieldName)?.value;
            if (dadata == null) { return { message: 'Укажите адрес электронной почты' }; }

            return null;
        };
    }

    static policyDate(): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату начала действия полиса' };
            }

            const date = moment(fc.value, 'YYYY-MM-DD', true);
            if (!date.isValid()) {
                return { message: 'Укажите корректную дату' };
            }

            const minDate = moment().add(3, 'day'); // actually 3 days - 1 day compensate for hours and minutes
            if (!date.isAfter(minDate)) {
                return { message: 'Минимальная дата начала полиса - ' + moment().add(4, 'day').format('DD.MM.YYYY') };
            }

            return null;
        };
    }

    static previousPolicySeries(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите серию полиса' };
            }

            return null;
        };
    }

    static previousPolicyNumber(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите номер полиса' };
            }

            const value = TextMaskHelper.unmask(fc.value, 10);
            if (value == null || value.length != 10) {
                return { message: 'Укажите номер полиса' };
            }

            return null;
        };
    }

    static productionYear(): ValidatorFn {
        return (fc: AbstractControl) => this.productionYearErrors(fc);
    }

    static productionYearErrors(fc: AbstractControl) {
        if (this.isNullOrEmpty(fc.value)) {
            return { message: 'Укажите год выпуска вашего автомобиля' };
        }

        let value = parseInt(fc.value);
        const year = (new Date()).getFullYear();
        if (isNaN(value) || value < 1970) {
            return { message: 'ОСАГО можно приобрести для автомобилей не старше 1970 года выпуска' };
        }

        if (value > year) {
            return { message: 'Укажите корректный год выпуска' };
        }

        return null;
    }

    static pts(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите данные ПТС' };
            }

            const value = TextMaskHelper.unmask(fc.value, 10);
            if (value == null || value.length != 10) {
                return { message: 'Укажите данные ПТС' };
            }

            return null;
        };
    }

    static ptsDate(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату выдачи ПТС' };
            }

            const date = moment(fc.value, 'YYYY-MM-DD', true);
            if (!date.isValid()) {
                return { message: 'Укажите корректную дату' };
            }

            // const productionYear = (fc.parent as FormGroup)?.get('productionYear')?.value;
            //
            // if (date.isBefore(moment('01.01.' + productionYear?.replace(' год', ''), 'DD.MM.YYYY'))) {
            //     return { message: 'Документ не может быть выдан до выпуска авто' };
            // }

            if (date.isAfter()) {
                return { message: 'Документ не может быть выдан в будущем' };
            }

            return null;
        };
    }

    static sts(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите данные СТС' };
            }

            const value = TextMaskHelper.unmask(fc.value, 10);
            if (value == null || value.length != 10) {
                return { message: 'Укажите данные СТС' };
            }

            return null;
        };
    }

    static stsDate(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите дату выдачи СТС' };
            }

            const date = moment(fc.value, 'YYYY-MM-DD', true);
            if (!date.isValid()) {
                return { message: 'Укажите корректную дату' };
            }

            const productionYear = (fc.parent as FormGroup)?.get('productionYear')?.value;

            if (date.isBefore(moment('01.01.' + productionYear?.toString().replace(' год', ''), 'DD.MM.YYYY'))) {
                return { message: 'Документ не может быть выдан до выпуска авто' };
            }

            if (date.isAfter()) {
                return { message: 'Документ не может быть выдан в будущем' };
            }

            return null;
        };
    }

    static term(): ValidatorFn {
        return (fc: AbstractControl) => {
            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите срок действия полиса' };
            }

            return null;
        };
    }

    static vinNumber(ignore: Function = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore() == true) {
                return null;
            }

            if (this.isNullOrEmpty(fc.value)) {
                return { message: 'Укажите VIN' };
            }

            const value = TextMaskHelper.unmask(fc.value, 17);
            if (value == null || value.length != 17) {
                return { message: 'Укажите VIN' };
            }

            return null;
        };
    }
}
