import { AfterViewInit, Component } from '@angular/core';
import { ApplicationService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientService } from '../../services/client.service';
import { LoggingService } from '../../services/loggingService';

@Component({
    templateUrl: './empty.component.html'
})

export class EmptyComponent implements AfterViewInit {
    constructor(
        private readonly appService: ApplicationService,
        private readonly loggingService: LoggingService,
        private readonly clientService: ClientService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.loggingService.trace('EmptyComponent', 'ctor');
    }

    // Подписка
    private subscription = new Subscription();

    // --------------------------------------------------------------------------
    // Инициализация
    public ngAfterViewInit(): void {
        this.loggingService.trace('EmptyComponent', 'ngAfterViewInit');
        this.initRouteParams();
        this.initCacheParams();
        this.appService.showStartScreen();
    }

    // --------------------------------------------------------------------------

    // Проверка параметров урла
    private initRouteParams(): void {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        var offerId = urlParams?.get('offerId') || undefined;

        if (offerId !== undefined) {
            this.appService._initialData.offerId = offerId;
            this.appService._initialData.applicationId = urlParams.get('applicationId') || undefined;
        }

        this.appService._initialData.clientId = urlParams.get('clientId') || undefined;

        if (!this.appService.isSuccess && !this.appService.isFail)
        {
            const isSuccess = urlParams?.get('isSuccess');

            switch (isSuccess) {
                case 'true':
                    this.appService._initialData.isSuccess = true;
                    break;
                case 'false':
                    this.appService._initialData.isSuccess = false;
                    break;
                default:
                    break;
            }
        }
        this.appService._returnSrc = urlParams.get('src') ? urlParams.get('src') : null;
        this.appService._code = urlParams.get('code') ? urlParams.get('code') : null;
    }

    // Ждем пока придут данные из кэша
    public initCacheParams(): void {
        // this.subscription.add(
        //     this.clientService.osagoApplications$.subscribe((osagoApplications) => {
        //         console.log(this.route.params);
        //         const currentUrl = this.router.url;
        //         const license = localStorage.getItem('license');
        //         if (license && license !== 'undefined') {
        //             console.log(currentUrl);
        //             this.appService.onLicenseScreenCompleteFromUrl(license);
        //         }
        //     })
        // );
    }
}
