<button
    [class]="modifierClassString"
    [type]="buttonType"
    [attr.action]="action ? action : null"
    [attr.disabled]="isDisabled ? 'disabled' : null"
    (click)="onClick($event)"
    [attr.tabindex]="index != null ? index : null"
>
    <ng-content></ng-content>
</button>
