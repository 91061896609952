import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonComponent } from './components/button/button.component';
import { DateDirective, LicenseDirective, MoneyDirective, NumberDirective } from './directives';
import { NavigationGuard } from './guards/navigation.guard';
import { CurrencyRuPipe, LicenseNumberPipe } from './pipes';
import { LicensePlateService } from './services/licensePlate.service';
import { AlertComponent } from './components/alert/alert.component';
import { ModalComponent } from './components/modal/modal.component';

@NgModule({
    declarations: [
        ButtonComponent,
        DateDirective,
        LicenseDirective,
        MoneyDirective,
        NumberDirective,
        CurrencyRuPipe,
        LicenseNumberPipe,
        AlertComponent,
        ModalComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ButtonComponent,
        DateDirective,
        LicenseDirective,
        MoneyDirective,
        NumberDirective,
        CurrencyRuPipe,
        LicenseNumberPipe,
        CommonModule,
        AlertComponent,
        ModalComponent,
    ],
    providers: [
        LicensePlateService,
        NavigationGuard
    ]
})
export class SharedModule { }
