import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import { delay, filter, map, repeat, switchMap, take, tap } from 'rxjs/operators';
import { HttpService } from '../../../services/http.service';
import {
    ApplicationRequest, ApplicationResponse,
    BaseApplicationRequest,
    DaDataAddress,
    DictionaryItem,
    GetModelsByBrandIdRequest,
    GetModelsByBrandIdResponse,
    InitialData
} from '../../../../../../common-lib/src/lib/models';
import { ApiError, ApiResponse, GetOffersResponse, Offer, OsagoOwnerViewModel, PreviousPolicy, SetWidgetStatusRequest } from '../models';
import { ApplicationService } from '../../../services';
import { ModificationsGenerationRequest } from '../models/modificationsGenerationRequest.model';
import { RequestByIdResponse } from '../../startup/models/requestByIdResponse.model';
import { RequestByIdRequest } from '../../startup/models/requestByIdRequest.model';
import { ModificationListResponse } from '../models/modificationListResponse.model';
import { Driver } from '../models/driver.model';
import { LicenseVerificationStartRequest } from '../../startup/models';
import { DiagnosticCardResponse } from '../../startup/models/diagnosticCardResponse.model';
import { OsagoApplication } from '../models/osagoApplication.model';
import { Modification } from '../models/modification.model';
import { OsagoCarData } from '../models/osagoCarData.model';
import * as moment from 'moment-mini';
import { OsagoDriver } from '../models/osagoDriver.model';
import { OsagoPolicy } from '../models/osagoPolicy.model';
import { OsagoCarDocument } from '../models/osagoCarDocument.model';
import { OsagoIdentifier } from '../models/osagoIdentifier.model';
import { OsagoOwner } from '../models/osagoOwner.model';
import { Converter } from '../../../../../../common-lib/src/lib/utils/converters';
import { OsagoPaymentLinkResponse } from '../models/osagoPaymentLinkResponse.model';
import { OsagoKbm } from '../models/osagoKbm.model';
import { KbmReportResponse } from '../models/kbmReportResponse.model';
import { PriceCalculatorService } from './priceCalculator.service';
import { OsagoReportResponse } from '../../startup/models/osagoReportResponse.model';
import { ModificationData } from '../models/modificationData.model';
import { CalendarHelper, FioHelper } from '../helpers';
import { ClientService } from '../../../services/client.service';
import { IDriversForOffersList } from '../../shared/interfaces/drivers-for-offers-list';
import { routes } from '../../shared/routes';
import { LoggingService } from '../../../services/loggingService';
import { WidgetStatuses } from '../../shared/widgetStatuses.enum';

@Injectable()
export class OsagoService extends HttpService implements OnDestroy {
    protected _initialData: InitialData = new InitialData('');
    protected _modelsCache = new Map<number, DictionaryItem[]>();
    protected _modificationsCache = new Map<string, ModificationData>();
    protected _currentModificationKey = '';

    private _apiKey = '';
    private _owner: Driver | null = null;
    public _selectedOffer: Offer | null = null;

    public _driverKbms: BehaviorSubject<OsagoKbm[]> = new BehaviorSubject<OsagoKbm[]>([]);
    public driverKbms$ = this._driverKbms.asObservable();

    protected _modifications: BehaviorSubject<Modification[] | null> = new BehaviorSubject<Modification[] | null>(null);
    public modifications$ = this._modifications.asObservable();

    protected _offers = new BehaviorSubject<Offer[] | null>(null);
    public offers$ = this._offers as Observable<Offer[] | null>;

    // Подписка на поулчение ссылки на оплату
    protected _paymentResult = new BehaviorSubject<boolean | null>(null);
    public paymentResult$ = this._paymentResult as Observable<boolean | null>;

    protected _carDescription = new BehaviorSubject<string | null>(null);
    public carDescription$ = this._carDescription as Observable<string | null>;

    protected _driverDescription = new BehaviorSubject<string>('');
    public driverDescription$ = this._driverDescription as Observable<string>;

    protected _drivers = new BehaviorSubject<DictionaryItem[]>([]);
    public drivers$ = this._drivers as Observable<DictionaryItem[]>;

    protected _preOffer = new BehaviorSubject<Offer | null>(null);
    public preoffer$ = this._preOffer as Observable<Offer | null>;

    public osagoToKbmDriverMap: {osagoDriverIndex: number, kbmDriverIndex: number}[] = [];
    public _isPaymentError = false;
    protected _paymentLink: string | null = null;
    protected _paymentStartTime: any = null;
    public _application: OsagoApplication;
    protected _ownerAddress: string | null = null;
    protected _ownerAddressDadata: DaDataAddress | null = null;
    public _applicationSent = false;
    public _applicationSentToInsurers = false;
    public _offersReceived = false;
    protected _term = '12 месяцев';
    protected _brandId: number | null = null;
    protected _selectedOwnerDriver: number | null = null;
    protected _selectedInsurerDriver: number | null = null;
    public _insurerPhone: string | null = null;
    public _insurerEmail: string | null = null;
    protected _modificationId: any = null;
    protected _preOfferInsurer: string | null = null;
    protected _offersStartTime: moment.Moment | null = null;
    protected _dcLicensePlate: string | null = null;
    protected _osagoOwners: OsagoOwner[] = [];
    protected _osagoInsurers: OsagoOwner[] = [];
    protected _osagoOwner: OsagoOwner | null = null;
    protected _osagoInsurer: OsagoOwner | null = null;
    protected _widgetStatusRequests: SetWidgetStatusRequest[] = [];

    // Таймаут для sendApplicationObservable
    public sendApplicationObservableTimeout: number | undefined;
    // Водители из offers
    public driversKvsKbm: IDriversForOffersList[] | undefined;

    // Склонения значения год
    public ageValuesList = ['год', 'года', 'лет'];
    // Индикатор загрузки КБМ
    public isLoadingKbm = false;
    // Подписка
    public subscription = new Subscription();
    // Подписка на методы отправки анкеты
    public sendApplicationSubscription = new Subscription();
    // Подписка на получение ссылки на оплату
    public paymentSubscription = new Subscription();
    // Пришли из смс или почты
    public fromUrlOrSms = false;

    // количество попыток запроса GetOffers
    protected getOffersMaxAttempts = 10;
    protected getOffersAttempts = 0;

    // количество попыток запроса SendOsagoApplication
    protected sendApplicationMaxAttempts = 10;
    protected sendApplicationAttempts = 0;

    // подготовка к SendOsagoApplication
    protected isSendOsagoApplicationTimeout = false;

    // Получаем ключ api
    public getApiKey(): string | null {
        return this._apiKey;
    }

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        protected appService: ApplicationService,
        protected loggingService: LoggingService,
        private priceService: PriceCalculatorService,
        private clientService: ClientService
    ) {
        super(http, loggingService);
        loggingService.trace('OsagoService', 'ctor');
        this._initialData = (window as any).osagoApplicationData as InitialData;
        this._apiKey = this._initialData.apiKey;
        this.baseUrl = this._initialData.apiBaseUrl || '';
        this.sessionId = this._initialData.sessionId || '';
        this._application = clientService.getCurrentApplication() || {};
        this.initInternal();
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        this.sendApplicationSubscription.unsubscribe();
        this.paymentSubscription.unsubscribe();
    }

    public get osagoOwners() {
        return this._osagoOwners;
    }

    public get osagoInsurers() {
        return this._osagoInsurers;
    }

    public get osagoOwner() {
        return this._osagoOwner;
    }

    public get osagoInsurer() {
        return this._osagoInsurer;
    }

    public get preOffer() {
        return this._preOffer.value;
    }

    public get carDescription() {
        return this._carDescription.value;
    }

    public set offersStartTime(value: moment.Moment | null) {
        this._offersStartTime = value;
    }

    public get offersStartTime() {
        return this._offersStartTime;
    }

    public get brandId() {
        return this._brandId;
    }

    public set brandId(value: number | null) {
        this._brandId = value;
    }

    public get modificationId() {
        return this._modificationId;
    }

    public set modificationId(value: number | null) {
        this._modificationId = value;
    }

    public get selectedOwnerDriver() {
        return this._selectedOwnerDriver;
    }

    public set selectedOwnerDriver(value: number | null) {
        this._selectedOwnerDriver = value;
    }

    public get selectedInsurerDriver() {
        return this._selectedInsurerDriver;
    }

    public set selectedInsurerDriver(value: number | null) {
        this._selectedInsurerDriver = value;
    }

    // Существует ли список водителей
    public get driversUnlimited(): boolean {
        return this._application != null && this._application.hasDriversRestriction === false;
    }

    public get ownerAddress() {
        return this._ownerAddress || this._application?.ownerCity;
    }

    public get ownerAddressDadata() {
        return this._ownerAddressDadata || this._application?.ownerCityDadata || this._application?.owner?.passport?.registrationAddressData?.daDataAddress;
    }

    public get isPaymentError() {
        return this._isPaymentError;
    }

    public get paymentLink() {
        return this._paymentLink;
    }

    public get selectedOffer(): Offer | null {
        return this._selectedOffer;
    }

    public set selectedOffer(value: Offer | null) {
        this._selectedOffer = value;
    }

    public get insurancePrice() {
        return 3417;
    }

    public get insuranceRate() {
        return 1.1;
    }

    // Псоле того, как выбрали новый номер авто, нужно применить новые данные авто в application
    public afterChangeLicense(): void {
        this._application = this.clientService.getCurrentApplication() || {};
    }

    protected initInternal() {
        const owner = this._application?.owner;
        const insurer = this._application?.insured;
        const drivers = this._application?.drivers;

        if (owner != null && drivers != null) {
            this._osagoOwners = [];
            let index = 0;
            let found = false;

            while (index < drivers.length) {
                const driver = drivers[index];

                if (driver.birthDate == owner.birthDate
                    && driver.firstName == owner.firstName
                    && driver.middleName == owner.middleName
                    && driver.lastName == owner.lastName) {
                        found = true;
                        this._osagoOwners.push(Object.assign(driver, owner));
                } else {
                    this._osagoOwners.push(Object.assign({}, driver));
                }

                index++;
            }

            if (!found) {
                this._osagoOwner = Object.assign({}, this._application.owner);
            }
        }

        if (insurer != null && drivers != null) {
            this._osagoInsurers = [];
            let index = 0;
            let found = false;

            while (index < drivers.length) {
                const driver = drivers[index];

                if (driver.birthDate == insurer.birthDate
                    && driver.firstName == insurer.firstName
                    && driver.middleName == insurer.middleName
                    && driver.lastName == insurer.lastName) {
                        found = true;
                        this._osagoInsurers.push(Object.assign(driver, insurer));
                } else {
                    this._osagoInsurers.push(Object.assign({}, driver));
                }

                index++;
            }

            if (!found) {
                this._osagoInsurer = Object.assign({}, this._application?.insured);
            }
        }

        this._insurerEmail = this._application!.insured?.email || null;
        this._insurerPhone = this._application!.insured?.phone || null;
    }

    public getDriver(index: number): OsagoDriver | void {
        if (this._application.drivers && this._application.drivers[index]) {
            return this._application.drivers[index];
        }
    }

    public get diagnosticCardRequired(): boolean {
        return !(this._application?.carData?.diagnosticCard != null
            && (this._application?.carData?.diagnosticCard.hasNoCard || this._dcLicensePlate != null));

    }

    public get osagoDrivers(): OsagoDriver[] {
        if (this._application == null || this._application.drivers == null) {
            return [];
        }

        return this._application.drivers;
    }

    public get drivers(): DictionaryItem[] {
        if (this._application == null || this._application.drivers == null) {
            return [];
        }

        const result = [];
        let index = 0;
        while (index < this._application.drivers.length) {
            const driver = this._application.drivers[index];
            driver.middleName ?
            result.push(new DictionaryItem(index++, driver.lastName + ' ' + driver.firstName + ' ' + driver.middleName)) :
            result.push(new DictionaryItem(index++, driver.lastName + ' ' + driver.firstName));
        }

        return result;
    }

    public get owner() {
        return this._application?.owner;
    }

    public get DC() {
        return this._application!.carData?.diagnosticCard;
    }

    public get insurer() {
        return this._application!.insured;
    }

    public get offersReceived() {
        return this._offersReceived;
    }

    public set offersReceived(value: boolean) {
        this._offersReceived = value;
    }

    public get term() {
        return this._term;
    }

    public get policy() {
        return this._application?.policyParameters || null;
    }

    public get previousPolicy() {
        return this._application?.previousPolicy || null;
    }

    public get carData(): OsagoCarData | null {
        if (this._application == null || this._application.carData == null) {
            return null;
        }

        return Object.assign({}, this._application.carData);
    }

    public clearCarData() {
        if (this._application != undefined) {
            this._application.carData = {};
        }
    }

    public onPreOfferSelected(offer: Offer): void {
        this._preOffer.next(offer);
        this._preOfferInsurer = offer.insurerType;
    }

    public onVendorComplete(carData: OsagoCarData, modificationId: any): void {
        if (this._application?.carData == null) {
            this._application = {
                carData: {}
            };
        }

        if (this.appService.licensePlate != null && this.appService.licensePlate.length > 0) {
            this._application.carData!.hasNoLicensePlate = false,
            this._application.carData!.licensePlate = this.appService.licensePlate;
        } else {
        this._application.carData!.hasNoLicensePlate = true;
        }

        this._application.carData!.carPower = carData.carPower;
        this._application.carData!.diagnosticCard = {
            hasNoCard: (moment().year() - carData.productionYear! < 4)
        };
        this._application.carData!.modelId = carData.modelId;
        this._application.carData!.brandId = carData.brandId;
        this._application.carData!.brandName = carData.brandName;
        this._application.carData!.modelName = carData.modelName;
        this._application.carData!.price = carData.price;
        this._application.carData!.modificationId = carData.modificationId;
        this._application.carData!.productionYear = carData.productionYear;
        this._application.carData!.purposeOfUseType = carData.purposeOfUseType;
        this._modificationId = modificationId;
        this.clientService.updateCurrentApplication(this._application);
    }

    public onDriversComplete(drivers: OsagoDriver[] = [], isSubmit = false): void {
        if (drivers == null || drivers.length === 0) {
            this._application.drivers = [];
            this._application.hasDriversRestriction = false;
            this._driverDescription.next('Без ограничений');
        } else {
            this._application.hasDriversRestriction = true;
            this._application.drivers = drivers;
            // this.updateKbmDrivers(drivers);
            // this.loadKbms();

            // if (!isSubmit) {
            //     return;
            // }

            if (drivers.length === 0) {
                this._driverDescription.next('');
            } else {
                this._driverDescription.next(this.getDriversDescription(drivers));
            }

            const result: OsagoOwner[] = [];
            let index = 0;

            while (index < drivers.length) {
                const driverIndex = this._osagoOwners.findIndex(
                    x => x.firstName === drivers[index].firstName
                        && x.lastName === drivers[index].lastName
                        && x.middleName === drivers[index].middleName);

                if (driverIndex !== -1) {
                    result.push(this._osagoOwners[driverIndex]);
                    result[result.length - 1].birthDate = drivers[index].birthDate;
                } else {
                    let owner = new OsagoOwner(drivers[index].birthDate, '', drivers[index].firstName, drivers[index].gender, drivers[index].lastName, drivers[index].middleName);

                    if (result.length == 0) {
                        this.fillUserData(owner);
                    }

                    result.push(owner);
                }

                index++;
            }

            this._osagoOwners = result;
            this._osagoInsurers = result;
        }

        // this._drivers.next(this.drivers);
        this.clientService.updateCurrentApplication(this._application);
    }

    // fill in data passed in url
    private fillUserData(owner: OsagoOwner) {
        let userData = (window as any).osagoApplicationData?.userData;

        // no data passed
        if (typeof userData === 'undefined' || userData == null) {
            return;
        }

        // parse and fill
        try {
            let user = JSON.parse(userData);

            if (user == null || user.credential == null || owner == null) {
                return;
            }

            owner.passport = { };

            if (user.credential.number != null) {
                owner.passport.passportNumber = user.credential.number.replace(/ /g, '');
            }

            if (user.credential.series != null) {
                owner.passport.passportSeries = user.credential.series.replace(/ /g, '');
            }

            if (user.credential.issue_point != null) {
                owner.passport.passportIssuer = user.credential.issue_point;
            }

            if (user.credential.issue_point_code != null) {
                owner.passport.departmentCode = user.credential.issue_point_code;
            }

            if (user.credential.issue_date != null) {
                let date = moment(user.credential.issue_date);

                if (date.isValid()) {
                    owner.passport.passportIssueDate = date.format('DD.MM.YYYY');
                }
            }
        }
        catch (ex) { }
    }

    public updateKbmDrivers(drivers: OsagoDriver[] ): void {
        let index = 0;
        const driverKbms = this._driverKbms.value;

        while (index < drivers.length) {
            const driver = drivers[index++];

            if (driver.firstName == null || driver.firstName.length == 0
                || driver.lastName == null || driver.lastName.length == 0
                || driver.middleName == null || driver.middleName.length == 0
                || driver.birthDate == null || driver.birthDate.length != 10
                || driver.driverLicense == null
                || driver.driverLicense.licenseIssueDate == null || driver.driverLicense.licenseIssueDate.length != 10
                || driver.driverLicense.licenseNumber == null || driver.driverLicense.licenseNumber.length != 6
                || driver.driverLicense.licenseSeries == null || driver.driverLicense.licenseSeries.length != 4) {
                continue;
            }

            const driverIndex = driverKbms.findIndex(x =>
                x.driver.firstName == driver.firstName
                && x.driver.lastName == driver.lastName
                && x.driver.middleName == driver.middleName
                && x.driver.birthDate == driver.birthDate
                && x.driver.driverLicense.licenseIssueDate == driver.driverLicense.licenseIssueDate
                && x.driver.driverLicense.licenseNumber == driver.driverLicense.licenseNumber
                && x.driver.driverLicense.licenseSeries == driver.driverLicense.licenseSeries
            );

            if (driverIndex == -1) {
                driverKbms.push(new OsagoKbm(null, null, driver));
                this.osagoToKbmDriverMap.push({
                    osagoDriverIndex: index - 1,
                    kbmDriverIndex: driverKbms.length - 1
                });

                continue;
            }

            if (driverKbms[driverIndex].driver.driverLicense == null && driver.driverLicense != null) {
                driverKbms.push(new OsagoKbm(null, null, driver));
                this.osagoToKbmDriverMap.push({
                    osagoDriverIndex: index - 1,
                    kbmDriverIndex: driverKbms.length - 1
                });

                continue;
            }

            if (driverKbms[driverIndex].driver.driverLicense != null && driver.driverLicense == null) {
                driverKbms.push(new OsagoKbm(null, null, driver));
                this.osagoToKbmDriverMap.push({
                    osagoDriverIndex: index - 1,
                    kbmDriverIndex: driverKbms.length - 1
                });

                continue;
            }

            if (driverKbms[driverIndex].driver.driverLicense.licenseIssueDate != driver.driverLicense.licenseIssueDate
                || driverKbms[driverIndex].driver.driverLicense.licenseNumber != driver.driverLicense.licenseNumber
                || driverKbms[driverIndex].driver.driverLicense.licenseSeries != driver.driverLicense.licenseSeries) {
                    driverKbms.push(new OsagoKbm(null, null, driver));
                    this.osagoToKbmDriverMap.push({
                        osagoDriverIndex: index - 1,
                        kbmDriverIndex: driverKbms.length - 1
                    });

                    continue;
            }

            // driverKbms[driverIndex].driver.experienceStartDate = driver.experienceStartDate;
        }
    }

    protected getDriversDescription(drivers: OsagoDriver[]): string {
        let result = '';

        switch (drivers.length) {
            case 1:
                result = 'Водитель';
                break;
            case 2:
            case 3:
            case 4:
                result = 'Водителя';
                break;
            default:
                result = 'Водителей';
        }

        result += ' ' + drivers.length + ': ';
        let index = 0;
        while (index < drivers.length) {
            result += FioHelper.toInitials(drivers[index].lastName, drivers[index].firstName, drivers[index].middleName);

            if (++index < drivers.length) {
                result += ', ';
            }
        }

        return result;
    }

    // Сохраняем дату начала действия полиса
    public onPolicyFromOwnerComplete(policy: OsagoPolicy): void {
        this._application.policyParameters = policy;
        this.clientService.updateCurrentApplication(this._application);
    }

    public onPolicyComplete(policy: OsagoPolicy, term: string, previousPolicy: PreviousPolicy | null) {
        this._application.policyParameters = policy;
        this._term = term;
        if (previousPolicy != null) {
            this._application.previousPolicy = previousPolicy;
        } else {
            this._application.previousPolicy = undefined;
        }

        this.clientService.updateCurrentApplication(this._application);
    }

    public onDocumentsComplete(document: OsagoCarDocument, identifier: OsagoIdentifier, isSubmit = false) {
        this._application!.carData!.carDocument = document;
        this._application!.carData!.carIdentificators = identifier;

        if (isSubmit) {
            // this.loadDiagnosticCard();
            this.loadOsagoReport();
        }

        this.clientService.updateCurrentApplication(this._application);
    }

    public onOwnerComplete(owner: OsagoOwner, index: number | null) {
        this._application!.owner = owner;

        if (index != null && index >= 0 && index < this._osagoOwners.length) {
            this._osagoOwners [index] = owner;
        } else if (index != null && index > (this._osagoOwners.length - 1)) {
            this._osagoOwner = owner;
        }
        this.appService.onOwnerComplete(true);
        this.clientService.updateCurrentApplication(this._application);
    }

    // При выборе другого водителя для страховщика без апдейта кэша
    public onOwnerSelected(owner: OsagoOwner, index: number | null): void {
        this._application!.owner = owner;

        if (index != null && index >= 0 && index < this._osagoOwners.length) {
            this._osagoOwners [index] = owner;
        } else if (index != null && index > (this._osagoOwners.length - 1)) {
            this._osagoOwner = owner;
        }
    }

    public onInsurerComplete(insurer: OsagoOwner, index: number | null) {
        this._application!.insured = insurer;

        if (index != null && index >= 0 && index < this._osagoInsurers.length) {
            this._osagoInsurers[index] = insurer;
        }
        else if (index != null && index > (this._osagoInsurers.length - 1)) {
            this._osagoInsurer = insurer;
 }

        if (this._insurerEmail != null) {
            this._application!.insured.email = this._insurerEmail;
        }

        if (this._insurerPhone != null) {
            this._application!.insured.phone = this._insurerPhone;
        }

        this.appService.onInsurerComplete();
        this.clientService.updateCurrentApplication(this._application);
    }

    // При выборе другого водителя для страхователя без апдейта кэша
    public onInsurerSelected(insurer: OsagoOwner, index: number | null): void {
        this._application!.insured = insurer;

        if (index != null && index >= 0 && index < this._osagoInsurers.length) {
            this._osagoInsurers[index] = insurer;
        }
        else if (index != null && index > (this._osagoInsurers.length - 1)) {
            this._osagoInsurer = insurer;
 }

        if (this._insurerEmail != null) {
            this._application!.insured.email = this._insurerEmail;
        }

        if (this._insurerPhone != null) {
            this._application!.insured.phone = this._insurerPhone;
        }
    }

    public onDiagnosticComplete(expireDate: string, number: string) {
        this._application!.carData!.diagnosticCard = {
            cardExpireDate: expireDate,
            cardNumber: number,
            hasNoCard: false
        };

        this.clientService.updateCurrentApplication(this._application);
    }

    public onContactsComplete(phone: string, email: string) {
        this._application!.owner!.phone = phone;
        this._application!.owner!.email = email;
        this._application!.insured!.phone = phone;
        this._application!.insured!.email = email;
        this._insurerPhone = phone;
        this._insurerEmail = email;

        // this.appService.onContactsComplete();
        // this.clientService.updateCurrentApplication(this._application);
    }

    public onYandexReachGoal(ymTarget: string) {
        window.dispatchEvent(new CustomEvent('ym.target', {
            detail: {
                target: ymTarget,
                applicationId: this.appService.applicationId
            },
            bubbles: true
        }));
    }

    public getPreapprovedOffers(currentPrice: number, basePrice: number): Offer[] {
        return [
            new Offer('', 0, 0, 'Альфастрахование', '', 'Alfa', [], false, '', this.calculatePrice(currentPrice, basePrice, 4321), '', '', '', [], false),
            new Offer('', 0, 0, 'СОГАЗ', '', 'Sogaz', [], false, '', this.calculatePrice(currentPrice, basePrice, 3372), '', '', '', [], false),

            new Offer('', 0, 0, 'Росгосстрах', '', 'Rosgosstrah', [], false, '', this.calculatePrice(currentPrice, basePrice, 3632), '', '', '', [], false),
            new Offer('', 0, 0, 'Тинькофф', '', 'Tinkoff', [], false, '', this.calculatePrice(currentPrice, basePrice, 3884), '', '', '', [], false),
            new Offer('', 0, 0, 'Абсолют', '', 'Absolut', [], false, '', this.calculatePrice(currentPrice, basePrice, 3953), '', '', '', [], false),
            new Offer('', 0, 0, 'ВСК', '', 'VSK', [], false, '', this.calculatePrice(currentPrice, basePrice, 3953), '', '', '', [], false),
            new Offer('', 0, 0, 'Ингосстрах', '', 'Ingos', [], false, '', this.calculatePrice(currentPrice, basePrice, 4075), '', '', '', [], false),
            new Offer('', 0, 0, 'Ренессанс', '', 'Renessans', [], false, '', this.calculatePrice(currentPrice, basePrice, 4451), '', '', '', [], false),
            new Offer('', 0, 0, 'Intouch', '', 'Intouch', [], false, '', this.calculatePrice(currentPrice, basePrice, 4451), '', '', '', [], false),
            new Offer('', 0, 0, 'Согласие', '', 'Soglasie', [], false, '', this.calculatePrice(currentPrice, basePrice, 4533), '', '', '', [], false),
            new Offer('', 0, 0, 'Макс', '', 'MAKS', [], false, '', this.calculatePrice(currentPrice, basePrice, 4668), '', '', '', [], false),

            new Offer('', 0, 0, 'Совком', '', 'Sovkombank', [], false, '', this.calculatePrice(currentPrice, basePrice, 3732), '', '', '', [], false),
            new Offer('', 0, 0, 'Intouch', '', 'Intouch', [], false, '', this.calculatePrice(currentPrice, basePrice, 3953), '', '', '', [], false),
            new Offer('', 0, 0, 'Zetta', '', 'Zetta', [], false, '', this.calculatePrice(currentPrice, basePrice, 3953), '', '', '', [], false),
            new Offer('', 0, 0, 'Энергогарант', '', 'EnergoGarant', [], false, '', this.calculatePrice(currentPrice, basePrice, 4139), '', '', '', [], false),
            new Offer('', 0, 0, 'Югория', '', 'Ugoria', [], false, '', this.calculatePrice(currentPrice, basePrice, 4523), '', '', '', [], false),
            new Offer('', 0, 0, 'ОСК', '', 'OSK', [], false, '', this.calculatePrice(currentPrice, basePrice, 5080), '', '', '', [], false),
            new Offer('', 0, 0, 'ООО СК "СБЕРБАНК СТРАХОВАНИЕ"', 'sber', 'ООО СК "СБЕРБАНК СТРАХОВАНИЕ"', [], false, '', this.calculatePrice(currentPrice, basePrice, 4080), '', '', '', [], false),
            new Offer('', 0, 0, 'ООО "ГЕЛИОС"', '', 'Gelios', [], false, '', this.calculatePrice(currentPrice, basePrice, 3372), '', '', '', [], false)
        ];
    }

    protected calculatePrice(currentPrice: number, basePrice: number, baseInsurerPrice: number): number {
        return Math.floor(currentPrice * (baseInsurerPrice / basePrice));
    }

    public loadKbms() {
        let index = 0;
        const driverKbms = this._driverKbms.value;

        while (index < driverKbms.length) {
            if (driverKbms[index].kbm == null && driverKbms[index].kbmRequestId == null) {
                const driverIndex = index;
                const timeout = 200 * index;
                window.setTimeout(() => {
                    this.creatKbmReportObservable(driverKbms[driverIndex])
                        .pipe(take(1))
                        .subscribe({
                            next: (response) => {
                                if (response.result == true && response.value != null) {
                                    driverKbms[driverIndex].kbmRequestId = response.value!.requestId;
                                } else {
                                    driverKbms![driverIndex].kbm = '1.00';
                                }
                            },
                            error: () => driverKbms[driverIndex].kbm = '1.00'
                        });
                }, timeout);
            }

            index++;
        }

        this.checkKbms();
    }

    // Получаем КБМ водителя
    protected checkKbms(): void {
        let index = this._driverKbms.value.findIndex((x: any) => x.kbm == null);
        if (index == -1) {
            this.priceService.setDrivers(this._driverKbms.value);
            return;
        }

        index = this._driverKbms.value.findIndex((x: any) => x.kbm == null && x.kbmRequestId != null);
        if (index == -1) {
            window.setTimeout(() => this.checkKbms(), 500);
            return;
        }

        const kbm = this._driverKbms.value[index];
        window.setTimeout(() => {
            this.getKbmReportObservable(kbm.kbmRequestId!)
                .pipe(take(1))
                .subscribe({
                    next: (response) => {
                        if (response.result == true && response.value != null) {
                            if (response.value.status === 'Complete') {
                                kbm.kbm = response.value.kbmData.kbm.toString();
                                this._driverKbms.next(this._driverKbms.value);
                                this.updatePriceServiceDrivers();
                            }
                        } else {
                            kbm.kbm = '1.00';
                        }

                        this.checkKbms();
                    },
                    error: () => {
                        kbm.kbm = '1.00';
                        this.checkKbms();
                    }
                });
        }, 500);
    }

    public getDriverKbm(driver: OsagoDriver, indexDriver: number): void {
        this.isLoadingKbm = true;
        this.priceService.driversKbmSubscribe.next({ status: 'Processing', indexDriver });
        this.subscription.add(
            this.creatKbmReport(driver)
                .subscribe((response) => {
                    if (response.result && response.value != null) {
                        this.setDriverKbmToPriceCalc(driver, indexDriver, response.value?.requestId);
                        this.checkKbm(response.value?.requestId, indexDriver);
                    } else {
                        this.setDriverKbmToPriceCalc(driver, indexDriver);
                    }
                }, error => {
                    this.isLoadingKbm = false;
                    this.priceService.driversKbmSubscribe.next({ status: 'Error', indexDriver });
                })
        );
    }

    // Вставляем данные в массив водителей driversKbm в сервисе priceCalculator
    public setDriverKbmToPriceCalc(driver: OsagoDriver, indexDriver: number, requestId?: string): void {
        this.priceService.driversKbm[indexDriver] = {
            fio: driver.lastName + ' ' + driver.firstName,
            kbm: '1.00',
            age: this.getDriverAge(driver),
            license: driver.driverLicense.licenseSeries + ' ' + driver.driverLicense.licenseNumber,
            experience: this.getDriverExperienceAge(driver),
            isShowPopup: false,
            kbmRequestId: requestId ? requestId : '',
            driverFormData: driver,
            status: 'Processing'
        };
        if (!requestId) {
            this.priceService.driversKbmSubscribe.next({ status: 'Error', indexDriver });
            this.priceService.driversKbm[indexDriver].status = 'Error';
        }
    }

    // Получаем КБМ водителя
    protected checkKbm(requestId: string, indexDriver: number): void {
        this.subscription.add(
            this.getKbmReportObservable(requestId)
                .subscribe((response) => {
                    if (response.result && response.value != null) {
                        if (response.value.status === 'Complete') {
                            this.isLoadingKbm = false;
                            this.priceService.driversKbm[indexDriver].kbm = response.value.kbmData.kbm.toString();
                            this.priceService.driversKbm[indexDriver].status = 'Complete';
                            this.priceService.driversKbmSubscribe.next({ status: 'Complete', indexDriver });
                        } else if (response.value.status === 'Processing') {
                            setTimeout(() => this.checkKbm(requestId, indexDriver), 1000);
                            this.priceService.driversKbmSubscribe.next({ status: 'Processing', indexDriver });
                        }
                    } else {
                        this.priceService.driversKbmSubscribe.next({ status: 'Error', indexDriver });
                        this.priceService.driversKbm[indexDriver].status = 'Error';
                    }
                }, error => {
                    this.isLoadingKbm = false;
                    this.priceService.driversKbmSubscribe.next({ status: 'Error', indexDriver });
                    this.priceService.driversKbm[indexDriver].status = 'Error';
                })
        );
    }

    // Получаем возраст водителя
    public getDriverAge(driver: OsagoDriver): string {
        const now = moment();
        const driverAge = now.diff(moment(driver?.birthDate, 'DD.MM.YYYY', true), 'years');
        return this.ageInitValue(driverAge, this.ageValuesList);
    }

    // Склонение слов 'год, года, лет'
    public ageInitValue(age: number, ageValuesList: string[]): string {
        return age + ' ' + ageValuesList[(age % 100 > 4 && age % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(age % 10 < 5) ? age % 10 : 5]];
    }

    // Получаем значение стажа водителя в годах
    public getDriverExperienceAge(driver: OsagoDriver): string {
        const now = moment();
        const driverExperienceAge = now.diff(moment(driver.experienceStartDate, 'DD.MM.YYYY', true), 'years');
        return this.ageInitValue(driverExperienceAge, this.ageValuesList);
    }

    protected creatKbmReport(driver: OsagoDriver): Observable<RequestByIdResponse> {
        const request = Object.assign({
            firstName: driver.firstName,
            lastName: driver.lastName,
            middleName: driver.middleName,
            birthDate: driver.birthDate,
            driverLicense: driver.driverLicense,
            oldDriverLicense: driver.oldDriverLicense,
        }, this.getBaseApplicationRequest());

        return this.post('enrichment/CreateKbmReport', request);
    }

    protected updatePriceServiceDrivers() {
        const drivers = this._driverKbms.value;
        const kbmDrivers = [];
        let index = 0;
        while (index < drivers.length) {
            const osagoToKbmDriverMap = this.osagoToKbmDriverMap.find(x => {
                return x.kbmDriverIndex === index;
            });
            if (osagoToKbmDriverMap != null
                && osagoToKbmDriverMap.osagoDriverIndex >= 0
                && osagoToKbmDriverMap.osagoDriverIndex < this.osagoDrivers.length) {
                kbmDrivers.push(new OsagoKbm(drivers[index].kbm, null, this.osagoDrivers[osagoToKbmDriverMap.osagoDriverIndex]));
            }

            index++;
        }

        this.priceService.setDrivers(kbmDrivers);
    }

    protected creatKbmReportObservable(osagoKbm: OsagoKbm): Observable<RequestByIdResponse> {
        const driver = osagoKbm.driver;
        const request = Object.assign({
            firstName: driver.firstName,
            lastName: driver.lastName,
            middleName: driver.middleName,
            birthDate: driver.birthDate,
            driverLicense: driver.driverLicense,
            oldDriverLicense: driver.oldDriverLicense,
        }, this.getBaseApplicationRequest());

        return this.post('enrichment/CreateKbmReport', request);
    }

    protected getKbmReportObservable(requestId: string): Observable<KbmReportResponse> {
        return this.post('enrichment/GetKbmReport', new RequestByIdRequest(this._apiKey, requestId!));
    }

    // Получение всех моделей для текущей выбранной марки
    public getModels(brandId: number): Observable<DictionaryItem[]> {
        const models = this._modelsCache.get(brandId);
        if (models != null) {
            return of(models) as Observable<DictionaryItem[]>;
        }

        return this.post('dictionaries/GetAllModels', new GetModelsByBrandIdRequest(this._apiKey, brandId))
            .pipe(
                map((response: GetModelsByBrandIdResponse) => {
                    if (response.result && response.value != null && response.value.models != null) {
                        return response.value.models.map((item) => new DictionaryItem(item.modelId, item.modelName));
                    }
                    else {
                        return [] as DictionaryItem[];
                    }
                }),
                tap({
                    next: (result) => this._modelsCache.set(brandId, result)
                })
            );
    }

    public loadModifications(modelId: number, year: number) {
        this._currentModificationKey = this.getModificationDataKey(modelId, year);
        const data = this._modificationsCache.get(this._currentModificationKey);
        if (data != null) {
            if (data.data != null) {
                this._modifications.next(data.data);
            }
            else if (data.requestId != null) {
                this.getModificationsByRequestId(data, modelId, year);
 }

            return;
        }

        this._modificationsCache.set(this._currentModificationKey, new ModificationData());
        this.getModificationsRequestId(modelId, year);
    }

    protected loadDiagnosticCard() {
        if (this.appService.licensePlate == null || this._application!.carData!.diagnosticCard!.hasNoCard == true) {
            return;
        }

        if (this._dcLicensePlate == this.appService.licensePlate) {
            return;
        }

        this._dcLicensePlate = null;
        const license = this.appService.licensePlate;
        this.getDiagnosticCardRequestIdObservable(license)
            .pipe(take(1))
            .subscribe({
                next: (response: RequestByIdResponse) => {
                    if (response.result == true && response.value != null) {
                        this.getDiagnosticCardByRequestId(response.value.requestId, license);
                    }
                }
            });
    }

    protected loadOsagoReport() {
        if (this.appService.licensePlate == null) {
            return;
        }

        this.getOsagoRequestIdObservable(this.appService.licensePlate)
            .pipe(take(1))
            .subscribe({
                next: (response: RequestByIdResponse) => {
                    if (response.result == true && response.value != null) {
                        this.getOsagoReportByRequestId(response.value.requestId);
                    }
                }
            });
    }

    public setWidgetStatus(widgetStatus: WidgetStatuses) {
        const applicationId = this.appService.applicationId || '';
        const index = this._widgetStatusRequests.findIndex((x: SetWidgetStatusRequest) => x.applicationId == applicationId && x.applicationStatus == widgetStatus);

        if (index != -1) {
            return;
        }

        const request = Object.assign({ applicationStatus: widgetStatus }, this.getBaseApplicationRequest());
        this.post('app/SetWidgetStatus', request)
            .pipe(take(1))
            .subscribe({
                next: _ => { this._widgetStatusRequests.push(request) }
            });
    }

    public waitForOffers(): void {
        this.loggingService.trace('OsagoService', 'waitForOffers');
        this.sendApplicationAttempts = 0;
        this._applicationSentToInsurers = false;
        this.initSubscriptions();
        this.sendApplication();
    }

    private initSubscriptions() {
        this.unsubscribe();
        this.sendApplicationSubscription = new Subscription();
        this.paymentSubscription = new Subscription();
    }

    private unsubscribe() {
        this.sendApplicationSubscription.unsubscribe();
        this.paymentSubscription.unsubscribe();
    }

    public stopwWaitForOffers() {
        this.unsubscribe();
    }

    // Запрос на получение офера, делается циклично каждые пол секунды
    public getOffers(isInitial = true): void {
        if (this._offersReceived) {
            return;
        }

        this.sendApplicationSubscription.add(
            this.getOffersObservable()
                .pipe(
                    delay(2000),
                    take(1))
                .subscribe({
                    next: (response) => {
                        if (response.result) {
                            if (isInitial && this._preOfferInsurer != null) {
                                const index = response.value?.offers?.findIndex(x => x.insurerType === this._preOfferInsurer);
                                if (index == null || index === -1) {
                                    this._preOfferInsurer = null;
                                }

                                this.getOffers(false);
                                return;
                            }

                            if (response.value?.allOffersReceived === true) {
                                this._offersReceived = true;

                                const newOffers = response.value?.offers.map(x => Offer.create(x, this._term));
                                this._offers.next(newOffers);
                                this.checkPreofferPrice();
                            } else {
                                if (response.value != null && response.value.offers.length > 0) {
                                    const newOffers = response.value?.offers.map(x => Offer.create(x, this._term));
                                    this._offers.next(newOffers);
                                    this.checkPreofferPrice();
                                }

                                this.getOffers(false);
                            }
                        } else {
                            this.getOffersAttempts++;

                            if (this.getOffersAttempts < this.getOffersMaxAttempts) {
                                this.appService.trace('GetOffers API error, ' + this.getApiErrorDescription(response.error) + ' attempt ' + this.getOffersAttempts);
                                this.getOffers(isInitial);
                            }
                            else {
                                this.appService.error('GetOffers API error ' + this.getApiErrorDescription(response.error));
                            }
                        }
                    },
                    error: () => {
                        this.getOffersAttempts++;

                        if (this.getOffersAttempts < this.getOffersMaxAttempts) {
                            this.appService.trace('GetOffers http error, attempt ' + this.getOffersAttempts);
                            this.getOffers(isInitial);
                        }
                        else {
                            this.appService.error('GetOffers http error');
                        }
                    }
                }));
    }

    public sendApplication(): void {
        this.loggingService.trace('OsagoService', 'sendApplication');
        this.getApplicationData();

        if (this.owner?.ownerIsInsured) {
            this._application.insured = undefined;
        }

        if (this.appService.carCharacteristicsRequestId != null) {
            this._application.carCharacteristicsRequestId = this.appService.carCharacteristicsRequestId;
        }

        this.loggingService.trace('OsagoService', 'sendApplication', 'sending');
        this.sendApplicationSubscription.add(
                this.sendApplicationObservable()
                .pipe(
                    delay(2000),
                    take(1))
                .subscribe({
                    next: (response) => {
                        this.loggingService.trace('OsagoService', 'sendApplication', 'got response');
                        if (response.result) {
                            this._applicationSent = true;
                            this.sendToInsurers();
                        } else {
                            this.sendApplicationAttempts++;

                            if (this.sendApplicationAttempts < this.sendApplicationMaxAttempts) {
                                this.appService.trace('sendApplication API error, ' + this.getApiErrorDescription(response.error) + ' attempt ' + this.sendApplicationAttempts);
                                this.sendApplication();
                            }
                            else {
                                this.appService.error('SendOsagoAplication API error ' + this.getApiErrorDescription(response.error));
                            }
                        }
                    },
                    error: () => {
                        this.sendApplicationAttempts++;

                        if (this.sendApplicationAttempts < this.sendApplicationMaxAttempts) {
                            this.appService.trace('sendApplication http error, attempt ' + this.sendApplicationAttempts);
                            this.sendApplication();
                        }
                        else {
                            this.appService.error('SendOsagoAplication http error');
                        }
                    }
                }));
    }

    protected getApiErrorDescription(apiError: ApiError | undefined) {
        let result = '';

        if (apiError != null) {
            if (apiError.type != null) {
                result += apiError.type;
            }

            if (apiError.message != null) {
                result += apiError.message;
            }
        }

        return result;
    }

    // Создаем новое приложение с новыми данными
    public createApplication(): Observable<any> {
        return this.post('app/new', new ApplicationRequest(
            this._initialData.apiKey,
            'Osago',
            this._initialData.cpaClientUid,
            this._initialData.cpaClientUid2,
            this._initialData.webMasterID,
            this._initialData.platformID,
            'Widget',
            this.clientService.getClientId(),
            undefined,
            (window as any).insappYandexId, // set in host sberbank.cshtml
            (window as any).insappYandexCounterId, // set in host sberbank.cshtml
            this._initialData.utm,
            this._initialData.referer,
            CalendarHelper.getLocalDateTimeString(),
            this.appService.returnSrc || undefined,
            this._initialData.AgentId
        )).pipe(
            tap((result) => this.appService.applicationId = result.value.applicationId),
            map((response: ApplicationResponse) => {
                if (response.result && response.value != null) {
                    this.clientService.addApplication(this.clientService.license, this.appService.applicationId);
                    return response.value;
                } else {
                    return null;
                }
            })
        );
    }

    protected getSuccessPageUrl() {
        try {
            return window.location.ancestorOrigins[0] + '/success.html';
        } catch {
            return undefined;
        }
    }

    public getSavedOwner(): OsagoOwnerViewModel {
        const result = new OsagoOwnerViewModel();

        if (this.owner != null) {
            if (this._selectedOwnerDriver == null && this._application.hasDriversRestriction === true) {
                console.log(this._selectedOwnerDriver + ' - _selectedOwnerDriver');
                const index = this._application.drivers && this._application.drivers
                    .findIndex(x =>
                        x.birthDate === this.owner?.birthDate
                        && x.firstName === this.owner?.firstName
                        && x.lastName === this.owner?.lastName);

                if (index && index !== -1) {
                    this._selectedOwnerDriver = index;
                } else if (this._application.drivers && this._application.drivers.length > 0) {
                    this._selectedOwnerDriver = this._application.drivers.length;
                }
            }

            result.selectedDriver = this.selectedOwnerDriver;
            console.log(result.selectedDriver);
            if (this.owner.firstName != null && this.owner?.firstName.length > 0
                && this.owner.lastName != null && this.owner?.lastName.length > 0
                && this.owner.middleName != null && this.owner?.middleName.length > 0) {
                result.fio = `${ this.owner.lastName } ${ this.owner.firstName } ${ this.owner.middleName }`;

                if (this.owner.firstName) {
                    result.firstName = `${ this.owner.firstName }`;
                }

                if (this.owner.lastName) {
                    result.lastName = `${ this.owner.lastName }`;
                }

                if (this.owner.middleName) {
                    result.middleName = `${ this.owner.middleName }`;
                }
            }

            if (this.owner.birthDate != null && this.owner.birthDate.length > 0) {
                const date = moment(this.owner.birthDate, 'DD.MM.YYYY', true);
                if (date.isValid()) {
                    result.birthDate = date.format('YYYY-MM-DD');
                }
            }
        } else {
            result.selectedDriver = this.drivers.length > 0
                ? 0
                : null;
        }

        if (result.fio == null && this.osagoDrivers.length > 0) {
            result.fio = `${ this.osagoDrivers[0].lastName } ${ this.osagoDrivers[0].firstName } ${ this.osagoDrivers[0].middleName }`;
            result.firstName = `${ this.osagoDrivers[0].firstName }`;
            result.lastName = `${ this.osagoDrivers[0].lastName }`;
            result.middleName = `${ this.osagoDrivers[0].middleName }`;
        }

        if (result.birthDate == null && this.drivers.length > 0) {
            const date = moment(this.osagoDrivers[0].birthDate, 'DD.MM.YYYY', true);
            if (date.isValid()) {
                result.birthDate = date.format('YYYY-MM-DD');
            }
        }

        if (this.owner?.passport != null && this.owner.passport.passportNumber != null && this.owner.passport.passportSeries != null
                && this.owner.passport.passportNumber.length == 6 && this.owner.passport.passportSeries.length == 4) {
            result.passport = `${ this.owner?.passport.passportSeries.substr(0, 2) } ${ this.owner?.passport.passportSeries.substr(2, 2) } ${ this.owner?.passport.passportNumber }`;
        }

        if (this.owner?.passport != null && this.owner?.passport.passportIssueDate != null) {
            const date = moment(this.owner?.passport.passportIssueDate, 'DD.MM.YYYY', true);
            if (date.isValid()) {
                result.passportDate = date.format('YYYY-MM-DD');
            }
        }

        if (this.owner?.passport != null && this.owner?.passport.registrationAddressData != null) {
            result.address = this.owner?.passport?.registrationAddressData?.addressAsString;
        } else {
            result.address = this.ownerAddress;
        }

        if (this.owner?.passport != null && this.owner?.passport.passportIssuer != null) {
            result.passportIssuer = this.owner?.passport.passportIssuer;
        }

        if (this.owner?.passport != null && this.owner?.passport.departmentCode != null && this.owner?.passport?.departmentCode?.length == 6) {
            result.passportIssuerCode = `${ this.owner?.passport.departmentCode.substr(0, 3) }-${ this.owner?.passport.departmentCode.substr(3) }`;
        }

        if (this.owner?.passport != null && this.owner?.passport.registrationAddressData != null) {
            result.addressDadata = this.owner?.passport.registrationAddressData;
        }
        else {
            result.addressDadata = this.ownerAddressDadata;
        }

        return result;
    }

    public getSavedInsurer(): OsagoOwnerViewModel {
        const result = new OsagoOwnerViewModel();

        if (this.insurer != null) {
            if (this.selectedInsurerDriver == null && this._application.hasDriversRestriction === true) {
                const index = this._application.drivers!
                    .findIndex(x =>
                        x.birthDate == this.insurer!.birthDate
                        && x.firstName == this.insurer!.firstName
                        && x.lastName == this.insurer!.lastName
                        && x.middleName == this.insurer!.middleName);

                if (index != -1) {
                    this.selectedInsurerDriver = index;
                } else if (this._application.drivers!.length > 0) {
                    this._selectedInsurerDriver = this._application.drivers!.length;
 }
            }

            result.selectedDriver = this.selectedInsurerDriver;

            if (this.insurer.firstName != null && this.insurer.firstName.length > 0
                && this.insurer.lastName != null && this.insurer.lastName.length > 0
                && this.insurer.middleName != null && this.insurer.middleName.length > 0) {
                result.fio = `${ this.insurer.lastName } ${ this.insurer.firstName } ${ this.insurer.middleName }`;
                if (this.insurer.firstName) {
                    result.firstName = `${ this.insurer.firstName }`;
                }

                if (this.insurer.lastName) {
                    result.lastName = `${ this.insurer.lastName }`;
                }

                if (this.insurer.middleName) {
                    result.middleName = `${ this.insurer.middleName }`;
                }
            }

            if (this.insurer.birthDate != null && this.insurer.birthDate.length > 0) {
                const date = moment(this.insurer.birthDate, 'DD.MM.YYYY', true);
                if (date.isValid()) {
                    result.birthDate = date.format('YYYY-MM-DD');
                }
            }
        }

        if (this.insurer?.passport != null && this.insurer.passport.passportNumber != null && this.insurer.passport.passportSeries != null
                && this.insurer.passport.passportNumber.length == 6 && this.insurer.passport.passportSeries.length == 4) {
            result.passport = `${ this.insurer?.passport.passportSeries.substr(0, 2) } ${ this.insurer?.passport.passportSeries.substr(2, 2) } ${ this.insurer?.passport.passportNumber }`;
        }

        if (this.insurer?.passport != null && this.insurer?.passport.passportIssueDate != null) {
            const date = moment(this.insurer?.passport.passportIssueDate, 'DD.MM.YYYY', true);
            if (date.isValid()) {
                result.passportDate = date.format('YYYY-MM-DD');
            }
        }

        if (this.insurer?.passport != null && this.insurer?.passport.passportIssuer != null) {
            result.passportIssuer = this.insurer?.passport.passportIssuer;
        }

        if (this.insurer?.passport != null && this.insurer?.passport.departmentCode != null && this.insurer?.passport?.departmentCode?.length == 6) {
            result.passportIssuerCode = `${ this.insurer?.passport.departmentCode.substr(0, 3) }-${ this.insurer?.passport.departmentCode.substr(3) }`;
        }

        if (this.insurer?.passport != null && this.insurer?.passport.registrationAddressData != null) {
            result.address = this.insurer?.passport?.registrationAddressData?.addressAsString;
        }

        if (this.insurer?.passport != null && this.insurer?.passport.registrationAddressData != null) {
            result.addressDadata = this.insurer?.passport.registrationAddressData;
        }

        return result;
    }

    protected checkPreofferPrice(): void {
        const offers = this._offers.value;
        const selectedOffer = this._preOffer.value;

        if (offers == null || offers.length === 0 || selectedOffer == null) {
            return;
        }

        const index = offers.findIndex(x => x.insurerType === selectedOffer.insurerType);

        if (index !== -1 && offers[index].premiumAmount != null && offers[index].premiumAmount !== selectedOffer.premiumAmount) {
            const preoffer = Object.assign({}, selectedOffer);
            preoffer.premiumAmount = offers[index].premiumAmount;
            this._preOffer.next(preoffer);
        }
    }

    public copyAndResend(): void {
        this.loggingService.trace('OsagoService', 'copyAndResend');
        this._applicationSent = true;
        this.copyApplicationObservable()
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    if (response.result && response.value != null) {
                        const applicationId = response.value.applicationId;

                        if (response.value.apiKey != null && response.value.apiKey.length > 0) {
                            this._apiKey = response.value.apiKey;
                        }

                        this.appService.setLicense(this.clientService.license);
                        this.clientService.addApplication(this.clientService.license, applicationId);
                        this.appService.onApplicationCopied(applicationId, response.value.apiKey, response.value.license);
                        // Отключили так как это лишний запрос на получение офферов, он уже запрашивается ниже вызова этой функции в компоненте offers
                        // this.sendToInsurers();
                    } else {
                        this.appService.error('Copy and resend error - API error - ' + (response.error?.message || ''));
                    }
                },
                error: () => this.appService.error('Copy and resend error - http error')
            });
    }

    protected sendToInsurers(): void {
        this.loggingService.trace('OsagoService', 'sendToInsurers');
        this.getOffersAttempts = 0;

        if (this._applicationSentToInsurers) {
            this.loggingService.trace('OsagoService', 'sendToInsurers', 'application is sent to insurers, calling getOffers()');
            this.getOffers();
            return;
        }

        this._applicationSentToInsurers = true;
        this.loggingService.trace('OsagoService', 'sendToInsurers', 'sending to insurers');

        this.sendApplicationSubscription.add(
            this.sendToInsurersObservable()
            .pipe(
                delay(300),
                take(1))
            .subscribe({
                next: (response) => {
                    if (response.result === true) {
                        this.getOffers();
                    }
                    else {
                        this.appService.error('SendToInsurers API error');
                    }
                },
                error: () => this.appService.error('SendToInsurers http error')
            }));
    }

    public clearPaymentResult(): void {
        this._paymentResult.next(null);
        this._isPaymentError = false;
    }

    // Генерируем ссылку на оплату, запрос SelectOffer
    public generatePaymentLink(): void {
        this.appService._payUrl = null;
        this._paymentLink = null;
        this.paymentSubscription?.unsubscribe();
        this._paymentStartTime = moment();

        // call SelectOffer
        this.paymentSubscription = this.selectOfferObservable()
            .pipe(switchMap(selectOfferResult => {
                if (selectOfferResult?.result != true) {
                    return of (new ApiResponse(false));
                }

                // call GenerateOsagoPaymentLink
                return this.getPaymentLinkObservable()
                    .pipe(
                        delay(500),
                        repeat(),
                        filter(getPaymentLinkResult => getPaymentLinkResult.value?.status === 'Received'
                            || getPaymentLinkResult.result != true
                            || moment().subtract(2, 'minutes').isAfter(this._paymentStartTime)),
                        take(1)
                    );
            }))
            .subscribe({
                next: (response: OsagoPaymentLinkResponse) => {
                    if (response?.result === true && response?.value?.status === 'Received') {
                        // Успешно получили ссылку на оплату
                        this._isPaymentError = false;
                        this._paymentLink = response?.value?.osagoPaymentLink.replace('http://', 'https://');
                        this._paymentResult.next(true);

                        if (response?.value?.osagoPaymentLink) {
                            this.appService.onPaymentComplete(this.paymentLink, this.selectedOffer, response?.value?.offer);
                        }
                    } else {
                        this.onPaymentError("GeneratePaymentLink API error");
                    }
                },
                error: () => this.onPaymentError("GeneratePaymentLink http error")
            });
    }

    // Получаем черновик
    public getDraft(): Observable<any> {
        return this.getDraftObservable()
            .pipe(take(1));
    }

    protected getDraftLink(drafts: string[]): void {
        drafts.map((draftKey) => {
            this.getDraftLinkObservable(draftKey)
                .pipe(take(1))
                .subscribe({
                    next: (response) => {
                        console.log(response);
                    },
                    // error: () => this.onPaymentError("GetDraft http error")
                });
        });
    }

    // Получить ссылку на оплету
    protected getPaymentLink(): void {
        this.loggingService.trace('OsagoService', 'getPaymentLink');
        this.appService._payUrl = null;
        this.appService.crossNotApproved = false;
        this._paymentLink = null;
        window.setTimeout(() => {
            this.getPaymentLinkObservable()
                .pipe(take(1))
                .subscribe({
                    next: (response) => {
                        if (response?.result === true) {
                            if (response?.value?.status === 'Received') {
                                this._isPaymentError = false;
                                this._paymentLink = response?.value?.osagoPaymentLink.replace('http://', 'https://');
                                // Успешно получили ссылку на оплату
                                this._paymentResult.next(true);
                                if (response?.value?.osagoPaymentLink) {
                                    this.appService.onPaymentComplete(this.paymentLink, this.selectedOffer, response?.value?.offer);
                                }
                            } else {
                                if (moment().subtract(10, 'minutes').isAfter(this._paymentStartTime)) {
                                    this.onPaymentError('GetPaymentLink - timeout expired');
                                } else {
                                    this.getPaymentLink();
                                }
                            }
                        } else {
                            this.onPaymentError('GetPaymentLink - API error');
                        }
                    },
                    error: () => this.onPaymentError('GetPaymentLink http error')
                });
        }, 500);
    }

    protected onPaymentError(message: string) {
        this.loggingService.trace('OsagoService', 'onPaymentError', message);
        this._isPaymentError =  true;
        this._paymentResult.next(false);
        this.appService.onPaymentComplete();
        this.appService.internalError(message);
    }

    protected getBaseApplicationRequest(): BaseApplicationRequest {
        return {
            apiKey: this._apiKey,
            applicationId: this.appService.applicationId || ''
        };
    }

    protected selectOfferObservable(): Observable<ApiResponse> {
        const request = Object.assign({ offerId: this._selectedOffer?.offerId }, this.getBaseApplicationRequest());
        return this.post('app/SelectOffer', request);
    }
    protected getDraftObservable(): Observable<any> {
        const request = Object.assign({ offerId: this._selectedOffer?.offerId }, this.getBaseApplicationRequest());
        return this.post('app/GetDraft', request);
    }
    protected getDraftLinkObservable(draftId: string): Observable<any> {
        const request = Object.assign({ draftId }, this.getBaseApplicationRequest());
        return this.post('app/GetDraftFile', request);
    }

    protected getPaymentLinkObservable(): Observable<OsagoPaymentLinkResponse> {
        const selectedUpsales = this._selectedOffer?.upsales?.filter((offer: any) => offer?.isSelected).map((offer: any) => offer.id) || [];

        const request = Object.assign({
            offerId: this._selectedOffer?.offerId,
            skipNotification: this.appService.isArmApplication === true,
            SelectedUpsales: selectedUpsales
        }, this.getBaseApplicationRequest());

        return this.post('app/GetOsagoPaymentLink', request);
    }

    public sendPaymentLink(email: boolean, sms: boolean): Observable<ApiResponse> {
        const request = Object.assign({
            sendEmail: email,
            sendSms: sms
        }, this.getBaseApplicationRequest());

        return this.post('app/SendOsagoPaymentLink', request);
    }

    public setStatusBuyButtonPressed(): Observable<ApiResponse> {
        return this.post('app/SetStatusBuyButtonPressed', this.getBaseApplicationRequest())
            .pipe(take(1));
    }

    protected sendApplicationObservable(): Observable<ApiResponse> {
        const request = Object.assign(this.getBaseApplicationRequest(), this._application);
        return this.post('app/SendOsagoApplication', request);
    }

    protected copyApplicationObservable(): Observable<ApplicationResponse> {
        const request = Object.assign(this.getBaseApplicationRequest(), {
            returnClientChannelType: this.appService.returnSrc,
            clientId: this.clientService.getClientId(),
            localTime: CalendarHelper.getLocalDateTimeString() });

        return this.post('app/copy', request);
    }

    protected sendToInsurersObservable(): Observable<ApiResponse> {
        return this.post('app/SendToInsurers', this.getBaseApplicationRequest());
    }

    protected getOffersObservable(): Observable<GetOffersResponse> {
        const request = Object.assign( { fullList: true }, this.getBaseApplicationRequest());
        return this.post('app/GetOffers', request);
    }

    protected getDiagnosticCardRequestIdObservable(licensePlate: string): Observable<RequestByIdResponse> {
        return this.post('enrichment/CreateDiagnosticCardReport', new LicenseVerificationStartRequest(this._apiKey, this.appService.applicationId, licensePlate));
    }

    protected getOsagoRequestIdObservable(licensePlate: string): Observable<RequestByIdResponse> {
        return this.post('enrichment/CreateOsagoReport', new LicenseVerificationStartRequest(this._apiKey, this.appService.applicationId, licensePlate));
    }

    protected getDiagnosticCardByRequestIdObservable(requestId: string): Observable<DiagnosticCardResponse> {
        return this.post('enrichment/GetDiagnosticCardReport', new RequestByIdRequest(this._apiKey, requestId!));
    }

    protected getOsagoReportByRequestIdObservable(requestId: string): Observable<OsagoReportResponse> {
        return this.post('enrichment/GetOsagoReport', new RequestByIdRequest(this._apiKey, requestId!));
    }

    protected getDiagnosticCardByRequestId(requestId: string, license: string) {
        this.getDiagnosticCardByRequestIdObservable(requestId!)
            .pipe(take(1))
            .subscribe({
                next: (response: DiagnosticCardResponse) => {
                    if (response.result == true && response.value != null) {
                        if (response.value.status != 'Complete') {
                            window.setTimeout(() => this.getDiagnosticCardByRequestId(requestId, license), 500);
                            return;
                        }

                        const result = response.value.diagnisticCardData;
                        if (result != null && result.expireDate != null && result.number != null
                            && result.expireDate.length >= 10 && result.number.length >= 15) {
                            this._application!.carData!.diagnosticCard!.cardExpireDate = Converter.toDateRu(result.expireDate);
                            this._application!.carData!.diagnosticCard!.cardNumber = result.number;
                            this._dcLicensePlate = license;
                        }
                    }
                }
            });
    }

    protected getOsagoReportByRequestId(requestId: string) {
        this.getOsagoReportByRequestIdObservable(requestId!)
            .pipe(take(1))
            .subscribe({
                next: (response: OsagoReportResponse) => {
                    if (response.result == true && response.value != null) {
                        if (response.value.status != 'Complete') {
                            window.setTimeout(() => this.getOsagoReportByRequestId(requestId), 500);
                            return;
                        }

                        const result = response.value.osagoData;
                        // do some logic
                    }
                }
            });
    }

    protected getModificationsRequestIdObservable(modelId: number, year: number): Observable<RequestByIdResponse> {
        return this.post('dictionaries/CreateModificationsList', new ModificationsGenerationRequest(this._apiKey, this.appService.applicationId, modelId, year));
    }

    protected getModificationsRequestId(modelId: number, year: number): void {
        this.getModificationsRequestIdObservable(modelId, year)
            .pipe(take(1))
            .subscribe({
                next: (response: RequestByIdResponse) => {
                    if (response.result === true && response.value != null) {
                        const key = this.getModificationDataKey(modelId, year);
                        const data = this._modificationsCache.get(key);
                        if (data) {
                            data.requestId = response.value.requestId;
                            if (this._currentModificationKey === key) {
                                this.getModificationsByRequestId(data, modelId, year);
                            }
                        }
                    } else {
                        this.appService.error('GetModificationsRequest API error');
                    }

                },
                error: () => this.appService.error('GetModificationsRequest http error')
            });
    }

    protected getModificationsByRequestIdObservable(requestId: string): Observable<ModificationListResponse> {
        return this.post('dictionaries/GetModificationsList', new RequestByIdRequest(this._apiKey, requestId!));
    }

    protected getModificationsByRequestId(data: ModificationData, modelId: number, year: number) {
        this.getModificationsByRequestIdObservable(data.requestId!)
            .pipe(take(1))
            .subscribe({
                next: (response: ModificationListResponse) => {
                    if (response.result == true && response.value != null) {
                        if (response.value.status != 'Complete') {
                            window.setTimeout(() => this.getModificationsByRequestId(data, modelId, year), 500);
                            return;
                        }

                        data.data = response.value.modifications;
                        const key = this.getModificationDataKey(modelId, year);
                        if (key === this._currentModificationKey) {
                            this._modifications.next(data.data);
                        }
                    } else {
                        this.appService.error('GetModificationsByRequestId API error');
                    }

                },
                error: () => this.appService.error('GetModificationsByRequestId http error')
            });
    }

    public getApplicationData(): void {
        const app = this.clientService.getCurrentApplication();
        if (app) {
            if ((app.owner && !app.owner.phone) && this._insurerPhone) {
                app.owner.phone = this._insurerPhone;
            }
            if ((app.owner && !app.owner.email) && this._insurerEmail) {
                app.owner.email = this._insurerEmail;
            }
            this._application = app;
        }

        this.loggingService.debug('osagoService', 'getApplicationData complete, this._application =', this._application);
    }

    protected getModificationDataKey(modelId: number, year: number) {
        return 'model=' + modelId + 'year=' + year;
    }

    // Запрос SetStatusWidgetDisplayed
    public setWidgetDisplayed(response: any): Observable<ApiResponse> {
        const request = {
            apiKey: this._apiKey,
            applicationId: response.applicationId
        };

        return this.post('app/SetStatusWidgetDisplayed', request)
            .pipe(
                take(1)
            );
    }


    // Запрос на поулчение цен подели
    public getPodeliPrice(enginePower: string, hasNoLicencePlate: boolean, regionCode: string): Observable<any> {
        const request = {
            apiKey: this._apiKey,
            applicationId: this.appService.applicationId,
            EnginePower: enginePower,
            HasNoLicensePlate: hasNoLicencePlate,
            RegionCode: regionCode
        };
        return this.post('dictionaries/GetOsagoPreliminaryCost', request)
            .pipe(
                take(1)
            );
    }
}
