<div id="documents-anchor" class="step5 scroll-target-wide">

	<form
        id="documentsForm"
        [formGroup]="documentsForm"
        (ngSubmit)="onSubmit($event)"
    >

	<app-price-summary></app-price-summary>
	<div class="widget__wrapper mt15 box-shadow">
		<div class="widget__header">
			<div class="widget__header-title">
				<div class="widget__header-title-text">Документы</div>
			</div>
			<div class="widget__header-number">
				<p>{{ carDescription }}</p>
				<span (click)="toggleLicensePopup()">Изменить</span>
			</div>
		</div>

		<p class="newText" style="padding: 0 95px; margin-bottom: -10px; padding: 48px 95px 0;">Заполните информацию по документам на авто для оплаты полиса выбранной страховой компании</p>
		<div class="widget__body">
			<div class="widget-input-inner-dropdown">
				<app-select
					[fieldControl]="documentType"
					[options]="documentTypes"
					fieldId="documentType"
					helperText="Выберите тип документа на автомобиль"
					fieldName="СТС / ПТС / ЕПТС">
				</app-select>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedDocument != 1)">
				<app-input
					[fieldControl]="sts"
					[isHidden]="(selectedDocument != 1)"
					fieldId="sts"
					fieldName="Серия и номер СТС"
					[textMask] ="{ mask: masks.sts, guide: true }">
					<div class="widget__icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
						<div class="widget__helper widget__helper-right"><img src="/assets/images/sts.png"/></div>
					</div>
				</app-input>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedDocument != 1)">
				<app-input-date
					[fieldControl]="stsDate"
					fieldId="stsDate"
					[modifier]="'widget-input-full'"
					[isHidden]="(selectedDocument != 1)"
					[checkFill]="true"
					[changeYear]="true"
					[maxDate]="maxDate"
					fieldName="Дата выдачи СТС">
					<div class="widget__icon">
						<div class="widget__helper widget__helper-right"><img src="/assets/images/dateptc.png"/></div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
					</div>
				</app-input-date>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedDocument != 0)">
				<app-input
					[fieldControl]="pts"
					[isHidden]="(selectedDocument != 0)"
					fieldId="pts"
					fieldName="Серия и номер ПТС"
					[textMask] ="{ mask: masks.pts, guide: true }">
					<div class="widget__icon">
						<div class="widget__helper widget__helper-right"><img src="/assets/images/pts-number.png"/></div>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
					</div>
				</app-input>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedDocument != 0)">
				<app-input-date
					[fieldControl]="ptsDate"
					fieldId="ptsDate"
					[modifier]="'widget-input-full'"
					[checkFill]="true"
					[isHidden]="(selectedDocument != 0)"
					[maxDate]="maxDate"
					[changeYear]="true"
					fieldName="Дата выдачи ПТС">
					<div class="widget__icon">
						<div class="widget__helper widget__helper-right"><img src="/assets/images/pts-date.png"/></div>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
					</div>
				</app-input-date>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedDocument != 2)">
				<app-input
					[fieldControl]="epts"
					[isHidden]="(selectedDocument != 2)"
					fieldId="epts"
					fieldName="Серия и номер еПТС"
					[textMask] ="{ mask: masks.epts, guide: true }">
				</app-input>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedDocument != 2)">
				<app-input-date
					[fieldControl]="eptsDate"
					fieldId="eptsDate"
					[modifier]="'widget-input-full'"
					[checkFill]="true"
					[isHidden]="(selectedDocument != 2)"
					[maxDate]="maxDate"
					[changeYear]="true"
					fieldName="Дата выдачи еПТС">
				</app-input-date>
			</div>
			<div class="widget-input-inner-dropdown">
				<app-select
					[fieldControl]="identifier"
					[options]="identifiers"
					fieldId="identifier"
					fieldName="VIN">
				</app-select>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedIdentifier != 0)">
				<app-input
					[fieldControl]="vin"
					[isHidden]="(selectedIdentifier != 0)"
					fieldId="vin"
					fieldName="Номер VIN"
					[textMask] ="{ mask: masks.vin, guide: true }">
					<div class="widget__icon">
						<div class="widget__helper widget__helper-right"><img src="/assets/images/vin.png"/></div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
					</div>
				</app-input>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedIdentifier != 1)">
				<app-input
					[fieldControl]="bodyNumber"
					[isHidden]="(selectedIdentifier != 1)"
					[maxLength] ="20"
					fieldId="bodyNumber"
					fieldName="Номер кузова">
					<div class="widget__icon">
						<div class="widget__helper widget__helper-right"><img src="/assets/images/body-number.png"/></div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
					</div>
				</app-input>
			</div>
			<div class="widget-input-inner"
				[class.widget-input-inner-hidden]="(selectedIdentifier != 2)">
				<app-input
					[fieldControl]="chassisNumber"
					[isHidden]="(selectedIdentifier != 2)"
					[maxLength] ="20"
					fieldId="chassisNumber"
					fieldName="Номер шасси">
					<div class="widget__icon">
						<div class="widget__helper widget__helper-right"><img src="/assets/images/shassi.png"/></div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
					</div>
				</app-input>
			</div>
		</div>

		<div class="widget__add">
			<div class="buttonMob"
				(mouseenter)="onMouseEnter()"
				(mouseleave)="onMouseLeave()">
				<app-button
					modifier="border-button"
					buttonType="button"
					(clicked)="onBackClick()"
				>
					<svg [class.hover]="isHover" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.8801 17.7145C10.9731 17.6217 11.0468 17.5114 11.0971 17.39C11.1475 17.2686 11.1734 17.1385 11.1734 17.007C11.1734 16.8756 11.1475 16.7455 11.0971 16.6241C11.0468 16.5027 10.9731 16.3924 10.8801 16.2995L7.58808 13.0065L18.0001 12.9995C18.2653 12.9995 18.5197 12.8942 18.7072 12.7066C18.8947 12.5191 19.0001 12.2648 19.0001 11.9995C19.0001 11.7343 18.8947 11.48 18.7072 11.2924C18.5197 11.1049 18.2653 10.9995 18.0001 10.9995L7.58608 11.0065L10.8801 7.71454C11.0677 7.52703 11.1732 7.27266 11.1733 7.00739C11.1734 6.74212 11.0681 6.48768 10.8806 6.30004C10.6931 6.1124 10.4387 6.00693 10.1734 6.00684C9.90816 6.00674 9.65372 6.11203 9.46608 6.29954L5.88008 9.88554C5.60145 10.1641 5.38043 10.4949 5.22963 10.8589C5.07884 11.2229 5.00122 11.613 5.00122 12.007C5.00122 12.401 5.07884 12.7912 5.22963 13.1552C5.38043 13.5192 5.60145 13.85 5.88008 14.1285L9.46608 17.7145C9.65361 17.902 9.90792 18.0073 10.1731 18.0073C10.4382 18.0073 10.6926 17.902 10.8801 17.7145Z"/>
					</svg>
					<span>Назад</span>
				</app-button>
			</div>
			<div class="buttonMob">
				<app-button
					modifier="blue-button"
					buttonType="submit">
					Продолжить
				</app-button>
			</div>
			<p
				*ngIf="isFormError == true"
				class="error-content">
				Проверьте правильность введённых данных
			</p>
		</div>
	</div>

	</form>
</div>

<ng-container *ngIf="isPopupOpen">
	<div class="popupBg popupBg-active"></div>
	<div class="popupAutoData popupAutoData-active">
		<div class="popupAutoData__close" (click)="toggleLicensePopup()">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">	<path d="M17.9998 6.00006C17.8123 5.81259 17.558 5.70728 17.2928 5.70728C17.0277 5.70728 16.7733 5.81259 16.5858 6.00006L11.9998 10.5861L7.41382 6.00006C7.22629 5.81259 6.97198 5.70728 6.70682 5.70728C6.44165 5.70728 6.18735 5.81259 5.99982 6.00006C5.81235 6.18759 5.70703 6.4419 5.70703 6.70706C5.70703 6.97223 5.81235 7.22653 5.99982 7.41406L10.5858 12.0001L5.99982 16.5861C5.81235 16.7736 5.70703 17.0279 5.70703 17.2931C5.70703 17.5582 5.81235 17.8125 5.99982 18.0001C6.18735 18.1875 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1875 7.41382 18.0001L11.9998 13.4141L16.5858 18.0001C16.7733 18.1875 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1875 17.9998 18.0001C18.1873 17.8125 18.2926 17.5582 18.2926 17.2931C18.2926 17.0279 18.1873 16.7736 17.9998 16.5861L13.4138 12.0001L17.9998 7.41406C18.1873 7.22653 18.2926 6.97223 18.2926 6.70706C18.2926 6.4419 18.1873 6.18759 17.9998 6.00006Z" fill="#979797"/></svg>
		</div>
		<app-vendors
			[editMode]="(true)"
			(close)="toggleLicensePopup()">
		</app-vendors>
	</div>
</ng-container>
