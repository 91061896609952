import { ApiError, ApiResponse } from "../../../../../../common-lib/src/lib/models";
import { ModificationList } from "./modificationList.model";

export class ModificationListResponse extends ApiResponse {
    constructor(
        public result: boolean,
        public error?: ApiError,
        public value?: ModificationList
    ) {
        super(
            result,
            error
        );
    }
}