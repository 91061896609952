import { Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// Окружение
import { environment } from '../../environments/environment';
import { LoggingService } from './loggingService';

export abstract class HttpService {
    private _baseUrl: string | null = null;
    private _hostUrl: string | null = null;
    private _sessionId: string  = '';
    
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        protected loggingService: LoggingService) {
        this.baseUrl = '';
        this._sessionId = '';
        this._hostUrl = environment.hostUrl;
    }

    public get baseUrl(): string | null {
        return this._baseUrl;
    }

    public set baseUrl(value: string | null) {
        this._baseUrl = value;
    }

    public get sessionId(): string {
        return this._sessionId;
    }

    public set sessionId(value: string) {
        this._sessionId = value;
    }

    protected get options(): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-session-id': this._sessionId
            }),
            responseType: 'json'
        };
    }

    protected get(relativeUrl: string): Observable<any> {
        this.loggingService.trace("httpService", 'get', relativeUrl);
        return this.http.get(this.baseUrl + relativeUrl, this.options);
    }

    protected getWithOptions(relativeUrl: string, options: any): Observable<any> {
        this.loggingService.trace("httpService", 'getWithOptions', relativeUrl);
        return this.http.get(this.baseUrl + relativeUrl, options);
    }

    protected post(relativeUrl: string, data: any): Observable<any> {
        this.loggingService.trace("httpService", 'post', relativeUrl);
        return this.http.post(this.baseUrl + relativeUrl, data, this.options)
            .pipe() as Observable<any>;
    }

    protected getLocal(relativeUrl: string): Observable<any> {
        this.loggingService.trace("httpService", 'getLocal', relativeUrl);
        return this.http.get(this._hostUrl + relativeUrl, this.options);
    }

    protected getWithOptionsLocal(relativeUrl: string, options: any): Observable<any> {
        this.loggingService.trace("httpService", 'getWithOptionsLocal', relativeUrl);
        return this.http.get(this._hostUrl + relativeUrl, options);
    }

    // Сохраняем данные applicationId и captcha в запрос captcha
    protected postLocal(relativeUrl: string, data: any): Observable<any> {
        this.loggingService.trace("httpService", 'postLocal', relativeUrl);
        return this.http.post(this._hostUrl + relativeUrl, data, this.options)
            .pipe() as Observable<any>;
    }
}
