import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-cross',
    templateUrl: './cross.component.html',
    styleUrls: ['./cross.component.scss']
})
export class CrossComponent implements OnInit {
    @Input() cross!: any;
    @Input() selectedId: string | null;
    @Input() isLoading: boolean;
    @Output() idSelected = new EventEmitter<string>();
    constructor() {
    }

    // Показываем блок с описанием
    public showCross = false;

    ngOnInit(): void {
    }

    transformDescription(desc: string): string {
        return desc.replace(/\n/g, '<br/> ');
    }

    // Выбрали кросс
    public changeCross(cross: any): void {
        this.idSelected.emit(cross.id);
    }
}
