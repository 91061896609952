import { timer, throwError, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export const genericRetryStrategy = () => (attempts: Observable<any>) => attempts.pipe(
  mergeMap((error, i) => {
    const maxAttempts = 5;
    const scalingDuration = 5000;

    const retryAttempt = i + 1;
    // if maximum number of retries have been met
    // or response is a status code we don't wish to retry, throw error
    if (retryAttempt > maxAttempts!) {
      return throwError(error);
    }

    return timer(retryAttempt! * scalingDuration!);
  })
);