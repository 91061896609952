import { Injectable } from '@angular/core';
import * as moment from 'moment-mini';
import { BehaviorSubject, Observable } from 'rxjs';
import { OsagoKbm } from '../models/osagoKbm.model';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { DriverKbmPopupModel, Offer, OsagoDriver, PriceSummary } from '../models';
import { FioHelper } from '../helpers';
import { IDriversForOffersList } from '../../shared/interfaces/drivers-for-offers-list';
import { IDriverKbmSubject } from '../models/driver-kbm-subject';
import { ApplicationService, LicensePlateService } from '../../../services';

@Injectable()
export class PriceCalculatorService {
    protected _priceBase = 4992;
    protected _territoryRate = 1;
    protected _seasonRate = 1;
    protected _carPowerRate = 1;
    protected _unlimitedDriversRate = 1;
    protected _termRate = 1;
    protected _fiasRates: any[] = [];
    protected _ktRates: number = 1;
    public _kbmDrivers: OsagoKbm[] | null = null;
    protected _priceSummary = new PriceSummary();
    protected _city: string | null = null;
    protected _fiases: string[] | null = null;

    // Водители с данными КБМ
    public driversKbm: IDriversForOffersList[] = [];
    public driversKbmSubscribe: BehaviorSubject<IDriverKbmSubject>
        = new BehaviorSubject<IDriverKbmSubject>({status: 'Start', indexDriver: 0});
    public driversKbmSubscribe$ = this.driversKbmSubscribe.asObservable();

    constructor(http: HttpClient,
                licenseService: LicensePlateService,
                appService: ApplicationService) {
        this.recalculate();
        // http.get('/assets/json/fiasRates.json')
        //     .pipe(take(1))
        //     .subscribe({
        //         next: (data: any) => {
        //             this._fiasRates = data.fiasRates;
        //             if (this._city != null && this._fiases != null) {
        //                 this.setCity(this._city, this._fiases);
        //             }
        //
        //             this.recalculate();
        //         }
        //     });
        http.get('/assets/json/ktRates.json')
            .pipe(take(1))
            .subscribe({
                next: (data: any) => {
                    const license = appService.licensePlate || '';
                    const region = licenseService.getLicenseRegion(license);
                    data.forEach((rates: {code: string, kt: number}) => {
                        this._ktRates = rates.code.toString() === region.toString() ? rates.kt : 1;
                    });
                    this.recalculate();
                }
            });
    }

    _popupDrivers = new BehaviorSubject<DriverKbmPopupModel[]>([]);
    public popupDrivers$ = this._popupDrivers as Observable<DriverKbmPopupModel[]>;

    protected _ageRate = new BehaviorSubject<number>(1.01);
    public ageRate$ = this._ageRate as Observable<number>;

    protected _kbmRate = new BehaviorSubject<string>('0.5');
    public kbmRate$ = this._kbmRate as Observable<string>;

    private _price = new BehaviorSubject<number>(2668.8);
    public price$ = this._price as Observable<number>;

    private _priceGroup = new BehaviorSubject<any>(4992);
    public priceGroup$ = this._priceGroup as Observable<any>;

    private _rate =  new BehaviorSubject<number>(1);
    public rate$ = this._rate as Observable<number>;

    protected recalculate() {
        const rate = this._territoryRate
                        * Number(this._kbmRate.value)
                        * this._ageRate.value
                        * this._seasonRate
                        * this._carPowerRate
                        * this._unlimitedDriversRate
                        * this._termRate;

        const minPrice = this._priceBase * Number(this._ktRates) * this._carPowerRate * 0.5 * 1.43;
        const middlePrice = this._priceBase * Number(this._ktRates) * this._carPowerRate * 0.78 * 1.56;
        const topPrice = this._priceBase * Number(this._ktRates) * this._carPowerRate * 1.17 * 1.72;
        const priceGroup = {
            minPrice,
            middlePrice,
            topPrice
        };
        this._priceGroup.next(priceGroup);
        this._price.next(Math.floor(this._priceBase * rate));
        this._rate.next(Math.floor(rate * 10) / 10);
    }

    get basePrice() {
        return this._priceBase;
    }

    get currentPrice() {
        return this._price.value;
    }

    get currentRate() {
        return this._rate.value;
    }

    getPriceSummary(offer: Offer): PriceSummary {
        this._priceSummary.insurerLogo = offer.logo || '';
        this._priceSummary.insurerBase = offer.basePrice;
        this._priceSummary.price = Math.floor(this._price.value * (offer.basePrice / this._priceBase));
        return this._priceSummary;
    }

    setBasePrice(price: number) {
        this._priceBase = price;
        this.recalculate();
    }

    setKbm(kbm: number) {
        this._kbmRate.next('0.5'); // kbm;
        this.recalculate();
    }

    protected setDriversPopupModel() {
        if (this._kbmDrivers == null) {
            this._popupDrivers.next([]);
            return;
        }

        const now = moment();
        const result = [];
        let index = 0;
        while (index < this._kbmDrivers.length) {
            const driver = this._kbmDrivers[index];
            const age = now.diff(moment(driver.driver.birthDate, 'DD.MM.YYYY', true), 'years');
            const experience = now.diff(moment(driver.driver.experienceStartDate, 'DD.MM.YYYY', true), 'years');
            const ageRate = this.calculateAgeRate(age, experience);

            result.push({
                fio: FioHelper.toInitials(driver.driver.lastName, driver.driver.firstName, driver.driver.middleName) || '',
                kbm: driver.kbm == null ? '1 / нет скидки' : '' + driver.kbm,
                kvs: '' + ageRate
            });

            index++;
        }

        this._popupDrivers.next(result);
    }

    updateExperience(driver: OsagoDriver) {
        if (this._kbmDrivers == null) {
            return;
        }

        const index = this._kbmDrivers.findIndex(x =>
            x.driver.firstName == driver.firstName &&
            x.driver.lastName == driver.lastName &&
            x.driver.middleName == driver.middleName
        );

        if (index != -1) {
            this._kbmDrivers[index].driver.birthDate = driver.birthDate;
            this._kbmDrivers[index].driver.experienceStartDate = driver.experienceStartDate;
            this.setDriversPopupModel();
            this.updateDriversInternal(this._kbmDrivers);
        }
    }

    deleteDriver(driver: OsagoDriver) {
        if (this._kbmDrivers == null) {
            return;
        }

        const index = this._kbmDrivers.findIndex(x =>
            x.driver.birthDate == driver.birthDate &&
            x.driver.firstName == driver.firstName &&
            x.driver.lastName == driver.lastName &&
            x.driver.middleName == driver.middleName
        );

        if (index != -1) {
            this._kbmDrivers.splice(index, 1);
            this.setDriversPopupModel();
            this.updateDriversInternal(this._kbmDrivers);
        }
    }

    // Удаляем водителя из массива водителей с КБМ
    public deleteDriverKbm(driver: IDriversForOffersList): void {
        if (this.driversKbm == null || driver == null) { return; }

        const index = this.driversKbm.findIndex(x =>
            x.fio === driver.fio &&
            x.age === driver.age &&
            x.license === driver.license
        );

        if (index !== -1) {
            this.driversKbm.splice(index, 1);
        }
    }

    protected updateDriversInternal(drivers: OsagoKbm[] | null) {
        if (drivers == null || drivers.length == 0) {
            this._unlimitedDriversRate = drivers == null ? 1.94 : 1;
            this._kbmRate.next(drivers == null ? '1.94' : '1.00');
            this._ageRate.next(drivers == null ? 1 : 1.01);
            this.recalculate();

            if (drivers == null) {
                this._priceSummary.kbmRate = '1.94';
                this._priceSummary.kvsRate = '1';
            } else {
                this._priceSummary.kbmRate = '1 / нет скидки';
                this._priceSummary.kvsRate = '1.01';
            }

            this._priceSummary.kvsRateDescription = '';
            return;
        }

        let index = 0;
        let rate = 0;
        let kbm = '0';
        let rateAge = 0;
        let rateExperience = 0;
        const now = moment();
        while (index < drivers.length) {
            const driver = drivers[index].driver;
            const age = now.diff(moment(driver.birthDate, 'DD.MM.YYYY', true), 'years');
            const experience = now.diff(moment(driver.experienceStartDate, 'DD.MM.YYYY', true), 'years');
            const result = this.calculateAgeRate(age, experience);
            if (result > rate) {
                rate = result;
                rateAge = age;
                rateExperience = experience;
            }

            if (drivers[index].kbm != null && drivers[index].kbm! > kbm) {
                kbm = drivers[index].kbm!;
            }

            index++;
        }

        this._ageRate.next(rate);
        this._kbmRate.next(kbm === '0' ? '1.00' : kbm);
        this._priceSummary.kbmRate = (Number(this._kbmRate.value) === 1) ? '1 / Нет скидки' : ('' + this._kbmRate.value);
        this._priceSummary.kvsRate = '' + this._ageRate.value;
        this._priceSummary.kvsRateDescription = '' + rateExperience + '/' + rateAge;
        this.recalculate();
    }

    setDrivers(drivers: OsagoKbm[] | null): void {
        this._kbmDrivers = drivers;
        this.setDriversPopupModel();
        this.updateDriversInternal(drivers);
    }

    // Поулучаем коэфициент по показателю мощности авто
    public setCarPower(horses: number): void {
        if (horses < 51) {
            this._carPowerRate = .6;
        }
        else if (horses < 71) {
            this._carPowerRate = 1;
 }
        else if (horses < 101) {
            this._carPowerRate = 1.1;
 }
        else if (horses < 121) {
            this._carPowerRate = 1.2;
 }
        else if (horses < 151) {
            this._carPowerRate = 1.4;
 }
        else {
            this._carPowerRate = 1.6;
 }

        this._priceSummary.horsePower = '' + horses + ' л.с.';
        this._priceSummary.horsePowerRate = '' + this._carPowerRate;
        this.recalculate();
    }

    setCity(city: string, fiasIds: string[]) {
        this._city = city;
        this._fiases = fiasIds;

        let index = 0;
        while (index < fiasIds.length) {
            const id = fiasIds[index].toLowerCase();
            const fiasIndex = this._fiasRates.findIndex(x => x.fias == id);
            if (fiasIndex != -1) {
                this._territoryRate = this._fiasRates[fiasIndex].rate;
                this._priceSummary.territory = city;
                this._priceSummary.territoryRate = '' + this._territoryRate;
                this.recalculate();
            }

            index++;
        }
    }

    setTerm(term: number) {
        switch (term) {
            case 1:
            case 2:
            case 3:
                this._termRate = .5;
                break;
            case 4:
                this._termRate = .6;
                break;
            case 5:
                this._termRate = .65;
                break;
            case 6:
                this._termRate = .7;
                break;
            case 7:
                this._termRate = .8;
                break;
            case 8:
                this._termRate = .9;
                break;
            case 9:
                this._termRate = .95;
                break;
            default:
                this._termRate = 1;
                break;
        }

        let termDecription = '';
        switch (term) {
            case 1:
                termDecription = 'месяц';
                break;
            case 3:
            case 3:
            case 4:
                termDecription = 'месяца';
                break;
            default:
                termDecription = 'месяцев';
        }

        this._priceSummary.termRate = '' + this._termRate;
        this._priceSummary.term = '' + term + ' ' + termDecription;
        this.recalculate();
    }

    getAgeRate(birthDate: string, experinceDate: string): number | null {
        const now = moment();
        if (!moment(birthDate, 'DD.MM.YYYY', true).isValid()
        || !moment(experinceDate, 'DD.MM.YYYY', true).isValid()) {
            return 1;
        }

        const age = now.diff(moment(birthDate, 'DD.MM.YYYY', true), 'years');
        const experience = now.diff(moment(experinceDate, 'DD.MM.YYYY', true), 'years');
        return this.calculateAgeRate(age, experience);
    }

    private calculateAgeRate(age: number, experience: number): number {
        if (age < 22) {
            switch (experience) {
                case 0:
                    return 1.93;
                case 1:
                    return 1.9;
                case 2:
                    return 1.87;
                case 3:
                case 4:
                    return 1.66;
                case 5:
                case 6:
                    return 1.64;
            }
        }
        else if (age < 25) {
            switch (experience) {
                case 0:
                    return 1.79;
                case 1:
                    return 1.77;
                case 2:
                    return 1.76;
                case 3:
                case 4:
                    return 1.08;
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    return 1.06;
            }
 }
        else if (age < 30) {
            switch (experience) {
                case 0:
                    return 1.77;
                case 1:
                    return 1.68;
                case 2:
                    return 1.61;
                case 3:
                case 4:
                    return 1.06;
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    return 1.05;
                default:
                    return 1.01;
            }
 }
        else if (age < 35) {
            switch (experience) {
                case 0:
                    return 1.62;
                case 1:
                    return 1.61;
                case 2:
                    return 1.59;
                case 3:
                case 4:
                case 5:
                case 6:
                    return 1.04;
                case 7:
                case 8:
                case 9:
                    return 1.01;
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                    return .96;
                default:
                    return .95;
            }
 }
        else if (age < 40) {
            switch (experience) {
                case 0:
                    return 1.61;
                case 1:
                    return 1.59;
                case 2:
                    return 1.58;
                case 3:
                case 4:
                    return 0.99;
                case 5:
                case 6:
                    return 0.96;
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                    return 0.95;
                default:
                    return 0.94;
            }
 }
        else if (age < 50) {
            switch (experience) {
                case 0:
                    return 1.59;
                case 1:
                    return 1.58;
                case 2:
                    return 1.57;
                case 3:
                case 4:
                case 5:
                case 6:
                    return 0.95;
                default:
                    return 0.94;
            }
 }
        else if (age < 60) {
            switch (experience) {
                case 0:
                    return 1.58;
                case 1:
                    return 1.57;
                case 2:
                    return 1.56;
                case 3:
                case 4:
                case 5:
                case 6:
                    return 0.95;
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                    return 0.94;
                default:
                    return 0.93;
            }
 }
        else {
            switch (experience) {
                case 0:
                    return 1.55;
                case 1:
                    return 1.54;
                case 2:
                    return 1.53;
                case 3:
                case 4:
                case 5:
                case 6:
                    return 0.92;
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                    return 0.91;
                default:
                    return 0.90;
            }
 }

        return 1;
    }
}
