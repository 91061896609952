import { AfterViewInit, Component } from '@angular/core';
import { ApplicationService } from '../../services';

@Component({
    templateUrl: './error.component.html'
})

export class ErrorComponent implements AfterViewInit {
    constructor(private appService: ApplicationService) { }

    ngAfterViewInit() {
        this.appService.onYandexReachGoal("ERROR_GOAL");
    }

    public refresh(): void {
        window.location.reload();
    }
}
