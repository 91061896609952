<div class="widget-input"
    [ngClass]="{
        'error-text': isError,
        'filled': isFilled
    }">
    <input
        isNumeric
        [id]="fieldId"
        [name]="fieldId"
        [numericThousandSeparator]="numericThousandSeparator"
        [numericMaxLength]="numericMaxLength"
        [decimalPlaces]="decimalPlaces"
        [postfix]="postfix"
        [attr.maxLength]="maxLength"
        [type]="fieldType"
        [readOnly]="isReadOnly"
        [formControl]="fieldControl"
        [attr.placeholder]="(placeHolder != null ? placeHolder : null)"
        (input)="onChange($event)"
        (change)="onChange($event)"
    >
    <label>{{ fieldName }}</label>
    <i class="error-content" *ngIf="isError == true">{{ errorMessage }}</i>
</div>