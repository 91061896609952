import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// Сервисы
import { ApplicationService } from '../../../../services';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent
    implements OnInit, OnDestroy {

    // Данные степпера
    public steps = [
        {
            title: 'Автомобиль',
            titleMobile: 'Шаг 1',
            systemName: 'car',
            route: '/info/car',
            disabled: true
        },
        {
            title: 'Водитель',
            titleMobile: 'Шаг 2',
            systemName: 'driver',
            route: '/info/drivers',
            disabled: true
        },
        // {
        //     title: 'Расчет',
        //     systemName: 'payment',
        //     route: 'info/price',
        //     disabled: true
        // },
        {
            title: 'Оформление',
            titleMobile: 'Шаг 3',
            systemName: 'owner',
            route: '/info/owner',
            disabled: true
        }
    ];

    // Показываем степпер
    public isShowStepper = false;

    // Подписка
    public subscription = new Subscription();

    // Предыдщуий url
    private previousUrl: string | undefined;
    // Текущий url
    private currentUrl: string | undefined;

    constructor(private readonly router: Router,
                private readonly appService: ApplicationService) {
        this.subscription.add(
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((event: any) => {
                    this.previousUrl = this.currentUrl;
                    this.currentUrl = event.urlAfterRedirects;
                    this.initStepper(this.currentUrl, this.previousUrl);

                    const parentWindow = window.parent as Window;
                    const iframeUrl = window.location.href;
                    parentWindow.postMessage({type: 'iframeURL', url: iframeUrl}, '*');
                })
        );
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
    }

    // Уничтожение
    public ngOnDestroy(): void {
    }
    // --------------------------------------------------------------------------

    // Слушаем роут для правильного отображения степпера
    private initStepper(currentUrl: string | undefined, previousUrl: string | undefined): void {
        const prev = this.steps.find((step) => step.route === previousUrl);
        const findStep = this.steps.find((step) => step.route === currentUrl);

        this.isShowStepper = !!findStep;
        // if (prev) { prev.disabled = true; }
        // if (findStep) { findStep.disabled = false; }
        // Если мы успешно прошли степ, то он активен
        this.steps.forEach((step) => {
            switch (step.systemName) {
                case 'car': {
                    step.disabled = false;
                    break;
                }
                case 'driver': {
                    step.disabled = !this.appService._driversComplete && currentUrl !== '/info/owner';
                    break;
                }
                case 'payment': {
                    step.disabled = !this.appService._priceComplete || currentUrl !== '/info/owner';
                    break;
                }
                case 'owner': {
                    step.disabled = !this.appService._ownerComplete;
                    break;
                }
                default: {
                    step.disabled = true;
                    break;
                }
            }
            // Если роут соответствует текущему степу, то он активен
            if (findStep) { findStep.disabled = false; }
        });
    }

}
