<input
    isNumeric
    [id]="fieldId"
    [name]="fieldId"
    [decimalPlaces]="0"
    [decimalSeparator]="null"
    [numericThousandSeparator]="numericThousandSeparator"
    [numericMaxLength]="numericMaxLength"
    [postfix]="postfix"
    [maxLength]="numericMaxLength"
    [type]="fieldType"
    [readOnly]="isReadOnly"
    [attr.placeholder]="(placeHolder != null ? placeHolder : null)"
    (input)="onInput($event)"
    #input
    autocomplete="off"
>
