import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseFormComponent } from '../base/base-form.component';
import { TextMaskHelper } from "../../../helpers";

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html'
})

export class InputComponent extends BaseFormComponent {
    @ViewChild('formElement') el: ElementRef;
    cssClass = "widget-input";
    @Input() maxLength = 1000;
    @Input() isPhoneNumber = false;
    @Input() fieldType = 'text';
    @Input() set modifier(value: string | undefined) {
        this.cssClass = "widget-input " + (value || "");
    }

    @Output() focused = new EventEmitter<void>();
    @Output() changed = new EventEmitter<string>();

    onFocus() {
        this.setCaretForPhone();
        this.focused.emit();
    }

    onInput(event: any) {
        let value = event.target.value || "";

        if (this.isPhoneNumber) {
            const unmaskedValue = value.match(/\d+/g).join('');
            if (unmaskedValue.length === 11) {
                event.target.value = value;
                // value = unmaskedValue.substr(1);
            }
        }

        this.changed.emit(value);
    }

    onChange(event: any) {
        this.changed.emit(event.target.value || "");
    }

    setCaretForPhone(): void {
        if (this.isPhoneNumber && this.fieldControl.value === '+7') {
            setTimeout(() => {
                this.el.nativeElement.setSelectionRange(2, 2);
            }, 0);
        }
    }
}
