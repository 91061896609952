import { ApiResponse, ApiError } from "../../../../../../common-lib/src/lib/models";
import { RequestIdResult } from ".";

export class RequestByIdResponse extends ApiResponse {
    constructor(
        public result: boolean,
        public error?: ApiError,
        public value?: RequestIdResult
    ) {
        super(result, error);
    }
}