import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable()
export class FormsService {
    private errors = new Map<FormGroup, ValidationErrors>();

    private getFieldError(field: AbstractControl): ValidationErrors | null {
        if (typeof field === 'undefined' || field == null)
            return null;

        const form = <FormGroup>field.parent;
        const formErrors = this.errors.get(form);
        if (formErrors != null)
            return formErrors[this.getControlName(field) || ''];

        return field.errors;
    }

    public errorMessage(field: AbstractControl): string | null {
        const validationErrors = this.getFieldError(field);
        return validationErrors ? validationErrors.message : null;
    }

    public getControlName(c: AbstractControl): string | null {
        if (c == null || c.parent == null) {
            return null;
        }

        const formGroup = c.parent.controls;
        return Object.keys(formGroup).find(name => c === (<any>formGroup)[name]) || null;
    }

    public isFieldInvalid(field: AbstractControl): boolean {
        return typeof field === 'undefined' || field == null
            ? false
            : (field.dirty || field.touched) && this.getFieldError(field) != null;
    }

    public isFieldEmpty(field: AbstractControl): boolean {
        return typeof field === 'undefined' || field == null || field.value === ''
            ? false
            : field.value === null || field.value === '';
    }

    public isValid(form: FormGroup): boolean {
        for (const field in form.controls) {
            form.get(field)?.markAsTouched();
            form.get(field)?.updateValueAndValidity();
        }

        return form.valid;
    }

    //отличается от isValid тем, что не орнетируется на form.Valid, поскольку она некорректно работает
    // - если имеет внутри себя FormArray, выдает false когда все контролы валидны
    public isFormValid(form: FormGroup, isChild = false, keepErrors = false, update = true): boolean {
        let result = true;
        let errors: ValidationErrors = {};
        for (const field in form.controls) {
            let control = <any>form.get(field);
            if (control.controls != null) {
                let index = 0;
                while (index < control.controls.length) {
                    const current = this.isFormValid(control.controls[index], true, keepErrors, update);
                    result = result && current;
                    index++;
                }
            }
            else {
                if (update) {
                    control.markAsTouched();
                    control.updateValueAndValidity();
                }
                result = result && (control.valid || control.disabled);
                if (control.errors != null)
                    errors[field] = control.errors;
            }
        }

        if (!result && !isChild) {
            const htmlErrors = document.getElementsByClassName("error");
            if (htmlErrors.length > 0)
                htmlErrors.item(0)?.scrollIntoView();
        }

        if (keepErrors)
            this.errors.set(form, errors);

        return result;
    }

    public setFieldValue(field: AbstractControl | null, value: any) {
        if (field != null) {
            field.setValue(value);
            field.markAsDirty();
            field.updateValueAndValidity();
        }
    }
}
