import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy, OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment-mini';

// Сервисы
import { FormsService, OsagoService, PriceCalculatorService, ScriptService } from '../../services';
import { ApplicationService } from '../../../../services';

// Окружение
import { environment } from '../../../../../environments/environment';

// Модели
import { DriverExperience, OsagoApplication, OsagoDriver, OsagoKbm } from '../../models';

// Компоненты
import { DriverComponent } from './driver/driver.component';

// Хелперы
import { GenderHelper, TextMaskHelper } from '../../helpers';
import { ClientService } from '../../../../services/client.service';
import { IDriversForOffersList } from '../../../shared/interfaces/drivers-for-offers-list';
import { WidgetStatuses } from '../../../shared/widgetStatuses.enum';

@Component({
    selector: 'app-drivers',
    templateUrl: './drivers.component.html',
    styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() close = new EventEmitter<boolean>();
    @Input() set editMode(value: boolean) {
        this.submitCaption = value ? 'Сохранить' : 'Продолжить';
        this.edit = value;
    }
    @Input() set editDriverIndex(value: number) {
        this.driverIndex = value;
    }
    @ViewChild('driverspopup') private driversPopupElement: ElementRef | null = null;
    @ViewChildren(DriverComponent) driverComponents!: QueryList<DriverComponent>;
    public edit = false;
    // Индекс водителя, которого редактируем
    public driverIndex: number | undefined;
    public driversUnlimited = false;
    // Форма
    public driversForm: FormGroup = new FormGroup({});
    public drivers: FormGroup[] = [];
    public isFormError = false;
    public isSubmit = false;
    public needCheckValidity = false;
    public ageRate: number | null = null;
    public kbmRate: number | null = null;
    public popupOpen = false;
    public privacyUrl: string;
    public termsUrl: string;
    public submitCaption = 'Продолжить';
    public driversPopupOpen = false;
    // Массив водителей
    public osagoDrivers: OsagoDriver[] = [];
    // Количество водителей
    private driversCounter = 0;
    // Интервал
    private interval = 0;
    // Подписка
    private subscription = new Subscription();
    // Загрузка
    public isLoading = true;
    // Данные осаго
    public osago: OsagoApplication | null = null;
    // Водители из offers
    public driversKvsKbm: IDriversForOffersList[] | undefined;

    constructor(
        private formBuilder: FormBuilder,
        private appService: ApplicationService,
        private formsService: FormsService,
        private osagoService: OsagoService,
        private priceService: PriceCalculatorService,
        private readonly clientService: ClientService,
        private scriptService: ScriptService,
    ) {
        scriptService.load();
        this.subscription.add(
            this.appService.onLoadApp$.subscribe((value) => {
                if (!value) {
                    this.osagoService.getApplicationData();
                    this.fillForm();
                    this.appService.scrollToElement('drivers-anchor');
                }
                this.isLoading = value;
            })
        );
        this.privacyUrl = environment.hostUrl + 'privacy';
        this.termsUrl = environment.hostUrl + 'terms';
    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event: any): void {
        if (!this.driversPopupElement?.nativeElement?.contains(event.target)) {
            this.driversPopupOpen = false;
        }
    }

    get unlimited(): AbstractControl | null{
        return this.driversForm?.get('driversunlimited') || null;
    }

    public ngOnInit(): void {

    }

    public ngAfterViewInit(): void {
        this.osagoService.onYandexReachGoal('DRIVERS_GOAL');
        this.osagoService.setWidgetStatus(WidgetStatuses.DriversScreen);
        this.compareLicenseValidator();
        this.compareVehicleValidator();
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        window.clearInterval(this.interval);
    }

    // fill in data passed in url
    private fillUserData(driver: OsagoDriver) {
        var userData = (<any>window).osagoApplicationData?.userData;

        // no data passed
        if (typeof userData === "undefined" || userData == null || driver == null)
            return;

        // parse and fill
        try {
            var user = JSON.parse(userData);

            if (user == null)
                return;

            if (user.first_name != null)
                driver.firstName = user.first_name;

            if (user.last_name != null)
                driver.lastName = user.last_name;

            if (user.patronymic != null)
                driver.middleName = user.patronymic;

            if (user.birth_date != null) {
                var date = moment(user.birth_date);

                if (date.isValid())
                    driver.birthDate = date.format("DD.MM.YYYY");
            }
        }
        catch (ex) { }
    }

    // Заполняем форму
    public fillForm(): void {
        this.driversForm = this.formBuilder.group({
            driversunlimited: new FormControl(this.osagoService.driversUnlimited, {validators: [], updateOn: 'change'}),
            drivers: new FormArray([])
        }, { updateOn: 'blur' });
        this.driversUnlimited = this.osagoService.driversUnlimited;
        this.osagoDrivers = this.osagoService.osagoDrivers.slice();
        this.drivers = ((this.driversForm.get('drivers') as FormArray).controls as FormGroup[]);
        this.addDrivers();

        if (this.osagoDrivers.length > 0)
            this.fillUserData(this.osagoDrivers[0]);

        this.subscription.add(
            this.driversForm.valueChanges.subscribe( x => this.needCheckValidity = true)
        );

        this.subscription.add(
            this.priceService.ageRate$.subscribe(
                (value) => this.ageRate = value
            )
        );

        this.subscription.add(
            this.priceService.driversKbmSubscribe$.subscribe(() => {
                this.kbmRate = Math.max(...this.priceService.driversKbm.map(item => Number(item.kbm)));
            })
        );

        this.subscription.add(
            this.driversForm.valueChanges.subscribe( x => this.needCheckValidity = true)
        );

        this.interval = window.setInterval(() => {
            if (this.needCheckValidity) {
                this.needCheckValidity = false;
                this.checkValidation();
            }
        }, 100);

        if (this.driversUnlimited) {
            this.priceService.setDrivers(null);
        }
    }

    public showPopup(): void {
        this.popupOpen = true;
    }

    public onPopupClosed(): void {
        this.popupOpen = false;
    }

    public onDriverExperienceChanged(event: DriverExperience, index: number): void {
        const driver = this.getDriver(index);
        if (driver == null) {
            return;
        }

        driver.birthDate = event.birthDate;
        driver.experienceStartDate = event.experienceStartDate;
        this.priceService.updateExperience(driver);
    }

    public onDriversLimitationChanged(event: any): void {
        this.driversUnlimited = event.target?.checked || false;
        this.priceService.setDrivers(this.driversUnlimited ? null : []);
        this.saveState();
    }

    // Удаляем водителя
    public onDriverDeleted(index: any): void {
        const driver = this.getDriver(index);
        if (driver != null) {
            this.priceService.deleteDriver(driver);
        }

        // Удаляем водителя из массива водителей с КБМ
        const driverKmb = this.priceService.driversKbm[index];
        if (driverKmb != null) {
            this.priceService.deleteDriverKbm(driverKmb);
        }

        this.drivers.splice(index, 1);
        this.osagoDrivers.splice(index, 1);
        this.saveState();
        this.checkValidation();
    }

    // Событие именения водителя
    public onDriverChanged(index: any): void {
        this.saveState();
        this.checkValidation();
    }

    public onBackClick(): void {
        const result = this.driversUnlimited ? [] : this.getDrivers();
        this.osagoService.onDriversComplete(result);
        this.appService.onDriversBack();
    }

    public onSubmit(): void {
        this.isSubmit = true;
        this.setValidators();
        this.checkValidation();
        this.compareVehicleValidator();
        this.compareLicenseValidator();
        if (!this.driversUnlimited && !this.formsService.isFormValid(this.driversForm)) {
            return;
        }

        const result = this.driversUnlimited ? [] : this.getDrivers();
        this.osagoService.onDriversComplete(result, !this.osagoService.offersReceived);

        if (this.edit) {
            this.close.emit(true);
        }
        this.osagoService.onYandexReachGoal('DRIVERS_SUBMIT');
        this.appService.onDriversComplete();
    }

    public onAddDriverClick(): void {
        if (this.drivers.length < 5) {
            this.addDriver();
            this.saveState();
        }
    }

    private checkValidation(): void {
        this.isFormError = this.isSubmit
            && !this.driversUnlimited
            && !this.formsService.isFormValid(this.driversForm, false, false, false);
    }

    private saveState(): void {
        this.osagoService.onDriversComplete(this.driversUnlimited ? [] : this.getDrivers());
    }

    private setValidators(): void {
        this.driverComponents.forEach(x => x.setValidators());
    }

    protected getDrivers(): OsagoDriver[] {
        const result: OsagoDriver[] = [];
        let index = 0;
        while (index < this.drivers.length) {
            const osagoDriver = this.getDriver(index);

            if (osagoDriver != null) {
                // if (osagoDriver.rememberOnlyYear && osagoDriver.driverLicense.licenseIssueDate) {
                //     osagoDriver.driverLicense.licenseIssueDate = '';
                // }
                result.push(osagoDriver);
            }

            index++;
        }

        // this.osagoDrivers = result;
        return result;
    }

    private getDriver(index: number): OsagoDriver | null {
        if (index < 0 || index >= this.drivers.length) {
            return null;
        }

        const driver = this.drivers[index].value;

        const osagoDriver: OsagoDriver = {
            birthDate: TextMaskHelper.getDate(driver.birthDate) || '',
            driverLicense: {
                isForeignLicense: false,
                licenseIssueDate: driver.rememberOnlyYearCheckbox === true
                    ? this.getExperienceDateFromYear(driver.experienceStartYear, driver.birthDate, driver.licenseDate)
                    : TextMaskHelper.getDate(driver.licenseDate) || '',
                licenseNumber: TextMaskHelper.removeMask(driver.license || '', 10).substr(4),
                licenseSeries: TextMaskHelper.removeMask(driver.license || '', 10).substr(0, 4).toUpperCase()
            },
            experienceStartDate: driver.rememberOnlyYearCheckbox === true
                ? this.getExperienceDateFromYear(driver.experienceStartYear, driver.birthDate, driver.licenseDate)
                : TextMaskHelper.getDate(driver.licenseDate) || '',
            firstName: driver.firstName,
            gender: GenderHelper.toGender(driver.fioDadata?.gender),
            lastName: driver.lastName,
            middleName: driver.middleName,
            rememberOnlyYear: driver.rememberOnlyYearCheckbox
        };

        if (driver.licenseWasChanged === true) {
            osagoDriver.oldDriverLicense = {
                lastNameInOldLicense: driver.oldfio,
                licenseSeries: TextMaskHelper.removeMask(driver.oldLicense || '', 10).substr(0, 4),
                licenseNumber: TextMaskHelper.removeMask(driver.oldLicense || '', 10).substr(4)
            };
        }

        return osagoDriver;
    }

    private getExperienceDateFromYear(experienceStartYear: string, birthDate: string, licenseDate: string): string {
        const experienceYear = Number(TextMaskHelper.getYear(experienceStartYear));
        const birthDateYear = parseInt((TextMaskHelper.getDate(birthDate) || '').substr(6), 10);
        const licenseDateYear = parseInt((TextMaskHelper.getDate(licenseDate) || '').substr(6), 10);

        const startDate = (birthDateYear + 16) >= experienceYear
            ? (TextMaskHelper.getDate(birthDate) || '').substr(0, 6) + experienceYear
            : '31.12.' + experienceYear;

        const endDate = licenseDateYear === experienceYear
            ? (TextMaskHelper.getDate(licenseDate) || '').substr(0, 6) + experienceYear
            : '31.12.' + experienceYear;

        const start = moment(startDate, 'DD.MM.YYYY', true);
        const end = moment(endDate, 'DD.MM.YYYY', true);
        const days = end.diff(start, 'days');
        const result = moment().isBefore(start) ? moment() : start;
        return result.format('DD.MM.YYYY');
    }

    // Добавляем водителей
    private addDrivers(): void {
        if (this.osagoDrivers.length === 0) {
            if (this.appService.clientFromAlfaBank && this.appService.clientFromAlfaBank?.driver) {
                this.osagoDrivers.push(this.appService.clientFromAlfaBank.driver as OsagoDriver);
            } else {
                this.osagoDrivers.push(OsagoDriver.create());
            }
        }

        let index = 0;
        while (index < this.osagoDrivers.length) {
            this.drivers.push(this.formBuilder.group({
                id: new FormControl(++this.driversCounter, null)
            }, { updateOn: 'blur' }));

            index++;
        }
    }

    // Добавляем водителя
    private addDriver(): void {
        if (this.drivers.length > 4) {
            return;
        }
        this.osagoDrivers.push(OsagoDriver.create());
        this.drivers.push(this.formBuilder.group({
            id: new FormControl(++this.driversCounter, null)
        }, { updateOn: 'blur' }));
    }

    // Валидация сравнения водителя на уникальное имя
    private compareVehicleValidator(): void {
        this.drivers.map((formGroup) => formGroup.valueChanges.subscribe(_ => {
            let i = 0;
            let item;
            const repeats = [];
            while (i < this.drivers.length){
                if (repeats.indexOf(item = this.drivers[i++]?.value?.fio) > -1) {
                    const control = this.drivers[i - 1]?.get('fio');
                    if (!control?.errors?.message) {
                        control?.setErrors({ message: 'Водитель с таким именем уже существует' });
                        control?.markAsTouched();
                    }
                } else {
                    repeats.push(item);
                    const control = this.drivers[i - 1]?.get('fio');
                    if (!control?.errors?.message) {
                        control?.setErrors(null);
                        control?.markAsTouched();
                    }
                }
            }
        }));
    }

    // Валидация сравнения водителя на уникальное ВУ
    private compareLicenseValidator(): void {
        this.drivers.map((formGroup) => formGroup.valueChanges.subscribe(_ => {
            let i = 0;
            let item;
            const repeats = [];
            while (i < this.drivers.length){
                if (repeats.indexOf(item = this.drivers[i++]?.value?.license) > -1) {
                    const control = this.drivers[i - 1]?.get('license');
                    if (!control?.errors?.message) {
                        control?.setErrors({ message: 'Водитель с таким номером ВУ уже существует' });
                        control?.markAsTouched();
                    }
                } else {
                    repeats.push(item);
                    const control = this.drivers[i - 1]?.get('license');
                    if (!control?.errors?.message) {
                        control?.setErrors(null);
                        control?.markAsTouched();
                    }
                }
            }
        }));
    }
}
