<div class="widget-input-dropdown"
    [ngClass]="{
        'error-text': isError,
        'filled': isFilled
    }">
    <ng-select
        [readonly]="readonly"
        [items]="items"
        [loading]="loading"
        bindLabel="value"
        bindValue="id"
        [editableSearchTerm]="true"
        (change)="onChange($event)"
        [labelForId]="fieldId"
        [formControl]="fieldControl"
        #ngSelectComponent>
    </ng-select>

    <label>{{ fieldName }}</label>
    <i class="error-content" *ngIf="isError == true">{{ errorMessage }}</i>
</div>
