import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment-mini';

// Сервисы
import { ApplicationService } from '../../../../services/application.service';
import { LicensePlateService } from '../../../../services/licensePlate.service';
import { ClientService } from '../../../../services/client.service';
import { OsagoService } from '../../services';

// Компоненты
import { LicenseNumberComponent } from './license-number/license-number.component';
import { LicenseRegionComponent } from './license-region/license-region.component';

// Модели
import { LicenseOsago } from '../../../../models';

import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller,
} from 'swiper';
import { LoggingService } from '../../../../services/loggingService';
import { WidgetStatuses } from '../../../shared/widgetStatuses.enum';

SwiperCore.use([
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller,
]);

@Component({
    selector: 'app-license',
    templateUrl: './license.component.html',
    styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit, AfterViewInit {
    @ViewChild('numberRef') public readonly numberComponent!: LicenseNumberComponent;
    @ViewChild('regionRef') public readonly regionComponent!: LicenseRegionComponent;

    // Закрыть окно
    @Output() close = new EventEmitter<boolean>();
    // Данные в компоненте редактируются
    @Input() set editMode(value: boolean) {
        this.edit = value;
    }
    public edit = false;

    // Подписка
    private subscription: Subscription = new Subscription();
    // Ошбика
    public isError = false;
    // Нажали "сохаренить"
    public isSubmitting = false;
    // Загрузка
    public isLoading = false;
    // Данные полисов взятые из запросе GetCache
    public osagoPolicies: LicenseOsago[] = [];

    public carImageSource: string;

    private _armApplication: LicenseOsago | null = null;

    constructor(
        private readonly appService: ApplicationService,
        private readonly loggingService: LoggingService,
        private readonly licenseService: LicensePlateService,
        private readonly clientService: ClientService,
        private readonly osagoService: OsagoService) {
        this.carImageSource = appService.isRosbank
            ? '../../../../../assets/images/main-car-r.svg'
            : '../../../../../assets/images/main-car.svg';

        appService.nextCheckDate$.subscribe({
            next: (value) => {
                this.nextReportDate = value;
                this.checkSuspendedState(value);
            }});
    }

    public isSuspended = false;
    public suspendedCounter: string | null = null;
    private nextReportDateInterval = 0;
    private nextReportDate: string | undefined;

    public hasCustomKeybordStyles() {
        switch (this.appService.apiKey) {
            case "717b3ffdd15b4d9a8bf720a2f642c7ed":
            case "bddb4b03c1ae47c3abe8371ddd25a3aa":
                return true;
        }

        return false;
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.loggingService.trace('LicenseComponent', 'ngOnInit');
        this.subscription.add(
            this.clientService.osagoPolicies$.subscribe((osagoPolicies) => {
                this.osagoPolicies = osagoPolicies;
            })
        );

        if (this.appService.isArmApplication) {
            if (this.appService.armApplication != null) {
                this._armApplication = <LicenseOsago>this.appService.armApplication;
                this.clientService.setArmPolicy(this._armApplication);
                this.loggingService.debug('switching to arm application', this._armApplication);
                this.changeOsagoPolicies(this._armApplication.license, this._armApplication);
                return;
            }
        }
    }

    public ngOnDestroy(): void {
        if (this.nextReportDateInterval != null)
            window.clearInterval(this.nextReportDateInterval);
    }

    public ngAfterViewInit(): void {
        const license = this.appService.licensePlate || '';
        const numb = this.licenseService.getLicenseNumber(license);
        const region = this.licenseService.getLicenseRegion(license);

        if (numb != null) { this.numberComponent?.setValue(numb); }

        if (region != null) { this.regionComponent?.setValue(region); }

        this.appService.scrollToElement('license-anchor');
        if (this.appService.focusOnNumber && this.appService.needFocus) {
            this.numberComponent?.focusRight();
        }

        if (this.appService.isArmApplication && this._armApplication == null) {
            this.appService.armApplication$
                .subscribe({
                    next: (armApplication) => {
                        if (armApplication != null) {
                            this._armApplication = <LicenseOsago>armApplication;
                            this.clientService.setArmPolicy(this._armApplication);
                        }},
                    complete: () => {
                        if (this._armApplication != null) {
                            console.log('switching to arm application', this._armApplication);
                            this.changeOsagoPolicies(this._armApplication.license, this._armApplication);
                        }
                    }
                });
        }
    }
    // --------------------------------------------------------------------------

    private checkSuspendedState(value: string | null): void {
        if (value != null && value.length > 0) {
            this.isSuspended = true;
            this.isSubmitting = false;
            this.checkSuspendedCounter();
            this.nextReportDateInterval = window.setInterval(() => this.checkSuspendedCounter(), 5000);
        }
        else {
            this.isSuspended = false;
            this.suspendedCounter = null;
            if (this.nextReportDateInterval != null) {
                window.clearInterval(this.nextReportDateInterval);
                this.nextReportDateInterval = 0;
            }
        }
    }
        
    private checkSuspendedCounter(): void {
        const nextDate = moment(this.nextReportDate);
        const now = moment(new Date());
        if (!nextDate.isValid || now.isAfter(nextDate)) {
            this.isSuspended = false;
            return;
        }

        const minutes = Math.abs(now.diff(nextDate, 'minutes'));
        if (minutes > 60) {
            const hoursLeft = Math.floor( minutes / 60);
            const minutesLeft = minutes - hoursLeft * 60;
            this.suspendedCounter = hoursLeft + ' ч. ' + minutesLeft + ' мин.';
        } else {
            const seconds = Math.abs( now.diff(nextDate, 'seconds'));
            const minutesLeft = Math.floor( seconds / 60);
            const secondsLeft = seconds - minutesLeft * 60;
            this.suspendedCounter = minutesLeft + ' мин. ' + secondsLeft + ' сек.';
        }
    }

    public onNumberFilled(): void {
        if (this.regionComponent != null) { this.regionComponent.focusLeft(); }
    }

    public onRegionMoveLeft(): void {
        if (this.numberComponent != null) { this.numberComponent.focusRight(); }
    }

    // Нажали "Рассчитать"
    public onSubmit(): void {
        this.appService.changeLicense = true;
        this.numberComponent?.updateValue();
        this.regionComponent?.updateValue();
        this.isError = this.numberComponent?.value == null
            || this.numberComponent?.value.length === 0
            || this.regionComponent?.value == null
            || this.regionComponent?.value.length === 0;
        if (this.isError) { return; }
        this.isSubmitting = true;
        // Номер авто
        const license = this.numberComponent.value + this.regionComponent.value;
        this.appService.onLicenseScreenComplete(license, true);
        this.appService.onYandexReachGoal('CALCULATE_GOAL');
        this.osagoService.setWidgetStatus(WidgetStatuses.CalculateWithLicense);

        try {
            localStorage.setItem('license', license);
        } catch { }

        if (this.edit) {
            this.close.emit();
        }
    }

    // Нажали "Рассчитать без номера"
    public onManualFill(event: any): void {
        event.preventDefault();
        this.appService.changeLicense = true;
        if (this.isSubmitting || this.isLoading) { return; }

        if (!this.appService.isLoaded) { this.isLoading = true; }

        this.appService.onYandexReachGoal('CALCULATE_GOAL');
        this.appService.onLicenseScreenComplete();
        this.osagoService.setWidgetStatus(WidgetStatuses.CalculateWithoutLicense);
    }

    // Выбрали существующий осаго полис
    public changeOsagoPolicies(license: string, item?: LicenseOsago): void {
        this.loggingService.debug("LicenseComponent", "changeOsagoPolicies", license);
        this.appService.changeLicense = true;
        this.isSubmitting = true;
        this.numberComponent?.updateValue();
        this.regionComponent?.updateValue();
        this.appService.onYandexReachGoal('CALCULATE_GOAL');
        this.appService.onLicenseScreenComplete(license);
        this.osagoService.setWidgetStatus(WidgetStatuses.CalculateFromCache);

        try {
            localStorage.setItem('license', license);
        } catch { }
        
        if (item && item?.osago?.owner?.phone && item?.osago?.owner?.email) {
            this.osagoService._insurerPhone = item?.osago?.owner?.phone;
            this.osagoService._insurerEmail = item?.osago?.owner?.email;
        }

        if (this.edit) {
            this.close.emit();
        }
    }
}
