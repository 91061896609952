import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DriverKbmPopupModel } from '../../../models';
import { PriceCalculatorService } from '../../../services';
import { PopupBaseComponent } from '../popup-base.component';
import { IDriversForOffersList } from '../../../../shared/interfaces/drivers-for-offers-list';

@Component({
    selector: 'app-popup-kbm',
    templateUrl: './kbm-popup.component.html'
})

export class KbmPopupComponent extends PopupBaseComponent {
    drivers: IDriversForOffersList[];

    constructor(priceService: PriceCalculatorService) {
        super();
        this.drivers = priceService.driversKbm;
    }
}
