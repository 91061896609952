import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'licenseNumber' })
export class LicenseNumberPipe implements PipeTransform {
    transform(value: string | undefined): string {
        if (typeof value !== 'undefined' && value?.length > 6)
            return value.substr(0, 1) + " " + value.substr(1, 3) + " " + value.substr(4, 2) + " " + value.substr(6);

        return "";
    }
}
