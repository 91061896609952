import { ApiError } from "./apiError.model";
import { Offers } from "./offers.model";
import { ApiResponse } from "./apiResponse.model";

export class GetOffersResponse extends ApiResponse {
    constructor(
        public result: boolean,
        public error?: ApiError,
        public value?: Offers
    ) {
        super(result, error);
    }
}