<div class="widget-input-dropdown filled"
	[style.display]="(isHidden == true) ? 'none' : null"
    [ngClass]="{
        'error-text': isError,
        'active': isActive
    }">
    <input
		type="text"
		readonly
		(focus)="toggleActive(true)"
		(blur)="toggleActive(false)"/>

	<label>{{ fieldName }}</label>
	<div class="widget-input-dropdown-value">{{ selectedValue }}</div>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4716 5.47156C12.3466 5.34658 12.177 5.27637 12.0003 5.27637C11.8235 5.27637 11.654 5.34658 11.5289 5.47156L8.4716 8.52889C8.34485 8.65055 8.17596 8.71849 8.00027 8.71849C7.82458 8.71849 7.65569 8.65055 7.52894 8.52889L4.4716 5.47156C4.34587 5.35012 4.17747 5.28292 4.00267 5.28444C3.82787 5.28596 3.66066 5.35607 3.53706 5.47968C3.41345 5.60328 3.34334 5.77049 3.34182 5.94529C3.3403 6.12009 3.4075 6.28849 3.52894 6.41422L6.5856 9.47156C6.77133 9.65731 6.99182 9.80466 7.23449 9.90519C7.47717 10.0057 7.73727 10.0575 7.99994 10.0575C8.26261 10.0575 8.52271 10.0057 8.76538 9.90519C9.00806 9.80466 9.22855 9.65731 9.41427 9.47156L12.4716 6.41422C12.5966 6.28921 12.6668 6.11967 12.6668 5.94289C12.6668 5.76612 12.5966 5.59658 12.4716 5.47156Z" fill="#979797"/></svg>
		<ul
			class="widget-dropdown"
			[style.display]="isActive ? null : 'none'">
			<li *ngFor="let option of items"
				class="widget-dropdown-item"
				(mousedown)="selectValue(option)">
				{{ option.value }}
			</li>
		</ul>
    <i class="error-content" *ngIf="isError == true">{{ errorMessage }}</i>
</div>
