import { Component, Input } from '@angular/core';
import { PriceSummary } from '../../../models';
import { PopupBaseComponent } from '../popup-base.component';

@Component({
    selector: 'app-popup-price',
    templateUrl: './price-popup.component.html'
})

export class PricePopupComponent extends PopupBaseComponent {
    @Input() model: PriceSummary | null = null;
}
