<div class="paymentLink">
	<div class="alertBlock__wrapper">
		<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M85.3732 14.6316C65.8671 -4.87641 34.131 -4.87641 14.6287 14.6269C-4.87747 34.1302 -4.87559 65.8672 14.6305 85.3743C34.131 104.877 65.8671 104.877 85.3714 85.3705C104.876 65.8672 104.875 34.1321 85.3732 14.6316ZM79.915 79.916C63.4204 96.4106 36.5806 96.4125 20.085 79.9179C3.58668 63.4205 3.58857 36.5779 20.085 20.0833C36.5796 3.58963 63.4185 3.58775 79.9168 20.0852C96.4114 36.5798 96.4096 63.4224 79.915 79.916ZM31.341 36.4619C31.341 33.2335 33.9594 30.6152 37.1878 30.6152C40.4152 30.6152 43.0336 33.2326 43.0336 36.4619C43.0336 39.6922 40.4152 42.3096 37.1878 42.3096C33.9594 42.3096 31.341 39.6922 31.341 36.4619ZM57.8518 36.4619C57.8518 33.2335 60.472 30.6152 63.7004 30.6152C66.9278 30.6152 69.5462 33.2326 69.5462 36.4619C69.5462 39.6922 66.9288 42.3096 63.7004 42.3096C60.472 42.3096 57.8518 39.6922 57.8518 36.4619ZM71.6743 60.408C68.0508 68.7873 59.565 74.2013 50.057 74.2013C40.3445 74.2013 31.8087 68.76 28.3107 60.3382C27.711 58.8966 28.3946 57.2409 29.8381 56.6412C30.1926 56.4951 30.5604 56.4253 30.9224 56.4253C32.0312 56.4253 33.0835 57.0806 33.5351 58.1696C36.1535 64.4737 42.6385 68.545 50.057 68.545C57.3077 68.545 63.7551 64.4708 66.4819 58.163C67.1023 56.7289 68.7674 56.068 70.2006 56.6893C71.6337 57.3107 72.2947 58.9748 71.6743 60.408Z" fill="#009DFF"/></g><defs><clipPath id="clip0"><rect width="100" height="100" fill="white"/></clipPath></defs></svg>
		<h3>Ссылка на оплату успешно сформирована</h3>

		<app-button
			modifier="border-button"
			(clicked)="copyLink()">
			Копировать
		</app-button>
		
		<p *ngIf="isCopied == true">Ссылка скопирована в буфер обмена</p>

		<div class="sendOption">
			<app-checkbox
				fieldId="sendEmail"
				[fieldName]="'Отправить на электронную почту ' + clientEmail"
				[fieldControl]="email"
			></app-checkbox>
		</div>

		<div class="sendOption">
			<app-checkbox
				fieldId="sendPhone"
				[fieldName]="'Отправить смс на номер телефона ' + clientPhone"
				[fieldControl]="phone"
			></app-checkbox>
		</div>

		<app-button
			modifier="border-button"
			[isDisabled]="(isSent || isSending || !optionSelected)"
			(clicked)="send()">
			Отправить
		</app-button>

		<p *ngIf="isSent == true">Ссылка отправлена</p>
		<p class="error" *ngIf="isSendError">При отправке ссылки произошла ошибка. Ссылка не была отправлена</p>
	</div>
</div>

