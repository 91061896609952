import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { BrandModel, DictionaryItem, InitialData } from '../../../../common-lib/src/lib/models';

import { routes } from '../modules/shared/routes';
import { CarData } from '../modules/startup/models';
import { ClientService } from './client.service';
import { Offer } from '../modules/osago/models';
import { v4 as uuidv4 } from 'uuid';

import { HttpService } from "./http.service";
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { LoggingService } from './loggingService';
import {getAgentId} from "../modules/shared/functions/getAgentId";

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends HttpService {
    protected _applicationId: string | null = null;
    public _carData: CarData | null = null;
    protected _brands: DictionaryItem[] = [];
    protected _licensePlate: string | undefined;
    public _initialData: InitialData = new InitialData('');
    _initialBrandModel: BrandModel | null = null;
    protected _isLoaded = false;
    protected _licenseCheckError = new BehaviorSubject<boolean>(false);
    public licenseCheckError$ = this._licenseCheckError as Observable<boolean>;

    protected _checkLicense = new BehaviorSubject<string>('');
    public checkLicense$ = this._checkLicense as Observable<string>;

    protected _onLicenseCheckedComplete = new BehaviorSubject<boolean>(false);
    public onLicenseCheckedComplete$ = this._onLicenseCheckedComplete as Observable<boolean>;

    //actually loading
    protected _onLoadApp = new BehaviorSubject<boolean>(true);
    public onLoadApp$ = this._onLoadApp as Observable<boolean>;

    //initial data loaded, copy not done
    protected _onPreloadedApp = new BehaviorSubject<boolean>(false);
    public onPreloadedApp$ = this._onPreloadedApp as Observable<boolean>;

    protected _isIntro = new BehaviorSubject<boolean>(true);
    public isIntro$ = this._isIntro as Observable<boolean>;

    protected nextCheckDate = new BehaviorSubject<string>('');
    public nextCheckDate$ = this.nextCheckDate as Observable<string>;

    // тип заявки - новая\пролонгация etc
    private applicationType = 'New';
    // Анкета открыта из арма
    private _isArmApplication = false;
    // Анкета из арма
    private _armApplication: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
    armApplication$ = this._armApplication.asObservable();

    public _vendorsComplete = false;
    public _driversComplete = false;
    public _policyComplete = false;
    public _ownerComplete = false;
    public _insurerComplete = false;
    public _dcComplete = false;
    public _contactsComplete = false;
    public _priceComplete = false;
    public _documentComplete = false;
    public _confirmationComplete = false;
    protected _isCompleted = false;
    protected _offerSelected = false;
    protected _onlyOffersMode = false;
    protected _isInFrame = false;
    protected _isLanding = false;
    public _payUrl: string | null = null;
    public _returnSrc: string | null = null;

    protected _partnerApplicationId: string | null = null;
    public _code: string | null = null;

    protected _errorMessages: string[] = [];
    protected readonly storageKey = "clientId";

    // Айди полученных из запроса обогащения
    public carCharacteristicsRequestId: string | null  = null;

    // Результат заявки
    protected _result: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    result$ = this._result.asObservable();
    // Девайс desktop || mobile
    public device: string | undefined;
    public changeAppFromEmail: boolean | undefined;

    // Ввели данные номера авто
    public changeLicense = false;
    // Были на роуте payment
    public isRoutePayment = false;

    // признак, что ввели номер вручную
    protected _licenseFilled : boolean | null = null;

    // Не получили одобрение по выбранному кросу
    public crossNotApproved = false;

    public clientFromAlfaBank    = {
        applicationId: '',
        prolongationApplicationId: '',
        error: '',
        driver: {
            birthDate: '',
            experienceStartDate: '',
            firstName: '',
            gender: '',
            lastName: '',
            middleName: '',
            rememberOnlyYear: false
        },
        licenseNumber: '',
        licenseSeries: '',
        phone: '',
        email: '',
        address: '',
    };

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        protected router: Router,
        protected clientService: ClientService,
        protected loggingService: LoggingService) {
        super(http, loggingService);
    }

    // Признак игнорирования данных кеша
    public get ignoreClientCache() {
        return this._partnerApplicationId != null
            || (this._returnSrc != null
                && (this._returnSrc.toLowerCase() == 'osago2clicks'
                || this._returnSrc.toLowerCase() == 'partnerprolongation'));
    }

    public get licenseFilled(): boolean {
        return this._licenseFilled == true;
    }

    public get isArmApplication(): boolean {
        return this._isArmApplication;
    }

    public get armApplication() {
        return this._armApplication.value;
    }

    public get returnSrc() {
        return this._returnSrc;
    }

    public get payUrl() {
        return this._payUrl;
    }

    public get isLanding() {
        return this._isLanding;
    }

    public get isInFrame() {
        return this._isInFrame;
    }

    public set isInFrame(value: boolean) {
        this._isInFrame = value;
    }

    public get needFocus() {
        if (!this.isInFrame) {
            return true;
        }

        return this._initialData?.apiKey != 'fd5ac4a6e8f144c1a846b2bc1f697e0d';
    }

    public onLoadApp(state: boolean) {
        this._onLoadApp.next(state);
    }

    public get apiKey() {
        return this._initialData?.apiKey;
    }

    public onPreloadedApp() {
        this._onPreloadedApp.next(true);
    }

    get onlyOffersMode() {
        return this._onlyOffersMode;
    }

    get vendorsComplete() {
        return this._vendorsComplete;
    }

    get offerSelected() {
        return this._offerSelected;
    }

    get driversComplete() {
        return this._driversComplete;
    }

    get policyComplete() {
        return this._policyComplete;
    }

    get ownerComplete() {
        return this._ownerComplete;
    }

    get insurerComplete() {
        return this._insurerComplete;
    }

    get dcComplete() {
        return this._dcComplete;
    }

    get contactsComplete() {
        return this._contactsComplete;
    }

    get priceComplete() {
        return this._priceComplete;
    }

    get documentComplete() {
        return this._documentComplete;
    }

    get confirmationComplete() {
        return this._confirmationComplete;
    }

    get isCompleted() {
        return this._isCompleted;
    }

    public set applicationId(value: string | null) {
        this.loggingService.trace('applicationService', 'setApplicationId', 'applicationId = ' + value);
        this._applicationId = value;
    }

    public get applicationId() {
        return this._applicationId;
    }

    public get partnerApplicationId() {
        return this._partnerApplicationId;
    }

    public get brands() {
        return this._brands;
    }

    public get carData() {
        return this._carData;
    }

    public get licensePlate() {
        return this._licensePlate || this._initialData?.licensePlate;
    }

    public get recommendedInsurer() {
        return this._initialData?.recommendedInsurer || null;
    }

    public get focusOnNumber() {
        return this._initialData?.noFocus == null || this._initialData?.noFocus == false;
    }

    public get isLoaded(): boolean {
        return this._isLoaded;
    }

    public get initialBrandModel() {
        return this._initialBrandModel;
    }

    public get isSuccess() {
        return this._initialData?.isSuccess == true;
    }

    public get isFail() {
        return this._initialData?.isSuccess == false;
    }

    public onYandexReachGoal(ymTarget: string) {
        var params = {
            appId: this._applicationId,
            sessionId: this.sessionId
        };

        window.dispatchEvent(new CustomEvent('ym.target', {
            detail: {
                target: ymTarget,
                params: params,
                applicationId: this.applicationId
            },
            bubbles: true
        }));
    }

    private checkIsArmApplication() {
        this._isArmApplication = this.isOpenFromArm();

        if (this._isArmApplication) {
            window.addEventListener('message', (ev: MessageEvent) => {
                if (ev.data && ev.data.type && ev.data.type === 'OsagoApplication') {
                    console.log('OsagoApplication message', ev.data);
                    this._initialData.apiKey = ev.data.apikey;
                    this._initialData.cpaClientUid = ev.data.cpaClientUid;
                    this._initialData.cpaClientUid2 = ev.data.cpaClientUid2;
                    this.applicationType = ev.data.applicationType;
                    var app = ev.data.application;
                    if (app != null) {
                        if (app.license == null)
                            app.license = '';

                        if (app.osago?.carData?.licensePlate == null)
                            app.osago.carData.licensePlate = '';
                    }

                    this._armApplication.next(app);
                    this._armApplication.complete();
                }
            });

            window.opener.postMessage({ type: 'OsagoApplication'}, '*');
        }
    }

    private isOpenFromArm(): boolean {
        try {
            if (document.referrer == null || document.referrer == '') {
                return false;
            }

            var url = new URL(document.referrer);
            return url.hostname.toLowerCase() == environment.armHost;
        } catch (e) {
            return false;
        }
    }

    // Загрузка при инициализации приложения
    public load(): void {
        window.location.hash = "";
        this.getInitialData();
        this.checkIsArmApplication();
        this.hideLoader();
    }

    public onDataLoaded(brands: DictionaryItem[], brandModel: BrandModel | null): void {
        this.loggingService.trace('applicationService', 'onDataLoaded');
        this._isLoaded = true;
        // Массив данных моделей авто
        this.loggingService.debug('applicationService', 'onDataLoaded, setting brands to ', brands);
        this._brands = brands;
        this.loggingService.debug('applicationService', 'onDataLoaded, setting _initialBrandModel to ', brandModel);
        this._initialBrandModel = brandModel;
    }

    public onLicenseChecked(carData: CarData | null = null): void {
        this.loggingService.trace('applicationService', 'onLicenseChecked');
        this._carData = carData;
        if (carData !== null) {
            this._initialBrandModel = {
                brandId: carData.brandId,
                brandName: carData.brandName,
                modelId: carData.modelId,
                modelName: carData.modelName
            };
        } else {
            var armApplication = this._armApplication.value;
            this.loggingService.debug('applicationService', 'onLicenseChecked, armApplication =', armApplication);
            if (armApplication != null && armApplication.osago?.carData != null) {
                this._carData = armApplication.osago.carData;
                this._initialBrandModel = {
                    brandId: this._carData!.brandId,
                    brandName: this._carData!.brandName,
                    modelId: this._carData!.modelId,
                    modelName: this._carData!.modelName
                };
            } else {
                this._initialBrandModel = null;
                this._licenseCheckError.next(true);
            }
        }

        this._onLicenseCheckedComplete.next(true);
        this.onLoadApp(false);

        if (this.changeAppFromEmail) {
            this.changeAppFromEmail = false;
        } else if (this.changeLicense) {
            this.navigate(routes.model);
            this._onlyOffersMode = false;
        }
    }

    public returnToPreoffers() {
        this.navigate(routes.price);
    }

    // Переходим на главную страницу
    public returnToStart(): void {
        this.navigate(routes.license);
    }

    // Переходим на страницу с ошибкой
    public error(message: string | null = null, logged = false): void {
        this.trace(message);

        if (!logged)
            this.log(message);

        this._isCompleted = true;
        this.router.navigateByUrl(routes.error);
    }

    public internalError(message: string | null = null) {
        this.trace(message);
        this.log(message);
        this.onYandexReachGoal("INTERNAL_ERROR_GOAL");
    }

    protected log(source: string | null = null) {
        let message = source || '';

        this.postLocal("log", {
            apiKey: this.apiKey,
            applicationId: this._applicationId,
            sessionId: this.sessionId,
            message: message + '; trace: ' + this._errorMessages.join(', ') + '; debug: ' + this.loggingService.journal
        })
        .pipe(take(1))
        .subscribe({
            next: () => {},
            error: () => {}
        });
    }

    public trace(message: string | null = null) {
        if (message != null) {
            var datetime = (new Date()).toString();
            this._errorMessages.push(datetime + ': ' + message)
        }
    }

    public setLicense(license: string) {
        this._checkLicense.next(license);
    }

    // Событие после нажатия кнопки "Рассчитать"
    public onLicenseScreenComplete(license?: string, filled?: boolean): void {
        this.loggingService.debug("applicationService", "onLicenseScreenComplete, license = ", license);
        // Сохраняем введенный номер в глобальный объект _initialData
        this._initialData.licensePlate = license || '';
        // Сохраняем введенный номер в _licensePlate
        this._licensePlate = license;
        this._licenseCheckError.next(false);
        this._carData = null;

        if (this._licenseFilled == null)
            this._licenseFilled = filled || false;

        // Добавляем данные номера авто в clientService
        this.clientService.addApplication(license || '', this._applicationId);

        // Если номер авто не введен
        if (license == null || license == undefined) {
            this.loggingService.debug("applicationService", "onLicenseScreenComplete, navigating to model screen");
            this.navigate(routes.model);
            // this._isIntro.next(false);
        } else {
            this.loggingService.debug("applicationService", "onLicenseScreenComplete, setting checkLicense");
            this._initialBrandModel = null;
            this._checkLicense.next(license);
        }
    }

    // Инициализация applicationId и license при обновлении страницы
    public onLicenseScreenCompleteFromUrl(license?: string): void {
        // Сохраняем введенный номер в глобальный объект _initialData
        this._initialData.licensePlate = license || '';
        // Сохраняем введенный номер в _licensePlate
        this._licensePlate = license;
        this._licenseCheckError.next(false);
        this._carData = null;

        // Если номер авто не введен
        if (license == null) {
            this.navigate(routes.model);
            // this._isIntro.next(false);
        } else {
            // Добавляем данные номера авто в clientService
            // TODO убрали т.к. изначально ненужны запросы updateCache
            // this.clientService.addApplication(license, this._applicationId);
            // Добавляем данные applicationId и license в клиент сервис
            this.clientService.license = license;
            this.clientService._client?.applications.push({
                applicationId: this._applicationId || '',
                license: license
            });
            this._initialBrandModel = null;
            // this._checkLicense.next(license);
        }
    }

    // Переходим на страницу водителей
    public onVendorComplete(): void {
        // this._vendorsComplete = true;
        this.navigate(routes.drivers);
    }

    onDriversComplete() {
        // this._driversComplete = true;
        this.navigate(routes.owner);
    }

    onDriversBack() {
        this.navigate(routes.vendors);
    }

    onPolicyComplete() {
        // this._policyComplete = true;
        this.navigate(routes.documents);
    }

    onPolicyBack() {
        this.navigate(routes.price);
    }

    public onPriceComplete(): void {
        // this._priceComplete = true;
        this.navigate(routes.owner);
    }

    onPriceBack() {
        this.navigate(routes.drivers);
    }

    onDocumentsComplete() {
        // this._documentComplete = true;
        // this.navigate(routes.owner);
    }

    onDocumentsBack() {
        this.navigate(routes.price);
    }

    // Собственник заполнен
    public onOwnerComplete(ownerIsInsurer = false): void {
        // this._ownerComplete = true;
        // this.navigate(ownerIsInsurer ? routes.diagnostic : routes.insurer);
    }

    // Возвращаемся назад на страницу водителей
    public onOwnerBack(): void {
        this.navigate(routes.drivers);
    }

    onInsurerBack() {
        this.navigate(routes.owner);
    }

    // Страхователь заполнен
    public onInsurerComplete(): void {
        // this._insurerComplete = true;
        // this.navigate(routes.diagnostic);
    }

    onDiagnosticBack(ownerIsInsured: boolean) {
        this.navigate(ownerIsInsured ? routes.owner : routes.insurer);
    }

    // Переходим на страницу подтверждения
    public onDiagnosticComplete(): void {
        // this._insurerComplete = true;
        // this._dcComplete = true;
        this.navigate(routes.offers);
    }

    onContactsBack() {
        this.navigate(routes.owner);
    }

    // Контакты заполнены
    public onContactsComplete(): void {
        // this._contactsComplete = true;
        this.navigate(routes.confirmation);
    }

    onOffersDenied() {
        this._isCompleted = true;
        this._result.next(false);
        this.router.navigateByUrl(routes.result);
    }

    // После выбор офера переходим на страницу "Подтверждение"
    public onOfferSelected(): void {
        // this._offerSelected = true;
        this.navigate(routes.payment);
    }

    // Переходим на страницу поиска оплаты
    public onOfferConfirmed(): void {
        // this._confirmationComplete = true;
        this.navigate(routes.payment);
    }

    // Переход на оплату
    public onPaymentComplete(url: string | null = null, selectedOffer: Offer | null = null, offer?: Offer | null): void {
        console.log(selectedOffer);
        console.log(offer);
        this.isRoutePayment = true;

        if (this._isArmApplication && url != null) {
            this._payUrl = url;
            this.navigate(routes.paymentLinkGenerated);
            return;
        }

        if (url == null) {
            this._confirmationComplete = false;
            this.navigate(routes.offers);
        } else if (!this.isLanding) {
            offer?.upsales.forEach((item: any) => {
                this.crossNotApproved = item.isSelected && !item.isApproved;
            });

            this._confirmationComplete = true;
            this._payUrl = url;
            if (this._initialData.apiKey === 'e9cc1719ae3548af83e8dcd5a793831e') {
                this._payUrl = url;
            } else {
                this.openPayWindow(url);
            }

            this.setStatusBuyButtonPresssed();
        } else {
            this._confirmationComplete = true;
            const message = {
                url,
                type: 'modal',
                systemName: 'pay',
                selectedOffer,
                offer,
            };

            parent.postMessage(message, '*');
            this._offerSelected = false;
            this.navigate(routes.offers);
            this.onYandexReachGoal('PAYMENT_SUBMIT');
            this.setStatusBuyButtonPresssed();
            // window.location.href = url;
            // window.open(url, "_blank");
        }
    }

    // Переходим на оплату
    public openPayWindow(link: string): void {
        this._payUrl = link;
        window.open(link, '_blank');
    }

    // Переходим на оплату
    public goToPay(link: string): void {
        this._payUrl = link;
        this.navigate(routes.pay);
    }

    public returnToOffers(): void {
        this.navigate(routes.offers);
    }

    public returnToConfirm(): void {
        this.navigate(routes.confirm);
    }

    public hideLoader(): void {
        window.dispatchEvent(new CustomEvent('insapp.osago.loaded', {
            detail: {},
            bubbles: true
        }));
    }

    public getClientId() {
		try {
            var clientId = this._initialData.clientId || null;

            if (clientId == null || clientId.length == 0) {
			    clientId = localStorage.getItem(this.storageKey);

                if (clientId == null || clientId.length == 0) {
				    clientId = this.generateGuid();
                }
            }

            this._initialData.clientId = clientId;
            localStorage.setItem(this.storageKey, clientId);
		} catch (ex) {}
	}

    public setStatusBuyButtonPresssed() {
        this.post('app/SetStatusBuyButtonPressed', {
            apiKey: this.apiKey,
            applicationId: this.applicationId
        })
        .pipe(take(1))
        .subscribe({
            next: _ => {}
        });
    }

    protected generateGuid(): string {
		return uuidv4();
	}

    protected getItemFromSessionStorage(key: string) {
        try {
            return sessionStorage.getItem(key);
        }
        catch (ex) {
            return null;
        }
    }

    protected getInitialData(): void {
        this._initialData = (window as any).osagoApplicationData as InitialData;
        this.baseUrl = this._initialData.apiBaseUrl || '';

        this._isLanding = this._initialData?.landing === true;
        this.sessionId = this._initialData?.sessionId || '';

        if (this._initialData.apiKey == null) {
            this._initialData.apiKey = this.getSessionApikey();
        }

        this.setSessionApikey(this._initialData.apiKey);

        if (this._initialData.clientId == null)
            this.getClientId();

        if (this._initialData?.isSuccess === true) {
            window.setTimeout(() => this.router.navigate([routes.success], { skipLocationChange: true }));
        }

        if (this._initialData?.isSuccess === false) {
            window.setTimeout(() => this.router.navigate([routes.fail], { skipLocationChange: true }));
        }

        this._initialData.AgentId = getAgentId();
    }

    // Получаем данные ключа api из сессии
    protected getSessionApikey(): string {
        try {
            return sessionStorage.getItem('apiKey') || '';
        } catch (ex) {
            return '';
        }
    }

    protected setSessionApikey(apiKey: string): void {
        try {
            sessionStorage.setItem('apiKey', apiKey);
        } catch (ex) { }
    }

    public onLicenseCheckSuspended(date: any): void {
        this.nextCheckDate.next(date as string);
        this.log("Превышен лимит запросов по номеру авто, следующий вызов в " + (date as string));
    }

    // Навигация
    public navigate(route: string, state: any = undefined ): void {
        this.router.navigate([route], { state: state });
    }

    // applicationId полученный из запроса copy
    public onApplicationCopied(applicationId: string, apikey?: string, license?: string): void {
        this._applicationId = applicationId;
        this._onlyOffersMode = false;

        if (apikey != null && apikey.length > 0) {
            if (this._initialData != null)
                this._initialData.apiKey = apikey;

            this.setSessionApikey(apikey);
        }

        this.clientService.onApplicationCopied(license);
        this.onLoadApp(false);
    }

    // Стартовая функция после инициализации empty компонента
    public showStartScreen(): void {
        if (this._initialData?.apiKey != null && this._initialData?.applicationId != null && this._initialData?.offerId != null) {
            this._applicationId = this._initialData.applicationId;
            this._onlyOffersMode = true;
            this.loggingService.trace('ApplicationService', 'showStartScreen', 'navigating to offers, applicationId=' + this._applicationId);
            this.router.navigate(['info/offers'], { queryParamsHandling: 'merge' });
            // this.returnToOffers();
            return;
        }

        if (this._initialData?.apiKey != null && this._initialData?.partnerApplicationId != null) {
            this._partnerApplicationId = this._initialData.partnerApplicationId;
            this._onlyOffersMode = true;
            this.loggingService.trace('ApplicationService', 'showStartScreen', 'navigating to offers');
            this.router.navigate(['info/offers'], { queryParamsHandling: 'merge' });
            // this.returnToOffers();
            return;
        }

        const route = this._initialData?.isSuccess != null
            ? this._initialData?.isSuccess == true
                ? routes.success
                : routes.fail
            : routes.license;

        window.setTimeout(() => {
            this.loggingService.trace('ApplicationService', 'showStartScreen', 'navigating to ' + (route || 'default'));
            this.router.navigate([route], { skipLocationChange: true });
        });
    }

    public scrollToElement(elementId: string, fallbackElementId: string | null = null): void {

        // const message = {
        //     url: null,
        //     type: 'scroll',
        //     systemName: 'scrollToElement'
        // };
        //
        // parent.postMessage(message, '*');
        // const options: ScrollIntoViewOptions = {
        //     behavior: 'smooth',
        //     block: 'start',
        //     inline: 'center'
        // };
        //
        // let elem = document.getElementById(elementId);
        //
        // if (elem == null && fallbackElementId != null) {
        //     elem = document.getElementById(fallbackElementId);
        // }
        //
        // if (elem == null) {
        //     return;
        // }
        // try {
        //     elem.scrollIntoView(options);
        // } catch (exception) {
        //     elem.scrollIntoView(true);
        // }

        // window.scrollTo(0, -60);
    }

    public navigateToLicense() {
        this.navigate(routes.license);
        this._isIntro.next(true);
    }

    public get isRosbank() {
        if (this._initialData == null)
            return false;

        switch (this._initialData.apiKey) {
            case "e9cc1719ae3548af83e8dcd5a793831e":
            case "a3296d50004d4cd19c8cbb6da81b8526":
            case "460bcf11d0f9476fb18292de446a43a7":
                return true;
        }

        return false;
    }

    public get reportsMinDelayDisabled() {
        if (this._initialData == null)
            return false;

        switch (this._initialData.apiKey) {
            case "c72882415ccc41dfbc9b336c7327ca68":
            case "ad57ca2a73fe49e6a8de66171c4c5d46":
                return true;
        }

        return false;
    }
}
