import { Injectable } from "@angular/core";
import { ApplicationService } from "./application.service";

@Injectable({
    providedIn: 'root'
})
export class ApplicationMockService extends ApplicationService {
    public hideLoader() {
        window.setTimeout(() =>
            window.dispatchEvent(new CustomEvent('insapp.osago.loaded', {
                detail: {},
                bubbles: true
            }))
        );
    }

    protected getInitialData() {
        this._initialData = {
            apiKey: '1234567890',
            apiBaseUrl: '/',
            licensePlate: 'О196СК150',
            partnerBrandName: 'Nissan',
            partnerModelName: '370Z'
        };
    }
}