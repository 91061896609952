import { NgModuleFactory, Type } from '@angular/core';

export const lazyWidgets: {
    path: string,
    loadChildren: () => Promise<NgModuleFactory<any> | Type<any>>
}[] = [{
    path: 'startup',
    loadChildren: () => import('../startup/startup.module')
        .then(m => m.StartupModule)
}];

export function lazyArrayToObj() {
    const result: any = {};
    for (const w of lazyWidgets) {
        result[w.path] = w.loadChildren;
    }
    return result;
}
