import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { take, map } from 'rxjs/operators';

// Сервисы
import { HttpService } from './http.service';

// Модели
import { OsagoApplication, OsagoPolicy } from '../modules/osago/models';
import { LicenseApplication, LicenseOsago, OsagoClient } from '../models';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-mini';
import { TextMaskHelper } from '../modules/osago/helpers';
import { Converter } from '../../../../common-lib/src/lib/utils/converters';
import { InitialData } from '../../../../common-lib/src/lib/models/initialData.model';
import { LoggingService } from './loggingService';

@Injectable({
    providedIn: 'root'
})
export class ClientService extends HttpService implements OnDestroy {
    // Ключ в localStorage
    protected readonly storageKey = 'clientId';
    // Подписка
    protected subscription: Subscription = new Subscription();
    // ID клиента
    protected _clientId: string | null = null;
    // Данные клиента
    public _client: OsagoClient = OsagoClient.create();
    // Ключ api
    protected _apiKey: string | null = null;
    // Данные номера авто
    public license = '';
    // Данные полиса осаго
    protected _policy: string | null = null;
    // Данные сохраненных полисов в кэше
    protected osagoPolicies = new BehaviorSubject<LicenseOsago[]>([]);
    public osagoPolicies$ = this.osagoPolicies as Observable<LicenseOsago[]>;
    // Данные сохраненных applications в кэше
    protected osagoApplications = new BehaviorSubject<LicenseApplication[]>([]);
    public osagoApplications$ = this.osagoApplications as Observable<LicenseApplication[]>;

    private _isArmPolicy = false;

    constructor(@Inject(HttpClient) protected http: HttpClient,
                protected loggingService: LoggingService,
                private readonly route: ActivatedRoute) {
        super(http, loggingService);
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
    // --------------------------------------------------------------------------

    public setArmPolicy(policy: LicenseOsago) {
        if (policy == null)
            return;

        this.license = policy.license;
        this._client = OsagoClient.create();
        this._client.osagoPolicies.push(policy);
        this._isArmPolicy = true;
    }

    public getPartnerClientId(): string | undefined {
        const data = (window as any).osagoApplicationData as InitialData;
        return data?.partnerClientId;
    }

    // Получить ID клиента
    public getClientId(): string {
        const data = (window as any).osagoApplicationData as InitialData;
        const isArray = Array.isArray(data.clientId);
        if (data.clientId != null) {
            if (isArray && data.clientId.length > 1) {
                this._clientId = data.clientId[0];
                this.loggingService.debug("clientService::getClientId, returning", this._clientId);
                return this._clientId;
            } else {
                this._clientId = data.clientId;
                this.loggingService.debug("clientService::getClientId, returning", this._clientId);
                return this._clientId;
            }
        }

        try {
            const clientIdInit = localStorage.getItem(this.storageKey);
            if (clientIdInit && clientIdInit.search(',') > 0) {
                localStorage.removeItem(this.storageKey);
            }
        } catch { }

        // Проверяем есть ли clientId в queryParams, если есть, то используем его
        var clientId = this.getUrlClientId();

        if (clientId != null) {
            this.loggingService.debug('clientService::getClientId, got clientId from url:' + clientId);
            this.setClientId(clientId);
        }

        if (this._clientId != null) {
            if (this._clientId.length > 50) {
                return this._clientId.split(',')[0];
            } else {
                return this._clientId;
            }
        }
        try {
            // Берем данные клиента с localStorage
            clientId = localStorage.getItem(this.storageKey);
            // Если ID клиента нет, то генерируем новый
            if (clientId == null || clientId.length === 0) {
                clientId = this.generateGuid();
            }
            // Записываем ID клиента в LocalStorage
            this.setClientId(clientId);
            return clientId;
        } catch (ex) {
            // Генерируем новый ID клиента
            return this.generateGuid();
        }
    }

    // Записываем ID клиента в LocalStorage
    protected setClientId(id: string): void {
        this.loggingService.debug("clientService::setClientId", id);

        try {
            if (id.length > 50) {
                this._clientId = id.split(',')[0];
                localStorage.setItem(this.storageKey, this._clientId);
            } else {
                this._clientId = id;
                localStorage.setItem(this.storageKey, id);
            }

        } catch (ex) {
        }
    }

    public onApplicationCopied(license?: string) {
        if (license != null && (this.license == null || this.license.length == 0)) {
            this.loggingService.debug("clientService::onApplicationCopied, setting license to ", license);
            this.license = license || '';
        }
    }

    // Получаем ключ api
    public setApiKey(apiKey: string): void {
        this.loggingService.debug("clientService::setApiKey", apiKey);
        this._apiKey = apiKey;
    }

    // поручение applicationId из парметров route или href
    private getUrlApplicationId(): string | null {
        const applicationIdFromRoute = this.route.snapshot.queryParamMap.get('applicationId');

        if (applicationIdFromRoute != null)
            return applicationIdFromRoute;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const applicationIdFromUrl = urlParams.get('applicationId');

        if (applicationIdFromUrl)
            return applicationIdFromUrl;

        return null;
    }

    // поручение applicationId из парметров route или href
    private getUrlClientId(): string | null {
        const clientIdFromRoute = this.route.snapshot.queryParamMap.get('clientId');

        if (clientIdFromRoute != null)
            return clientIdFromRoute;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const clientIdFromUrl = urlParams.get('clientId');

        if (clientIdFromUrl)
            return clientIdFromUrl;

        return null;
    }

    public setLicense(value: string) {
        this.loggingService.debug("clientService::setLicense, license:", value);
        this.license = value;
    }

    // Сохраняем данные клиента из запроса GetCache
    public setClient(client: OsagoClient | null): void {
        // client already generated with arm policy
        if (this._isArmPolicy == true) {
            return;
        }

        this.loggingService.debug("clientService::setClient, client:", client);

        if (client != null) {
            try {
                if (typeof client === 'string') {
                    this._client = JSON.parse(client);
                } else {
                    this._client = client;
                }

                var applicationId = this.getUrlApplicationId();

                if (applicationId != null) {
                    this.loggingService.debug('clientService::setClient, got applicationId from url:' + applicationId);

                    // Фильтруем массив данных формы осаго из запроса GetCache и выбираем последний заполненный по applicationId
                    const currentApplication = client?.applications
                        .filter((app: LicenseApplication) => app.applicationId === applicationId);

                    this.loggingService.debug(
                        'clientService::setClient, read queryParams, currentApplication:',
                        currentApplication
                    );

                    if (currentApplication && currentApplication.length) {
                        const license = currentApplication[currentApplication.length - 1].license;
                        const applicationId = currentApplication[currentApplication.length - 1].applicationId;
                        this.license = license;

                        this._client?.applications.push({
                            applicationId,
                            license
                        });
                    }
                };

                this.osagoPolicies.next(client?.osagoPolicies);
                this.osagoApplications.next(this._client?.applications);
            } catch { }
        } else {
            // Создаем нового клиента
            this._client = OsagoClient.create();
            this.osagoPolicies.next(this._client?.osagoPolicies);
            this.osagoApplications.next(this._client?.applications);
        }
    }

    public addApplication(license: string, applicationId: string | null): void {
        if (license != null && license != undefined) {
            this.license = license;

            this._client?.applications?.push({
                applicationId: applicationId || '',
                license: license
            });
            // Обновляем данные клиента в кэше
            this.updateCache();
        }
    }

    // Получаем данные полиса клиента по номеру авто
    public getCurrentPolicy(): LicenseOsago | null {
        if (this._client === null) {
            this.loggingService.debug("clientService::getCurrentPolicy, returning null");
            return null;
        }
        // Найти данные авто в текущем списке клиента
        const policies = this._client.osagoPolicies?.filter(x => x.license === this.license);

        // const osago = this._client.osagoPolicies[index];
        //
        // if (osago?.osago?.policyParameters?.policyStartDate
        //     && this.isDateBeforeToday(new Date(osago?.osago?.policyParameters?.policyStartDate))) {
        //     osago.osago.policyParameters.policyStartDate = this.modifyPolicyDate(osago?.osago?.policyParameters?.policyStartDate);
        // }

        const result = policies?.length > 0
            ? policies[policies.length - 1]
            : null;

        this.loggingService.debug("clientService::getCurrentPolicy, returning", result);
        return result;
    }

    // Меняем дату старта полиса, если дата, которая пришла, раньше текущей даты
    public modifyPolicyDate(policyDate: string): string {
        const recommendDate = moment().add(4, 'day').format('DD.MM.YYYY');
        return this.isDateBeforeToday(new Date(policyDate)) ? policyDate : recommendDate;
    }

    public adjustPolicyDate(policyDate: string): string {
        var date = this.isDateBeforeToday(new Date(policyDate))
            ? moment().add(4, 'day')
            : moment(policyDate);

        return date.format('DD.MM.YYYY');
    }

    private isDateBeforeToday(date: Date): boolean {
        return new Date(date.toDateString()) <= new Date(new Date().toDateString());
    }

    // Получить текущие данные осаго
    public getCurrentApplication(): OsagoApplication | null {
        const policy = this.getCurrentPolicy();
        return policy !== null
            ? policy.osago
            : null;
    }

    // Обновить текущие данные осаго
    public updateCurrentApplication(osago: OsagoApplication): void {
        const policy = this.getCurrentPolicy();

        // Если данные полиса у клиента не найдены, добавляем их
        if (policy == null) {
            this._client.osagoPolicies?.push({
                    license: this.license,
                    osago
                });
        } else {
            policy.osago = osago;
        }
        const newPolicy = JSON.stringify(policy);

        // Если данные полиса существуют, обновляем кэш
        if (this._policy !== newPolicy && this.license != null && this.license != undefined) {
            this._policy = newPolicy;
            this.updateCache();
        }
    }

    // Обновляем данные клиента в кэше
    public updateCache(): void {
        if (this._isArmPolicy) {
            return;
        }

        const request = {
            apiKey: this._apiKey,
            clientId: this._clientId,
            partnerClientId: this.getPartnerClientId(),
            data: JSON.stringify(this._client)
        };
        this.subscription.add(
            // Запрос на запись данных клиента в кэш
            this.postLocal('client/UpdateCache', request)
                .pipe(take(1))
                .subscribe());
    }

    // Генерируем новый ID клиента
    protected generateGuid(): string {
        return uuidv4();
    }
}
