<section class="denied">
    <h1 class="visually-hidden">ОСАГО онлайн</h1>

    <p class="denied__title">
        К сожалению, мы не можем рассчитать
        стоимость полиса для указанных Вами параметров
    </p>

    <p class="denied__sub-title">
        Есть несколько вариантов, почему такое возможно:
    </p>

    <p class="denied__text">
        Вы ввели очень редкую или непопулярную у страховых компаний комбинацию
        автомобиля, стажа, возраста и т.д.
        В итоге ни одна из компаний не готова в
        автоматическом режиме сделать калькуляцию.
    </p>

    <p class="denied__text">
        В базе данных одной или нескольких компаний происходит обновление
        тарифов, в результате чего расчёт временно невозможен.
    </p>
</section>
