export class routes {
    public static confirmation = 'info/confirmation';
    public static contacts = 'info/contacts';
    public static diagnostic = 'info/diagnostic';
    public static documents = 'info/documents';
    public static drivers = 'info/drivers';
    public static error = '/error';
    public static fail = '/fail';
    public static license = '/license';
    public static model = 'info';
    public static insurer = 'info/insurer';
    public static offers = '/info/offers';
    public static owner = 'info/owner';
    public static payment = 'info/payment';
    public static policy = 'info/policy';
    public static price = 'info/price';
    public static result = '/result';
    public static success = '/success';
    public static vehicle = 'info';
    public static vendors = 'info/car';
    public static ordering = 'info/ordering';
    public static confirm = 'info/confirm';
    public static pay = 'info/pay';
    public static paymentLinkGenerated = '/paymentlink';
}
