<div class="step2" *ngIf="isLoading">
    <div class="widget__wrapper">
        <div class="widget__header">
            <div class="widget__header-left">
                <h2>Получаем данные</h2><span>Это может занять несколько секунд</span>
            </div>
            <div class="widget__header-right">
            </div>
        </div>
        <div class="widget__loader active"></div>
    </div>
</div>

<div class="scroll-target" id="drivers-anchor">
    <div *ngIf="!isLoading" class="step3" [ngClass]="{ 'widget__edit': edit }">
        <form
            class="drivers__form"
            id="drivers"
            [formGroup]="driversForm"
            (ngSubmit)="onSubmit()"
        >

            <div  class="widget__wrapper">
                <div class="widget__header">
                    <div class="widget__header-title">
                        <div class="widget__header-title-text">Водители
                        </div>
                        <app-checkbox
                            fieldId="drivers-unlimited"
                            fieldName="Без ограничений"
                            [fieldControl]="unlimited"
                            (changeCheckbox)="onDriversLimitationChanged($event)"
                        ></app-checkbox>
                    </div>
                    <!--            <div class="widget__mobInfo" *ngIf="drivers.length > 1 && !driversUnlimited">-->
                    <!--                <div class="widget__mobInfo-item" >-->
                    <!--                    <p>КБМ: </p><span>{{ kbmRate }}</span>-->
                    <!--                </div>-->
                    <!--                <div class="widget__icon callCoefficients" (click)="showPopup()"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#979797"/><path d="M8 5.33301C8.55228 5.33301 9 4.88529 9 4.33301C9 3.78072 8.55228 3.33301 8 3.33301C7.44772 3.33301 7 3.78072 7 4.33301C7 4.88529 7.44772 5.33301 8 5.33301Z" fill="#979797"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 6.66699C8.36884 6.66699 8.66732 6.96547 8.66732 7.33366L8.66732 12.0003C8.66732 12.3685 8.36884 12.667 8.00065 12.667C7.63246 12.667 7.33398 12.3685 7.33398 12.0003L7.33398 7.33366C7.33398 6.96547 7.63246 6.66699 8.00065 6.66699Z" fill="#979797"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg></div>-->
                    <!--            </div>-->
                </div>

                <div
                    class="widget__tip"
                    [class.hidden]="!driversUnlimited">
                    Управлять ТС может любой водитель. Полис без ограничений обычно стоит значительно дороже полиса с ограниченным списком водителей, допущенных
                    к управлению
                </div>

                <div class="alert-container" [class.hidden]="driversUnlimited">
                    <app-alert>
                        <p>По ФИО и номеру прав страховые компании проверяют историю вашего вождения,
                            чтобы применить скидку на страховой полис (КБМ). Указывайте данные корректно,
                            чтобы получить точную цену</p>
                    </app-alert>
                </div>

                <div
                    class="widget__body"
                    [class.hidden]="driversUnlimited">
                    <div
                        class="widget__driver"
                        *ngFor="let driver of drivers; let i=index;"
                        [class.show-driver]="driverIndex !== -1 && driverIndex !== i">
                        <app-driver
                            [options]="{ form: driver, driver: osagoDrivers[i] }"
                            [index]="i + 1"
                            [allowDelete]="drivers.length > 1"
                            (deleted)="onDriverDeleted(i)"
                            (changed)="onDriverChanged(i)"
                            (experienceChanged)="onDriverExperienceChanged($event, i)"
                        ></app-driver>
                    </div>
                </div>

                <div class="widget__add">
                    <div class="buttonMob" *ngIf="!driversUnlimited">
                        <app-button
                            modifier="regular-button"
                            buttonType="button"
                            (clicked)="onAddDriverClick()">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 11H13V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11Z" fill="var(--color-basic-light)"/></svg>
                            <span>Добавить водителя</span>
                        </app-button>
                    </div>
                    <div class="buttonMob">
                        <app-button
                            modifier="blue-button"
                            buttonType="submit">
                            {{ submitCaption }}
                        </app-button>
                    </div>

                    <p
                        *ngIf="isFormError == true"
                        class="error-content">
                        Проверьте правильность введённых данных
                    </p>
                </div>
            </div>

        </form>
    </div>
</div>

<app-popup-kbm
	*ngIf="popupOpen"
	(closed)="onPopupClosed()">
</app-popup-kbm>
