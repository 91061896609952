<div class="price-list">
    <div class="price-item">
        <span>Мин</span>
        {{ !minimalCost ? '----' : minimalCost | currencyRU }}
        <div class="podeli-price"><img src="/assets/images/podeli-circle.svg" alt="Podeli"> {{splittedMinimal | currencyRU}} х 4 </div>
    </div>
    <div class="price-item">
        <span>Средн</span>
        {{ !middleCost ? '----' : middleCost | currencyRU }}
        <div class="podeli-price"><img src="/assets/images/podeli-circle.svg" alt="Podeli"> {{splittedMiddle | currencyRU}} х 4 </div>
    </div>
    <div class="price-item">
        <span>Макс</span>
        {{ !maximalCost ? '----' : maximalCost | currencyRU }}
        <div class="podeli-price"><img src="/assets/images/podeli-circle.svg" alt="Podeli"> {{splittedMax | currencyRU}} х 4 </div>
    </div>
</div>
