import { Converter } from "../../../../../../common-lib/src/lib/utils/converters";
import * as moment from 'moment-mini';

export class Offer {
    constructor(
        public offerId: string,
        public preapprovePremiumAmount: number,
        public offerFranchisePreapprove: number,
        public productName: string,
        public insurerName: string,
        public insurerType: string,
        public productDescriptions: string[],
        public useUpsales: boolean = false,
        public status: string,
        public premiumAmount = 0,
        public policyStartDateApproved = '',
        public term = '12 месяцев',
        public startDateDescription = '',
        public promocodes: any = [],
        public upsales: any = [],
        public hasDraft: boolean = false,
        public isProlongation: boolean = false,
        public policyExpireDate: string = '',
        public installmentFirstPay: number = 0,
    ) { }

    public static create(source: Offer | null = null, term: string = '12 Месяцев'): Offer {
        if (source != null)
            return this.fromOffer(source, term);

        return new Offer('', 0, 0, '', '', '', [], false, '', 0, '', term, '', [], [], false, false, '', 0);
    }

    public static sort(a: Offer, b: Offer): number {
        if (a.isApproved && !b.isApproved)
            return -1;

        if (b.isApproved && !a.isApproved)
            return 1;

        let premiumAmountA = a.premiumAmount || 100000000;
        let premiumAmountB = b.premiumAmount || 100000000;

        if (premiumAmountA == 0)
            premiumAmountA = 100000000;

        if (premiumAmountB == 0)
            premiumAmountB = 100000000;

        return premiumAmountA - premiumAmountB;
    }

    private static fromOffer(source: Offer, term: string): Offer {
        return new Offer(
            source.offerId,
            source.preapprovePremiumAmount,
            source.offerFranchisePreapprove,
            source.productName,
            this.getInsurerName(source.insurerName, source.insurerType),
            source.insurerType,
            source.productDescriptions,
            source.useUpsales,
            source.status,
            source.premiumAmount,
            Converter.toDateRu(source.policyStartDateApproved || ''),
            term,
            this.getStartDateDescription(Converter.toDateRu(source.policyStartDateApproved || '')),
            source.promocodes,
            source.upsales,
            source.hasDraft,
            source.isProlongation,
            source.policyExpireDate,
            source.installmentFirstPay
        );
    }

    private static getInsurerName(insurerName: string, insurerType: string) {
        switch (insurerType) {
            case 'Alfa':
                return 'АльфаСтрахование';
            case 'Sogaz':
                return 'Согаз';
            case 'Ingos':
                return 'Ингосстрах';
            case 'Absolut':
                return 'Абсолют Страхование';
            case 'Rosgosstrah':
                return 'Росгосстрах';
            case 'Tinkoff':
                return 'Тинькофф';
            case 'VSK':
                return 'ВСК';
            case 'Renessans':
                return 'Ренессанс';
            case 'Soglasie':
                return 'Согласие';
            case 'MAKS':
                return 'МАКС';
            case 'Sovkombank':
                return 'Совком';
            case 'Verna':
                return 'Верна';
            case 'Intouch':
                return 'Intouch';
            case 'Zetta':
                return 'Зетта Страхование';
            case 'EuroIns':
                return 'Евроинс';
            case 'Gaide':
                return 'Гайде';
            case 'EnergoGarant':
                return 'Энергогарант';
            case 'Ugoria':
                return 'Югория';
            case 'Asko':
                return 'Аско';
            case 'OSK':
                return 'ОСК';
            case 'Sber':
                return 'СберСтрахование';
            case 'Gelios':
                return 'Гелиос';
            case 'SpasskieVorota':
                return 'Спасские ворота';
        }

        return insurerName;
    }

    private static getStartDateDescription(value: string | null): string {
        if (value == null || value == undefined)
            return "";

        const date = moment(value, "DD.MM.YYYY", true);
        if (!date.isValid())
            return "";

        const days = Math.abs(date.diff(moment(), "days"));
        return `${ value } (через ${ days + 1 } дн)`;
    }

    public get isApproved() {
        return this.status == "Approved" || this.status == "Paid";
    }

    public get isComplete() {
        return this.status != "Requested";
    }

    public get rate(): number {
        let result = 5;

        //Insapp.Models.Core.Enum.InsurerType
        switch (this.insurerType) {
            case "Sber":
                result = 4.5;
                break;
            case "VSK":
                result = 5;
                break;
            case "Alfa":
                result = 5;
                break;
            case "Verna":
                result = 4.2;
                break;
            case "Zetta":
                result = 4.5;
                break;
            case "Ingosstrah":
                result = 5;
                break;
            case "MAKS":
                result = 4;
                break;
            case "Gaide":
                result = 3.8;
                break;
            case "Renessans":
                result = 4.5;
                break;
            case "Rosgosstrah":
                result = 5;
                break;
            case "Sovkombank":
                result = 3.7;
                break;
            case "Soglasie":
                result = 4;
                break;
            case "EnergoGarant":
                result = 3.5;
                break;
            case "Ugoria":
                result = 4;
                break;
            case "PolisGarant":
                result = 4.2;
                break;
            case "Tinkoff":
                result = 4;
                break;
            case "RESO":
                result = 4.2;
                break;
            case "Sogaz":
                result = 5;
                break;
            case "Absolut":
                result = 4.5;
                break;
            case "Gelios":
                result = 4;
                break;
            case "SpasskieVorota":
                result = 4;
                break;
        }

        return result;
    }

    public get basePrice(): number {
        let result = 3417;

        //Insapp.Models.Core.Enum.InsurerType
        switch (this.insurerType) {
            case "VSK":
                result = 3953;
                break;
            case "Alfa":
                result = 4321;
                break;
            case "Verna":
                result = 3840;
                break;
            case "Zetta":
                result = 3953;
                break;
            case "Ingosstrah":
                result = 4075;
                break;
            case "MAKS":
                result = 4668;
                break;
            case "Gaide":
                result = 4100;
                break;
            case "Renessans":
                result = 4451;
                break;
            case "Rosgosstrah":
                result = 3632;
                break;
            case "Sovkombank":
                result = 3732;
                break;
            case "Soglasie":
                result = 4533;
                break;
            case "EnergoGarant":
                result = 4139;
                break;
            case "Ugoria":
                result = 4523;
                break;
            case "Tinkoff":
                result = 3884;
                break;
            case "RESO":
                result = 4730;
                break;
            case "Sogaz":
                result = 3372;
                break;
            case "Intouch":
                result = 3953;
                break;
            case "Absolut":
                result = 3953;
                break;
            case "EuroIns":
                result = 3953;
                break;
            case "Asko":
                result = 4851;
                break;
            case "OSK":
                result = 5080;
                break;
            case "Sber":
                result = 3417;
                break;
            case "Gelios":
                result = 3417;
                break;
        }

        return result;
    }

    public get logo(): string | null {
        return this.insurerType != null
            ? '/assets/images/' + this.insurerType + "-logo.svg"
            : null;
    }

    public get altText(): string {
        let result: string = "";

        //Insapp.Models.Core.Enum.InsurerType
        switch (this.insurerType) {
            case "VSK":
                result = "ВСК Страховой Дом";
                break;
            case "Alfa":
                result = "Альфа-Страхование";
                break;
            case "Verna":
                result = "Верна";
                break;
            case "Zetta":
                result = "Зетта Страхование.svg";
                break;
            case "Ingosstrah":
                result = "Ингосстрах";
                break;
            case "MAKS":
                result = "МАКС";
                break;
            case "Gaide":
                result = "Гайде";
                break;
            case "Renessans":
                result = "Ренессанс Страхование";
                break;
            case "Rosgosstrah":
                result = "Росгосстрах";
                break;
            case "Sovkombank":
                result = "Совкомбанк Страхование";
                break;
            case "Soglasie":
                result = "Согласие.svg";
                break;
            case "EnergoGarant":
                result = "Энергогарант";
                break;
            case "Ugoria":
                result = "Югория";
                break;
            case "PolisGarant":
                result = "Полис Гарант";
                break;
            case "Tinkoff":
                result = "Тинькофф Страхование";
                break;
            case "RESO":
                result = "РЕСО-Гарантия";
                break;
            case "Sogaz":
                result = "СОГАЗ";
                break;
            case "Asko":
                result = "АСКО";
                break;
            case "Absolut":
                result = "Абсолют";
                break;
            case "EuroIns":
                result = "ЕвроИнс";
                break;
            case "Gelios":
                result = "Гелиос";
                break;
            case "Intouch":
                result = "In Touch";
                break;
            case "Sber":
                result = "СБЕР";
                break;
            case "SpasskieVorota":
                result = "СПАССКИЕ ВОРОТА";
                break;
        }

        return result;
    }
}
