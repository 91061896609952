import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select';
import { FormsService } from '../../../services/forms.service';
import { BaseFormComponent } from '../base/base-form.component';

@Component({
    selector: 'app-select-autocomplete',
    templateUrl: './select-autocomplete.component.html'
})

export class SelectAutoCompleteComponent extends BaseFormComponent {
    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent | null = null;

    readonly: boolean = true;
    items = [];
    loading: boolean = false;

    @Input() fieldId = '';
    @Input() fieldName = '';

    @Input()
    set options(val: any) {
        this.items = val || [];
    }

    @Input()
    set isLoading(val: boolean | null) {
        this.loading = val || false;
    }

    @Input()
    set isReadonly(val: boolean | null) {
        this.readonly = val || false;
    }

    @Output() changed = new EventEmitter<any>();

    constructor(private config: NgSelectConfig, formService: FormsService) {
        super(formService);
        this.config.notFoundText = 'Данные не найдены';
        this.config.clearAllText = 'Очистить';
        this.config.loadingText = 'Загрузка...';
    }

    onChange(event: any) {
        this.changed.emit(event);
        this._isFilled = true;
    }

    clear() {
        if (this.ngSelectComponent) {
            this.ngSelectComponent.clearModel();
            this.ngSelectComponent.handleClearClick();
        }

        this._isFilled = false;
    }

    enable() {
        this.isReadonly = false;
    }

    open() {
        if (this.isReadonly)
            this.isReadonly = false;

        if (this.ngSelectComponent)
            this.ngSelectComponent.open();
    }

    select(item: any) {
        if (this.ngSelectComponent) {
            const option = this.ngSelectComponent.itemsList.findByLabel(item.value);
            if (option != null) {
                this.ngSelectComponent.select(option);
                this._isFilled = true;
            }
        }
    }
}
