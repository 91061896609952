import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OsagoRoutingModule } from './osago-routing.module';
import { environment } from '../../../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import {
    CheckboxComponent,
    ConfirmationComponent,
    ContactsComponent,
    CopyrightComponent,
    DocumentsComponent,
    DriverComponent,
    DriversComponent,
    InputComponent,
    InputDateComponent,
    InputNumericComponent,
    KbmPopupComponent,
    OffersComponent,
    OsagoRouterComponent,
    OwnerComponent,
    PaymentComponent,
    PaymentLinkComponent,
    PreOfferComponent,
    PriceComponent,
    PricePopupComponent,
    PriceSummaryComponent,
    SelectAutoCompleteComponent,
    SelectComponent,
    VendorsComponent,
    StepperComponent
} from './components';

import {
    FormsService,
    OsagoService,
    OsagoMockService,
    PriceCalculatorService,
    ScriptService,
    ScriptMockService, } from './services';

import { GlobalErrorHandler } from '../../services';
import { HttpErrorInterceptor } from '../../services';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from '../shared/shared.module';
import { OrderingComponent } from './components/ordering/ordering.component';
import { LicenseModalComponent } from './components/modals/license-modal/license-modal.component';
import { LicenseComponent, LicenseNumberComponent, LicenseRegionComponent } from './components/license';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { PayComponent } from './components/pay/pay.component';
import { DatepickerModule } from 'ng2-datepicker';
import { SwiperModule } from 'swiper/angular';
import { CrossComponent } from './components/payment/cross/cross.component';
import { PodeliPriceComponent } from './components/vendors/podeli-price/podeli-price.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        TextMaskModule,
        NgSelectModule,
        SharedModule,
        OsagoRoutingModule,
        DatepickerModule,
        SwiperModule,
    ],
    declarations: [
        CheckboxComponent,
        ConfirmationComponent,
        ContactsComponent,
        CopyrightComponent,
        DocumentsComponent,
        DriverComponent,
        DriversComponent,
        InputComponent,
        InputDateComponent,
        InputNumericComponent,
        KbmPopupComponent,
        PreOfferComponent,
        PriceComponent,
        PriceSummaryComponent,
        PricePopupComponent,
        OffersComponent,
        OsagoRouterComponent,
        OwnerComponent,
        PaymentComponent,
        SelectAutoCompleteComponent,
        SelectComponent,
        VendorsComponent,
        StepperComponent,
        OrderingComponent,
        LicenseModalComponent,
        LicenseComponent,
        LicenseNumberComponent,
        LicenseRegionComponent,
        ConfirmComponent,
        PayComponent,
        PaymentLinkComponent,
        CrossComponent,
        PodeliPriceComponent
    ],
    exports: [
        DriversComponent,
        InputComponent,
        InputNumericComponent,
        SelectAutoCompleteComponent,
        StepperComponent,
        OsagoRouterComponent,
        LicenseComponent,
        LicenseNumberComponent,
        LicenseRegionComponent
    ],
    providers: [
        FormsService,
        environment.mock
            ? {provide: ScriptService, useClass: ScriptMockService}
            : ScriptService,
        environment.mock
            ? {provide: OsagoService, useClass: OsagoMockService}
            : OsagoService,
        PriceCalculatorService,
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            // interceptor for HTTP errors
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true // multiple interceptors are possible
        }
    ]
})
export class OsagoModule {
    constructor() { }
}
