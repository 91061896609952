export const environment = {
    production: true,
    mock: false,
    routerLogging: false,
	hostUrl: "/",
	dadataKey: "6e1e70327c0437a3d0d34fe74fd68a767c32d0d4",
    logging: {
        debug: true
    },
    armHost: 'test-arm.insapp.ru',
    alfaLanding: 'https://test-osago.ab.insapp.ru/'
};
