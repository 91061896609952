export class PriceSummary {
	constructor(
		public horsePower = '',
		public horsePowerRate = '',
		public insurerBase = 0,
		public insurerLogo = '',
		public kbmRate = '',
		public kvsRate = '',
		public kvsRateDescription = '',
		public price = 0,
		public term = '12 месяцев',
		public termRate = '1',
		public territory = '',
		public territoryRate = '',
	) { }
}