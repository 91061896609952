import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent, InputDateComponent } from '../core';
import * as moment from 'moment-mini';
import { Subscription } from 'rxjs/internal/Subscription';

// Окружение
import { environment } from '../../../../../environments/environment';

// Модели
import { OsagoOwner, OsagoOwnerViewModel, OsagoPolicy, PreviousPolicy } from '../../models';
import { DictionaryItem } from '../../../../../../../common-lib/src/lib/models';

// Хелперы
import { FioHelper, GenderHelper, OsagoValidators, TextMaskHelper } from '../../helpers';

// Сервисы
import { FormsService, OsagoService, PriceCalculatorService, ScriptService } from '../../services';
import { ApplicationService } from '../../../../services';
import { Converter } from '../../../../../../../common-lib/src/lib/utils/converters';
import { ClientService } from '../../../../services/client.service';
import { take } from 'rxjs/operators';
import { WidgetStatuses } from '../../../shared/widgetStatuses.enum';

// using jQuery suggestions, attached in Index.chtml
declare var $: any;

@Component({
    selector: 'app-owner',
    templateUrl: './owner.component.html',
    styleUrls: ['./owner.component.scss']
})
export class OwnerComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('driverfio') private fioComponent: BaseFormComponent | null = null;
    @ViewChild('driverbirthdate') private birthdateComponent: InputDateComponent | null = null;
    @ViewChild('driverpassportdate') private passportDateComponent: InputDateComponent | null = null;
    @ViewChild('insurerdriverfio') private insurerFioComponent: BaseFormComponent | null = null;
    @ViewChild('insurerbirthdate') private insurerBirthdateComponent: InputDateComponent | null = null;
    @ViewChild('insurerpassportdate') private insurerPassportDateComponent: InputDateComponent | null = null;
    @ViewChild('fmscomponent') private passportIssuerComponent: BaseFormComponent | null = null;
    @ViewChild('insurerfmscomponent') private insurerPassportIssuerComponent: BaseFormComponent | null = null;
    // Элемент и иконкой для контрола Phone
    @ViewChild('phonePopup') private phonePopup: ElementRef | null = null;
    // Элемент и иконкой для контрола Email
    @ViewChild('emailPopup') private emailPopup: ElementRef | null = null;
    @Output() close = new EventEmitter<boolean>();
    @Input() set editMode(value: boolean) {
        this.edit = value;
    }
    // Состояние формы для собственника
    @Input() set ownerMode(value: boolean) {
        this.editOwner = value;
    }
    // Состояние формы для страхователя
    @Input() set insurerMode(value: boolean) {
        this.editInsurer = value;
    }
    // Состояние формы для контактов
    @Input() set contactsMode(value: boolean) {
        this.editContacts = value;
    }

    // Попап с подсказкой для контрола Phone
    public phonePopupOpen = false;

    // Попап с подсказкой для контрола Email
    public emailPopupOpen = false;

    // Индикатор загрузки
    public isLoading: boolean = true;

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event: any): void {
        if (!this.phonePopup?.nativeElement?.contains(event.target)) {
            this.phonePopupOpen = false;
        }
        if (!this.emailPopup?.nativeElement?.contains(event.target)) {
            this.emailPopupOpen = false;
        }
    }

    // Состояние редактирования страницы
    public edit = false;
    // Состояние редактирования блока Собственник
    public editOwner = false;
    // Состояние редактирования блока Страхователь
    public editInsurer = false;
    // Состояние редактирования блока Контакты
    public editContacts = false;

    public drivers!: DictionaryItem[];
    public ownerForm: FormGroup = this.formBuilder.group({
        driver: new FormControl(null),
        // fio: new FormControl(null),
        firstName: new FormControl(null),
        lastName: new FormControl(null),
        middleName: new FormControl(null),
        // 'fio-dadata': new FormControl(null),
        firstNameDadata: new FormControl(null),
        lastNameDadata: new FormControl(null),
        middleNameDadata: new FormControl(null),
        gender: new FormControl(null),
        birthDate: new FormControl(null),
        passport: new FormControl(null),
        'passport-date': new FormControl(null),
        'passport-issuer': new FormControl(null),
        'passport-issuer-code': new FormControl(null),
        'passport-issuer-code-dadata': new FormControl(null),
        address: new FormControl(null),
        'address-dadata': new FormControl(null),
        'is-insurer': new FormControl(null),
        'insurer-driver': new FormControl(null),
        // 'insurer-fio': new FormControl(null),
        insurerFirstName: new FormControl(null),
        insurerLastName: new FormControl(null),
        insurerMiddleName: new FormControl(null),
        // 'insurer-fio-dadata': new FormControl(null),
        insurerFirstNameDadata: new FormControl(null),
        insurerLastNameDadata: new FormControl(null),
        insurerMiddleNameDadata: new FormControl(null),
        'insurer-gender': new FormControl(null),
        'insurer-birthDate': new FormControl(null),
        'insurer-passport': new FormControl(null),
        'insurer-passport-date': new FormControl(null),
        'insurer-address': new FormControl(null),
        'insurer-address-dadata': new FormControl(null),
        'insurer-passport-issuer': new FormControl(null),
        'insurer-passport-issuer-code': new FormControl(null),
        'insurer-passport-issuer-code-dadata': new FormControl(null),
        phone: new FormControl('+7'),
        email: new FormControl(null),
        emailDadata: new FormControl(null),
    });
    public ownerFio = '';
    public ownerFirstName = '';
    public ownerLastName = '';
    public ownerMiddleName = '';
    public maxDate = moment().format('DD.MM.YYYY');
    public masks = {
        passportIssuer(): any[] {
            let index = 0;
            const result = [];
            while (index < 300) {
                result.push(/^[0-9а-яА-ЯеЁ. -]$/);
                index++;
            }

            return result;
        },
        passport: [/\d/, /\d/,  ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        passportIssuerCode: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
    };
    public phoneMask = ['+', '7', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

    public driverFio = '';
    public isPopupOpen = false;
    public isFormError = false;
    public ownerIsInsurer = true;
    public driversUnlimited = false;
    public isHover = false;

    private owner: {
        $address: any,
        // $fio: any,
        $firstName: any,
        $lastName: any,
        $middleName: any,
        // $passportCode: any,
        driverChosen: boolean,
        hasNoAppartment: boolean,
        selectedDriver: number | null,
        dadataRestored: boolean
    } = {
        $address: null,
        // $fio: null,
        $firstName: null,
        $lastName: null,
        $middleName: null,
        // $passportCode: null,
        driverChosen: false,
        hasNoAppartment: true,
        selectedDriver: null,
        dadataRestored: false
    };

    private insurer: {
        $address: any,
        // $fio: any,
        $insurerFirstName: any,
        $insurerLastName: any,
        $insurerMiddleName: any,
        // $passportCode: any,
        driverChosen: boolean,
        hasNoAppartment: boolean,
        selectedDriver: number | null,
        dadataRestored: boolean
    } = {
        $address: null,
        // $fio: null,
        $insurerFirstName: null,
        $insurerLastName: null,
        $insurerMiddleName: null,
        // $passportCode: null,
        driverChosen: false,
        hasNoAppartment: true,
        selectedDriver: null,
        dadataRestored: false
    };

    private _validatorsSet = false;
    private isSubmit = false;
    private needCheckValidity = false;
    private _subscription = new Subscription();
    private _interval = 0;
    private $email: any = null;

    // Условие сервиса
    private agreementChecked = true;

    constructor(
        private formBuilder: FormBuilder,
        private appService: ApplicationService,
        private formsService: FormsService,
        private osagoService: OsagoService,
        private priceService: PriceCalculatorService,
        private scriptService: ScriptService,
    ) {
        scriptService.load();
        this.ownerForm = this.formBuilder.group({});
        this.osagoService.getApplicationData();
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        // this.initForm();
        this._subscription.add(
            this.appService.onLoadApp$.subscribe((value) => {
                this.isLoading = value;
                if (!value) {
                    this.osagoService.getApplicationData();

                    if (this.owner.selectedDriver != null) {
                        this.selectDriver(this.owner.selectedDriver, true);
                    } else {
                        console.log('restore');
                        this.restoreDadata();
                    }

                    if (this.insurer.selectedDriver != null) {
                        this.selectInsurerDriver(this.insurer.selectedDriver, true);
                    } else {
                        this.restoreInsurerDadata();
                    }

                    this.appService.scrollToElement('owner-anchor');

                    this.fillForm();
                    this.formSubscriptions();

                }
            })
        );



        this._interval = window.setInterval(() => {
            if (this.needCheckValidity) {
                this.needCheckValidity = false;
                this.isFormError = this.isSubmit && !this.formsService.isFormValid(this.ownerForm, false, false, false);
            }
        }, 100);
    }

    public ngAfterViewInit(): void {
        if (!this.scriptService.ready) {
            window.setTimeout(() => this.ngAfterViewInit(), 500);
            return;
        }
        // this.initDadata();

        this.osagoService.onYandexReachGoal('OWNER_GOAL');
        this.osagoService.setWidgetStatus(WidgetStatuses.OwnerScreen);

        this.initDadata();
        this.restoreDadata();
        this.restoreInsurerDadata();
    }

    public initForm(): void {
        this.ownerForm = this.formBuilder.group({
            driver: new FormControl(null),
            // fio: new FormControl(null),
            firstName: new FormControl(null),
            lastName: new FormControl(null),
            middleName: new FormControl(''),
            // 'fio-dadata': new FormControl(null),
            firstNameDadata: new FormControl(null),
            lastNameDadata: new FormControl(null),
            middleNameDadata: new FormControl(null),
            gender: new FormControl(null),
            birthDate: new FormControl(null),
            passport: new FormControl(null),
            'passport-date': new FormControl(null),
            'passport-issuer': new FormControl(null),
            'passport-issuer-code': new FormControl(null),
            'passport-issuer-code-dadata': new FormControl(null),
            address: new FormControl(this.appService.clientFromAlfaBank?.address),
            'address-dadata': new FormControl(null),
            'is-insurer': new FormControl(null),
            'insurer-driver': new FormControl(null),
            // 'insurer-fio': new FormControl(null),
            insurerFirstName: new FormControl(null),
            insurerLastName: new FormControl(null),
            insurerMiddleName: new FormControl(''),
            // 'insurer-fio-dadata': new FormControl(null),
            insurerFirstNameDadata: new FormControl(null),
            insurerLastNameDadata: new FormControl(null),
            insurerMiddleNameDadata: new FormControl(null),
            'insurer-gender': new FormControl(null),
            'insurer-birthDate': new FormControl(null),
            'insurer-passport': new FormControl(null),
            'insurer-passport-date': new FormControl(null),
            'insurer-address': new FormControl(null),
            'insurer-address-dadata': new FormControl(null),
            'insurer-passport-issuer': new FormControl(null),
            'insurer-passport-issuer-code': new FormControl(null),
            'insurer-passport-issuer-code-dadata': new FormControl(null),
            phone: new FormControl('+7' + this.appService.clientFromAlfaBank?.phone),
            email: new FormControl(this.appService.clientFromAlfaBank?.email),
            emailDadata: new FormControl(null),
        });
    }

    private waitForDadata() {
        if (!this.scriptService.ready) {
            window.setTimeout(() => this.waitForDadata(), 500);
            return;
        }

        this.initDadata();
        this.restoreDadata();
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
        window.clearInterval(this._interval);
    }
    // --------------------------------------------------------------------------

    // fill in data passed in url
    private fillUserData(owner: OsagoOwnerViewModel) {
        var userData = (<any>window).osagoApplicationData?.userData;

        // no data passed
        if (typeof userData === "undefined" || userData == null)
            return;

        // parse and fill
        try {
            var user = JSON.parse(userData);

            if (user == null)
                return;

            var result = { owner: Object.assign({}, owner), email: null, phone: null};

            if (user.credential != null && owner != null) {
                if (user.credential.number != null && user.credential.series != null)
                    result.owner.passport = user.credential.series.replace(/ /g,"") + " " + user.credential.number.replace(/ /g,"");

                if (user.credential.issue_point != null)
                    result.owner.passportIssuer = user.credential.issue_point;

                if (user.credential.issue_point_code != null)
                    result.owner.passportIssuerCode = user.credential.issue_point_code;

                if (user.credential.issue_date != null) {
                    var date = moment(user.credential.issue_date);

                    if (date.isValid())
                        result.owner.passportDate = date.format("DD.MM.YYYY");
                }
            }

            if (user.email != null)
                result.email = user.email;

            user.phone = user.phone.indexOf('8') === 0 ? user.phone[0] = 7 : user.phone;
            if (user.phone != null && user.phone.length == 11)
                result.phone = <any>(`+7(${ user.phone.substr(1, 3) })${ user.phone.substr(4, 3) }-${ user.phone.substr(7, 2) }-${ user.phone.substr(9, 2) }`);

            return result;
        }
        catch (ex) { return null; }
    }

    public fillForm(): void {
        this.driversUnlimited = this.osagoService.driversUnlimited;
        this.drivers = this.osagoService.drivers.map(x => new DictionaryItem(x.id, FioHelper.toInitials(x.value) || ''));
        this.drivers.push(new DictionaryItem(this.drivers.length, 'Другой'));

        // Ошибка в консоле из-за определения this.owner.selectedDriver
        let ownerData = this.osagoService.getSavedOwner();
        this.owner.selectedDriver = ownerData.selectedDriver;
        const insurerData = this.osagoService.getSavedInsurer();
        this.insurer.selectedDriver = insurerData.selectedDriver;

        if (this.osagoService.owner != null) {
            this.ownerIsInsurer = this.osagoService.owner.ownerIsInsured === true;
        }

        let phone;
        let email = this.osagoService.insurer?.email || this.osagoService.owner?.email || this.appService.clientFromAlfaBank?.email || null;

        const maskedPhone = this.osagoService.insurer?.phone || this.osagoService.owner?.phone;

        if (maskedPhone != null && maskedPhone.length === 11) {
            maskedPhone.substring(1);
            phone = `+7(${ maskedPhone.substr(1, 3) })${ maskedPhone.substr(4, 3) }-${ maskedPhone.substr(7, 2) }-${ maskedPhone.substr(9, 2) }`;
        } else {
            phone = '+7' + this.appService.clientFromAlfaBank?.phone;
        }

        var data = this.fillUserData(ownerData);

        if (data != null && data.owner != null)
            ownerData = data.owner;

        if (data != null && data.email != null)
            email = data.email;

        if (data != null && data.phone != null) {
            phone = data.phone;
        }

        if (this.osagoService._insurerPhone && this.osagoService._insurerEmail) {
            phone = this.osagoService._insurerPhone;
            email = this.osagoService._insurerEmail;
        }
        if (email != null) {
            if (this.osagoService._application.insured?.email) {
                this.osagoService._application.insured.email = email;
            }
            this.osagoService._insurerEmail = email;
        }

        if (phone != null) {
            if (this.osagoService._application.insured?.phone) {
                this.osagoService._application.insured.phone = phone;
            }
            this.osagoService._insurerPhone = phone;
        }

        if (this.osagoService._insurerPhone) {
            const maskedPhoneFromService = phone;
            if (maskedPhoneFromService != null && maskedPhoneFromService.length === 11) {
                maskedPhoneFromService.substring(1);
                phone = `+7(${ maskedPhoneFromService.substr(1, 3) })${ maskedPhoneFromService.substr(4, 3) }-${ maskedPhoneFromService.substr(7, 2) }-${ maskedPhoneFromService.substr(9, 2) }`;
            }
        }

        const passportFromAlfaId = this.appService.clientFromAlfaBank?.licenseSeries + this.appService.clientFromAlfaBank?.licenseNumber;

        this.ownerForm = this.formBuilder.group({
            driver: new FormControl(ownerData.selectedDriver, null),
            // fio: new FormControl(ownerData.fio, null),
            firstName: new FormControl(ownerData.firstName ? ownerData.firstName : this.appService.clientFromAlfaBank?.driver?.firstName, [ OsagoValidators.firstName(() => this.owner.driverChosen) ]),
            lastName: new FormControl(ownerData.lastName ? ownerData.lastName : this.appService.clientFromAlfaBank?.driver?.lastName, [ OsagoValidators.lastName(() => this.owner.driverChosen) ]),
            middleName: new FormControl(ownerData.middleName && ownerData.middleName !== 'null' ? ownerData.middleName : this.appService.clientFromAlfaBank?.driver?.middleName, [ OsagoValidators.middleName(() => this.owner.driverChosen) ]),
            // 'fio-dadata': new FormControl(null, null),
            firstNameDadata: new FormControl(null, null),
            lastNameDadata: new FormControl(null, null),
            middleNameDadata: new FormControl(null, null),
            gender: new FormControl(null, null),
            birthDate: new FormControl(ownerData.birthDate ? ownerData.birthDate : this.appService.clientFromAlfaBank?.driver?.birthDate, null),
            passport: new FormControl(ownerData.passport ? ownerData.passport : passportFromAlfaId, null),
            'passport-date': new FormControl(ownerData.passportDate, null),
            'passport-issuer': new FormControl(ownerData.passportIssuer, null),
            'passport-issuer-code': new FormControl(ownerData.passportIssuerCode, null),
            'passport-issuer-code-dadata': new FormControl(null, null),
            address: new FormControl(ownerData.address ? ownerData.address : this.appService.clientFromAlfaBank?.address, null),
            'address-dadata': new FormControl(ownerData.addressDadata, null),
            'is-insurer': new FormControl(this.ownerIsInsurer, null),
            'insurer-driver': new FormControl(insurerData.selectedDriver, null),
            // 'insurer-fio': new FormControl(insurerData.fio, null),
            insurerFirstName: new FormControl(insurerData.firstName, [ OsagoValidators.firstName(() => this.ownerIsInsurer) ]),
            insurerLastName: new FormControl(insurerData.lastName, [ OsagoValidators.lastName(() => this.ownerIsInsurer) ]),
            insurerMiddleName: new FormControl(insurerData.middleName && insurerData.middleName !== 'null' ? insurerData.middleName : null, [ OsagoValidators.middleName(() => this.ownerIsInsurer) ]),
            // 'insurer-fio-dadata': new FormControl(null, null),
            insurerFirstNameDadata: new FormControl(null, null),
            insurerLastNameDadata: new FormControl(null, null),
            insurerMiddleNameDadata: new FormControl(null, null),
            'insurer-gender': new FormControl(null, null),
            'insurer-birthDate': new FormControl(insurerData.birthDate, null),
            'insurer-passport': new FormControl(insurerData.passport, null),
            'insurer-passport-date': new FormControl(insurerData.passportDate, null),
            'insurer-address': new FormControl(insurerData.address, null),
            'insurer-address-dadata': new FormControl(insurerData.addressDadata, null),
            'insurer-passport-issuer': new FormControl(insurerData.passportIssuer, null),
            'insurer-passport-issuer-code': new FormControl(insurerData.passportIssuerCode, null),
            'insurer-passport-issuer-code-dadata': new FormControl(null, null),
            phone: new FormControl(phone),
            email: new FormControl(email),
            emailDadata: new FormControl(null),
        });
    }

    private formSubscriptions(): void {
        this._subscription.add(
            this.driver?.valueChanges.subscribe((value: number | null) => this.selectDriver(value))
        );

        this._subscription.add(
            this.insurerDriver?.valueChanges.subscribe((value: number | null) => this.selectInsurerDriver(value))
        );

        this._subscription.add(
            this.isInsurer?.valueChanges.subscribe((value) => {
                if (value != null) {
                    this.ownerIsInsurer = value;
                }
            })
        );

        this._subscription.add(
            this.ownerForm?.valueChanges.subscribe(value => {
                this.selectedState();
                this.needCheckValidity = true;
            })
        );

        this._subscription.add(
            this.osagoService.driverDescription$
                .subscribe((value) => {
                    this.driverFio = value;
                })
        );
    }

    private restoreDadata(): void {
        // address
        if (this.address?.value != null && this.address?.value.length > 0) {
            const interval = window.setInterval(() => {
                if (this.owner.$address.suggestions &&
                    this.owner.$address.suggestions().fixData) {
                    window.clearInterval(interval);
                    this.owner.$address.on('suggestions-fixdata', (e: any, suggestion: any) => {
                        if (suggestion) {
                            this.onDadataAddress(suggestion.data, suggestion.value);
                        }
                    });

                    this.owner.$address.suggestions().fixData();
                }
            }, 100);
        }
    }

    private restoreInsurerDadata(): void {

        // address
        if (this.insurerAddress?.value != null && this.insurerAddress?.value.length > 0) {
            const interval = window.setInterval(() => {
                if (this.insurer.$address.suggestions &&
                    this.insurer.$address.suggestions().fixData) {
                    window.clearInterval(interval);
                    this.insurer.$address.on('suggestions-fixdata', (e: any, suggestion: any) => {
                        if (suggestion) {
                            this.onInsurerDadataAddress(suggestion.data, suggestion.value);
                        }
                    });

                    this.insurer.$address.suggestions().fixData();
                }
            }, 100);
        }

    }

    // Сохраняем текущий стейт
    private saveState(): void {
        this.osagoService.selectedOwnerDriver = this.owner.selectedDriver;
        this.osagoService.selectedInsurerDriver = this.insurer.selectedDriver;
        this.osagoService.onOwnerComplete(this.getOwner(), this.owner.selectedDriver);
        this.osagoService.onInsurerComplete(this.getInsurer(), this.insurer.selectedDriver);
        this.osagoService.onContactsComplete(
            Converter.toPhoneRu(TextMaskHelper.removeMask(this.phone?.value, 11)),
            this.email?.value
        );
    }

    private selectedState(): void {
        this.osagoService.selectedOwnerDriver = this.owner.selectedDriver;
        this.osagoService.selectedInsurerDriver = this.insurer.selectedDriver;
        this.osagoService.onOwnerSelected(this.getOwner(), this.owner.selectedDriver);
        this.osagoService.onInsurerSelected(this.getInsurer(), this.insurer.selectedDriver);
    }

    // Валидация
    private setValidators(): void {
        if (this._validatorsSet) {
            return;
        }

        this._validatorsSet = true;

        this.firstName?.setValidators([ OsagoValidators.firstName() ]);
        this.lastName?.setValidators([ OsagoValidators.lastName() ]);
        this.middleName?.setValidators([ OsagoValidators.middleName() ]);
        this.birthDate?.setValidators([ OsagoValidators.ownerBirthDate() ]);
        this.passport?.setValidators([ OsagoValidators.passport() ]);
        this.passportDate?.setValidators([ OsagoValidators.passportDate('birthDate') ]);

        this.address?.setValidators([ OsagoValidators.address('address-dadata') ]);

        this.insurerFirstName?.setValidators([ OsagoValidators.firstName(() => this.ownerIsInsurer) ]);
        this.insurerLastName?.setValidators([ OsagoValidators.lastName(() => this.ownerIsInsurer) ]);
        this.insurerMiddleName?.setValidators([ OsagoValidators.middleName(() => this.ownerIsInsurer) ]);
        this.insurerBirthDate?.setValidators([ OsagoValidators.ownerBirthDate(() => this.ownerIsInsurer) ]);
        this.insurerPassport?.setValidators([ OsagoValidators.passport(() => this.ownerIsInsurer) ]);
        this.insurerPassportDate?.setValidators([ OsagoValidators.passportDate('insurer-birthDate', () => this.ownerIsInsurer) ]);
        this.insurerAddress?.setValidators([ OsagoValidators.address('insurer-address-dadata', () => this.ownerIsInsurer) ]);

        this.phone?.setValidators([ OsagoValidators.phone() ]);
        this.email?.setValidators([ Validators.email, OsagoValidators.required() ]);
    }

    get isInsurer(): AbstractControl | null{
        return this.ownerForm?.get('is-insurer') || null;
    }

    get address(): AbstractControl | null{
        return this.ownerForm?.get('address') || null;
    }

    get addressDadata(): AbstractControl | null {
        return this.ownerForm?.get('address-dadata') || null;
    }

    get fio(): AbstractControl | null{
        return this.ownerForm?.get('fio') || null;
    }

    get firstName(): AbstractControl | null{
        return this.ownerForm?.get('firstName') || null;
    }

    get lastName(): AbstractControl | null{
        return this.ownerForm?.get('lastName') || null;
    }

    get middleName(): AbstractControl | null{
        return this.ownerForm?.get('middleName') || null;
    }

    get fioDadata(): AbstractControl | null {
        return this.ownerForm?.get('fio-dadata') || null;
    }

    get firstNameDadata(): AbstractControl | null {
        return this.ownerForm?.get('firstNameDadata') || null;
    }

    get lastNameDadata(): AbstractControl | null {
        return this.ownerForm?.get('lastNameDadata') || null;
    }

    get middleNameDadata(): AbstractControl | null {
        return this.ownerForm?.get('middleNameDadata') || null;
    }

    get driver(): FormControl {
        return this.ownerForm?.get('driver') as FormControl;
    }

    get birthDate(): AbstractControl | null{
        return this.ownerForm?.get('birthDate') || null;
    }

    get gender(): AbstractControl | null{
        return this.ownerForm?.get('gender') || null;
    }

    get passport(): AbstractControl | null{
        return this.ownerForm?.get('passport') || null;
    }

    get passportDate(): AbstractControl | null{
        return this.ownerForm?.get('passport-date') || null;
    }

    get insurerAddress(): AbstractControl | null{
        return this.ownerForm?.get('insurer-address') || null;
    }

    get insurerAddressDadata(): AbstractControl | null {
        return this.ownerForm?.get('insurer-address-dadata') || null;
    }

    get insurerFio(): AbstractControl | null{
        return this.ownerForm?.get('insurer-fio') || null;
    }

    get insurerFirstName(): AbstractControl | null {
        return this.ownerForm?.get('insurerFirstName') || null;
    }

    get insurerLastName(): AbstractControl | null {
        return this.ownerForm?.get('insurerLastName') || null;
    }

    get insurerMiddleName(): AbstractControl | null {
        return this.ownerForm?.get('insurerMiddleName') || null;
    }

    get insurerFioDadata(): AbstractControl | null {
        return this.ownerForm?.get('insurer-fio-dadata') || null;
    }

    get insurerFirstNameDadata(): AbstractControl | null {
        return this.ownerForm?.get('insurerFirstNameDadata') || null;
    }

    get insurerLastNameDadata(): AbstractControl | null {
        return this.ownerForm?.get('insurerLastNameDadata') || null;
    }

    get insurerMiddleNameDadata(): AbstractControl | null {
        return this.ownerForm?.get('insurerMiddleNameDadata') || null;
    }

    get insurerBirthDate(): AbstractControl | null{
        return this.ownerForm?.get('insurer-birthDate') || null;
    }

    get insurerGender(): AbstractControl | null{
        return this.ownerForm?.get('insurer-gender') || null;
    }

    get insurerPassport(): AbstractControl | null{
        return this.ownerForm?.get('insurer-passport') || null;
    }

    get insurerPassportDate(): AbstractControl | null{
        return this.ownerForm?.get('insurer-passport-date') || null;
    }

    get insurerDriver(): FormControl {
        return this.ownerForm?.get('insurer-driver') as FormControl;
    }

    get passportIssuer(): AbstractControl | null{
        return this.ownerForm?.get('passport-issuer') || null;
    }

    get passportIssuerCode(): AbstractControl | null{
        return this.ownerForm?.get('passport-issuer-code') || null;
    }

    get passportIssuerCodeDadata(): AbstractControl | null{
        return this.ownerForm?.get('passport-issuer-code-dadata') || null;
    }

    get insurerPassportIssuer(): AbstractControl | null{
        return this.ownerForm?.get('insurer-passport-issuer') || null;
    }

    get insurerPassportIssuerCode(): AbstractControl | null{
        return this.ownerForm?.get('insurer-passport-issuer-code') || null;
    }

    get insurerPassportIssuerCodeDadata(): AbstractControl | null{
        return this.ownerForm?.get('insurer-passport-issuer-code-dadata') || null;
    }

    get phone(): AbstractControl | null{
        return this.ownerForm?.get('phone') || null;
    }

    get email(): AbstractControl | null {
        return this.ownerForm?.get('email') || null;
    }

    get emailDadata(): AbstractControl | null {
        return this.ownerForm?.get('emailDadata') || null;
    }

    public onMouseEnter(): void {
        this.isHover = true;
    }

    public onMouseLeave(): void {
        this.isHover = false;
    }

    public onEmailChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.emailDadata?.setValue(null);
    }

    public onBackClick(): void {
        this.saveState();
        this.appService.onOwnerBack();
    }

    public onAddressChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.addressDadata?.setValue(null);
    }

    public onInsurerAddressChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.insurerAddressDadata?.setValue(null);
    }

    public onFirstNameChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.owner.driverChosen = false;
        this.driver?.setValue(this.drivers.length - 1);
        this.firstNameDadata?.setValue(null);
        this.ownerFio = '';
    }

    public onLastNameChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.owner.driverChosen = false;
        this.driver?.setValue(this.drivers.length - 1);
        this.lastNameDadata?.setValue(null);
        this.ownerFio = '';
    }

    public onMiddleNameChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.owner.driverChosen = false;
        this.driver?.setValue(this.drivers.length - 1);
        this.middleNameDadata?.setValue(null);
        this.ownerFio = '';
    }

    public onInsurerFIOChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.insurer.driverChosen = false;
        this.insurerDriver?.setValue(this.drivers.length - 1);
        this.insurerFioDadata?.setValue(null);
    }

    public onInsurerFirstNameChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.insurer.driverChosen = false;
        this.insurerDriver?.setValue(this.drivers.length - 1);
        this.insurerFirstNameDadata?.setValue(null);
    }

    public onInsurerLastNameChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.insurer.driverChosen = false;
        this.insurerDriver?.setValue(this.drivers.length - 1);
        this.insurerLastNameDadata?.setValue(null);
    }

    public onInsurerMiddleNameChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.insurer.driverChosen = false;
        this.insurerDriver?.setValue(this.drivers.length - 1);
        this.insurerMiddleNameDadata?.setValue(null);
    }

    public onBirthDateChanged(): void {
        this.owner.driverChosen = false;
        this.driver?.setValue(this.drivers.length - 1);
    }

    public onInsurerBirthDateChanged(): void {
        this.insurer.driverChosen = false;
        this.insurerDriver?.setValue(this.drivers.length - 1);
    }

    public onPassportChanged(): void {
        this.passport?.updateValueAndValidity();
    }

    public onInsurerPassportChanged(): void {
        this.insurerPassport?.updateValueAndValidity();
    }

    public onPassportIssuerCodeChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.passportIssuerCodeDadata?.setValue(null);
    }

    public onInsurerPassportIssuerCodeChanged(event: any): void {
        if (event != null && event.type === 'keyup' && (event.key === 'Enter' || event.keyCode === 13 || event.which === 13)) {
            return;
        }

        this.insurerPassportIssuerCodeDadata?.setValue(null);
    }

    public onOwnerIsInsurerClicked(event: any): void {
        this.ownerIsInsurer = event.target?.checked || false;
    }

    // Нажали "Продолжить"
    public onSubmit(): void {
        this.isSubmit = true;
        this.appService.isRoutePayment = false;
        this.setValidators();
        if (!this.formsService.isFormValid(this.ownerForm)) {
            return;
        }

        if (!this.agreementChecked) {
            return;
        }

        const owner = this.getOwner();
        const insurer = this.getInsurer();
        this.osagoService.onOwnerComplete(owner, this.owner.selectedDriver);
        this.osagoService.onInsurerComplete(insurer, this.insurer.selectedDriver);

        const dadataAddress = owner?.passport?.registrationAddressData?.daDataAddress;
        const fiasIds = [ dadataAddress?.settlement_fias_id || '', dadataAddress?.city_fias_id || '', dadataAddress?.region_fias_id || ''];
        this.priceService.setCity(this.owner.$address.val(), fiasIds);

        this.osagoService.onContactsComplete(
            Converter.toPhoneRu(TextMaskHelper.removeMask(this.phone?.value, 11)),
            this.email?.value
        );

        // Минимальная дата начала полиса + 4 дня
        const minDate = moment().add(4, 'day');

        const policyDate = minDate.format('YYYY-MM-DD');

        const policy: OsagoPolicy = {
            osagoUtilisationPeriod: 12,
            policyStartDate: TextMaskHelper.removeMask(Converter.toDateRu(policyDate), 10)
        };

        // Сохраняем дату начала действия полиса
        this.osagoService.onPolicyFromOwnerComplete(policy);

        this.appService.onDiagnosticComplete();
        this.osagoService.onYandexReachGoal('OWNER_SUBMIT');
        // this.close.emit(true);
    }

    public togglePopup(): void {
        this.isPopupOpen = !this.isPopupOpen;
    }

    private getOwner(): OsagoOwner {
        return {
            birthDate:  TextMaskHelper.getDate(this.birthDate?.value) || '',
            firstName: this.firstName?.value || '',
            gender: GenderHelper.toGender(this.gender?.value),
            lastName: this.lastName?.value || '',
            middleName: this.middleName?.value || null,
            ownerIsInsured: this.ownerIsInsurer,
            passport: {
                // TODO убрать валидацию на бэке
                // departmentCode: TextMaskHelper.removeMask(this.passportIssuerCode?.value, 6),
                departmentCode: null,
                passportIssueDate: TextMaskHelper.getDate(this.passportDate?.value) || '',
                // TODO убрать валидацию на бэке
                passportIssuer: null,
                passportNumber: TextMaskHelper.removeMask(this.passport!.value, 10).substr(4),
                passportSeries: TextMaskHelper.removeMask(this.passport!.value, 10).substr(0, 4),
                registrationAddressData: {
                    addressAsString: this.address!.value,
                    daDataAddress: this.addressDadata!.value,
                    hasNoApartment: this.owner.hasNoAppartment
                }
            }
        };
    }

    private getInsurer(): OsagoOwner {
        return {
            birthDate:  TextMaskHelper.getDate(this.insurerBirthDate?.value) || '',
            firstName: this.insurerFirstName?.value || '',
            gender: GenderHelper.toGender(this.insurerGender?.value),
            lastName: this.insurerLastName?.value || '',
            middleName: this.insurerMiddleName?.value || null,
            passport: {
                departmentCode: null,
                passportIssueDate: TextMaskHelper.getDate(this.insurerPassportDate?.value) || '',
                passportIssuer: null,
                passportNumber: TextMaskHelper.removeMask(this.insurerPassport!.value, 10).substr(4),
                passportSeries: TextMaskHelper.removeMask(this.insurerPassport!.value, 10).substr(0, 4),
                registrationAddressData: {
                    addressAsString: this.insurerAddress!.value,
                    daDataAddress: this.insurerAddressDadata!.value,
                    hasNoApartment: this.insurer.hasNoAppartment
                }
            }
        };
    }

    private selectDriver(index: number | null, force = false): void {
        if (this.owner.selectedDriver === index && !force) {
            return;
        }

        this.owner.selectedDriver = index;
        if (index == null) {
            return;
        }

        this.owner.driverChosen = true;
        let driver;

        if (index >= this.osagoService.osagoOwners.length) {
            driver = this.osagoService.osagoOwner;
        } else {
            driver = this.osagoService.osagoOwners[index];
        }

        if (driver == null) {
            driver = new OsagoOwner();
        }

        if (driver.lastName != null && driver.firstName != null != null && driver.gender != null) {
            const value = driver.lastName + ' ' + driver.firstName + ' ' + driver.middleName;

            this.firstName?.setValue(driver.firstName);
            this.lastName?.setValue(driver.lastName);
            this.middleName?.setValue(driver.middleName);
            this.ownerLastName = driver.lastName;
            this.ownerFirstName = driver.firstName ? driver.firstName : '';

            this.firstNameDadata?.setValue({
                name: driver.firstName,
                gender: driver.gender
            });
            this.lastNameDadata?.setValue({
                surname: driver.lastName,
                gender: driver.gender
            });
            this.middleNameDadata?.setValue({
                patronymic: driver.lastName,
                gender: driver.gender
            });
            const dadataAddress = {
                value: driver.passport?.registrationAddressData?.addressAsString,
                ...driver.passport?.registrationAddressData?.daDataAddress
            };
            this.addressDadata?.setValue(dadataAddress);
            this.address?.setValue(driver.passport?.registrationAddressData?.addressAsString);
        } else {
            this.firstName?.setValue(null);
            this.lastName?.setValue(null);
            this.middleName?.setValue('');

            this.firstNameDadata?.setValue(null);
            this.lastNameDadata?.setValue(null);
            this.middleNameDadata?.setValue(null);
            this.addressDadata?.setValue(null);
            this.address?.setValue(null);
        }

        this.gender?.setValue(driver.gender);
        this.birthDate?.setValue(driver.birthDate);
        this.birthdateComponent?.checkFormValue();

        this.passportDate?.setValue(driver.passport?.passportIssueDate);
        this.passportDateComponent?.checkFormValue();

        if (driver.passport?.passportNumber?.length === 6 && driver.passport?.passportSeries?.length === 4) {
            this.passport?.setValue(`${ driver?.passport?.passportSeries?.substr(0, 2)}${ driver?.passport?.passportSeries?.substr(2)} ${ driver?.passport?.passportNumber }`);
        } else {
            this.passport?.setValue(null);
        }

        if (driver.passport?.registrationAddressData?.addressAsString != null) {
            this.address?.setValue(driver.passport?.registrationAddressData?.addressAsString);
        }
        this.restoreDadata();
        this.saveState();
    }

    private selectInsurerDriver(index: number | null, force = false): void {
        if (this.insurer.selectedDriver === index && !force) {
            return;
        }

        this.insurer.selectedDriver = index;
        if (index == null) {
            return;
        }

        this.insurer.driverChosen = true;
        let driver = null;

        if (index >= this.osagoService.osagoOwners.length) {
            driver = this.osagoService.osagoInsurer;
        } else {
            driver = this.osagoService.osagoOwners[index];
        }

        if (driver == null) {
            driver = new OsagoOwner();
        }

        if (driver.lastName != null && driver.firstName != null && driver.middleName != null && driver.gender != null) {
            const value = driver.lastName + ' ' + driver.firstName + ' ' + driver.middleName;

            this.insurerFirstName?.setValue(driver.firstName);
            this.insurerLastName?.setValue(driver.lastName);
            this.insurerMiddleName?.setValue(driver.middleName);

            this.insurerFirstNameDadata?.setValue({
                name: driver.firstName,
                gender: driver.gender
            });
            this.insurerLastNameDadata?.setValue({
                surname: driver.lastName,
                gender: driver.gender
            });
            this.insurerMiddleNameDadata?.setValue({
                patronymic: driver.lastName,
                gender: driver.gender
            });
        } else {
            // this.insurer.$fio.val('');
            // this.insurerFio?.setValue(null);
            this.insurerFirstName?.setValue(null);
            this.insurerLastName?.setValue(null);
            this.insurerMiddleName?.setValue(null);
            // this.insurerFioDadata?.setValue(null);
            this.insurerFirstNameDadata?.setValue(null);
            this.insurerLastNameDadata?.setValue(null);
            this.insurerMiddleNameDadata?.setValue(null);
        }

        this.insurerGender?.setValue(driver.gender);
        this.insurerBirthDate?.setValue(driver.birthDate);
        // this.insurerFioComponent?.checkFilled();
        this.insurerBirthdateComponent?.checkFormValue();

        this.insurerPassportDate?.setValue(driver.passport?.passportIssueDate);
        this.insurerPassportDateComponent?.checkFormValue();

        // if (driver.passport?.departmentCode?.length === 6) {
        //     this.insurerPassportIssuerCode?.setValue(driver.passport?.departmentCode.substr(0, 3)
        //         + '-'
        //         + driver.passport?.departmentCode.substr(3));
        // } else {
        //     this.insurerPassportIssuerCode?.setValue(null);
        // }

        // if (driver.passport?.passportNumber?.length == 6 && driver.passport?.passportSeries?.length == 4) {
        //     this.insurerPassport?.setValue(`${ driver?.passport?.passportSeries?.substr(0, 2)} ${ driver?.passport?.passportSeries?.substr(2)} ${ driver?.passport?.passportNumber }`);
        // }
        // else {
        //     this.insurerPassport?.setValue(null);
        // }
        //
        // this.insurerPassportIssuer?.setValue(driver.passport?.passportIssuer);

        if (driver.passport?.registrationAddressData?.addressAsString != null) {
            this.insurerAddress?.setValue(driver.passport?.registrationAddressData?.addressAsString);
        }

        this.insurerAddressDadata?.setValue(driver.passport?.registrationAddressData?.daDataAddress);

        this.restoreInsurerDadata();
    }

    private initDadata(): void {
        // this.owner.$fio = $('#fio');
        this.owner.$firstName = $('#firstName');
        this.owner.$lastName = $('#lastName');
        this.owner.$middleName = $('#middleName');
        this.owner.$address = $('#address');

        this.insurer.$insurerFirstName = $('#insurerFirstName');
        this.insurer.$insurerLastName = $('#insurerLastName');
        this.insurer.$insurerMiddleName = $('#insurerMiddleName');
        this.insurer.$address = $('#insurerAddress');

        this.owner.$firstName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['NAME']
            },
            onSelect: (suggestion: any) => {
                this.onDadataFirstName(suggestion.data);
            }
        });
        this.owner.$lastName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['SURNAME']
            },
            onSelect: (suggestion: any) => {
                this.onDadataLastName(suggestion.data);
            }
        });
        this.owner.$middleName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['PATRONYMIC']
            },
            onSelect: (suggestion: any) => {
                this.onDadataMiddleName(suggestion.data);
            }
        });

        this.owner.$address.suggestions({
            token: environment.dadataKey,
            type: 'address',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            geoLocation: true,
            onSelect: (suggestion: any) => {
                this.onDadataAddress(suggestion.data, suggestion.value);
            }
        });

        this.insurer.$insurerFirstName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['NAME']
            },
            onSelect: (suggestion: any) => {
                this.onDadataInsurerFirstName(suggestion.data);
            }
        });
        this.insurer.$insurerLastName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['SURNAME']
            },
            onSelect: (suggestion: any) => {
                this.onDadataInsurerLastName(suggestion.data);
            }
        });
        this.insurer.$insurerMiddleName.suggestions({
            token: environment.dadataKey,
            type: 'NAME',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            params: {
                parts: ['PATRONYMIC']
            },
            onSelect: (suggestion: any) => {
                this.onDadataInsurerMiddleName(suggestion.data);
            }
        });

        this.insurer.$address.suggestions({
            token: environment.dadataKey,
            type: 'address',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            geoLocation: true,
            onSelect: (suggestion: any) => {
                this.onInsurerDadataAddress(suggestion.data, suggestion.value);
            }
        });

        this.$email = $('#email');

        this.$email.suggestions({
            token: environment.dadataKey,
            type: 'EMAIL',
            count: 6,
            mobileWidth: 0,
            addon: 'none',
            triggerSelectOnEnter: true,
            enrichmentEnabled: false,
            onSelect: (suggestion: any) => {
                this.onDadataEmail(suggestion.data);
            }
        });
    }

    private onDadataAddress(data: any, value: any): void {
        if (!value) {
            value = this.owner.$address.val();
        }
        this.addressDadata?.setValue(Object.assign({ value }, data));
        this.addressDadata?.markAsDirty();
        this.address?.setValue(this.owner.$address.val());
        this.owner.hasNoAppartment = data.flat == null || data.flat === '';
        const fiasIds = [ data.settlement_fias_id || '', data.city_fias_id || '', data.region_fias_id || ''];
        this.priceService.setCity(this.owner.$address.val(), fiasIds);
    }

    private onDadataFirstName(data: any): void {
        const valid = data.name != null;
        this.firstNameDadata?.setValue(valid ? data : null);
        this.firstNameDadata?.markAsDirty();

        if (valid) {
            this.firstName?.setValue(this.owner.$firstName.val());
            this.gender?.setValue(data.gender);
            this.ownerFirstName = this.owner.$firstName.val();
        }
    }

    private onDadataLastName(data: any): void {
        const valid = data.surname != null;
        this.lastNameDadata?.setValue(valid ? data : null);
        this.lastNameDadata?.markAsDirty();

        if (valid) {
            this.lastName?.setValue(this.owner.$lastName.val());
            this.gender?.setValue(data.gender);
            this.ownerLastName = this.owner.$lastName.val();
        }
    }

    private onDadataMiddleName(data: any): void {
        const valid = data.patronymic != null;
        this.middleNameDadata?.setValue(valid ? data : null);
        this.middleNameDadata?.markAsDirty();

        if (valid) {
            this.middleName?.setValue(this.owner.$middleName.val());
            this.gender?.setValue(data.gender);
            // this.ownerMiddleName = '(' + FioHelper.toInitials(this.owner.$middleName.val()) + ')';
        }
    }

    private onInsurerDadataAddress(data: any, value: any): void {
        if (!value) {
            value = this.insurer.$address.val();
        }
        this.insurerAddressDadata?.setValue(Object.assign({ value }, data));
        this.insurerAddressDadata?.markAsDirty();
        this.insurerAddress?.setValue(this.insurer.$address.val());
        this.insurer.hasNoAppartment = data.flat == null || data.flat === '';
    }

    private onDadataInsurerFirstName(data: any): void {
        const valid = data.name != null;
        this.insurerFirstNameDadata?.setValue(valid ? data : null);
        this.insurerFirstNameDadata?.markAsDirty();

        if (valid) {
            this.insurerFirstName?.setValue(this.insurer.$insurerFirstName.val());
            this.insurerGender?.setValue(data.gender);
        }
    }

    private onDadataInsurerLastName(data: any): void {
        const valid = data.surname != null;
        this.insurerLastNameDadata?.setValue(valid ? data : null);
        this.insurerLastNameDadata?.markAsDirty();

        if (valid) {
            this.insurerLastName?.setValue(this.insurer.$insurerLastName.val());
            this.insurerGender?.setValue(data.gender);
        }
    }

    private onDadataInsurerMiddleName(data: any): void {
        const valid = data.patronymic != null;
        this.insurerMiddleNameDadata?.setValue(valid ? data : null);
        this.insurerMiddleNameDadata?.markAsDirty();

        if (valid) {
            this.insurerMiddleName?.setValue(this.insurer.$insurerMiddleName.val());
            this.insurerGender?.setValue(data.gender);
        }
    }

    private onDadataEmail(suggestion: any): void {
        const value = suggestion != null &&
        suggestion.domain != null &&
        suggestion.local != null ? suggestion.local + '@' +
            suggestion.domain : null;

        this.emailDadata?.setValue(value);
        this.emailDadata?.markAsDirty();

        if (value != null) {
            this.email?.setValue(this.$email.val());
        }
    }

    public onAgreementChange(event: any): void {
        this.agreementChecked = event.target?.checked || false;
    }
}
