import {
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpInterceptor,
    HttpClient
  } from "@angular/common/http";
import { Observable, Subscription, throwError } from "rxjs";
import { catchError, retryWhen } from "rxjs/operators";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { HttpService } from "./http.service";
import { genericRetryStrategy } from "./retryParams";
import { ApplicationService } from "./application.service";
import { LoggingService } from "./loggingService";
  
@Injectable()
export class HttpErrorInterceptor extends HttpService implements HttpInterceptor, OnDestroy {
  private _subscription = new Subscription();

  constructor(
      @Inject(HttpClient) protected http: HttpClient,
      private appService: ApplicationService,
      protected loggingService: LoggingService) {
      super(http, loggingService);
  }

  ngOnDestroy() {
      this._subscription.unsubscribe();
  }

  private handleError(error: HttpErrorResponse) {
      this._subscription.add(
          this.postLocal("log", {
              applicationId: this.appService.applicationId,
              message: JSON.stringify(error)
          })
          .subscribe({
              next: () => {},
              error: () => {}
          })
      );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((error: HttpErrorResponse) => {
          if (error != null && error.url != null && error.url.toLowerCase().indexOf("/log") == -1)
              this.handleError(error);

          return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
  }
}