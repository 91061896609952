<div [class]="cssClass"
    [style.display]="(isHidden == true) ? 'none' : null"
    [ngClass]="{
        'error-text': isError,
        'filled': isFilled
    }">
    <input
        #formElement
        [id]="fieldId"
        [name]="fieldId"
        [attr.maxlength]="maxLength"
        [type]="fieldType"
        [readOnly]="isReadOnly"
        [formControl]="fieldControl"
        (focus)="onFocus()"
        (input)="onInput($event)"
        (change)="onChange($event)"
        (click)="setCaretForPhone()"
        [placeholder]="isPhoneNumber ? '+7 ' : ''"
        autocomplete="off"
    >
    <label>{{ fieldName }}</label>
    <i class="error-content" *ngIf="isError == true">{{ errorMessage }}</i>

    <ng-content></ng-content>
</div>
