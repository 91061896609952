import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { InputNumericComponent, SelectAutoCompleteComponent } from '../core';
import * as moment from 'moment-mini';

// Окружение
import { environment } from '../../../../../environments/environment';

// Модели
import { Modification, OsagoCarData, OsagoCarDocument, OsagoIdentifier } from '../../models';
import { BrandModel, DictionaryItem } from '../../../../../../../common-lib/src/lib/models';

// Хелперы
import { OsagoValidators, StringHelper, TextMaskHelper } from '../../helpers';

// Сервисы
import { ApplicationService } from '../../../../services';
import { FormsService, OsagoService, PriceCalculatorService, ScriptService } from '../../services';

// Конвертеры
import { Converter } from '../../../../../../../common-lib/src/lib/utils/converters';
import { Router } from '@angular/router';
import { WidgetStatuses } from '../../../shared/widgetStatuses.enum';
import { LoggingService } from '../../../../services/loggingService';

// using jQuery suggestions, attached in Index.chtml
declare var $: any;

@Component({
    selector: 'app-vendors',
    templateUrl: './vendors.component.html',
    styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit, OnDestroy {
    @Output() close = new EventEmitter<boolean>();
    @Input() set editMode(value: boolean) {
        this.submitCaption = value ? 'Сохранить' : 'Продолжить';
        this.edit = value;
    }
    @ViewChild('modelComponent') private modelComponent: SelectAutoCompleteComponent | undefined;
    @ViewChild('brandComponent') private brandComponent: SelectAutoCompleteComponent | undefined;
    @ViewChild('carPowerComponent') private carPowerComponent: InputNumericComponent | undefined;
    // Элемент и иконкой для контрола Серия и номер СТС
    @ViewChild('seriesNumberStsPopup') private seriesNumberStsPopup: ElementRef | null = null;
    // Элемент и иконкой для контрола Дата выдачи СТС
    @ViewChild('stsDatePopup') private stsDatePopup: ElementRef | null = null;
    // Элемент и иконкой для контрола Серия и номер ПТС
    @ViewChild('seriesNumberPtsPopup') private seriesNumberPtsPopup: ElementRef | null = null;
    // Элемент и иконкой для контрола Дата выдачи ПТС
    @ViewChild('ptsDatePopup') private ptsDatePopup: ElementRef | null = null;
    // Элемент и иконкой для контрола Номер VIN
    @ViewChild('vinPopup') private vinPopup: ElementRef | null = null;
    edit = false;
    driversUnlimited = false;
    changeLicenseTitle = 'Указать гос. номер';
    // Форма данных автомобиля
    vehicleForm = new FormGroup({});
    brands: DictionaryItem[] = [];
    models$ = new BehaviorSubject<DictionaryItem[]>([]);
    modifications: DictionaryItem[] = [];
    apiModifications: Modification[] = [];
    license: string | undefined;
    brandSelected = false;
    modelsLoading = false;
    modificationEnabled = false;
    modificationsLoading = false;
    isFormError = false;
    isSubmit = false;
    needCheckValidity = false;
    price: number | undefined;
    submitCaption = 'Продолжить';
    isPrivatePurpose = true;
    formValid = false;
    showPrice = true;
    // Показать алерт подели
    public isShowAlertPodeli = false;

    // Показываем окно LicenseModal
    public isShowLicenseModal: boolean | undefined;

    private brandName = '';
    private modelName = '';
    private _interval = 0;
    private _initialBrandModel: BrandModel | null = null;
    private _initialYear: number | null = null;
    private _subscription = new Subscription();
    private _currentModelId: number | null = null;
    private _currentYear: number | null = null;
    private _validatorsSet = false;
    private carData: OsagoCarData | null = null;
    private _brandId: number | undefined = 0;
    private _initialModification: string | number | null = null;

    // Форма документов
    // Выбранный Тип документа по умолчанию
    public selectedDocument = 1;
    // Выбранный Тип номера по умолчанию
    public selectedIdentifier = 0;
    // Формат даты
    public maxDate = moment().format('DD.MM.YYYY');
    // Типы документов
    public documentTypes = [
        {
            id: 0,
            value: 'ПТС'
        },
        {
            id: 1,
            value: 'СТС'
        },
        {
            id: 2,
            value: 'еПТС'
        }
    ];
    // Тип VIN номера
    public identifiers = [
        {
            id: 0,
            value: 'VIN'
        },
        {
            id: 1,
            value: 'Номер кузова'
        },
        {
            id: 2,
            value: 'Номер шасси'
        }
    ];
    // Маска
    public masks = {
        sts: [ /\d/, /\d/,  ' ', /^[0-9а-яА-ЯёЁ]$/, /^[0-9а-яА-ЯёЁ]$/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/ ],
        pts: [ /\d/, /\d/,  ' ', /^[а-яА-ЯёЁ]$/, /^[а-яА-ЯёЁ]$/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/ ],
        epts: [ /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/ ],
        vin: () => {
            let index = 0;
            const result = [];
            while (index < 19) {
                if (index === 3 || index === 10) {
                    result.push(' ');
                } else {
                    result.push(/^[0-9a-jklmnpr-zKLMNPR-ZA-JKLMNPR-Z]$/);
                }
                index++;
            }

            return result;
        }
    };
    // Если форма документов не валидна
    public isFormErrorDocuments = false;
    private needCheckValidityDocuments = false;
    // Попап с подсказкой для контрола Серия и номер СТС
    public seriesNumberStsPopupOpen = false;
    // Попап с подсказкой для контрола Дата выдачи СТС
    public stsDatePopupPopupOpen = false;
    // Попап с подсказкой для контрола Серия и номер ПТС
    public seriesNumberPtsPopupOpen = false;
    // Попап с подсказкой для контрола Дата выдачи ПТС
    public ptsDatePopupOpen = false;
    // Попап с подсказкой для контрола Дата выдачи ПТС
    public vinPopupOpen = false;

    // Индикатор загрузки
    public isLoading = true;

    // Минимальная цена
    public minPrice = 0;
    // Средняя цена
    public middlePrice = 0;
    // Максимальная цена
    public topPrice = 0;
    // Пришли из смс или почты
    public fromUrlOrSms: boolean;

    private isReturnFromConfirmation: boolean = false;

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event: any): void {
        if (!this.seriesNumberStsPopup?.nativeElement?.contains(event.target)) {
            this.seriesNumberStsPopupOpen = false;
        }
        if (!this.stsDatePopup?.nativeElement?.contains(event.target)) {
            this.stsDatePopupPopupOpen = false;
        }
        if (!this.seriesNumberPtsPopup?.nativeElement?.contains(event.target)) {
            this.seriesNumberPtsPopupOpen = false;
        }
        if (!this.ptsDatePopup?.nativeElement?.contains(event.target)) {
            this.ptsDatePopupOpen = false;
        }
        if (!this.vinPopup?.nativeElement?.contains(event.target)) {
            this.vinPopupOpen = false;
        }
    }

    constructor(
        private readonly formGroup: FormBuilder,
        private scriptService: ScriptService,
        private appService: ApplicationService,
        private osagoService: OsagoService,
        private formsService: FormsService,
        private priceService: PriceCalculatorService,
        private loggingService: LoggingService,
        router: Router
    ) {
        // this.initForm();
        var routeState = router.getCurrentNavigation()?.extras.state;
        this.isReturnFromConfirmation = routeState != null && routeState.fromConfirmation == true;
        this.osagoService.onYandexReachGoal('CAR_DATA_GOAL');
        scriptService.load();
        this.fromUrlOrSms = osagoService.fromUrlOrSms;
        this.brands = this.appService.brands;
        this._brandId = this.appService._initialBrandModel?.brandId;
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this._subscription.add(
            this.appService.onLoadApp$.subscribe((value) => {
                this.isLoading = value;
                this.appService.scrollToElement('vendors-anchor');
            })
        );
        // this.createForm();
        // Если режим редактирования, слушаем изменение номера авто

        this.licenseSubscription();
    }

    public afterCreateForm(): void {
        this.loggingService.trace('VendorsComponent', 'afterCreateForm, _brandId' + this._brandId);
        this.osagoService.setWidgetStatus(WidgetStatuses.CarScreen);

        if (this._brandId !== null) {
            const brand = this.getBrand(this._brandId);
            if (brand) {
                window.setTimeout(() => this.brandComponent?.select(brand));
            }
            return;
        }

        if (this._initialBrandModel !== null && this._initialBrandModel.brandId !== null) {
            const brand = this.getBrand(this._initialBrandModel.brandId);
            if (brand) {
                window.setTimeout(() => this.brandComponent?.select(brand));
            }
        }
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
        window.clearInterval(this._interval);
    }
    // --------------------------------------------------------------------------

    get brand(): AbstractControl {
        return this.vehicleForm.get('brand') as AbstractControl;
    }

    get model(): AbstractControl {
        return this.vehicleForm.get('model') as AbstractControl;
    }

    get modification(): AbstractControl{
        return this.vehicleForm.get('modification') as AbstractControl;
    }

    get productionYear(): AbstractControl {
        return this.vehicleForm.get('productionYear') as AbstractControl;
    }

    get horsePower(): AbstractControl {
        return this.vehicleForm.get('horsePower') as AbstractControl;
    }

    get documentType(): AbstractControl {
        return this.vehicleForm.get('documentType') as AbstractControl;
    }

    get pts(): AbstractControl {
        return this.vehicleForm.get('pts') as AbstractControl;
    }

    get ptsDate(): AbstractControl {
        return this.vehicleForm.get('ptsDate') as AbstractControl;
    }

    get sts(): AbstractControl {
        return this.vehicleForm.get('sts') as AbstractControl;
    }

    get stsHide(): AbstractControl {
        return this.vehicleForm.get('stsHide') as AbstractControl;
    }

    get stsDate(): AbstractControl {
        return this.vehicleForm.get('stsDate') as AbstractControl;
    }

    get epts(): AbstractControl {
        return this.vehicleForm.get('epts') as AbstractControl;
    }

    get eptsDate(): AbstractControl {
        return this.vehicleForm.get('eptsDate') as AbstractControl;
    }

    get identifier(): AbstractControl {
        return this.vehicleForm.get('identifier') as AbstractControl;
    }

    get vin(): AbstractControl {
        return this.vehicleForm.get('vin') as AbstractControl;
    }

    get vinHide(): AbstractControl {
        return this.vehicleForm.get('vinHide') as AbstractControl;
    }

    get chassisNumber(): AbstractControl {
        return this.vehicleForm.get('chassisNumber') as AbstractControl;
    }

    get bodyNumber(): AbstractControl {
        return this.vehicleForm.get('bodyNumber') as AbstractControl;
    }

    // Инициализация формы
    private initForm(): void {
        this.vehicleForm = new FormGroup({
            brand: new FormControl(null),
            model: new FormControl(null),
            productionYear: new FormControl(null),
            horsePower: new FormControl(null),
            documentType: new FormControl(null),
            pts: new FormControl(null),
            ptsDate: new FormControl(null),
            sts: new FormControl(null),
            stsHide: new FormControl(null),
            stsDate: new FormControl(null),
            epts: new FormControl(null),
            eptsDate: new FormControl(null),
            identifier: new FormControl(null),
            vin: new FormControl(null),
            vinHide: new FormControl(null),
            chassisNumber: new FormControl(null),
            bodyNumber: new FormControl(null),
        });
    }

    // Создаем форму
    private createForm(): void {
        this.license = this.appService.licensePlate || '';
        this.changeLicenseTitle = this.license.length === 0 ? 'Указать гос. номер' : 'Изменить';
        this.brands = this.appService.brands;
        this._initialBrandModel = this.appService.initialBrandModel;
        this._initialYear = this.appService.carData !== null ? this.appService.carData.productionYear : null;
        this._currentYear = this._initialYear;
        this.brandSelected = false;

        this.loggingService.debug("VendorsComponent", "createForm, _initialBrandModel=", this._initialBrandModel);
        this._initialModification = this.osagoService.modificationId;

        this.osagoService.modifications$.subscribe((value) => {
            if (value != null) {
                this.modificationsLoading = false;
                this.apiModifications = value;
                this.modifications = value.map(x => new DictionaryItem(x.modificationId, x.modificationName
                    + ' '
                    + x.enginePower.toString()
                    + ' л.с.'));
                // select initial value
                if (this._initialModification != null) {
                    const mod = this.modifications.findIndex(x => x.id === this._initialModification);
                    this._initialModification = null;
                }
            }
        });

        this._interval = window.setInterval(() => {
            if (this.needCheckValidity) {
                this.needCheckValidity = false;
                this.isFormError = this.isSubmit && !this.formsService.isFormValid(this.vehicleForm, false, false, false);

                this.formValid = OsagoValidators.brandErrors(this.brand) === null
                    && OsagoValidators.modelErrors(this.model) === null
                    && OsagoValidators.productionYearErrors(this.productionYear) === null
                    && OsagoValidators.horsePowerErrors(this.horsePower) === null;
            }
        }, 100);

        let carPower;
        let productionYear: string;
        let address = null;
        let addressDadata = null;
        let date = null;
        let identifier = 0;
        let vin = null;
        let bodyNumber = null;
        let chassisNumber = null;

        this.carData = this.osagoService.carData;

        // clear masked data if car characteristics report is missing
        if (this.appService.carCharacteristicsRequestId == null
            && this.carData != null
            && (StringHelper.hasMask(this.carData.carIdentificators?.vin)
                || StringHelper.hasMask(this.carData.carDocument?.documentSeries)
                || StringHelper.hasMask(this.carData.carDocument?.documentNumber))) {
                    this.osagoService.clearCarData();
                    this.carData = null;
                }

        if (this.carData !== null) {
            carPower = '' + this.carData.carPower + ' л.с.';
            productionYear = '' + this.carData.productionYear + ' год';
            this.loggingService.trace("VendorsComponent", "createForm, setting _brandId to " + this.carData.brandId);
            this._brandId = this.carData.brandId;
        } else {
            carPower = this.appService.carData?.carPower ? '' + this.appService.carData?.carPower + ' л.с.' : null;
            productionYear = '';
        }

        if ((this._brandId === undefined || this._brandId === null) && this.osagoService.brandId !== undefined && this.osagoService.brandId !== null) {
            this._brandId = this.osagoService.brandId;
        }

        if (carPower != null && !isNaN(parseFloat(carPower))) {
            this.priceService.setCarPower(parseFloat(carPower));
        }

        if (this.osagoService.ownerAddress != null && this.osagoService.ownerAddress.length > 0) {
            address = this.osagoService.ownerAddress || null;
        }

        if (this.osagoService.ownerAddressDadata != null) {
            addressDadata = this.osagoService.ownerAddressDadata || null;
        }

        const documentType = this.carData?.carDocument?.documentType;
        // if (isNaN(documentType) || documentType < 0 || documentType > 2) {
        //     documentType = 1;
        // }
        let documentTypeNumber = this.getDocumentNumberType(documentType || 'STS');
        this.selectedDocument = documentTypeNumber;
        if (isNaN(documentTypeNumber) || documentTypeNumber < 0 || documentTypeNumber > 2) {
            documentTypeNumber = 1;
        }

        let pts = null;
        let sts = null;
        let epts = null;

        const documentNumber = (this.carData?.carDocument?.documentSeries || '')
            + (this.carData?.carDocument?.documentNumber || '');
        switch (documentTypeNumber) {
            case 0:
                if (documentNumber.length === 10) {
                    pts = documentNumber.substr(0, 2) + ' '
                        + documentNumber.substr(2, 2) + ' '
                        + documentNumber.substr(4, 3) + ' '
                        + documentNumber.substr(7);
                }
                break;
            case 1:
                if (documentNumber.length === 10) {
                    sts = documentNumber.substr(0, 2) + ' '
                        + documentNumber.substr(2, 2) + ' '
                        + documentNumber.substr(4, 3) + ' '
                        + documentNumber.substr(7);
                } else {
                    sts = TextMaskHelper.toPtsFormat(this.appService.carData?.stsSeriesNumber || null);
                }
                break;
            case 2:
                if (documentNumber.length === 15) {
                    epts = documentNumber.substr(0, 5) + ' ' + documentNumber.substr(5, 5) + ' ' + documentNumber.substr(10);
                }
                break;
        }

        // DOCUMENTS --------------------------------------------------------------------------

        // let date = null;
        if (this.carData?.carDocument?.documentIssueDate != null
            && this.carData?.carDocument?.documentIssueDate.length > 0) {
            const d = moment(this.carData?.carDocument?.documentIssueDate, 'DD.MM.YYYY', true);
            if (d.isValid()) {
                date = d.format('YYYY-MM-DD');
            } else {
                date = this.carData?.carDocument?.documentIssueDate;
            }
        } else if (documentTypeNumber === 1) {
            date = Converter.toDate(this.appService.carData?.stsIssueDate);
        }

        if (this.carData?.carIdentificators?.bodyNumber != null
            && this.carData?.carIdentificators?.bodyNumber.length > 0) {
            identifier = 1;
        } else if (this.appService.carData?.bodyNumber != null) {
            identifier = 1;
        }

        if (this.carData?.carIdentificators?.chassisNumber != null
            && this.carData?.carIdentificators?.chassisNumber.length > 0) {
            identifier = 2;
        } else if (this.appService.carData?.chassisNumber != null) {
            identifier = 2;
        }

        this.selectedIdentifier = identifier;

        if (this.carData?.carIdentificators?.vin != null
            && this.carData?.carIdentificators?.vin.length === 17) {
            vin = TextMaskHelper.toVinFormat(this.carData?.carIdentificators?.vin);
        } else if (this.appService.carData?.vin != null) {
            vin = TextMaskHelper.toVinFormat(this.appService.carData?.vin);
        }

        if (this.carData?.carIdentificators?.bodyNumber != null
            && this.carData?.carIdentificators?.bodyNumber.length === 17) {
            bodyNumber = this.carData?.carIdentificators?.bodyNumber;
        } else if (this.appService.carData?.bodyNumber != null) {
            bodyNumber = this.appService.carData?.bodyNumber;
        }

        if (this.carData?.carIdentificators?.chassisNumber != null
            && this.carData?.carIdentificators?.chassisNumber.length === 17) {
            chassisNumber = this.carData?.carIdentificators?.chassisNumber;
        } else if (this.appService.carData?.chassisNumber != null) {
            chassisNumber = this.appService.carData?.chassisNumber;
        }

        // END DOCUMENTS --------------------------------------------------------------------------

        this.vehicleForm = new FormGroup({
            brand: new FormControl(null, { updateOn: 'change' }),
            model: new FormControl(null, { updateOn: 'change' }),
            productionYear: new FormControl(productionYear || this._initialYear),
            horsePower: new FormControl(carPower),
            documentType: new FormControl(documentTypeNumber),
            pts: new FormControl(pts),
            ptsDate: new FormControl(date),
            sts: new FormControl(sts || this.appService.carData?.stsSeriesNumber),
            stsHide: new FormControl(sts ? sts?.toString().slice(0, -2) + '**' : ''
                || this.appService.carData?.stsSeriesNumber ? this.appService.carData?.stsSeriesNumber.toString().slice(0, -2) + '**' : ''),
            stsDate: new FormControl(date),
            epts: new FormControl(epts),
            eptsDate: new FormControl(date),
            identifier: new FormControl(identifier),
            vin: new FormControl(vin),
            vinHide: new FormControl(vin ? vin?.toString().slice(0, -2) + '**' : ''),
            chassisNumber: new FormControl(chassisNumber),
            bodyNumber: new FormControl(bodyNumber),
        });

        this.vehicleForm.get('stsDate')?.setValue(date);

        this.formSubscription();
        this.afterCreateForm();
    }

    // Подписка на форму
    private formSubscription(): void {
        this._subscription.add(
            this.priceService.price$.subscribe((value) => this.price = value)
        );
        this._subscription.add(
            this.priceService.priceGroup$.subscribe((value) => {
                this.minPrice = value.minPrice;
                this.middlePrice = value.middlePrice;
                this.topPrice = value.topPrice;
            })
        );

        this._subscription.add(
            this.vehicleForm.valueChanges.subscribe(x => this.needCheckValidity = true)
        );

        this._subscription.add(
            this.documentType.valueChanges.subscribe((value: number) => this.selectedDocument = value)
        );

        this._subscription.add(
            this.identifier.valueChanges.subscribe((value: number) => this.selectedIdentifier = value)
        );

        this._subscription.add(
            this.stsHide.valueChanges.subscribe((value: number) => {
                const stsHide = value.toString().indexOf('*');
                if (stsHide === -1) {
                    this.sts.setValue(value);
                }
            })
        );

        this._subscription.add(
            this.vinHide.valueChanges.subscribe((value: number) => {
                const vinHide = value.toString().indexOf('*');
                if (vinHide === -1) {
                    this.vin.setValue(value);
                }
            })
        );

        this._subscription.add(
            this.vehicleForm.valueChanges.subscribe(() => {
                this.saveState();
                this.needCheckValidityDocuments = true;
            })
        );
    }

    // Инициализируем данные марки и модели в форму
    public changeBrandAndModel(): void {
        this.loggingService.trace('VendorsComponent', 'changeBrandAndModel, _brandId' + this._brandId);
        if (this._brandId !== null) {
            const brand = this.getBrand(this._brandId);
            if (brand) {
                window.setTimeout(() => this.brandComponent?.select(brand));
            }
            return;
        }

        if (this._initialBrandModel !== null && this._initialBrandModel.brandId !== null) {
            const brand = this.getBrand(this._initialBrandModel.brandId);
            if (brand) {
                window.setTimeout(() => this.brandComponent?.select(brand));
            }
        }
    }

    // Слушаем изменение номера авто
    public licenseSubscription(): void {
        if (this.isReturnFromConfirmation) {
            this.onCheckLicenseComplete();
            return;
        };

        this.isLoading = true;
        this._subscription.add(
            this.appService.checkLicense$.subscribe((license) => {
                this.loggingService.trace('VendorsComponent', 'appService.checkLicense$ subscription, license=' + license);
                this.isLoading = true;
                if (license) {
                    this.onCheckLicense();
                }
            })
        );
        this._subscription.add(
            this.appService.onLicenseCheckedComplete$.subscribe((license) => {
                this.loggingService.trace('VendorsComponent', 'appService.onLicenseCheckedComplete$ subscription, license=' + license);
                this.onCheckLicenseComplete();
                this.isLoading = false;
                this.appService.onLoadApp(false);
            })
        );
    }

    private onCheckLicense() {
        this.loggingService.trace('VendorsComponent', 'onCheckLicense');
        // this.vehicleForm = new FormGroup({});
        // this.initForm();
        this.osagoService.afterChangeLicense();
        this.osagoService.getApplicationData();
    }

    private onCheckLicenseComplete() {
        this.loggingService.trace('VendorsComponent', 'onCheckLicenseComplete');
        this.brands = this.appService.brands;
        this._brandId = this.appService._initialBrandModel?.brandId || this._brandId;
        this.loggingService.trace('VendorsComponent', 'onCheckLicenseComplete, _brandId=' + this._brandId);
        this.createForm();
        this.changeBrandAndModel();
    }

    // Отправляем данные в UpdateCache и переходим на шаг Водители
    public onSubmit(): void {
        this.setValidators();
        this.isSubmit = true;
        if (!this.formsService.isValid(this.vehicleForm)) {
            this.isFormError = true;
            this.isFormErrorDocuments = true;
            return;
        }

        if (!this.isPrivatePurpose) {
            return;
        }

        const result = this.getResult();
        // Значение мощности авто
        if (result.carPower) {
            this.priceService.setCarPower(result.carPower);
        }
        // ID выбранной модели авто
        this.osagoService.brandId = this.brand?.value;
        // Сохраняем данные авто в _application и отправляем в запросе UpdateCache
        this.osagoService.onVendorComplete(result, this.modification?.value);

        // Сохраняем документы
        this.osagoService.onDocumentsComplete(this.getDocument(), this.getIdentifier(), true);
        this.appService.onDocumentsComplete();
        this.osagoService.onYandexReachGoal('CAR_DATA_SUBMIT');

        if (this.edit) {
            this.close.emit(true);
        } else {
            this.appService.onVendorComplete();
        }
    }

    // Получить данные формы для сохранения в UpdateCache
    protected getResult(): OsagoCarData {
        return {
            carPower: Math.floor(parseFloat(this.horsePower.value.replace(' л.с.', ''))),
            modelId: this.model.value,
            brandId: this.brand.value,
            brandName: this.brandName,
            modelName: this.modelName,
            price: this.price,
            productionYear: typeof this.productionYear.value === 'string'
                ? parseInt( this.productionYear.value.substr(0, 4))
                : this.productionYear.value,
            purposeOfUseType: 'Personal'
        };
    }

    // Чекбокс - цель использования
    public onPurposeChange(event: any): void {
        this.isPrivatePurpose = event.target?.checked || false;
    }

    // Выбираем марку авто
    public onBrandChange(event: any): void {
        const selectModel = !this.brandSelected;
        this.brandSelected = true;
        const value = event as DictionaryItem;
        const id: number = typeof value.id === 'number' ? value.id : parseInt(value.id, 10);
        this.osagoService.brandId = id;
        this.brandName = value.value;
        this.loadModels(id, selectModel);
    }

    onProductionChange(event: any) {
        const date = TextMaskHelper.getYear(event);
        const year = parseInt(date || '');
        if (date == null || date.length != 4 || isNaN(year)) {
            this._currentYear = null;
            return;
        }

        // HACK for modifications
        if (this._currentYear != year) {
            this._currentYear = year;
        }
    }

    onHorsePowerChange(event: any) {
        if (event == null || event.length == 0) {
            return;
        }

        const value = parseFloat(event.replace(' л.с.', ''));
        if (isNaN(value) || value < 30) {
            return;
        }

        this.priceService.setCarPower(value);
    }

    onModelChange(event: any) {
        if (typeof event === 'undefined' || event == null) {
            this._currentModelId = null;
            this.modificationEnabled = false;
            return;
        }

        this.modelName = (event as DictionaryItem).value;
        const id = (event as DictionaryItem).id;
        this._currentModelId = typeof id == 'number' ? id : parseInt(id);
    }

    onModificationChange(event: any) {
        if (typeof event === 'undefined' || event == null) {
            return;
        }

        const id = (event as DictionaryItem).id;
        const index = this.apiModifications.findIndex(x => x.modificationId == id);
        const carPower = index != -1 ? this.apiModifications[index].enginePower : 0;
        if (carPower != 0) {
            this.priceService.setCarPower(carPower);
            this.formsService.setFieldValue(this.horsePower, '' + carPower + ' л.с.');
            this.carPowerComponent?.checkFilled();
        }
    }

    // Валидация формы
    private setValidators(): void {
        if (this._validatorsSet) {
            return;
        }
        this._validatorsSet = true;
        this.brand?.setValidators([OsagoValidators.brand()]);
        this.model?.setValidators([OsagoValidators.model()]);
        this.productionYear?.setValidators([OsagoValidators.productionYear()]);
        this.horsePower?.setValidators([OsagoValidators.horsePower()]);

        // Documents
        this.pts?.setValidators([ OsagoValidators.pts(() => this.selectedDocument !== 0) ]);
        this.ptsDate?.setValidators([ OsagoValidators.ptsDate(() => this.selectedDocument !== 0) ]);
        this.sts?.setValidators([ OsagoValidators.sts(() => this.selectedDocument !== 1) ]);
        this.stsHide?.setValidators([ OsagoValidators.sts(() => this.selectedDocument !== 1) ]);
        this.stsDate?.setValidators([ OsagoValidators.stsDate(() => this.selectedDocument !== 1) ]);
        this.epts?.setValidators([ OsagoValidators.epts(() => this.selectedDocument !== 2) ]);
        this.eptsDate?.setValidators([ OsagoValidators.eptsDate(() => this.selectedDocument !== 2) ]);
        this.vin?.setValidators([ OsagoValidators.vinNumber(() => this.selectedIdentifier !== 0) ]);
        this.vinHide?.setValidators([ OsagoValidators.vinNumber(() => this.selectedIdentifier !== 0) ]);
        this.chassisNumber?.setValidators([ OsagoValidators.chassisNumber(() => this.selectedIdentifier !== 2) ]);
        this.bodyNumber?.setValidators([ OsagoValidators.bodyNumber(() => this.selectedIdentifier !== 1) ]);
    }

    // Загрузка моделей авто
    private loadModels(brandId: number, selectModel = false): void {
        this.models$.next([]);
        this.modificationEnabled = false;
        this.modelsLoading = true;
        this.modelComponent?.enable();
        this.modelComponent?.clear();
        this._subscription.add(
            this.osagoService.getModels(brandId)
                .pipe(take(1))
                .subscribe({
                    next: (result) => {
                        this.models$.next(result);
                        this.modelsLoading = false;
                        if (selectModel && this.carData != null) {
                            const model = this.getModel(this.carData.modelId);
                            if (model) {
                                window.setTimeout(() => {
                                    this.modelComponent?.select(model);
                                    if (this._initialYear != null) {
                                        this.productionYear?.updateValueAndValidity();
                                    }
                                });
                            }
                        } else if (selectModel && this._initialBrandModel != null && this._initialBrandModel.modelId != null) {
                            const model = this.getModel(this._initialBrandModel.modelId);
                            if (model != null) {
                                window.setTimeout(() => {
                                    this.modelComponent?.select(model);
                                    if (this._initialYear != null) {
                                        this.productionYear?.updateValueAndValidity();
                                    }
                                });
                            }
                        } else if (result != null && result.length > 0) {
                            this.modelComponent?.open();
                        }
                    },
                    error: () => this.appService.error()
                })
        );
    }

    // Марка авто
    private getBrand(id: number | undefined) {
        return this.brands.find(x => x.id === id);
    }

    // Модель автомобиля
    private getModel(id: number | undefined) {
        return this.models$.value.find(x => x.id === id);
    }

    // Закрыть окно LicenseModal
    public closeLicenseModal(event: boolean): void {

    }

    // Сохраняем стейт документов
    private saveState(): void {
        // this.osagoService.onDocumentsComplete(this.getDocument(), this.getIdentifier());
    }

    // Получить документ
    private getDocument(): OsagoCarDocument {
        return {
            documentIssueDate: this.getDocumentIssueDate(),
            documentNumber: this.getDocumentNumber(),
            documentSeries: this.getDocumentSeries(),
            documentType: this.getDocumentType()
        };
    }

    // Получить тип номера
    private getIdentifier(): OsagoIdentifier {
        const identifier = typeof this.selectedIdentifier === 'string'
            ? parseInt(this.selectedIdentifier)
            : this.selectedIdentifier;
        switch (identifier) {
            case 0:
                return {
                    vin: TextMaskHelper.removeMask(this.vin!.value || '', 17)
                };
            case 1:
                return {
                    bodyNumber: this.bodyNumber!.value
                };
            default:
                return {
                    chassisNumber: this.chassisNumber!.value
                };
        }
    }

    // Получить тип документа
    private getDocumentType(): string {
        const document = typeof this.selectedDocument === 'string' ? parseInt(this.selectedDocument) : this.selectedDocument;
        switch (document) {
            case 0:
                return 'PTS';
            case 1:
                return 'STS';
            case 2:
                return 'ePTS';
        }
        return '';
    }

    // Получить номер типа документа
    private getDocumentNumberType(documentType: string): number {
        switch (documentType) {
            case 'PTS':
                return 0;
            case 'STS':
                return 1;
            case 'ePTS':
                return 2;
        }
        return 1;
    }

    // Получить дату выдачи документа
    private getDocumentIssueDate(): string {
        const document = typeof this.selectedDocument === 'string' ? parseInt(this.selectedDocument) : this.selectedDocument;
        let result = '';
        switch (document) {
            case 0:
                result = this.ptsDate.value || '';
                break;
            case 1:
                result = this.stsDate.value || '';
                break;
            case 2:
                result = this.eptsDate.value || '';
                break;
        }

        return result.length > 0
            ? TextMaskHelper.removeMask(Converter.toDateRu(result))
            : result;
    }

    // Получить номер документа
    private getDocumentNumber(): string {
        const document = typeof this.selectedDocument == 'string' ? parseInt(this.selectedDocument) : this.selectedDocument;
        switch (document) {
            case 0:
                return TextMaskHelper.removeMask(this.pts!.value || '', 10).substr(4);
            case 1:
                return TextMaskHelper.removeMask(this.sts!.value || '', 10).substr(4);
            case 2:
                return TextMaskHelper.removeMask(this.epts!.value  || '', 15);
        }

        return '';
    }

    // Получить серию документа
    private getDocumentSeries(): string | undefined {
        const document = typeof this.selectedDocument == 'string' ? parseInt(this.selectedDocument) : this.selectedDocument;
        switch (document) {
            case 0:
                return TextMaskHelper.removeMask(this.pts!.value || '', 10).substr(0, 4);
            case 1:
                return TextMaskHelper.removeMask(this.sts!.value || '', 10).substr(0, 4);
            case 2:
                return undefined;
        }

        return '';
    }
}
