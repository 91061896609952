import { Component, AfterViewInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, AbstractControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { DictionaryItem } from "../../../../../../../common-lib/src/lib/models";
import { Converter } from "../../../../../../../common-lib/src/lib/utils/converters";
import { environment } from "../../../../../environments/environment";
import { ApplicationService } from "../../../../services";
import { OsagoValidators, TextMaskHelper } from "../../helpers";
import { FormsService, OsagoService, PriceCalculatorService } from "../../services";
import * as moment from 'moment-mini';
import { OsagoPolicy, PreviousPolicy } from "../../models";

declare var $: any;

@Component({
	templateUrl: './contacts.component.html'
})

export class ContactsComponent implements AfterViewInit, OnDestroy {
	contactsForm: FormGroup;
	numberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	showPreviousPolicy = false;
	isFormError = false;
	startDate: string;
    isHover = false;

	terms = [
        new DictionaryItem(3, "3 месяца"),
        new DictionaryItem(6, "6 месяцев"),
        new DictionaryItem(9, "9 месяцев"),
        new DictionaryItem(12, "12 месяцев")
    ];

	seriesTypes = [
		new DictionaryItem(0, "ХХХ"),
		new DictionaryItem(1, "ССС"),
		new DictionaryItem(2, "МММ"),
		new DictionaryItem(3, "ККК"),
		new DictionaryItem(4, "ЕЕЕ"),
		new DictionaryItem(5, "ВВВ"),
		new DictionaryItem(6, "РРР"),
		new DictionaryItem(7, "ТТТ"),
		new DictionaryItem(8, "ННН"),
		new DictionaryItem(9, "ААН"),
		new DictionaryItem(10, "ААВ")
	];

    private _validatorsSet = false;
    private isSubmit = false;
    private needCheckValidity = false;
    private _subscription = new Subscription();
    private _interval = 0;

    constructor(
        formBuilder: FormBuilder,
        private appService: ApplicationService,
        private formsService: FormsService,
        private osagoService: OsagoService,
		private priceService: PriceCalculatorService
    ) {
		const minDate = moment().add(4, "day");
		this.startDate = minDate.format("DD.MM.YYYY");

		let policyDate = minDate.format("YYYY-MM-DD");
		if (osagoService.policy?.policyStartDate != null && osagoService.policy?.policyStartDate.length > 0) {
			const date = moment(osagoService.policy?.policyStartDate, "DD.MM.YYYY", true);
			if (date.isValid())
				policyDate = date.format("YYYY-MM-DD");
			else
				policyDate = osagoService.policy?.policyStartDate;
		}

		let term = 12;
		if (osagoService.policy?.osagoUtilisationPeriod != null)
			term = osagoService.policy?.osagoUtilisationPeriod;

        let series = null;
        if (osagoService.previousPolicy != null && osagoService.previousPolicy.previousPolicySeries != null && osagoService.previousPolicy.previousPolicySeries.length == 3)
            series = this.seriesTypes.findIndex(x => x.value == osagoService.previousPolicy!.previousPolicySeries);

        let number = null;
        if (osagoService.previousPolicy != null && osagoService.previousPolicy.previousPolicyNumber != null)
            number = TextMaskHelper.removeMask(osagoService.previousPolicy!.previousPolicyNumber, 10);

        this.showPreviousPolicy = osagoService.previousPolicy != null;

        this.contactsForm = formBuilder.group({
			"is-previous-policy": new FormControl(this.showPreviousPolicy, null),
			"policy-date": new FormControl(policyDate, null),
            "series": new FormControl(series, null),
            "number": new FormControl(number, null),
            "term": new FormControl(term, { updateOn: "change" })
        }, { updateOn: "change" });

		this._subscription.add(
            this.term?.valueChanges.subscribe(value => this.priceService.setTerm(value))
        );

        this._subscription.add(
            this.term?.valueChanges.subscribe(value => this.priceService.setTerm(value))
        );

        this._subscription.add(
            this.contactsForm.valueChanges.subscribe(x => {
                this.saveState();
                this.needCheckValidity = true;
            })
        );

        this._subscription.add(
            this.isPreviousPolicy!.valueChanges.subscribe((value) => {
                if (value != null)
                    this.showPreviousPolicy = value;
            })
        );

        this._interval = window.setInterval(() => {
            if (this.needCheckValidity) {
                this.needCheckValidity = false;
                this.isFormError = this.isSubmit && !this.formsService.isFormValid(this.contactsForm, false, false, false);
            }
        }, 100)
    }

    private setValidators() {
        if (this._validatorsSet)
            return;

        this._validatorsSet = true;
		this.policyDate?.setValidators([ OsagoValidators.policyDate() ]);
        this.term?.setValidators([ OsagoValidators.term() ]);
        this.series?.setValidators([ OsagoValidators.previousPolicySeries(() => this.showPreviousPolicy != true) ]);
        this.number?.setValidators([ OsagoValidators.previousPolicyNumber(() => this.showPreviousPolicy != true) ]);
    }

    ngAfterViewInit() {
        this.appService.scrollToElement("contacts-anchor");
        this.restoreDadata();
        this.osagoService.onYandexReachGoal("CONTACTS_GOAL");
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
        window.clearInterval(this._interval);
    }

	get isPreviousPolicy(): AbstractControl | null {
        return this.contactsForm?.get('is-previous-policy') || null;
    }

	get policyDate(): AbstractControl | null{
        return this.contactsForm.get('policy-date');
    }

    get term(): AbstractControl | null{
        return this.contactsForm.get('term');
    }

    get series(): AbstractControl | null{
        return this.contactsForm.get('series');
    }

    get number(): AbstractControl | null{
        return this.contactsForm.get('number');
    }

    onMouseEnter() {
		this.isHover = true;
	}

	onMouseLeave() {
		this.isHover = false;
	}

    onBackClick() {
        this.saveState();
        this.appService.onContactsBack();
    }

    onSubmit() {
        this.isSubmit = true;
        this.setValidators();
        if (!this.formsService.isFormValid(this.contactsForm))
            return;

        const policy: OsagoPolicy = {
            osagoUtilisationPeriod: this.term?.value,
            policyStartDate: TextMaskHelper.removeMask(Converter.toDateRu(this.policyDate?.value || ''), 10)
        };

        const previousPolicy: PreviousPolicy = {
            previousPolicySeries: this.getSeries(this.series?.value),
            previousPolicyNumber: TextMaskHelper.removeMask(this.number!.value || "", 10)
        };

        const term = this.term?.value != null
            ? this.terms[this.terms.findIndex(x => x.id == policy.osagoUtilisationPeriod)].value
            : '';

        this.osagoService.onPolicyComplete(policy, term,  this.showPreviousPolicy ? previousPolicy : null);
        this.appService.onContactsComplete();
    }

    private getSeries(index: any) {
        const arrayIndex = parseInt(index);
        if (!isNaN(arrayIndex) && arrayIndex >= 0 && arrayIndex < this.seriesTypes.length)
            return this.seriesTypes[arrayIndex].value;

        return "";
    }

    private restoreDadata() {

    }

    private saveState() {
		const policy: OsagoPolicy = {
            osagoUtilisationPeriod: this.term?.value,
            policyStartDate: TextMaskHelper.removeMask(Converter.toDateRu(this.policyDate?.value || ''), 10)
        };

        const previousPolicy: PreviousPolicy = {
            previousPolicySeries: this.getSeries(this.series?.value),
            previousPolicyNumber: TextMaskHelper.removeMask(this.number!.value || "", 10)
        };

        const term = this.term?.value != null
            ? this.terms[this.terms.findIndex(x => x.id == policy.osagoUtilisationPeriod)].value
            : '';

        this.osagoService.onPolicyComplete(policy, term, this.showPreviousPolicy ? previousPolicy : null);
    }
}
