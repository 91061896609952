import { HttpClient } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpService } from "./http.service";
import { ApplicationService } from "./application.service";
import { LoggingService } from "./loggingService";

@Injectable()
export class GlobalErrorHandler extends HttpService implements ErrorHandler,  OnDestroy {
    private _subscription = new Subscription();

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        private appService: ApplicationService,
        protected loggingService: LoggingService) {
        super(http, loggingService);
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    handleError(error: Error) {
        console.error("Error from global error handler", error);

        var message =  "Global error handler - " + (error.message ?? JSON.stringify(error));
        this.appService.internalError(message);
    }
}