import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Сервисы
import { ClientService } from '../../../../services/client.service';
import { ApplicationService } from '../../../../services';

// Модели
import { OsagoApplication } from '../../models';
import * as moment from 'moment-mini';
import { OsagoService } from '../../services';


@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

    // Закрыть окно
    @Output() close = new EventEmitter<boolean>();
    // Данные в компоненте редактируются
    @Input() set editMode(value: boolean) {
        this.edit = value;
    }
    public edit = false;

    constructor(private readonly clientService: ClientService,
                private readonly appService: ApplicationService,
                private readonly osagoService: OsagoService) {
        this.osago = this.clientService.getCurrentApplication();
    }

    // Данные осаго
    public osago: OsagoApplication | null;

    // Показать окно Данные авто
    public isShowVendors = false;

    // Показать окно Данные авто
    public isShowDrivers = false;

    // Показать окно Собственник
    public isShowOwner = false;

    // Показать окно Страхователь
    public isShowInsurer = false;

    // Состояния редактируемых окон
    // Собственник
    public ownerMode = false;
    // Страхователь
    public insurerMode = false;
    // Контакты
    public contactsMode = false;

    // Показать окно Контакты
    public isShowContacts = false;

    // Дата окончания полиса осаго
    public finishOsagoDate: string | undefined;

    // Индекс редактируемого водителя
    public driverIndex = 0;

    // Номер телефона водителя с маской
    public phone: string | undefined;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.initOsagoData();
    }
    // --------------------------------------------------------------------------

    // Инициализация данных осаго
    public initOsagoData(): void {
        // Маска для номера телефона
        this.initPhoneNumber(this.osago?.owner?.phone ? this.osago?.owner?.phone : this.osago?.insured?.phone);
        this.finishOsagoDate = moment(this.osago?.policyParameters?.policyStartDate, 'DD.MM.YYYY').add(1, 'year').toLocaleString();
    }

    // Изменить данные авто
    public updateAutoData(): void {

    }

    // Нажали "Продолжить"
    public onSubmit(device: string): void {
        this.appService.device = device;
        this.osagoService.onYandexReachGoal('CONFIRMATION_GOAL');
        this.osagoService.clearPaymentResult();
        window.clearTimeout(this.osagoService.sendApplicationObservableTimeout);
        this.appService.onOfferConfirmed();
    }

    // Показываем окно Собственник
    public changeShowOwner(): void {
        this.isShowOwner = true;
    }

    // Ставим маску на номер телефона
    public initPhoneNumber(phoneNumber: string | undefined): void {
        const maskedPhone = phoneNumber?.toString();
        if (maskedPhone != null && maskedPhone.length === 11) {
            this.phone = `+7(${ maskedPhone.substr(1, 3) })${ maskedPhone.substr(4, 3) }-${ maskedPhone.substr(7, 2) }-${ maskedPhone.substr(9, 2) }`;
        }
    }

    // Событие закротого модального окна
    public changeClose(event: any): void {
        this.isShowVendors = false;
        this.isShowDrivers = false;
        this.isShowOwner = false;
        this.initOsagoData();
        this.close.emit(event);
    }

    // Переход по роуту
    public navigate(route: string, state: any = undefined): void {
        this.osagoService.fromUrlOrSms = true;
        this.osagoService.offersReceived = true;
        this.close.emit(true);
        this.appService.navigate(route, state);
    }
}
