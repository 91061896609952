import { OsagoDriverLicense } from "./osagoDriverLicense.model";
import { OsagoOldDriverLicense } from "./osagoOldDriverLicense.model";

export class OsagoDriver {
    constructor(
        public birthDate: string,
        public driverLicense: OsagoDriverLicense,
        public experienceStartDate: string,
        public firstName: string,
        public gender: string,
        public lastName: string,
        public middleName: string,
        public rememberOnlyYear: boolean,
        public oldDriverLicense?: OsagoOldDriverLicense,
        public isShowDriver?: boolean
    ) { }

    public static create(): OsagoDriver {
        return {
            birthDate: "",
            driverLicense: {
                isForeignLicense: false,
                licenseIssueDate: "",
                licenseNumber: "",
                licenseSeries: ""
            },
            experienceStartDate: "",
            firstName: "",
            gender: "",
            lastName: "",
            middleName: "",
            rememberOnlyYear: false
        };
    }
}
