export class GenderHelper {
    static MALE = "Male";
    static FEMALE = "Female";

    static toGender(value: string | null): string {
        switch (value?.toLowerCase()) {
            case "male":
                return GenderHelper.MALE;
            case "female":
                return GenderHelper.FEMALE;
            default:
                return this.getRandomGender();
        }
    }

    private static getRandomGender(): string {
        const rnd = Math.floor((Math.random() * 10) + 1);
        return rnd < 5 ? GenderHelper.MALE : GenderHelper.FEMALE;
    }
}