export class OsagoOwnerViewModel {
    public address: string | undefined;
    public addressDadata: any;
    public birthDate: string | null;
    public fio: string | null;
    public firstName: string | null;
    public lastName: string | null;
    public middleName: string | null;
    public passport: string | null;
    public passportDate: string | null;
    public passportIssuer: string | null;
    public passportIssuerCode: string | null;
    public selectedDriver: number | null;

    constructor() {
        this.address = '';
        this.addressDadata = null;
        this.birthDate = null;
        this.fio = null;
        this.firstName = null;
        this.lastName = null;
        this.middleName = null;
        this.passport = null;
        this.passportDate = null;
        this.passportIssuer = null;
        this.passportIssuerCode = null;
        this.selectedDriver = null;
    }
}
