<div class="cross-tem">
    <div class="checkbox-item">
        <input type="checkbox" [id]="cross.id" [checked]="cross.id === selectedId" (change)="changeCross(cross)" name="cross" [disabled]="isLoading">
        <label [for]="cross.id"><span>{{cross.name}}</span></label>
    </div>
    <div class="price">{{cross.price}} ₽</div>
    <div *ngIf="cross.description">
        <svg (click)="showCross = !showCross" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M28.6232 16.3266C28.3819 16.1175 28.0546 16 27.7134 16C27.3722 16 27.0449 16.1175 26.8036 16.3266L20.902 21.4423C20.6574 21.6459 20.3314 21.7595 19.9922 21.7595C19.6531 21.7595 19.3271 21.6459 19.0824 21.4423L13.1809 16.3266C12.9382 16.1234 12.6131 16.011 12.2757 16.0135C11.9383 16.0161 11.6155 16.1334 11.3769 16.3402C11.1383 16.547 11.003 16.8268 11 17.1193C10.9971 17.4118 11.1268 17.6935 11.3612 17.9039L17.2615 23.0196C17.62 23.3304 18.0456 23.577 18.5141 23.7452C18.9825 23.9134 19.4846 24 19.9916 24C20.4986 24 21.0007 23.9134 21.4691 23.7452C21.9376 23.577 22.3632 23.3304 22.7217 23.0196L28.6232 17.9039C28.8645 17.6947 29 17.4111 29 17.1153C29 16.8195 28.8645 16.5358 28.6232 16.3266Z" fill="#98A2B3"/>
        </svg>
    </div>

    <div *ngIf="showCross" class="collapse" [innerHTML]="transformDescription(cross.description)"></div>
</div>
