import { AfterViewInit, Component } from '@angular/core';
import { ApplicationService } from '../../services';

@Component({
    templateUrl: './failed-payment.component.html'
})

export class FailedPaymentComponent implements AfterViewInit {
    constructor(private appService: ApplicationService) { }

    ngAfterViewInit() {
        this.appService.onYandexReachGoal("FAILED_PAYMENT_GOAL");
    }
}
