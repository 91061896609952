import {Component, Output, EventEmitter} from "@angular/core";
import {BaseFormComponent} from "../base/base-form.component";

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html'
})
export class CheckboxComponent extends BaseFormComponent {
    @Output() changeCheckbox = new EventEmitter<Event>();

    onChange(evt: Event) {
        this.changeCheckbox.emit(evt);
    }
}
