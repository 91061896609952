import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Offer } from '../../models';
import { OsagoService } from '../../services';

@Component({
    selector: 'app-pre-offer',
    templateUrl: './pre-offer.component.html',
    styleUrls: ['./pre-offer.component.scss']
})
export class PreOfferComponent {
    @Input() offer: Offer | null = null;
    @Input() isDisabled = false;
    @Input() isProfit = false;
    @Input() isRecommended = false;
    @Input() showStartDate = false;
    @Input() isDenied = false;
    @Input() isActualOffer = false;
    @Input() isHidden = false;
    @Output() selected: EventEmitter<void> = new EventEmitter<void>();
    @Output() clicked: EventEmitter<Offer> = new EventEmitter<Offer>();
    isHover = false;
    isShowGiftModal = false;
    constructor(private osagoService: OsagoService) {
    }

    get checkPriorityOffer(): boolean {
        return this.osagoService.getApiKey() === '090c9d5eb76f486d85094d165e324b75';
    }

    // onMouseEnter() {
    //     this.isHover = true;
    // }
    //
    // onMouseLeave() {
    //     this.isHover = false;
    // }

    // Оформить
    public onButtonClick(): void {
        // После выбора офера, перестаем делать запрос но новых офферов
        // this.osagoService.offersReceived = true;
        this.selected.next();
    }

    public onTooltipClick(): void {
        if (this.offer != null) {
            this.clicked.next(this.offer);
        }
    }
}
