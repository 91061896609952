import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LicensePlateService {
    public numberLength = 6;
    private numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    private letters = ['а', 'в', 'е', 'к', 'м', 'н', 'о', 'р', 'с', 'т', 'у', 'х', 'х', 'А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х', 'Х'];
    private transcriptLetters = ['f', 'd', 't', 'r', 'v', 'y', 'j', 'h', 'c', 'n', 'e', '{', '[', 'F', 'D', 'T', 'R', 'V', 'Y', 'J', 'H', 'C', 'N', 'E', '{', '['];

    public isKeyAllowed(char: string, position: number): boolean {
        if ((position === -1 || position === 0 || position === 4 || position === 5) && this.isAllowedLetter(char)) {
            return true;
        } else if ((position === -1 || position === 1 || position === 2 || position === 3) && this.isNumeric(char)) {
            return true;
        }
        return false;
    }

    public getPlateNumberValue(value: string, transcript = true): string {
        let result = '';
        let index = 0;
        while (index < value.length) {
            if (this.isKeyAllowed(value.substr(index, 1), result.length)) {
                result += this.getChar(value.substr(index, 1), transcript);
            }

            if (result.length === 6) { break; }

            index++;
        }

        return result;
    }

    public getLicenseNumber(license: string): string {
        if (!this.isValidLicense(license)) { return ''; }
        return license.toUpperCase().substr(0, this.numberLength);
    }

    public getLicenseRegion(license: string): string {
        if (!this.isValidLicense(license)) { return ''; }
        return license.substr(this.numberLength);
    }

    private isValidLicense(license: string): boolean {
        if (license.length !== 8 && license.length !== 9) { return false; }

        const region = license.substr(this.numberLength).replace(/\D/g, '');
        if (region.length !== 2 && region.length !== 3) { return false; }

        const numb = license.substr(0, this.numberLength);
        const allowedNumber = this.getPlateNumberValue(numb, false);
        return allowedNumber.length === this.numberLength;
    }

    private getChar(char: string, transcript = true): string {
        let index = this.numbers.indexOf(char);
        if (index !== -1) { return this.numbers[index]; }

        index = this.letters.indexOf(char);
        if (index !== -1) { return this.letters[index].toUpperCase(); }

        if (!transcript) { return ''; }

        index = this.transcriptLetters.indexOf(char);
        if (index !== -1) { return this.letters[index].toUpperCase(); }

        return '';
    }

    private isNumeric(char: string): boolean {
        return this.numbers.indexOf(char) !== -1;
    }

    private isAllowedLetter(char: string): boolean {
        return this.letters.indexOf(char) !== -1
            || this.transcriptLetters.indexOf(char) !== -1;
    }
}
