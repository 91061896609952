import { OsagoPassport } from "./osagoPassport.model";

export class OsagoOwner {
    constructor(
        public birthDate?: string,
        public email?: string,
        public firstName?: string,
        public gender?: string,
        public lastName?: string,
        public middleName?: string,
        public ownerIsInsured?: boolean,
        public passport?: OsagoPassport,
        public phone?: string,
    ) {}
}