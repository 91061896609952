<div class="step14 scroll-target-wide">
    <div class="widget__company" *ngIf="offer">
        <div class="widget__company-left">
            <div class="widget__company-title">
                <img [src]="offer?.logo" [alt]="offer?.altText"/>

                <span>
                {{ offer?.productName || offer?.insurerName }}
                    <div class="widget__company-rate">
                        <span class="rate-gray">Начало действия: {{ offer?.startDateDescription }}</span>
                    </div>
                </span>
            </div>
        </div>
        <div class="widget__company-right">

            <div class="widget__company-price">
                <span>{{ finalPrice | currencyRU }}</span>
                <div class="podeli-alert" *ngIf="offer?.installmentFirstPay">
                    <div class="podeli-price">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 1C3.68623 1 1 3.68623 1 7C1 10.3138 3.68623 13 7 13C10.3138 13 13 10.3138 13 7C13 3.68623 10.3138 1 7 1ZM7 11.4959C4.5175 11.4959 2.50409 9.4825 2.50409 7C2.50409 4.5175 4.5175 2.50409 7 2.50409C9.4825 2.50409 11.4959 4.5175 11.4959 7C11.4959 9.4825 9.4825 11.4959 7 11.4959Z" fill="#444" stroke="#444" stroke-width="0.454001"/>
                            <path d="M6.99805 1.80551C6.99805 2.1765 7.26843 2.49593 7.6369 2.54875C9.60882 2.82919 11.1682 4.38861 11.4487 6.36053C11.5015 6.729 11.8209 6.99939 12.1919 6.99939C12.6459 6.99939 13.0018 6.59947 12.9377 6.14925C12.5642 3.51584 10.4803 1.432 7.84693 1.05849C7.39796 0.995613 6.99805 1.35151 6.99805 1.80551Z" fill="red" stroke="red" stroke-width="0.454001"/>
                        </svg>
                        {{ offer?.installmentFirstPay | currencyRU }}  x 4 ⓘ</div>
                    <div class="alert-window">
                        <img src="/assets/images/podeli-alert-img.png" alt="Podeli">
                    </div>
                </div>
            </div>
        </div>

        <div class="widget__company-button">
            <button *ngIf="!appService._payUrl && isLoading" class="blue-button gray-button pc" type="button" disabled>
                <span>Создаем полис {{ timer }}</span>
            </button>
            <button *ngIf="!appService._payUrl && !isLoading" (click)="createPayment()" class="blue-button payment-btn pc" type="button">
                <span>Перейти к оплате</span>
            </button>
            <button *ngIf="appService._payUrl" (click)="openPayment(appService._payUrl)" class="blue-button payment-btn pc" type="button">
                <span>Перейти к оплате</span>
            </button>
            <button *ngIf="offer?.hasDraft" [disabled]="!draftLink" (click)="openDraft()" class="btn-outline pc draft-btn" type="button">
                <span *ngIf="draftLink">Черновик</span>
                <span *ngIf="!draftLink">Загружаем черновик</span>
                <span *ngIf="!draftLink" id="spinner" class="spinner"></span>
            </button>
        </div>

        <div *ngIf="offer?.upsales && offer?.upsales?.length" class="cross">
            <div class="alert alert-light">Добавить к полису ОСАГО дополнительную защиту КАСКО</div>

            <ng-container *ngFor="let cross of offer?.upsales">
                <app-cross *ngIf="cross.isOptional" [cross]="cross" [isLoading]="isLoading" [selectedId]="selectedCrossId" (idSelected)="changeCross($event)"></app-cross>
            </ng-container>

        </div>
    </div>

    <div class="widget__company" *ngIf="appService.crossNotApproved">
        <div class="title-error-cross">
            Невозможно включить "КАСКО от бесполисных" в покупку
        </div>

        <div class="description-error-cross">
            К сожалению, страховая компания не подтвердила создание полиса "КАСКО от бесполисных",
            но вы можете оплатить ОСАГО перейдя по ссылке
        </div>
    </div>

    <div class="show-desktop">
        <div class="swiper">
            <div class="swiper-slider">
                <div class="swiper-title">
                    Что будет<br>
                    после оплаты
                </div>
                <div class="swiper-text">
                    После оплаты полиса, вы получите электронный чек и PDF полиса, подписанный страховой компанией
                    электронной подписью. Уже через 15-20 минут полис будет у вас на почте и внесен во все базы данных.
                    Вы всегда сможете проверить статус своего полиса на сайте Российского союза автосраховщиков (РСА)
                </div>
            </div>
            <div class="swiper-slider">
                <div class="swiper-title">
                    Бумажный полис
                    равнозначен электронному
                </div>
                <div class="swiper-text">
                    Электронный полис ничем не отличается
                    от бумажного, разве только тем, что не нужно ехать в офис. Просто распечатайте полис
                    и сохраните его фотографию в телефон.
                    При проверке сотрудникам ГИБДД достаточно показать распечатку
                    или фото на телефоне. Это не наша
                    выдумка - это гарантирует закон об ОСАГО
                </div>
            </div>
            <div class="swiper-slider">
                <div class="swiper-title">
                    Полис гарантированно попадает
                    в базу РСА и ГИБДД
                </div>
                <div class="swiper-text">
                    Как только вы оплатите страховой полис
                    - он автоматически будет переведен
                    в дейстующий статус, это безопасно
                </div>
            </div>
        </div>
    </div>

    <swiper
    [slidesPerView]="'auto'"
    class="payment-swipe show-mobile"
>
        <ng-template swiperSlide>
            <div class="swiper-slider">
                <div class="swiper-title">
                    Что будет<br>
                    после оплаты
                </div>
                <div class="swiper-text">
                    После оплаты полиса, вы получите электронный чек и PDF полиса, подписанный страховой компанией
                    электронной подписью. Уже через 15-20 минут полис будет у вас на почте и внесен во все базы данных.
                    Вы всегда сможете проверить статус своего полиса на сайте Российского союза автосраховщиков (РСА)
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide>
            <div class="swiper-slider">
                <div class="swiper-title">
                    Бумажный полис
                    равнозначен электронному
                </div>
                <div class="swiper-text">
                    Электронный полис ничем не отличается
                    от бумажного, разве только тем, что не нужно ехать в офис. Просто распечатайте полис
                    и сохраните его фотографию в телефон.
                    При проверке сотрудникам ГИБДД достаточно показать распечатку
                    или фото на телефоне. Это не наша
                    выдумка - это гарантирует закон об ОСАГО
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide>
            <div class="swiper-slider">
                <div class="swiper-title">
                    Полис гарантированно попадает
                    в базу РСА и ГИБДД
                </div>
                <div class="swiper-text">
                    Как только вы оплатите страховой полис
                    - он автоматически будет переведен
                    в дейстующий статус, это безопасно
                </div>
            </div>
        </ng-template>
    </swiper>
</div>
