import { CalendarHelper } from 'projects/osagoV2/src/app/modules/osago/helpers';
import { ApiRequest } from './apiRequest.model';
import {getAgentId} from "../../../../../osagoV2/src/app/modules/shared/functions/getAgentId";

export class ApplicationRequest extends ApiRequest {
    constructor(
        public apiKey: string,
        public productType: string,
        public cpaClientUid?: string,
        public cpaClientUid2?: string,
        public webMasterID?: string,
        public platformID?: string,
        public channelType?: string,
        public clientId?: string,
        public successPaymentUrl?: string,
        public yandexId?: string,
        public yandexCounterId?: string,
        public utm?: string,
        public referer?: string,
        public localTime?: string,
        public returnClientChannelType?: string,
        public AgentId?: string
    ) {
        super(apiKey);
        this.localTime = CalendarHelper.getLocalDateTimeString();
        this.AgentId = getAgentId();
    }
}
