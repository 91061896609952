import * as moment from "moment-mini";

export class CalendarHelper {
    static toRandonDate(year: string): string {
        return this.getRandomDayAndMonth() + year;
    }

    static getRandomDayAndMonth(): string {
        const month = this.getRandomNumber(12);
        let maxDay = 31;
        switch (month) {
            case 2:
                maxDay = 28;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                maxDay = 30;
                break;
        }

        const day = this.getRandomNumber(maxDay);
        return (day < 10 ? "0" : "")
            + day
            + "."
            + (month < 10 ? "0" : "")
            + month
            + ".";  
    }

    private static getRandomNumber(maxValue: number): number {
        return Math.floor((Math.random() * maxValue) + 1);
    }

    static getRandomDayAndMonthThisYear(): string {
        const firstOfJanuary = moment("01.01." + moment().year(), "DD.MM.YYYY", true);
        const days = moment().diff(firstOfJanuary, "days");
        if (days == 0)
            return "01.01.";
        
        const day = this.getRandomNumber(days);
        return firstOfJanuary.add(day, "days").format("DD.MM.");
    }

    public static getLocalDateTimeString(): string {
        return moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    }
}