import { AfterViewInit, Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// Модели
import { Offer } from '../../models';

// Сервисы
import { ApplicationService } from '../../../../services';
import { OsagoService } from '../../services';

@Component({
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements AfterViewInit {
    // Выбранные офер
    public offer: Offer | null;
    // Ошибка
    public isError = false;
    // Показать окно с оплатой
    public isShowPayModal = false;
    // Ссылка на систему оплаты
    public paySrc: SafeResourceUrl | undefined;

    constructor(
        private appService: ApplicationService,
        private osagoService: OsagoService,
        private sanitizer: DomSanitizer
    ) {
        this.offer = osagoService.selectedOffer;
        this.isError = osagoService.isPaymentError;
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngAfterViewInit(): void {
        this.subscribePaymentLink();
        this.osagoService.onYandexReachGoal('CONFIRMATION_GOAL');
        this.appService.scrollToElement('confirmation-anchor');
        if (typeof this.appService.payUrl === 'string') {
            this.paySrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.appService.payUrl);
        }
    }
    // --------------------------------------------------------------------------

    // Перейти к оплате
    public onOfferConfirmed(device: string): void {
        this.appService.device = device;
        this.osagoService.onYandexReachGoal('CONFIRMATION_GOAL');
        this.osagoService.clearPaymentResult();
        window.clearTimeout(this.osagoService.sendApplicationObservableTimeout);
        this.appService.onOfferConfirmed();
    }

    // Возвращаемся на страницу оферов
    public onReturnToOffers(): void {
        this.appService.returnToOffers();
    }

    // Следим за получением ссылки на оплату и открываем модальное окно с оплатой
    private subscribePaymentLink(): void {
        this.osagoService.paymentResult$.subscribe((paymentLink) => {
            // if (paymentLink) {
            //     this.isShowPayModal = true;
            // }
        });
    }
}
