<div class="step4">

    <div id="price-anchor" class="widget__wrapper scroll-target">
        <div class="widget__header">
            <div class="widget__header-title">
                <div class="widget__header-title-text scroll-target">Предварительный расчет</div>
            </div>
            <div class="alert-container">
                <app-alert>
                    <p>
                        Согласно закону об ОСАГО, страховые компании могут изменить стоимость на основе
                        указанных данных. Если окончательная цена вас не устроит, вы сможете выбрать
                        другую компанию
                    </p>
                </app-alert>
            </div>
            <div *ngIf="isLoading" class="widget__loader active"></div>
        </div>

        <div class="widget__body">

            <app-pre-offer *ngFor="let offer of filteredOffers; let i=index;"
                           [offer]="offer"
                           [isRecommended]="i == recommendedIndex"
                           [isProfit]="i == profitIndex"
                           (selected)="onOfferSelected(offer)"
                           (clicked)="onOfferTooltipClicked($event)"
            ></app-pre-offer>

            <app-pre-offer *ngFor="let offer of offers; let i=index;"
                           [offer]="offer"
                           (selected)="onOfferSelected(offer)"
                           (clicked)="onOfferTooltipClicked($event)"
                           [isHidden]="!showAllOffers"
            ></app-pre-offer>

            <div class="widget__company-more">
                <span *ngIf="offers.length > 6 && !showAllOffers"
                      (click)="toggleOffers()">Показать больше предложений</span>
                <span *ngIf="offers.length > 6 && showAllOffers" (click)="toggleOffers()">Показать меньше</span>
            </div>

        </div>

    </div>

    <ng-container *ngIf="isPopupOpen">
        <div class="popupBg popupBg-active"></div>
        <div class="popupCalcInfo popupCalcInfo-active">
            <app-popup-price
                [model]="priceSummary"
                (closed)="togglePopup()">
            </app-popup-price>
        </div>
    </ng-container>
