import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from '../base/base-form.component';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html'
})
export class SelectComponent extends BaseFormComponent implements OnInit {
    public items: any[] = [];
    public isActive = false;
    public selectedValue: string | null = null;

    @Input()
    set options(val: any[] | null) {
        this.items = val || [];
        if (this.items.length === 0) {
            this.selectedValue = '';
        }

        if (this.selectedValue == null) {
            const index = this.items.findIndex(x => x.value === this.selectedValue);
            if (index === -1) {
                this.selectedValue = '';
            }
        }
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        if (this.fieldControl != null) {
            const index = this.items.findIndex(x => x.id === this.fieldControl.value);
            if (index !== -1) {
                this.selectedValue = this.items[index].value;
            }
        }
    }

    // --------------------------------------------------------------------------

    public toggleActive(active: boolean | null = null): void {
        if (active === true) {
            this.isActive = true;
            return;
        }

        if (active === false) {
            this.isActive = false;
            return;
        }

        this.isActive = !this.isActive;
    }

    public selectValue(option: any): void {
        this.selectedValue = option.value;
        this.toggleActive(false);
        this.updateFieldValue(option.id);
    }
}
