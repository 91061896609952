import { Directive, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Directive()
export class BaseComponent {
    @ViewChild('input') protected nativeElement!: ElementRef;
    @Input() fieldId = '';
    @Input() fieldName = '';
    @Input() helperText: string | null = null;
    @Input() placeHolder: string | null = null;
    @Input() postfix: string | null = null;
    @Input() isReadOnly = false;
    @Input() isDisabled = false;
    @Input() isCentered = false;
    @Input() adjustPlaceholder: string | null = null;
    @Input() tabindex: string | null = null;
    @Input() checkHtml = true;
    @Output() filled = new EventEmitter<void>();
    @Output() moveLeft = new EventEmitter<void>();
    public _htmlElement: any | null = null;
    public currentValue!: string;
    public value!: string;

    constructor() { }

    public updateValue(): void {
        this.value = this.currentValue;
    }

    public setValue(newValue: string): void {
        this.currentValue = newValue;
        this.nativeElement.nativeElement.value = newValue;
    }

    public focusLeft(): void {
        if (this._htmlElement == null) {
            this._htmlElement = document.getElementById(this.fieldId);
        }

        if (this._htmlElement != null) {
            this._htmlElement.focus();
            window.setTimeout(() => this.setCaretPosition(this._htmlElement, 0), 0);
        }
    }

    public focusRight(): void {
        if (this._htmlElement == null) {
            this._htmlElement = document.getElementById(this.fieldId);
        }

        if (this._htmlElement != null) {
            this._htmlElement.focus();
            window.setTimeout(() => {
                const value = this._htmlElement.value || '';
                this.setCaretPosition(this._htmlElement, value.length);
            }, 0);
        }
    }

    protected getCaretPosition(elem: any): number {
        return elem.selectionStart || elem.selectionStart === 0
            ? elem.selectionStart
            : null;
    }

    protected setCaretPosition(elem: any, position: number): void {
        if (elem.createTextRange) {
            const range = elem.createTextRange();
            range.move('character', position);
            range.select();
        } else if (elem.selectionStart) {
            elem.setSelectionRange(position, position);
        }
    }
}
