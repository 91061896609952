import { ApiError, ApiResponse } from "../../../../../../common-lib/src/lib/models";
import { OsagoPaymentLink } from "./osagoPaymentLink.model";

export class OsagoPaymentLinkResponse extends ApiResponse {
    constructor(
        public result: boolean,
        public error?: ApiError,
        public value?: OsagoPaymentLink
    ) {
        super(
            result,
            error
        );
    }
}