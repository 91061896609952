export class Converter {
    static toNumber(value: string | number) {
        if (typeof value === 'string')
            return parseInt(value);
        else
            return value;
    }

    static formatNumber(value: number | undefined, thousandSeparator: string | null = null, postfix: string | null = null) {
        let result = thousandSeparator != null
            ? value?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandSeparator)
            : value?.toString();

        if (postfix != null && result != null && result.length > 0)
            result += " " + postfix;

        return result;
    }

    static toDate(value: string | null = null): string | null {
        return value != null && value.length > 10 ? value.substr(0, 10) : value;
    }

    static toDateRu(value: string): string {
        let result = value.length > 10 ? value.substr(0, 10) : value;
        if (result.indexOf("-") != -1)
            result = result.substr(8, 2) + "." + result.substr(5, 2) + "." + result.substr(0, 4);

        return result;
    }

    static toPhoneRu(value: string): string {
        if (value != null && value.length == 11 && value.substr(0, 1) == "8")
            return "7" + value.substr(1);

        if (value != null && value.length == 10)
            return "7" + value;

        return value;
    }
}