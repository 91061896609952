import { ApiRequest } from "../../../../../../common-lib/src/lib/models/requests/apiRequest.model";

export class LicenseVerificationStartRequest extends ApiRequest {
    constructor(
        public apiKey: string,
        public applicationId: string | null,
        public licensePlate: string,
        public captcha?: string
    ) {
        super(apiKey);
    }
}