import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { delay } from "rxjs/operators";
import { ApiResponse } from "../../../../common-lib/src/lib/models";
import { ClientService } from "./client.service";

@Injectable()
export class ClientMockService extends ClientService {
    // Обновляем данные клиента в кэше
    public updateCache(): void {
    }
}