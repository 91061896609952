import { AfterViewInit, Component } from "@angular/core";
import { Offer, PriceSummary } from "../../models";
import { ApplicationService } from "../../../../services";
import { OsagoService } from "../../services/osago.service";
import { PriceCalculatorService } from "../../services/priceCalculator.service";

@Component({
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss']
})

export class PriceComponent implements AfterViewInit {
    filteredOffers: Offer[] = [];
    isLoading = true;
    recommendedInsurer: string | null = null;
    showAllOffers = false;
	recommendedIndex = 0;
    profitIndex = 1;
	isPopupOpen = false;
    priceSummary: PriceSummary | null = null;
    offers: Offer[] = [];

    private _preOffers: Offer[] = [];
    private _currentPreofferIndex = 0;
    private readonly filteredOffersCount = 6;

    constructor(
        private appService: ApplicationService,
        private osagoService: OsagoService,
        private priceService: PriceCalculatorService
    ) {
        this.recommendedInsurer = appService.recommendedInsurer;

        priceService.price$.subscribe((value) => {
            if (value != null) {
                this._preOffers = osagoService.getPreapprovedOffers(value, priceService.basePrice);
                this.waitForOffers(true);
            }
        });
    }

    ngAfterViewInit() {
        this.osagoService.onYandexReachGoal("PRICE_GOAL");
        this.appService.scrollToElement("price-anchor", "price-offers");
    }

    public onOfferSelected(offer: Offer): void {
        this.osagoService.onPreOfferSelected(offer);
        this.onSubmit();
    }

    private onSubmit(): void {
        this.appService.onPriceComplete();
    }

    onBackClick() {
        this.appService.onPriceBack();
    }

	onOfferTooltipClicked(offer: Offer) {
        this.priceSummary = this.priceService.getPriceSummary(offer);
		this.togglePopup();
	}

    toggleOffers() {
        this.showAllOffers = !this.showAllOffers;
    }

	togglePopup() {
		this.isPopupOpen = !this.isPopupOpen;
	}

    private waitForOffers(initial = false) {
        var timeout = this.osagoService.preOffer != null
            ? 0
            : initial
                ? 1500 + this.getWaitTimeout()
                : this.getWaitTimeout();

        window.setTimeout(() => {
            this._currentPreofferIndex++;
            if (this._currentPreofferIndex <= this._preOffers.length) {
                if (this._currentPreofferIndex <= 10)
                    this.filteredOffers.push(this._preOffers[this._currentPreofferIndex - 1]);
                else
                    this.offers.push(this._preOffers[this._currentPreofferIndex - 1]);
            }

            if (this._currentPreofferIndex < this._preOffers.length)
                this.waitForOffers();
            else
                this.isLoading = false;

        }, timeout);
    }

    private getWaitTimeout() {
        return Math.floor(Math.random() * 1500);
    }
}
