import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';

// Сервисы
import { LazyLoaderService } from './modules/lazy-loader/lazy-loader.service';
import { ApplicationService } from './services';
import { ScriptService } from './modules/osago/services';
import { LoggingService } from './services/loggingService';

@Component({
    selector: 'app-insapp-osago-V2',
    templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {
    @ViewChild('lazyLoaderContainer', { read: ViewContainerRef, static: false })
    public container: ViewContainerRef | null = null;
    public title = 'osago';
    public isIntro = true;

    constructor(
        private loader: LazyLoaderService,
        private appService: ApplicationService,
        private loggingService: LoggingService,
        private scriptService: ScriptService) {
        if (window.top !== window.self) {
            appService.isInFrame = true;
        }

        // Загрузка скриптов suggestion, jqiery
        // scriptService.load();

        // Загрузка при иницаиализации приложения
        loggingService.trace('AppComponent', 'ctor', 'url = ' + this.getCurrentUrl());
        loggingService.trace('AppComponent', 'ctor', 'calling appService.load()');
        appService.load();

        appService.isIntro$.subscribe((value) => {
            if (value != null) {
                this.isIntro = value;
            }
        });
    }

    private getCurrentUrl(): string {
        try {
            return window.location.href;
        } catch {
            return '';
        }
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngAfterViewInit(): void {
        if (this.appService.isSuccess || this.appService.isFail) { return; }

        if (this.container != null) {
            this.container.clear();
            this.loggingService.trace('AppComponent', 'ngAfterViewInit', 'calling this.loader.load()');
            this.loader.load('startup', this.container);
        }
    }
    // --------------------------------------------------------------------------
}
