import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { routes } from "../routes";
import { ApplicationService } from "../../../services";


@Injectable()
export class NavigationGuard implements CanActivate, CanActivateChild {
 
    constructor (
        private appService: ApplicationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
            return this.checkUrl(state.url.substr(1));
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
            return this.checkUrl(state.url.substr(1));
    }

    private checkUrl(url: string): boolean {
        if (this.appService.isCompleted)
            return false;
            
        switch (url) {
            case "":
            case routes.vendors:
            case routes.drivers:
            case routes.policy:
            case routes.price:
            case routes.owner:
            case routes.insurer:
            case routes.diagnostic:
            case routes.documents:
            case routes.contacts:
                return !this.appService.contactsComplete;
            /* case routes.confirmation:
                return !this.appService.confirmationComplete; */
        }

        return true;
    }
}