import { Component } from "@angular/core";
import { OsagoService } from "../../services/osago.service";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { ClientService } from "../../../../services/client.service";
import { finalize } from "rxjs/operators";
import { ApplicationService } from "../../../../services";

@Component({
    templateUrl: './payment-link.component.html'
})

export class PaymentLinkComponent {
    public clientEmail = "";
    public clientPhone = "";
    public isCopied = false;
    public isSent = false;
    public isSending = false;
    public isSendError = false;
    public optionSelected = true;
    public form: FormGroup;

    constructor(
        private readonly fb: FormBuilder,
        private readonly osagoService: OsagoService,
        private readonly clientService: ClientService,
        private readonly appService: ApplicationService
    ) {
        var osago = this.clientService.getCurrentApplication();
        this.clientEmail = osago?.owner?.email || osago?.insured?.email || "";
        this.clientPhone = this.initPhoneNumber(osago?.owner?.phone || osago?.insured?.phone);

        this.form = this.fb.group({
            email: [true, {updateOn: 'change'}, ],
            phone: [true, {updateOn: 'change'}, ],
        });
          
        this.form.valueChanges.subscribe((currentValue) => {
            this.optionSelected = currentValue.email === true || currentValue.phone === true;
        });
    }

    get email(): AbstractControl | null{
        return this.form?.get('email') || null;
    }

    get phone(): AbstractControl | null{
        return this.form.get('phone') || null;
    }

    public copyLink() {
        navigator.clipboard.writeText(this.osagoService.paymentLink || this.appService.payUrl || '');
        this.isCopied = true;
    }

    public send() {
        this.isSending = true;
        this.isSendError = false;
        const sendEmail = this.form.value.email;
        const sendSms = this.form.value.phone;

        this.osagoService.sendPaymentLink(sendEmail, sendSms)
            .pipe(
                finalize(() => this.isSending = false)
            )
            .subscribe({
                next: (res) => {
                    this.isSent = res.result == true;
                    this.isSendError = res.result != true
                }, 
                error: () => this.isSendError = true
            });
    }
    
    private initPhoneNumber(phoneNumber: string | undefined): string {
        try {
            const maskedPhone = phoneNumber?.toString();
            if (maskedPhone != null && maskedPhone.length === 11) {
                return `+7(${ maskedPhone.substr(1, 3) })${ maskedPhone.substr(4, 3) }-${ maskedPhone.substr(7, 2) }-${ maskedPhone.substr(9, 2) }`; }

            return "";
        }
        catch {
            return "";
        }
    }
}