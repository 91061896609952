import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseFormComponent } from '../base/base-form.component';
import * as moment from 'moment-mini';

// window.jquery
declare var $: any;

@Component({
    selector: 'app-input-date',
    templateUrl: './input-date.component.html'
})
export class InputDateComponent extends BaseFormComponent implements AfterViewInit {
    cssClass = 'widget-input';
    @Input() showError = false;
    @Input() changeYear = false;
    @Input() checkFill = false;
    @Input() showIcon = true;
    @Input() minDate: string | null = null;
    @Input() maxDate: string | null = null;

    @Input() set modifier(value: string | undefined) {
        this.cssClass = 'widget-input ' + (value || 'widget-input-calendar');
    }

    @Output() changed = new EventEmitter<string>();
    @ViewChild('mask') maskField: ElementRef | null = null;
    public dateMask = [ /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/ ];
    private datepicker: any = null;
    private _displayValue: string | null = null;

    ngAfterViewInit() {
        if (this.fieldId) {
            const selector = '#' + this.fieldId + '-datepicker';
            // this.datepicker = $(selector);

            // this.datepicker?.datepicker({
            //     dateFormat: 'dd.mm.yy',
            //     showAnim: 'fadeIn',
            //     buttonText: 'Выберите дату'
            // });

            // this.datepicker?.on('input change', (e: any) => {
            //     if (this.maskField != null ) {
            //         this.maskField.nativeElement.value = e.target.value;
            //         this.setFieldValue(e.target.value);
            //         this.changed.emit(e.target.value);
            //     }
            // });

            this.checkFormValue();

            if (this.minDate != null) {
                // this.datepicker?.datepicker( 'option', 'minDate', this.minDate);
            }

            if (this.maxDate != null) {
                // this.datepicker?.datepicker( 'option', 'maxDate', this.maxDate);
            }

            if (this.changeYear) {
                // this.datepicker?.datepicker( 'option', 'changeYear', true);
                // this.datepicker?.datepicker( 'option', 'yearRange', '' + (moment().year() - 100) + ':' + (moment().year()) );
            }


        }

    }

    public get displayValue() {
        return this._displayValue;
    }

    public onMaskChanged(event: any) {
        this._displayValue = event.target.value;
        const date = this.parseDate(this._displayValue || '');
        this.setFieldValue(date || '');
        if (date != null) {
            // this.datepicker?.datepicker('setDate', date);
            this.changed.emit(this._displayValue || '');
        }
    }

    // Открыть календарь
    public open(): void {
        // this.datepicker?.datepicker('show');
    }

    public checkFormValue() {
        if (this.fieldControl != null) {
            let date = this.parseDate(this.fieldControl.value, 'YYYY-MM-DD');
            if (date == null) {
                date = this.parseDate(this.fieldControl.value, 'DD.MM.YYYY');
            }

            if (date != null) {
                // this.datepicker?.datepicker('setDate', date);
                if (this.maskField != null ) {
                    this.maskField.nativeElement.value = date;
                }
            } else if (this.maskField != null) {
                this.maskField.nativeElement.value = '';
 }

			         this.checkFilled();
        }
    }

    public checkFilled() {
        if (this.maskField != null) {
            this._isFilled = this.maskField.nativeElement.value != null && this.maskField.nativeElement.value.length > 0;
        }
    }

    private parseDate(value: string, inputFormat = 'DD.MM.YYYY', outputFormat = 'DD.MM.YYYY'): string | null {
        const t = moment(value, inputFormat, true);
        return t.isValid()
            ? t.format(outputFormat)
            : null;
    }

    private setFieldValue(value: string) {
        if (this.fieldControl != null) {
            const date = this.parseDate(value, 'DD.MM.YYYY', 'YYYY-MM-DD');
            this.fieldControl.setValue(date || '');
        }

		      this.checkFilled();
    }
}
