import { Directive, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormsService } from '../../../services/forms.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';

@Directive()
export class BaseFormComponent extends BaseComponent {
    @Input() fieldControl: FormControl | AbstractControl | any;
    @Input() isHidden = false;

    protected _isFilled: boolean = false;

    constructor(
        private formsHelper: FormsService
    ) {
        super();
    }

    public get errorMessage(): string {
        return this.formsHelper.errorMessage(this.fieldControl) || '';
    }

    public get isFilled(): boolean {
        // ---for forms with updateOn="submit"
        // if (this.checkHtml) {
        //     this.checkHtmlElement();
        // }
        // ---for forms with updateOn="submit"

        return this._htmlElement === null
            ? !this.formsHelper.isFieldEmpty(this.fieldControl)
            : this._isFilled;
    }

    public get isSelected(): boolean {
        return this.isFilled;
    }

    public get isError(): boolean {
        return this.formsHelper.isFieldInvalid(this.fieldControl);
    }

    public checkFilled(): void {
        if (this._htmlElement != null) {
            this._isFilled = this._htmlElement.value != null && this._htmlElement.value.length > 0;
        }
    }

    protected updateFieldValue(value: any): void {
        this.formsHelper.setFieldValue(this.fieldControl, value);
    }

    private checkHtmlElement(): void {
        if (this._htmlElement === null) {
            this._htmlElement = document.getElementById(this.fieldId);
            if (this._htmlElement != null) {
                this._isFilled = this._htmlElement.value != null && this._htmlElement.value.length > 0;

                this._htmlElement.addEventListener('change', (event: any) => {
                    const value = event.target.value;
                    this._isFilled = value != null && value.length > 0;
                });

                this._htmlElement.addEventListener('blur', (event: any) => {
                    const value = event.target.value;
                    this._isFilled = value != null && value.length > 0;
                });

                window.addEventListener('insapp.kasko.valuecheck', (ev) => {
                    const value = this._htmlElement.value;
                    this._isFilled = value != null && value.length > 0;
                });

                if (this.adjustPlaceholder != null) {
                    this._htmlElement.addEventListener('focus', () => window.setTimeout(() => {
                        const value = this._htmlElement.value;
                        if (value != null && value.length > 0) {
                            const position = this.getCaretPosition(this._htmlElement);
                            const minPosition = value.indexOf(this.adjustPlaceholder);
                            if (position != null && minPosition < position) {
                                this.setCaretPosition(this._htmlElement, minPosition);
                            }
                        }
                    }, 100));
                }
            }
        }
    }
}
