export class FioHelper {
    static toInitials(value: string, firstName: string | null = null, middlename: string | null = null): string | null {
        if (value == null) {
            return null;
        }

        if (firstName != null && middlename === null && firstName.length > 0) {
            return value + ' ' + firstName.substr(0, 1) + '.';
        }

        if (firstName != null && middlename != null && firstName.length > 0 && middlename.length > 0) {
            return value + ' ' + firstName.substr(0, 1) + '. ' + middlename.substr(0, 1) + '.';
        }

        const firstSpaceindex = value.indexOf(' ');
        if (firstSpaceindex === -1) {
            return value;
        }

        const secondSpaceindex = value.indexOf(' ', firstSpaceindex + 1);
        if (secondSpaceindex === -1) {
            return value.substr(0, firstSpaceindex + 1) + value.substr(firstSpaceindex + 1, 1) + '.';
        }

        return value.substr(0, firstSpaceindex + 1) + value.substr(firstSpaceindex + 1, 1) + '. ' + value.substr(secondSpaceindex + 1, 1) + '.';
    }
}
