import { ApiRequest } from './apiRequest.model';

export class GetBrandModelByNameRequest extends ApiRequest {
    constructor(
        public apiKey: string,
        public partnerBrandName: string,
        public partnerModelName?: string
    ) {
        super(apiKey);
    }
}